import React, { useState, useEffect, useRef, useMemo } from "react";
import { Box, Typography, Divider, Button, Card, Stack, Autocomplete, TextField, InputAdornment, IconButton, Paper, Popover, List, ListItem, ListItemText } from "@mui/material";
import { styled } from "@mui/system";
import sections from "./helpSections";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone';
import BugReportTwoToneIcon from '@mui/icons-material/BugReportTwoTone';
import { useAuth0 } from "@auth0/auth0-react";
import BugReportDialog from "src/components/modal/Support/BugReport/BugReportDialog";
import { useAuth } from "src/context/AuthContext";
import CustomerServiceDialog from "./Components/CustomerService/CustomerServiceDialog";
import SupportIcon from 'src/public/assets/icons/icons/support.png'
import BugIcon from 'src/public/assets/icons/icons/bug.png'
import TipsIcon from 'src/public/assets/icons/icons/tips.png'
import TipsDialog from './Components/CustomerService/tipsDialog/TipsDialog'


const HelpCenter = () => {
  const { user, isAuthenticated } = useAuth0();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showBugDialog, setShowBugDialog] = useState(false);
  const [showCustomerServiceDialog, setShowCustomerServiceDialog] = useState(false);
  const [showTipsDialog, setShowTipsDialog] = useState(false)
  const questionRefs = useRef([]);
  const {subUserData} = useAuth();
  const [activeStep, setActiveStep] = useState(0);



  const handleOpenBugDialog = () => {
    setShowBugDialog(true);
  }

  const handleCloseBugDialog = () => {
    setShowBugDialog(false);
  }

  const handleOpenCustomerServiceDialog = () => {
    setActiveStep(0)
    setShowCustomerServiceDialog(true)
  }


  const handleCloseCustomerServiceDialog = () => {
    setShowCustomerServiceDialog(false)
  }

  const handleOpenTipsDialog = () => {
    setShowTipsDialog(true)
  }

  const handleCloseTipsDialog = () => {
    setShowTipsDialog(false)
  }

  // Define content for each topic
  const refs = useRef({});
  useEffect(() => {
    refs.current = {};
  }, []);


  
  const [showHelpCenter, setShowHelpCenter] = useState(false);

  const cardData = [
    { icon: <img src={SupportIcon} style={{ height:'49px', width:'auto', justifyContent:'center', alignSelf:'center', padding:.25 }} />, text: "Contact Support" },
    {  icon: <img src={TipsIcon} style={{ height:'49px', width:'auto', color: "#7FA1B2", justifyContent:'center', alignSelf:'center', padding:.25 }} />, text: "Tips & Tricks" },
    { icon: <img src={BugIcon} style={{ height:'49px', width:'auto', color: "#7FA1B2", justifyContent:'center', alignSelf:'center', padding:.55 }} />, text: "Report A Bug" },
  ];


  const handleCardClick = (index, text) => {
    if (text === "Report A Bug") {
      handleOpenBugDialog(); // Trigger the dialog when "Report A Bug" is clicked
    }
    if (text === "Contact Support") {
      handleOpenCustomerServiceDialog();
    }
    if (text === "Tips & Tricks") {
      handleOpenTipsDialog();
    }
  };





  const quickQuestionData = [
    {  question: "How can I upgrade my account type?", 
  answer: "You can upgrade your account at anytime in the Billing section in your Account Settings page" },
    { question: "How can I change my subscription plan?",
  answer:"Your subscription plan is automatically configured based on your company size and team members. For more information on your subscription, check out 'Your Monthly Plan' in the billing page"
  },
    {  question: "How can I reset my password?",
  answer:"You can reset your password from the 'Account Security' panel in the Account Settings section, or by clicking 'Forgot My Password' directly from the login screen",
  },
    { question: "How can I schedule a notification to send at a later time?",
        answer:"You can schedule a notification to send exactly at the moment you need it too by clicking the 'more' button in the new notification window, then selecting a date and time for when you'd like it to send",
    },
    {  question: "What are two-way notifications?",
      answer:"Two-way notifications allow your customers to respond back to your notifications for things like approval or confirming an action. Your customers can simply respond 'yes or 'no' and you will be alerted when they do so",
    },
    {  question: "How can I manually update a two-way notification?",
      answer:"You can manually update the status of a two-way notification by clicking on the row, then selecting the corresponding status you need to manually update. Your customer will no longer be able to respond once a status has been manually updated",
    },
    {  question: "How many different notification types can I create?",
      answer:"NanoNotify supports up to 5 custom notifications at this time. We plan to increase that amount as demand increases in the future",
    },
    {  question: "How do I report a bug?",
      answer:"If something doesnt seem to be working the way it should you can let us know by the clicking 'Report a Bug' button from the Support Page ",
    },
    {  question: "How can I verify if a notification has been delivered?",
      answer:"Your notification will have a green, red or grey icon next to the date and time it was sent. These icons indicate if the notification is still sending, has been delivered or has failed to send",
    },
    {question: "What do the different field types mean when I create a new notification type?",
      answer:"There are currently 5 different types. Currency, date, time, date/time and length of time. Each field will automatically format the data for you, just enter what you need and we'll handle the rest ",
    }
  ];




  const [searchTerm, setSearchTerm] = useState(""); // Track the user's input
  const [filteredQuestions, setFilteredQuestions] = useState([]); // Filtered questions based on input

  // Update the search term and filter the questions
  const handleSearchChange = (event, value) => {
    setSearchTerm(value);
    if (value) {
      setFilteredQuestions(
        quickQuestionData.filter((item) =>
          item.question.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredQuestions([]); // Keep this empty for now; we're going to show all questions.
    }
  };
  
  // When a user clicks on a question
  const handleQuestionClick = (value) => {
    if (!value) return; 
  
    const index = quickQuestionData.findIndex((q) => q.question === value);
    if (index >= 0 && questionRefs.current[index]) {
      const element = questionRefs.current[index];
  
      const scrollContainer = element.closest('.scroll-container'); // Adjust this selector to your needs
  
      if (scrollContainer) {
        scrollContainer.scroll({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      } else {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  
    // Clear the input value to close the dropdown
    setSearchTerm("");  
    setFilteredQuestions([])
  };


  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scrollbar
    return () => {
      document.body.style.overflow = ""; // Re-enable scrollbar on component unmount
    };
  }, []);

  
  return (
<Box
  sx={{
    width: "auto",
    mx: "4px",
    borderRadius: "10px",
    overflowY: "hidden",
    backgroundColor: "#FFFFFF",
    boxShadow:
      "0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)",
    mt: -0.5,
    position:'sticky'

  }}
>
  {/* Fixed Header Section */}
{/* Fixed Header Section */}
<Box
  sx={{

    background: "#f2f7f9",
    width: "100%",
    textAlign: "center",
    padding: "2%",
    flexShrink: 0,
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Optional: Add a shadow for better visibility
    position:'sticky',
    zIndex:1000
  }}
>
  <Typography
    sx={{
      fontSize: 27.5,
      fontWeight: 550,
      color: "#48768C",
      letterSpacing: -1.7,
      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.01)",
      mb: 3,
      lineHeight: -.02,
      wordSpacing: "3.5px",
      pb: 0,
      py: 1,
      pt:1
    }}
  >
    Hi {subUserData?.first_name}, How Can We Help?
  </Typography>

  <Box sx={{ display: "flex", justifyContent: "center", width: "100%", mb: 7 }}>
  <Paper
  elevation={8}
  sx={{
    width: "70%",
    borderRadius: "28px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}
>
  <Autocomplete
    sx={{ width: "100%" }}
    value={searchTerm}
    onInputChange={handleSearchChange} // Update the search term as the input changes
    inputValue={searchTerm}
    options={filteredQuestions.map((q) => q.question)} // Set options as the filtered questions
    freeSolo // Allows for custom input
    onChange={(event, value) => handleQuestionClick(value)} // When the user selects an option
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        placeholder="Enter Your Search Terms..."
        sx={{
          width: "100%",
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            height: 55,
            borderRadius: 8,
            fontSize: "14px",
            display: "flex",
            alignItems: "center", // Ensures vertical centering
     
          },
          "& .MuiInputBase-input": { height: "17px", padding: "10px", width: "100%", },
          "& .MuiInputAdornment-root": {
            display: "flex",
            alignItems: "center", // Centers icons inside adornments
            padding:0,
            padding:0,
            margin:0
          },
        }}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="start" sx={{ display: "flex", alignItems: "center" }}>
              <SearchIcon sx={{ color: "#6595ab", fontSize: 34 }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" sx={{ display: "flex", alignItems: "center", }}>
            
                <ArrowForwardIcon
                  sx={{
                    color: "#FFFFFF",
                    border: ".5px solid #48768C",
                    borderRadius: "50%",
                    fontSize: "29px",
                    background: "#6595ab",
                    opacity: 0.7,
                    mr:-3.25
                  }}
                />
      
            </InputAdornment>
          ),
        }}
      />
    )}
    renderOption={(props, option) => (
      <ListItem {...props} button onClick={() => handleQuestionClick(option)}>
        <ListItemText sx={{color:'#48768C', fontWeight:600}} primary={option} />
      </ListItem>
    )}
  />
</Paper>
  </Box>
</Box>




  <Box
    sx={{
     
      padding: "2%",
      maxHeight: "calc(100vh - 409px)", // Adjust based on header + quick answers height
      pb:10,
      overflowY:'auto'
      
    }}
  >




    {/* Quick Answer Section (Fixed) */}
    <Box sx={{ position: "sticky", bottom: 0, backgroundColor: "white", zIndex: 10,     px:8, }}>
    <Typography
    className="scroll-container"
    align="center"
    sx={{
      padding: "15px",
      color: "#48768C",
      fontSize: 21.5,
      width: "100%",
      letterSpacing: -1.09,
      wordSpacing: 2.5,
      pt:2,
      pb:5
    }}
  >
    Or <strong>Browse</strong> categories
  </Typography>
    <Stack
      direction="row"
      spacing={5}
      sx={{ justifyContent: "center", width: "50%", py: 1, ml:'auto', mr:'auto',  }}
    >



{cardData.map(({ icon, text }, index) => (
  <Card
    key={index}
    onClick={() => handleCardClick(index, text)} // Pass index and text to the click handler

    sx={{
      maxWidth: 220,
      maxHeight: 200,
      minWidth: 220,
      minHeight: 200,
      p: "2%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
      background: '#FFFFFF',
      background: 'linear-gradient(315deg, #FFFFFF, #F7FDFF)',
      border: ".5px solid #97bac9",
      transition: "all 0.3s ease",  // Smooth transition for all properties
      cursor:'pointer',
      "&:hover": {
        transform: "scale(1.02)", // Slightly increase the size of the card
        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)", // Add a stronger shadow
      },
    }}
  >
    <Stack
      direction="column"
      spacing={2}
      sx={{ width: "100%", alignItems: "center" }}
    >
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "65px",
          height: "65px", // Adjust based on your need
          background: "#FFFFFF", // White background for the icon container
          border: ".5px solid #c2d7df",
          transition: "all 0.3s ease", // Smooth transition for the icon
        }}
      >
        {icon}
      </Paper>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: 20,
          fontWeight: "bold",
          letterSpacing: "-0.02em",
          color: "#48768C",
        }}
      >
        {text}
      </Typography>
    </Stack>
  </Card>
))}
    </Stack>
      <Paper elevation={0}>
        <Typography
          align="center"
          sx={{
            padding: "15px",
            color: "#48768C",
            fontSize: 22,
            width: "100%",
            letterSpacing: -1.09,
            wordSpacing: 2.5,
            pt:8,
            pb:5,
            fontWeight:550
          }}
        >
          Quick Answers
        </Typography>
        {quickQuestionData.map(({ icon, question, answer }, index) => (
     <Box id="scrollable-content" sx={{ }}
        key={index}
        ref={(el) => (questionRefs.current[index] = el)}
       
      >
        <Stack
          direction="column"
          spacing={5}
          sx={{ justifyContent: "center", width: "100%", py: 1, alignItems: "center", pb: 10 }}
        >
          <Card
            sx={{
              width: 630,
              minHeight: 200,
              p: "2%",
              display: "flex",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Stack direction="column" spacing={1} sx={{ width: "100%", alignItems: "left" }}>
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: 20,
                  fontWeight: "bold",
                  letterSpacing: "-0.02em",
                  color: "#48768C",
                  mb: "auto",
                  pt: 1,
                }}
              >
                {question}
              </Typography>
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: 17,
                  fontWeight: 550,
                  letterSpacing: "-0.02em",
                  color: "#6595ab",
                  mb: "auto",
                  pt: 0.25,
                  width: "80%",
                }}
              >
                {answer}
              </Typography>
            </Stack>
          </Card>
        </Stack>
      </Box>
    ))}
  


      </Paper>
    </Box>
  </Box>
  <BugReportDialog
  isOpen={showBugDialog}
  onClose={handleCloseBugDialog}
  />
  <CustomerServiceDialog
  isOpen={showCustomerServiceDialog}
  onClose={handleCloseCustomerServiceDialog}  
  activeStep={activeStep}
  setActiveStep={setActiveStep}
  />
  <TipsDialog
  open={showTipsDialog}
  onClose={handleCloseTipsDialog}
  handleClose={handleCloseTipsDialog}
  />
</Box>
  );
};

export default HelpCenter;