import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Button,
  Box,
  Modal,
  IconButton,
  Skeleton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Fade,
  Divider, 
  Paper,
  TextField, 
} from "@mui/material";
import PauseIcon from "src/public/assets/icons/icons/pause.png";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckIcon from 'src/public/assets/icons/icons/check.png';
import DisabledCheckIcon from 'src/public/assets/icons/icons/disabledCheck.png';
import { Transition } from "react-transition-group";
import { useAuth0 } from '@auth0/auth0-react';







//Pass icons as props to render different icons 
//Set accept to true to render confirm dialog with accept icon
//Set accept to false to render confirm dialog with decline icon

const DeleteAccountModal = ({
  open,
  onClose,
  subscriptionStatus,
  accountData,
  customerId, 
  userId,




}) => {

const apiUrl = process.env.REACT_APP_API_URL; // Get the API URL from environment variables
const { getAccessTokenSilently, logout } = useAuth0(); // Hook to get the access token
const [loading, setLoading] = useState(false); // State to manage loading state


const deleteAccount = async (userId, customerId) => {
  setLoading(true); // Set loading to true when starting the deletion process

  try {
    // Get the access token
    const token = await getAccessTokenSilently();

    // Make a DELETE request to the server with userId and customerId in the request body
    const response = await fetch(`${apiUrl}/accounts/delete-account`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include the access token in the Authorization header
      },
      body: JSON.stringify({ userId, customerId }), // Send userId and customerId in the request body
    });

    const data = await response.json(); // Parse the response JSON

    if (!response.ok) {
      throw new Error(data.message || 'Failed to delete account');
    }

    // Handle success
    setDeleteSuccess(true);
    localStorage.clear();
    sessionStorage.clear();
  } catch (error) {
    console.error('Error deleting account:', error.message);
  } finally {
    setLoading(false); // Ensure loading is set to false in both success and error cases
  }
};

const [showConfirmDelete, setShowConfirmDelete] = useState(false);  
const [deleteConfirmation, setDeleteConfirmation] = useState(''); // State to hold the delete confirmation input
const [showIcon, setShowIcon] = useState(false);
const [deleteSuccess, setDeleteSuccess] = useState(false);  



const handleShowConfirmDelete = () => { 
  if (deleteConfirmation === 'DELETE') {
    deleteAccount(userId, customerId)
  }
  if (deleteConfirmation !== 'DELETE') {
  setShowConfirmDelete(true);
  }
}

const handleClearField = () => {  
  setDeleteConfirmation('');
  setShowConfirmDelete(false);
}



  // Define transition styles for easing in/out
  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  useEffect(() => { 
    if (open) {
      setShowConfirmDelete(false);
      setDeleteConfirmation('');
      setDeleteSuccess(false);
      setLoading(false); // Reset loading state when modal opens
    }
  }, [open]); 

  const handleSignOut = () => {
    // Clear Auth0 session (logs the user out)
    logout({
      returnTo: "http://www.nanonotify.io", // Redirect to NanoNotify home page after logout
    });
  };




    return (
      <Dialog open={open} onClose={deleteSuccess ? (e) => e.stopPropagation() : onClose} 
      
      TransitionComponent={Fade} transitionDuration={300} PaperProps={{ sx: { borderRadius: '10px', width: '100%', 
      
      minWidth: '350px', maxWidth: '980px', 
      
      flexGrow: 1 } }}
      disableEscapeKeyDown={deleteSuccess} // Disable the escape key when deleteSuccess is true

      >
        <Box sx={{ width: '100%', borderRadius: '15px', overflowY: 'auto', px: 2, pt: 2, pb: 0 }}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 4, top: 7, color: '#7589A2', borderRadius: '12px', width: '35px', height: '35px', p: '3px', display: deleteSuccess ? "none" : "block"  }}
          >
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
  
          <Box sx={{ flexGrow: 1, width: '100%', flexShrink:1 }}>
            <Stack direction="row" sx={{ width: '100%', flexWrap: 'nowrap', pb: 3, mb: 1, overflowY: 'auto' }}>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', overflowX: 'auto', p: 2 }}>
                <Stack direction="column" sx={{display: deleteSuccess ? "none" : "block" }}>
                  <Typography sx={{ fontSize: '22px', fontWeight: 550, color: '#48768C', pl: 0.5, letterSpacing: '-0.2px', lineHeight: '-2' }}>
                    We’re sorry to see you go!
                  </Typography>
                  <Typography sx={{ fontSize: '15px', fontWeight: 550, color: '#6c90a2', mb: 1, width: '90%', pl: 0.5 }}>
                    We’ve loved having you! Need help? Contact support before you decide to cancel.
                  </Typography>
                  <Divider sx={{ borderBottomWidth: '1.5px', mt: 0.25, width: '98%', ml: 0.5 }} />
                </Stack>


<Box sx={{display: deleteSuccess ? 'none' : 'block' }}>
  <React.Fragment>
    <Fade in={!showConfirmDelete} timeout={400}>
                  <Box sx={{ mt: 1.5, flexDirection: 'column', ml: 0.5, display: !showConfirmDelete ? 'flex' : 'none', }}>
                    <Typography sx={{ fontSize: '18.5px', fontWeight: 550, color: '#48768C', mb: 1.5, letterSpacing: '-0.2px', lineHeight: '-2' }}>
                      Deleting your account is permanent and cannot be undone. This will:
                    </Typography>
  
                    <Box sx={{display:'flex',  flexDirection:'column', width:'100%', borderRadius:'10px', backgroundColor:'#F0F4F8', mb:2, px:1, ml:-.5, gap:1, py:2}}>
            
           
           {subscriptionStatus === "active" && (
            <Stack direction="row" sx={{ width: "100%", justifyContent: "flex-start", alignItems: "center",  }}>
            <img src={CheckIcon} alt="check" style={{ width: "31px", height: "31px", marginRight: "5px" }} />

              <Typography sx={{ fontSize: "16.5px", fontWeight: 550, color: "#48768C",       letterSpacing: "-.2px",
      lineHeight: "-2", }}>
                Cancel your active subscription
              </Typography>
              </Stack>
              )}
                           <Stack direction="row" sx={{ width: "100%", justifyContent: "flex-start", alignItems: "center",  }}>
              <img src={CheckIcon} alt="check" style={{ width: "31px", height: "31px", marginRight: "5px" }} />
              <Typography sx={{ fontSize: "16.5px", fontWeight: 550, color: "#48768C",       letterSpacing: "-.2px",
      lineHeight: "-2", }}>
              Permanently delete your entire notification history 
              </Typography>
              </Stack>
             
              <Stack direction="row" sx={{ width: "100%", justifyContent: "flex-start", alignItems: "center",  }}>
              <img src={CheckIcon} alt="check" style={{ width: "31px", height: "31px", marginRight: "5px" }} />
              <Typography sx={{ fontSize: "16.5px", fontWeight: 550, color: "#48768C",       letterSpacing: "-.2px",
      lineHeight: "-2", }}>
                Delete your company and location info immediately
              </Typography>
              </Stack>

              {accountData?.total_employees >= 2 && (
              <Stack direction="row" sx={{ width: "100%", justifyContent: "flex-start", alignItems: "center",  }}>
              <img src={CheckIcon} alt="check" style={{ width: "31px", height: "31px", marginRight: "5px" }} />
              <Typography sx={{ fontSize: "16.5px", fontWeight: 550, color: "#48768C",       letterSpacing: "-.2px",
      lineHeight: "-2", }}>
                Delete all users associated with your account immediately
              </Typography>
              </Stack>
                )}

{accountData?.total_locations >= 2 && (
              <Stack direction="row" sx={{ width: "100%", justifyContent: "flex-start", alignItems: "center", }}>
              <img src={CheckIcon} alt="check" style={{ width: "31px", height: "31px", marginRight: "5px" }} />
              <Typography sx={{ fontSize: "16.5px", fontWeight: 550, color: "#48768C",       letterSpacing: "-.2px",
      lineHeight: "-2", }}>
                Delete all locations associated with your account immediately
              </Typography>
              </Stack>
              )}
              <Stack direction="row" sx={{ width: "100%", justifyContent: "flex-start", alignItems: "center",  }}>
              <img src={CheckIcon} alt="check" style={{ width: "31px", height: "31px", marginRight: "5px" }} />
              <Typography sx={{ fontSize: "16.5px", fontWeight: 550, color: "#48768C",       letterSpacing: "-.2px",
      lineHeight: "-2", }}>
              Prevent customers from responding to your active two-way notifications you may have sent
              </Typography>
              </Stack>
 


</Box>  
  
                    <Typography sx={{ fontSize: '17.5px', fontWeight: 550, color: '#48768C', width: '80%', pt: 0.25, letterSpacing: '-0.2px', lineHeight: '-2', px: 0.5 }}>
                      If you need assistance or have any concerns, please contact support before continuing.
                    </Typography>
  
                    <Typography sx={{ fontSize: '17.5px', fontWeight: 550, color: '#48768C', mt: 1, letterSpacing: '-0.2px', lineHeight: '-2', px: 0.5, mb:2.5 }}>
                      Are you sure you want to delete your account?
                    </Typography>
                  </Box>
                  </Fade>
  </React.Fragment>


  <React.Fragment>
    <Fade in={showConfirmDelete} timeout={400}>
      
                                     <Box sx={{display: !showConfirmDelete ? 'none' : 'flex',  flexDirection:'column', width:'100%', borderRadius:'10px', backgroundColor:'#F0F4F8', mb:4, px:1, ml:-.5, gap:1, py:3, mt:4}}>

                    <Typography sx={{ fontSize: '18px', fontWeight: 550, color: '#48768C', mb: 1.5, letterSpacing: '-0.035em', lineHeight: '-2em' }}>
                      Please confirm that you want to delete your account:
                    </Typography>
                    <Typography sx={{ fontSize: '16.5px', fontWeight: 550, color: '#8c4350', mb: 1.5, letterSpacing: '-0.025em', lineHeight: '-2em' }}>
                     Type "DELETE" in the field below to confirm:
                    </Typography>
                    <TextField
  variant="outlined"
  fullWidth
  value={deleteConfirmation}
  onChange={(e) => setDeleteConfirmation(e.target.value)}
  sx={{
    mb: 2,
    backgroundColor: "#fff",
    borderRadius: "5px",
    width: "55%",
    
  }}
  InputProps={{
    endAdornment: (
      <Transition in={deleteConfirmation !== ""} timeout={200}>
        {(state) => (
          <IconButton
            onClick={handleClearField}
            sx={{
              color: "#48768C",
              "&:hover": { color: "#bf7588" },
              opacity: state === "entered" ? 1 : 0, // Fade in/out based on the state
              transition: "opacity 200ms ease-in-out", // Ease-in/out transition
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Transition>
    ),
    startAdornment: (
      <>
        <img
          src={CheckIcon}
          style={{
            width: "34px",
            height: "34px",
            marginRight: "5px",
            position: "absolute", // Prevents the icon from affecting layout flow
            opacity: deleteConfirmation === "DELETE" ? 1 : 0, // Fade in the check icon when DELETE is typed
            transition: "opacity 200ms ease-in-out",
          }}
        />
    
        <img
          src={DisabledCheckIcon}
          style={{
            width: "34px",
            height: "34px",
            marginRight: "5px",
            position: "absolute", // Prevents the icon from affecting layout flow
            opacity: deleteConfirmation === "DELETE" ? 0 : 1, // Fade in the disabled check icon when DELETE is not typed
            transition: "opacity 200ms ease-in-out",
          }}
        />
      </>
    ),
    inputProps: {
      style: {
        paddingLeft: deleteConfirmation === "DELETE" ? "40px" : "40px", // Adjust padding based on icon visibility
      },
    },
  }}
  InputLabelProps={{
    shrink: true,
  }}
  error={deleteConfirmation !== "DELETE"}
/>
                    </Box>
                    </Fade>
  </React.Fragment>
  </Box>
             
  
                <Box sx={{ width: '90%', display: 'flex', mb: 1,  }}>
                  <Button
                  onClick={handleShowConfirmDelete}
                  disabled={showConfirmDelete && deleteConfirmation !== 'DELETE' || loading}
                    sx={{
                      fontSize: '18.5px',
                      fontWeight: 550,
                      color: '#bf7588',
                      border: '1px solid #bf7588',
                      borderRadius: '6px',
                      py: 1.25,
                      px: 1,
                      width: '250px',
                      mt: 0,
                      mb: -2,
                      mr: 'auto',
                      letterSpacing: '-0.015em',
                      backgroundColor: '#8C43501A',
                      display: deleteSuccess ? 'none' : 'flex',
                      ml: 0.25,
                      '&:hover': {
                        backgroundColor: '#8C435026',
                      },
                    }}
                  >
                  { loading ? "Loading...." : "Delete My Account" }
                  </Button>
                </Box>
                <React.Fragment>
    <Fade in={deleteSuccess} timeout={400}>
                                     <Box sx={{display: !deleteSuccess ? 'none' : 'flex',  flexDirection:'column', width:'100%', borderRadius:'10px', backgroundColor:'#F0F4F8', 
                                      mb:0, px:1, ml:-.5, gap:1, py:3, mt:0, alignItems:'center', mb:-2, mt:-.5}}>

                    <Typography sx={{ fontSize: 20, fontWeight: 550, color: '#48768C', mb: 1.5, letterSpacing: '-0.035em', lineHeight: '-2em', justifyContent: 'center', display: 'flex' }}>
                      Your Account Has Been Deleted
                    </Typography>
                    <Typography sx={{ fontSize: 16.5, fontWeight: 550, color: '#48768C', mb: 2, letterSpacing: '-0.025em', lineHeight: '-2em' }}>
                    Thanks for using NanoNotify! 
                    </Typography>
                    <Button
                
                  onClick={handleSignOut}
                    sx={{
                      fontSize: '18.5px',
                      fontWeight: 550,
                      color: '#48768C',
                      border: '1px solid #48768C',
                      borderRadius: '6px',
                      py: 1,
                      px: 1,
                      width: '250px',
                      mt: .5,
                      mb:0,
                      letterSpacing: '-0.01em',
                      backgroundColor: '#FFFFFF',
                      display: deleteSuccess ? 'flex' : 'none',
                      ml: 0.25,
                    
                    }}
                  >
                    Sign Out
                  </Button>
                    </Box>
                    </Fade>
  </React.Fragment>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Dialog>
    );
  };

export default DeleteAccountModal;
