import { sub } from "date-fns";
import { useEffect, useState } from "react";

export const useGeneratePlanDetails = (planDetails, subscriptionDetails) => {
    const [detailOne, setDetailOne] = useState("");
    const [detailTwo, setDetailTwo] = useState("");
    const [detailThree, setDetailThree] = useState("");
    const [detailFour, setDetailFour] = useState("");
    const [billingDetailOne, setBillingDetailOne] = useState("");
    const [billingDetailTwo, setBillingDetailTwo] = useState("");
    const [billingDetailThree, setBillingDetailThree] = useState("");
    const [billingDetailFour, setBillingDetailFour] = useState("");
    const [billingDetailFive, setBillingDetailFive] = useState("");
    const[billingValueOne, setBillingValueOne] = useState("");
    const[billingValueTwo, setBillingValueTwo] = useState("");
    const[billingValueThree, setBillingValueThree] = useState("");
    const[billingValueFour, setBillingValueFour] = useState("");
    const[billingValueFive, setBillingValueFive] = useState("");
    const [planCost, setPlanCost] = useState(0);
    const [totalPlanCost, setTotalPlanCost] = useState(0);
    const [loadingPlanDetails, setLoadingPlanDetails] = useState(true);   
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [totalUserCost, setTotalUserCost] = useState(0);
    const multiUserPlan = planDetails?.plan_name === "Multi User Plan";
    const singleLocationPlan = planDetails?.plan_name === "Single User Plan";
    const multiLocationPlan = planDetails?.plan_name === "Multi Location Plan"; 
    const [discountName, setDiscountName] = useState("");   
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [couponExpires, setCouponExpires] = useState("");
    const [promotion, setPromotion] = useState(false);
  
    




 // New state for discounted plan cost
const [discountedPlanCost, setDiscountedPlanCost] = useState(0);

useEffect(() => {
    if (!planDetails) return; // Ensure planDetails is available
    
    let calculatedTotalUserCost = (planDetails.total_users * 5) - 10; 
    let calculatedTotalLocationCost = (planDetails.total_locations * 10) - 20;  
    let baseTotal = 0; // This will hold the base cost before applying any discounts

    // Phase 1: Calculate base plan cost (before applying any discounts)
    if (multiLocationPlan) {
        setDetailOne("Includes one additional location and user at no extra cost");
        setDetailTwo("Additional locations $10/month");
        setDetailThree("Additional users $5/month");
        setDetailFour("Add or remove locations and users at any time");
        setBillingDetailOne("Total Users:");
        setBillingValueOne(planDetails?.total_users);
        setBillingDetailThree("Total Locations:");
        setPlanCost(20.00);

        setBillingDetailTwo("Additional Monthly User Cost");
        setBillingValueTwo(`$${calculatedTotalUserCost}.00`);
        setBillingValueThree(planDetails?.total_locations);
        setBillingDetailFour("Additional Monthly Location Cost");
        setBillingValueFour(`$${calculatedTotalLocationCost}.00`);
        
        // Calculate base total (without applying any coupon discount)
        baseTotal = 20 + calculatedTotalLocationCost + calculatedTotalUserCost
        
    } else if (singleLocationPlan) {
        setDetailOne("Includes unlimited access for a single location and a single user");
        setDetailTwo("Two-way and scheduled notifications included at no extra cost");
        setDetailThree("Customizable notifications included at no extra cost");
        setPlanCost(5.00);
        setBillingDetailOne("");
        setBillingValueOne("");
        
        // Calculate base total for single location plan
        baseTotal = 5.00;
        
    } else if (multiUserPlan) {
        setDetailOne("Includes an additional user at no extra cost");
        setDetailTwo("Each additional user $5/month");  
        setDetailThree("Add or remove users at any time");
        setPlanCost(10.00);
        setBillingDetailOne("Total Users:");
        setBillingValueOne(planDetails?.total_users);

        setBillingDetailTwo("Additional Monthly User Cost");
        setBillingValueTwo(`$${calculatedTotalUserCost}.00`);
        
        // Calculate base total for multi-user plan
        baseTotal = 10 + calculatedTotalUserCost
    }

    // Phase 2: Apply the discount if a promotion exists
    let totalAfterDiscount = baseTotal;

    // Apply discount only if promotion exists
    if (subscriptionDetails?.promotion && discountPercentage) {
        const discountAmount = (baseTotal * discountPercentage) / 100;
        totalAfterDiscount = baseTotal - discountAmount;
        
        // Set discounted plan cost
        setDiscountedPlanCost("$" + totalAfterDiscount.toFixed(2) + ".00" ); // Save the discounted total
    }

    // Set the total plan cost (base total without discount)
    setTotalPlanCost("$" + baseTotal.toFixed(2)); // Set the base total, without any discount

    // Set the promotion details if available
    if (subscriptionDetails?.promotion) {
        setPromotion(true);
        setDiscountName(subscriptionDetails?.couponName);
        setDiscountPercentage(subscriptionDetails?.discountPercentage);
        setCouponExpires(subscriptionDetails?.couponExpires);
    }
    setSubscriptionStatus(subscriptionDetails?.subscriptionStatus); 
    // Finalize the loading state
    setLoadingPlanDetails(false);



}, [planDetails, subscriptionDetails]); // Re-run when planDetails or subscriptionDetails change






  
    return { detailOne, detailTwo, detailThree, 
        billingDetailOne, billingDetailTwo, billingDetailThree,
        billingDetailFour, billingDetailFive, billingValueOne, billingValueTwo,
        billingValueThree, billingValueFour, billingValueFive,
        
        planCost, totalPlanCost, loadingPlanDetails, subscriptionStatus, totalUserCost, promotion, discountName, discountPercentage, couponExpires, discountedPlanCost,
    
    
    
    
    
    };
  };

