
export const handleSubmit = async ({ values, isSingleLocation, isSingleUser, setButtonDisabled, getAccessTokenSilently, user, setActiveStep, setIsLoading, showSnackBar, handleClose }) => {
  setButtonDisabled(true); // Disable button to prevent duplicate submissions

  const apiUrl = process.env.REACT_APP_API_URL;

  try {
    const accessToken = await getAccessTokenSilently();

    const requestData = {
      name: values.name,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      email: values.email,
      url: values.url,
      locationName: isSingleLocation ? "Default Location" : values.locationName,
      locationPhone: values.locationPhone,
      locationEmail: values.locationEmail,
      newFirstName: values.newFirstName,
      newLastName: values.newLastName,
      newUsername: values.newUsername,
      newPin: isSingleUser ? "" : values.newPin,
      confirmNewPin: isSingleUser ? "" : values.confirmNewPin,
      isSingleLocation,
      isSingleUser,
      userSub: user.sub,
      firstName: user.firstName,
      lastName: user.lastName,
      title: values.title

    };

    const response = await fetch(`${apiUrl}/accounts/setup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();

    if (response.ok) {
      setActiveStep(4); // Move to the next step in the setup process
      localStorage.clear();
      sessionStorage.clear();




      // Handle success (e.g., redirect, show success message, etc.)
    } else {
      console.error("Account setup failed:", data);
      showSnackBar("Unable process your request. Please Try Again.", false);

      // Handle errors (e.g., display error messages)
    }
  } catch (error) {
    console.error("Error during account setup:", error);
  } finally {
    setButtonDisabled(false); // Re-enable button after request completes
    
  }
};