import { useEffect, useState } from "react";
import { Typography, TextField, InputAdornment } from "@mui/material";

const BugDescriptionForm = ({ bugDetails, handleChange }) => {
  const [charCount, setCharCount] = useState(bugDetails.description.length);
  const charLimit = 500;
  const warningThreshold = 25; // Show warning when 50 characters are left

  useEffect(() => {
    setCharCount(bugDetails.description.length);
  }, [bugDetails.description]);

  return (
    <>
      <Typography
        sx={{
          fontSize: "1.15rem",
          fontWeight: 600,
          pb: "5px",
          width: "100%",
          letterSpacing: "-0.029em",
          color: "#48768c",
        }}
      >
        Give us some details about the issue you're experiencing.
      </Typography>

      <Typography
        color="textSecondary"
        sx={{ fontSize: ".93rem", fontWeight: 600, pl: "2px", pb: "10px", mt: "15px", pt: "5px" }}
      >
        Feel free to be as detailed as possible. The more information you provide, the better we can
        work towards resolving the problem.
      </Typography>

      <TextField
        sx={{
          marginBottom: 3.5,
          border: "none",
          "& textarea": {
            minHeight: "50px",
          },
          "& .MuiOutlinedInput-root": {
            border: "none",
            "&:hover": {
              borderColor: "none",
            },
          },
        }}
        placeholder="Describe the problem in detail"
        name="description"
        value={bugDetails.description}
        onChange={handleChange}
        multiline
        fullWidth
        required
        rows={1}
        inputProps={{
          maxLength: charLimit, // Prevents more than 500 characters
        }}
        InputProps={{
          endAdornment: (
            <>
              {charCount > 0 && charCount < 20 && (
                <InputAdornment
                  position="end"
                  sx={{
                    fontSize: "0.75rem",
                    color: "#d32f2f",
                    position: "absolute",
                    bottom: "15px",
                    right: "10px",
                  }}
                >
                  <Typography color="textSecondary" sx={{ fontSize: ".93rem", fontWeight: 600 }}>
                    Your description needs to be at least 20 characters.
                  </Typography>
                </InputAdornment>
              )}

              {charCount >= charLimit - warningThreshold && (
                <InputAdornment
                  position="end"
                  sx={{
                    fontSize: "0.75rem",
                    color: charCount >= charLimit ? "#d32f2f" : "#f57c00",
                    position: "absolute",
                    bottom: "15px",
                    right: "10px",
                  }}
                >
                  <Typography  sx={{ fontSize: ".9rem", fontWeight: 600, color:'#8C4350', pt:'10px' }}>
                  {charLimit - charCount} / 500
                  </Typography>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </>
  );
};

export default BugDescriptionForm;