import React, { useState } from "react";
import {Accordion, Paper, Stack} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box'
import ExpandIcon from 'src/public/assets/icons/icons/tips/expand.png'
import MinusIcon from 'src/public/assets/icons/icons/tips/minus.png'
import FieldTypeHelp from "./FieldTypeHelp";


const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: "1px solid #c2d7df",
  boxShadow: "2px 2px 10px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  marginBottom: "10px", // This adds the default margin when closed
  alignItems:'center',

  // Apply bottom margin only when the accordion is expanded
  "&.Mui-expanded": {
    marginBottom: "30px", // Adjust this value to create space below the accordion when expanded
  },
}));


const StyledIconWrapper = styled(Paper)({
  border: "1px solid #48B09B",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "10px",
  height:'auto',
  background:'#48B09B4F'

});

const StyledSummaryContent = styled("div")({
  display: "flex",
  alignItems: "center", // Ensures everything aligns vertically
  gap: "10px",
  width: "100%",
  flexGrow: 1, // Ensures proper spacing for text
});

const TopicIconWrapper = styled(Paper)({
  display: "flex",
  alignItems: "center", // Center icon vertically
  justifyContent: "center",
  marginRight: "10px",
  padding:'1px',
  borderRadius:'50%',
  border:'2px solid #dfeaee',
  marginBottom:'2px',
  height:'40px',
  width:'40px'
});




const TipAccordion = ({ title, content, IconComponent, topicIcon, keyword, example, additionalContent, footer, showFieldTypeHelp }) => {
  const [expanded, setExpanded] = useState(false); // Track expanded state

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded); // Update expanded state
  };


  const renderTopicIcon = topicIcon && React.isValidElement(topicIcon) ? topicIcon : null;



  return (

      <StyledAccordion
        expanded={expanded}
        onChange={handleChange}
      >
  <AccordionSummary
    expandIcon={
      <Paper elevation={3} sx={{borderRadius:'50%', border:'2px solid #aae5d6', background:'transparent'}}>

      <img
        src={expanded ? MinusIcon : ExpandIcon}
        style={{
          width: "27px",
          height: "27px",
          transition: "transform 0.3s ease-in-out",
        }}
      />
          </Paper>

    }
    sx={{
      "& .MuiAccordionSummary-expandIconWrapper": {
        transition: "transform 0.3s ease-in-out",
      },
    }}
  >
          <StyledSummaryContent>

          <Box>
            <img src={topicIcon}  style={{ width: "32px", height: "32px", marginRight:'5px' }} /> 
          </Box>
        
            <Typography
     
            sx={{textAlign:"center", alignItems:'center', justifyContent:'center',  fontSize: 17,
                  fontWeight: "bold",
                  letterSpacing: "-0.02em",
                  color: "#637381",}}>{title || ''}</Typography>
          </StyledSummaryContent>
        </AccordionSummary>
        <AccordionDetails sx={{ }}>  {/* Added margin-bottom */}
<Stack direction="column" sx={{p:1, mt:-1}}>
  <Typography sx={{ fontSize: 16.5,
             
                  letterSpacing: "-0.02em",
                  color: "#40657a", }}> <strong>{keyword}</strong>  {content || ''}</Typography>
 
 {example && (
  <Typography sx={{ fontSize: 16.5,
             
             letterSpacing: "-0.02em",
             color: "#40657a", mt:1, fontWeight:550, mb:-.5}}> For Example:</Typography>
  )}
    <Typography sx={{ fontSize: 16.5,
             
             letterSpacing: "-0.02em",
             color: "#40657a", mt:.5}}> {additionalContent || ''}</Typography>

                <Typography sx={{ fontSize: 16.5,
             
             letterSpacing: "-0.02em",
             color: "#40657a",mt:.5}}> {footer || ''}</Typography>
           
             {showFieldTypeHelp && (
              <FieldTypeHelp/>
             )}
</Stack>


</AccordionDetails>
  
      </StyledAccordion>

  );
};

export default TipAccordion;