import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Icon,
  IconButton,
  InputAdornment,
  Skeleton,
  CardMedia,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../../../../context/AuthContext.js"; // Assuming subUserData is coming from this context
import { useAccountContext } from "src/context/AccountContext";
import { useAppContext } from "../../../../context/AppContext";
import StepTwo from "src/components/Account-Upgrades/components/MultiLocationUpgrade/StepTwo.js";
import StepFour from "src/components/Account-Upgrades/components/MultiLocationUpgrade/StepFour.js";
import Review from "./Review.js";
import companyImage from "src/public/assets/multiUser.png";
import completeImage from "src/public/assets/upgradeComplete.png";
import { useNavigate } from "react-router-dom";
import useUpgradeToMultiUser from "src/components/payments/hooks/useUpgradeToMultiUser.js";


const steps = [
  "Upgrade To A Multi-User Account",
  "Add your new employees",
  "Create your username and pin.",
  "Review Your Plan Changes",
];

const MultiUserUpgradeStepper = ({
  users,
  isDialogClosing,
  isLoading,
  setIsLoading,
  setUpgradeComplete,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const { user, getAccessTokenSilently } = useAuth0();
  const {
    subUserData,
    username,
    logout: customLogout,
    resetContext,
  } = useAuth();
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const { accountData } = useAccountContext();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate(); // Initialize the hook
  const [userSub, setUserSub] = useState(null); // Initialize the userSub state
  const { UpgradeToMultiUser } = useUpgradeToMultiUser();

  useEffect(() => {
    const updateUserSub = () => {
      if (accountData && accountData.single_user === true) {
        setUserSub(accountData.auth0_user);
      } else {
        setUserSub(subUserData.auth0_user_id);
      }
    };

    updateUserSub(); // Call the function
  }, [accountData, subUserData]);

  const handleLogout = () => {
    localStorage.clear();
    resetContext();
    navigate("/");
    window.location.reload();
  };

  const handleNext = async () => {
    // Handle step 3 scenario (or any other step-specific logic)
    if (activeStep === 3) {
      // Call the multi-user upgrade submit function
      const upgradeSuccess = await handleMultiUserUpgradeSubmit();

      // Do not move to the next step if any error occurred during the upgrade
      if (!upgradeSuccess) {
        return; // Stop here if the submission failed
      }
    }

    // Only advance to the next step if there was no error in the upgrade process
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  //New Location Logic
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [showConfirmNewPin, setShowConfirmNewPin] = useState(false);
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const [isAssignEmployeeVisible, setIsAssignEmployeeVisible] = useState(false);
  const [isMoveUserVisible, setIsMoveUserVisible] = useState(false);
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [isLocationButtonSaved, setIsLocationButtonSaved] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameTouched(true);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailTouched(true);
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, "").slice(0, 10);
    setPhone(formatPhoneNumber(value));
    setPhoneTouched(true);
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return `(${match[1]})${match[2] ? " " + match[2] : ""}${match[3] ? "-" + match[3] : ""}`;
    }
    return value;
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneRegex.test(phone);
  };

  const handleSaveLocationClick = async () => {
    setName(name);
    setEmail(email);
    setPhone(phone);
    setIsLoading(true);
  };

  //new employee logic
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pin, setPin] = useState("");
  const [showPin, setShowPin] = useState(false);
  const [newUserName, setNewUserName] = useState(subUserData?.username || ""); // Initialize with subUserData.username
  const [isSaveUserButtonEnabled, setIsSaveUserButtonEnabled] = useState(false);

  const [userNameTaken, setUserNameTaken] = useState(false);

  useEffect(() => {
    if (newUserName === userName) {
      setNewUserNameTaken(true); // Username taken, show helper text
    } else {
      setNewUserNameTaken(false); // No issue, hide helper text
    }
  }, [newUserName, userName]); // Trigger when newUserName or userName changes

  useEffect(() => {
    const isValidInput =
      pin.length >= 4 &&
      pin.length <= 4 &&
      /^\d+$/.test(pin) &&
      confirmNewPin.length === 4 &&
      /^\d+$/.test(confirmNewPin) &&
      pin === confirmNewPin &&
      userNameTaken === false &&
      subUserData.username !== userName &&
      userName.trim() !== "";

    setIsSaveUserButtonEnabled(isValidInput);
  }, [pin, confirmNewPin, firstName, lastName, userNameTaken, userName]);

  const handleTogglePinVisibility = () => {
    setShowPin(!showPin);
  };

  const handleConfirmNewPinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "").substr(0, 4);
    setConfirmNewPin(inputValue);
  };

  const toggleShowConfirmNewPin = () => {
    setShowConfirmNewPin((prev) => !prev);
  };

  //Transfer List Logic
  // Split lists between left and right (initially all in left)
  const [checked, setChecked] = useState([]);
  const [right, setRight] = useState([]);
  const [assignedUser, setAssignedUser] = useState(null);
  const [assignedLocation, setAssignedLocation] = useState(null);
  const availableUsers = users.filter(
    (user) => user.user_id !== subUserData.user_id,
  );
  const [left, setLeft] = useState(availableUsers);
  const [isNewUserSaved, setIsNewUserSaved] = useState(false);

  // Helpers to move items between lists
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    const selectedUsers = checked.filter((user) => left.includes(user));
    setRight(right.concat(selectedUsers));
    setLeft(left.filter((user) => !checked.includes(user)));
    setChecked([]);

    // Set the assigned user and location (for now location can be set statically or passed as props)
    if (selectedUsers.length > 0) {
      setAssignedUser(selectedUsers[0]); // Assuming you assign one user at a time
      setAssignedLocation(name); // Assuming `name` represents the location
    }
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(checked.filter((user) => right.includes(user))));
    setRight(right.filter((user) => !checked.includes(user)));
    setChecked([]);

    // Reset assigned user and location if user is moved back to the left
    setAssignedUser(null);
    setAssignedLocation(null);
  };

  const handleCreateNewEmployeeClick = async () => {
    setAssignedUser(null);
    setAssignedLocation(null);
    setIsAssignEmployeeVisible(true);
  };

  const handleSaveNewUserClick = async () => {
    setFirstName(firstName);
    setLastName(lastName);
    setNewUserName(newUserName);
    setPin(pin);
    setIsNewUserSaved(true);
  };

  //add username to existing user

  const [updatePin, setUpdatePin] = useState("");
  const [confirmUpdateNewPin, setConfirmUpdateNewPin] = useState("");
  const [showUpdatePin, setShowUpdatePin] = useState(false);
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const [isUpdateUserButtonEnabled, setIsUpdateUserButtonEnabled] =
    useState(false);
  const [hideCreateUserButton, setHideCreateUserButton] = React.useState(false);

  const handleToggleUpdatePinVisibility = () => {
    setShowUpdatePin(!showUpdatePin);
  };

  const toggleShowConfirmNewUpdatePin = () => {
    setShowConfirmNewPin((prev) => !prev);
  };

  const handleConfirmNewUpdatePinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "").substr(0, 4);
    setConfirmUpdateNewPin(inputValue);
  };

  const handleUpdateUserClick = async () => {
    setNewUserName(newUserName);
    setPin(pin);
    setIsUserUpdated(true);
    setIsUpdateUserButtonEnabled(false);
  };

  const [newUserNameTaken, setNewUserNameTaken] = useState(false);

  useEffect(() => {
    if (newUserName === userName) {
      setNewUserNameTaken(true); // Username taken, show helper text
    } else {
      setNewUserNameTaken(false); // No issue, hide helper text
    }
  }, [newUserName, userName]); // Trigger when newUserName or userName changes

  useEffect(() => {
    const isExsistingValidInput =
      updatePin.length >= 4 &&
      updatePin.length <= 4 &&
      /^\d+$/.test(updatePin) &&
      confirmUpdateNewPin.length === 4 &&
      /^\d+$/.test(confirmUpdateNewPin) &&
      updatePin === confirmUpdateNewPin &&
      newUserName.trim() !== "" &&
      newUserName !== userName;

    setIsUpdateUserButtonEnabled(isExsistingValidInput);
  }, [updatePin, confirmUpdateNewPin, newUserName, username]);

  const isNextButtonDisabled = () => {
    switch (activeStep) {
      case 0:
        return false; // Step 1, button is always enabled
      case 1:
        return !isSaveUserButtonEnabled || isLoading; // Step 2, enabled if form is valid
      case 2:
        return !isUpdateUserButtonEnabled || isLoading; // Step 4, enabled if `updatedName` has a value
      case 3:
        return isLoading; // Step 4, enabled if `updatedName` has a value
      default:
        return true; // Default, button disabled if unknown step
    }
  };

  const handleMultiUserUpgradeSubmit = async () => {
    const userUpgradeData = {
      firstName,
      lastName,
      userName,
      pin,
      newUserName,
      userId: subUserData.user_id,
      locationId: subUserData.location_id,
      userSub,
      updatePin,
    };

    setIsLoading(true); // Start loading state

    try {
      const upgradeResult = await UpgradeToMultiUser(userUpgradeData);

      if (!upgradeResult) {
        showSnackBar("Subscription upgrade failed. Please try again.", false);
        setIsLoading(false); // End loading state
        return false; // Return false if upgrade fails
      }

      showSnackBar("Account Upgrade Complete", true);
      setIsLoading(false); // End loading state
      setUpgradeComplete(true); // Set upgrade complete state
      return true; // Return true for success
    } catch (error) {
      showSnackBar("Account Upgrade Failed. Please Try Again.", false);
      setIsLoading(false); // End loading state
      return false; // Return false if there is an error
    }
  };

  useEffect(() => {
    if (isDialogClosing) {
      // Clear all relevant states when the dialog closes
      setFirstName("");
      setLastName("");
      setUserName("");
      setPin("");
      setConfirmNewPin("");
      setShowPin(false);
      setShowConfirmNewPin(false);
      setIsSaveUserButtonEnabled(false);
      setIsLoading(false);
      setName("");
      setIsNewUserSaved(false);
      setNewUserName("");
      setAssignedLocation("");
      setNameTouched(false);
      setEmail("");
      setEmailTouched(false);
      setPhone("");
      setPhoneTouched(false);
      setIsFormValid(false);
      setUpdatePin("");
      setConfirmUpdateNewPin("");
      setShowUpdatePin(false);
      setUserNameTaken(false);
      setNewUserNameTaken(false);

      // Optionally, you can log the states before clearing for tracking
    }
  }, [isDialogClosing]);

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = companyImage;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);

  const [completeImageLoaded, setCompleteImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = completeImage;
    img.onload = () => setCompleteImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex", // Use flex to manage the layout
        justifyContent: "center", // Center the stepper
        overflowX: "auto", // Allow scrolling if the stepper overflows horizontally
        flexDirection: "column", // Stack the steps vertically
      }}
    >
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={label}
            sx={{
              // Custom styling for each step
              minWidth: "50px", // Set a minimum width to prevent cutting off labels
              flexShrink: 0, // Prevent shrinking of step icons and labels
              "& .MuiStepLabel-label": {
                color: activeStep === index ? "#647D9E" : "#3B708A",
                fontWeight: activeStep === index ? "bold" : "normal",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
              "& .MuiStepIcon-root": {
                color: activeStep === index ? "#647D9E" : "#B0BEC5",
              },
              "& .MuiStepLabel-root .Mui-completed": {
                color: "#72C5B4",
              },
              "& .MuiStepLabel-root .Mui-active": {
                color: "#647D9E",
              },
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{
              mb: -2,
              mt: 3,
              fontSize: "16.5px",
              letterSpacing: "-2%",
              lineHeight: "-2%",
              fontWeight: 600,
              color: "#647D9E",
            }}
          >
            Your Account Has Been Successfully Upgraded!
          </Typography>

          {completeImageLoaded && (
            <Skeleton
              variant="rectangular"
              sx={{
                margin: "auto",
                width: "65%",
                height: "65%",
                borderRadius: "10px",
                backgroundColor: "transparent",
                objectFit: "contain",
                opacity: completeImageLoaded ? 0 : 1,
                transition: "opacity 0.3s ease-in-out",
              }}
            />
          )}
          <CardMedia
            component="img"
            src={completeImage}
            sx={{
              display: "block",
              margin: "auto",
              width: "65%",
              height: "65%",
              opacity: completeImageLoaded ? 1 : 0,
              transition: "opacity 0.2s ease-in-out",
              objectFit: "contain",
            }}
            onLoad={() => setCompleteImageLoaded(true)}
          />

          <Typography
            sx={{
              mt: -8,
              fontSize: "14.5px",
              letterSpacing: "-2%",
              lineHeight: "-2%",
              fontWeight: 600,
              color: "#647D9E",
            }}
          >
            All of your changes can be reviewed from the billing section in your
            account page{" "}
          </Typography>
          <Typography
            sx={{
              mt: 1,
              fontSize: "14.75px",
              letterSpacing: "-2%",
              lineHeight: "-2%",
              fontWeight: 500,
              color: "#647D9E",
            }}
          >
            Your team can now log in using the new username and pin you just
            created
          </Typography>

          <Button
            onClick={handleLogout}
            sx={{
              display: "flex",
              border: "1px solid #48768C",
              borderRadius: "10px",
              padding: "8px",
              color: "#48768C",
              backgroundColor: "#FFFFFF",
              ml: "auto",
              mr: "auto",
              mt: 4,
              mb: 1,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              "&:hover": {
                backgroundColor: "#DBEAF0",
              },
              "&:active": {
                backgroundColor: "#DBEAF0",
              },
            }}
            color="primary"
          >
            Return To Dashboard
          </Button>
        </Box>
      ) : (
        <Box sx={{ mt: 2 }}>
          {/* Display the current step content */}

          {/* Conditionally render a Box with a border for specific steps */}
          {activeStep === 0 && (
            <Box
              sx={{
                backgroundColor: "#F2F9FF",
                border: "1.25px solid rgba(173, 216, 230, 0.5)",
                borderRadius: "14px",
                padding: 2,
                mt: 2,
                mb: 2,
                pb: 1,
              }}
            >
              <Typography className="stepper_text_header">
                Multiple Users. One Account.
              </Typography>
              <Typography className="stepper_text_body">
                Upgrading your account is quick and easy, and only takes a few
                minutes.
              </Typography>
              {imageLoaded && (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "50%",
                    height: "50%",
                    borderRadius: "10px",
                    display: imageLoaded ? "none" : "block",
                    backgroundColor: "transparent",
                    objectFit: "contain",
                  }}
                />
              )}

              <CardMedia
                component="img"
                src={companyImage}
                sx={{
                  margin: "auto",
                  width: "50%",
                  height: "50%",
                  opacity: imageLoaded ? 1 : 0,
                  transition: "opacity 0.2s ease-in-out",
                  objectFit: "contain",
                }}
                onLoad={() => setImageLoaded(true)}
              />

              <Typography className="stepper_text_body">
                Each employee uses the same email & password that you currently
                use to sign in.
              </Typography>

              <Typography className="stepper_text_subbody">
                Assign users a unique username and pin. Create account wide
                roles. Add or remove users at any time.
              </Typography>
            </Box>
          )}

          {activeStep === 1 && (
            <StepTwo
              firstName={firstName}
              lastName={lastName}
              userName={userName}
              pin={pin}
              confirmNewPin={confirmNewPin}
              showPin={showPin}
              showConfirmNewPin={showConfirmNewPin}
              isAssignEmployeeVisible={isAssignEmployeeVisible}
              isMoveUserVisible={isMoveUserVisible}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setUserName={setUserName}
              setPin={setPin}
              handleConfirmNewPinChange={handleConfirmNewPinChange}
              handleTogglePinVisibility={handleTogglePinVisibility}
              toggleShowConfirmNewPin={toggleShowConfirmNewPin}
              setIsAssignEmployeeVisible={setIsAssignEmployeeVisible}
              users={users}
              subUserData={subUserData}
              isSaveUserButtonEnabled={isSaveUserButtonEnabled}
              isLoading={isLoading}
              name={name}
              handleToggle={handleToggle}
              checked={checked}
              left={left}
              right={right}
              handleCheckedRight={handleCheckedRight}
              handleCheckedLeft={handleCheckedLeft}
              handleCreateNewEmployeeClick={handleCreateNewEmployeeClick}
              setNewUserName={setNewUserName}
              isNewUserSaved={isNewUserSaved}
              setIsNewUserSaved={setIsNewUserSaved}
              handleSaveNewUserClick={handleSaveNewUserClick}
              assignedLocation={assignedLocation}
              setLeft={setLeft}
              userNameTaken={userNameTaken}
              setUserNameTaken={setUserNameTaken}
              setHideCreateUserButton={setHideCreateUserButton}
            />
          )}

          {activeStep === 2 && (
            <StepFour
              name={name}
              nameTouched={nameTouched}
              handleNameChange={handleNameChange}
              email={email}
              emailTouched={emailTouched}
              handleEmailChange={handleEmailChange}
              phone={phone}
              phoneTouched={phoneTouched}
              handlePhoneChange={handlePhoneChange}
              validateEmail={validateEmail}
              validatePhoneNumber={validatePhoneNumber}
              isFormValid={isFormValid}
              subUserData={subUserData}
              setUpdatePin={setUpdatePin}
              updatePin={updatePin}
              confirmUpdateNewPin={confirmUpdateNewPin}
              showUpdatePin={showUpdatePin}
              handleConfirmNewUpdatePinChange={handleConfirmNewUpdatePinChange}
              toggleShowConfirmNewUpdatePin={toggleShowConfirmNewUpdatePin}
              assignedLocation={assignedLocation}
              setNewUserName={setNewUserName}
              newUserName={newUserName}
              newUserNameTaken={newUserNameTaken}
            />
          )}

          {activeStep === 3 && (
            <Review
              assignedUser={assignedUser}
              assignedLocation={assignedLocation}
              name={name}
              email={email}
              phone={phone}
              isNewUserSaved={isNewUserSaved}
              firstName={firstName}
              lastName={lastName}
              newUserName={newUserName}
              pin={pin}
              userName={userName}
              accountData={accountData}
              
            />
          )}

          <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{
                display: "flex",
                border: "1px solid #48768C",
                borderRadius: "10px",
                padding: "5px",
                color: "#48768C",
                backgroundColor: "#FFFFFF",
                mb: 1,
                mt: -1,
                ml: 1,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "#DBEAF0",
                },
                "&:active": {
                  backgroundColor: "#DBEAF0",
                },
              }}
              color="primary"
            >
              Back
            </Button>
            <Button
              onClick={handleNext}
              disabled={isNextButtonDisabled()}
              sx={{
                display: "flex",
                border: "1px solid #48768C",
                borderRadius: "10px",
                padding: "5px",
                color: "#48768C",
                backgroundColor: "#FFFFFF",
                mb: 1,
                mt: -1,
                ml: "auto",
                mr: 1,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "#DBEAF0",
                },
                "&:active": {
                  backgroundColor: "#DBEAF0",
                },
              }}
              color="primary"
            >
              {isLoading
                ? "Saving..."
                : activeStep === steps.length - 1
                  ? "Save and Confirm"
                  : "Next"}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MultiUserUpgradeStepper;
