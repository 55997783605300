// AffectedAreasForm.js
import {
    Box,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    Stack,
  } from "@mui/material";
  import { FormControl } from "@mui/material";
  import { styled } from "@mui/system";
  
  const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: "100%",
    backgroundColor: "#E9EDF2",
    marginBottom: 3,
    position: "relative",
    "& .MuiInputLabel-shrink": {
      transform: "translate(0, -1.5px) scale(0.75)",
      paddingLeft: "7px", // Adjust the translateY value as needed
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#48768c", // Set the border color here
      },
      "&:hover fieldset": {
        borderColor: "#6fa3b7", // Set the hover border color here (optional)
      },
      "&.Mui-focused fieldset": {
        borderColor: "#48768c", // Set the focused border color here
      },
    },
  }));
  
  const SubmitSuccess = ({ ticketDetails }) => {
  
  

    const severityLabels = {
      Low: "Causes issues, but is still functional",
      Medium: "Requires workaround, but still works",
      High: "Does not work at all",
    };
  
    return (
        <Stack
          direction="column"
          spacing={0}
          sx={{
            width: "100%",
            display: "flex",
            whiteSpace: "wrap",
            alignItems: "fit-contain",
       
            mb:4
          }}
        >
            <Box
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: "#ffffff", // Ensures it's visible over content
        zIndex: 1000, // Keeps it on top
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
    >
          <Typography
                     
  
                     sx={{
                       fontSize: "1.29rem",
                       fontWeight: 600,
                    
                   
                       width: "100%", // Makes sure it takes up full width
                       letterSpacing: "-0.03em", // Adds letter spacing
                       color: "#48768c",
                       lineHeight:'-.02em'
                   
                     }}
                   >    
           We'll get to work right away on your request
          </Typography>

          </Box>
  
          <Box sx={{height:'18vh', overflowY:'auto',              
            mt:'5px'
          }}>
  

  
  
          <Box sx={{mt:1}}>
            <Typography
              color="textSecondary"
              sx={{ fontSize: ".98rem", fontWeight: 500, width:'95%'
              }}
            >
                    We'll get in touch at the contact information you provided below as soon as we have an update, or if we need more information from you

              </Typography>
  
              <Box sx={{ width: "98%", height: "auto", flexGrow: 1,      mt:2.5
 }}>

   <Stack direction="row" sx={{mt:'3px' }}>
   <Typography
      sx={{
        fontSize: ".99rem",
        fontWeight: 600,

        borderRadius: "5px",
        display: "block",
        alignItems: "center",
        whiteSpace: "normal", // Allows wrapping
        wordBreak: "break-word", // Ensures long words don't overflow


        color: "#395465",
        mr:'5px'
  
      }}
    >
     Name: 
    </Typography>
    <Typography
      sx={{
        fontSize: ".99rem",
        fontWeight: 500,

        borderRadius: "5px",
        display: "block",
        alignItems: "center",
        whiteSpace: "normal", // Allows wrapping
        wordBreak: "break-word", // Ensures long words don't overflow


        color: "#395465",
        mr:'5px'
  
      }}
    >
      {ticketDetails.firstName}
    </Typography>
    <Typography
      sx={{
        fontSize: ".99rem",
        fontWeight: 500,

        borderRadius: "5px",
        display: "block",
        alignItems: "center",
        whiteSpace: "normal", // Allows wrapping
        wordBreak: "break-word", // Ensures long words don't overflow


        color: "#395465",
  
      }}
    >
      {ticketDetails.lastName}
      </Typography>
    </Stack>
    <Stack direction="row" sx={{mt:'2px' }}>
    <Typography
      sx={{
        fontSize: ".99rem",
        fontWeight: 600,

        borderRadius: "5px",
        display: "block",
        alignItems: "center",
        whiteSpace: "normal", // Allows wrapping
        wordBreak: "break-word", // Ensures long words don't overflow

      
        color: "#395465",
  
      }}
    >
      Email: 
    </Typography>
    <Typography
      sx={{
        fontSize: ".99rem",
        fontWeight: 500,

        borderRadius: "5px",
        display: "block",
        alignItems: "center",
        whiteSpace: "normal", // Allows wrapping
        wordBreak: "break-word", // Ensures long words don't overflow

        px: 1,
        color: "#395465",
  
      }}
    >
      {ticketDetails.email}
      </Typography>
    </Stack>
  </Box>
          </Box>
    
        </Box>
        </Stack>
    );
  };
  
  export default SubmitSuccess;
  