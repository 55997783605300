import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  Fade,
  Divider,
  ButtonBase
} from "@mui/material";
import { Stack, styled } from "@mui/system";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "src/components/payments/CheckoutForm.js";
import ActivePlanCard from "../billingComponents/activePlanCard";
import { useAccountContext } from "src/context/AccountContext";
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useCancelSubscription from "src/components/payments/hooks/useCancelSubscription.js";
import NewSubscription from "src/components/payments/components/NewSubscription.js";
import { useSubscriptionDetails } from "src/components/payments/routes/useSubscriptionDetails";
import { useValidateSubscription } from "src/components/payments/routes/validateSubscription";
import BillingDataCard from "../billingComponents/billingDataCard";
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import ViewPlanDetails from "../billingComponents/ViewPlanDetails";
import ManageSubscription from "../billingComponents/ManageSubscription";
import BillingHistory from "../billingComponents/BillingHistory";
import {usePaymentSchedule} from "src/components/payments/hooks/usePaymentSchedule.js";  
import { useGeneratePlanDetails } from "src/components/AccountPage/billingComponents/useGeneratePlanDetails.js"; // Assuming this is the correct path
import { Navigate } from "react-router-dom";


const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
);



function Billing({
  totalLocations,
  totalUsers,
  users,
  locations,
  subscriptionDetails,
  cancelSubscription,
  subscriptionValid,
  handleSubscriptionUpdate,
  verifying,
  planDetails,
  refetchSubscriptionDetails,
  fetchBillingHistory,
  billingHistory,
  loadingBilling,
  cardTitle,
  cardSubTitle,
  billingAddress,
  subUserData,
  billingContact,
  fetchUsers,
  fetchLocations,
  paymentStatus,
  failedPaymentError,
  keepSubscription,
  isCancelling,
  isKeeping,
  isPausing, 
  pauseSubscription
}) {


  const { accountData } = useAccountContext();

  const [showNewSubscription, setShowNewSubscription] = React.useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showPauseDialog, setShowPauseDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);



  const handleTabClick = (index) => {
    setActiveTab(index);
  };



  const customerId = accountData ? accountData.customer_id : null;
  const subscriptionId = subscriptionDetails
    ? subscriptionDetails.lastSubscriptionId
    : null;
  const priceId = planDetails ? planDetails.plan_price_id : null;

  const [activeTab, setActiveTab] = useState(0); 
  const { getAccessTokenSilently } = useAuth0();
  const { paymentDetails, loadingPaymentDetails, paymentDetailsError, setRefetchPaymentDetails } = usePaymentSchedule(customerId, getAccessTokenSilently);

const handleCancelClick = async () => {
  if (customerId && subscriptionId) {
    try {
      const success = await cancelSubscription(customerId, refetchSubscriptionDetails);
      if (success) {
        setRefetchPaymentDetails(true); // Trigger useEffect to refetch payment details
        setShowCancelDialog(false); // Close the cancel dialog
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
    }
  }
};

const handleKeepClick = async () => {
  if (customerId && subscriptionId) {
    try {
      const success = await keepSubscription(customerId, refetchSubscriptionDetails);
      if (success) {
        setRefetchPaymentDetails(true); // Trigger useEffect to refetch payment details
        setShowCancelDialog(false); // Close the cancel dialog
      }
    } catch (error) {
      console.error("Error keeping subscription:", error);
    }
  }
};

const handlePauseClick = async () => {
  if (customerId && subscriptionId) {
    try {
      const success = await pauseSubscription(customerId, selectedDate, refetchSubscriptionDetails);
      if (success) {
        setRefetchPaymentDetails(true); // Trigger useEffect to refetch payment details
        setShowPauseDialog(false); // Close the cancel dialog
      }
    } catch (error) {
      console.error("Error keeping subscription:", error);
    }
  }
};






 

  const Header = styled(Typography)({
    width: "100%",
    textAlign: "left",
    letterSpacing: "-.02em",
  });


  const {
    detailOne,
    detailTwo,
    detailThree,
    billingDetailOne,
    billingDetailTwo,
    billingDetailThree,
    billingDetailFour,
    billingDetailFive,
    billingValueOne,
    billingValueTwo,
    billingValueThree,
    billingValueFour,
    billingValueFive,

    planCost,
    loadingPlanDetails,
    totalPlanCost,
    subscriptionStatus,
    promotion, 
    discountName,
    discountPercentage,
    couponExpires,
    discountedPlanCost
  } = useGeneratePlanDetails(planDetails, subscriptionDetails);


  const isAllowed = subUserData?.is_admin === true ;



    // Redirect unauthorized users to /dashboard/app
    if (!isAllowed) {
      return <Navigate to="/dashboard/app" replace />;
    }
  


  return (
    <>
 {isAllowed && (
<Box
  sx={{
    width: "100%",  // Ensures the parent takes up the full available width
    height: "82vh", // Fixed height for the parent
    overflowY: "hidden", // Disable vertical scrolling
    overflowX: "auto", // Enable horizontal scrolling only
    display: "flex", // Flex container to align children
    flexDirection: "column", // Stack children vertically
    minWidth: 0, // Prevents shrinking of the parent container
    padding:1
  }}
>

      <Header
        sx={{
          color: "#48768C",
          fontWeight: 700,
          fontSize: 18.5,
          pt:.5,
          pl:.25
        }}
      >
        Billing & Subscription
      </Header>
      <Header
        sx={{ color: "#48768C", fontSize: ".9rem", letterSpacing: "-.01em", pb:1, pl:.25 }}
      >
        Manage your subscription and payment information.
      </Header>

        

          {subscriptionValid && subscriptionDetails ? (
            <>
<Stack
  spacing={2}
  direction="row"
  sx={{
    mt: 0.5,
    alignItems: "stretch", // Ensures children take up the full width
    boxSizing: "border-box", // Ensures padding and border are included in the width
    width: "100%", // Ensures the child container takes up the full available width
    minWidth: 0, // Prevents shrinking of the child container inside the flex parent
    flexShrink: 0, // Prevents the child container from shrinking
    display: 'flex', // Flex container to align children inside    

    justifyContent: 'space-between',
    mb:1
  }}
>
                                <ActivePlanCard
                  name={planDetails ? planDetails.plan_name : ""}

                  status={
                    subscriptionDetails
                      ? subscriptionDetails.subscriptionStatus
                          .charAt(0)
                          .toUpperCase() +
                        subscriptionDetails.subscriptionStatus.slice(1)
                      : ""
                  }
                  cost={
                    planDetails
                      ? "$" + (planDetails.plan_cost / 100).toFixed(2)
                      : ""
                  }
                  subscriptionValid={subscriptionValid}
                  subscriptionId={subscriptionId}
                  customerId={customerId}
                  priceId={planDetails ? planDetails.plan_price_id : ""}
                  onSubscriptionUpdate={handleSubscriptionUpdate}
                  planDetails={planDetails}
                  stripePromise={stripePromise}
                  subscriptionDetails={subscriptionDetails}
                  totalLocations={totalLocations}
                  totalUsers={totalUsers}
                  users={users}
                  locations={locations}
                  accountData={accountData}
                  handleSubscriptionUpdate={handleSubscriptionUpdate}
                  fetchUsers={fetchUsers}
                  fetchLocations={fetchLocations}
                />

                <BillingDataCard
                  title={"Payment Method"}
                  name={
                    subscriptionDetails
                      ? subscriptionDetails.subscriptionName
                      : ""
                  }
                  subscriptionValid={subscriptionValid}
                  status={
                    subscriptionDetails.subscriptionStatus
                      ? subscriptionDetails.subscriptionStatus
                          .charAt(0)
                          .toUpperCase() +
                        subscriptionDetails.subscriptionStatus.slice(1)
                      : ""
                  }
                  cost={
                    subscriptionDetails ? "$" + subscriptionDetails.cost : ""
                  }
                  reoccurrence={
                    subscriptionDetails &&
                    subscriptionDetails.paymentReoccurrence
                      ? subscriptionDetails.paymentReoccurrence
                          .charAt(0)
                          .toUpperCase() +
                        subscriptionDetails.paymentReoccurrence.slice(1) +
                        "ly"
                      : ""
                  }
                  paymentMethod={
                    subscriptionDetails &&
                    subscriptionDetails.last4 !== "No card found"
                      ? "**** **** **** " + subscriptionDetails.last4
                      : "No Active Payment Method"
                  }
                  subscriptionId={subscriptionId}
                  description={"Change how you pay for your subscription."}
                  customerId={customerId}
                  onSubscriptionUpdate={handleSubscriptionUpdate}
                  planDetails={planDetails}
                  stripePromise={stripePromise}
                  priceId={priceId}
                  subscriptionDetails={subscriptionDetails}
                  refetchSubscriptionDetails={refetchSubscriptionDetails}
                  billingAddress={billingAddress}
                  subUserData={subUserData}
                  billingHistory={billingHistory}
                  billingContact={billingContact}
                  fetchBillingHistory={fetchBillingHistory}
                  paymentStatus={paymentStatus}
                  failedPaymentError={failedPaymentError}
                  billingDetailOne={billingDetailOne}
                  billingDetailTwo={billingDetailTwo}
                  billingDetailThree={billingDetailThree}
                  billingDetailFour={billingDetailFour}
                  billingDetailFive={billingDetailFive}
                  billingValueOne={billingValueOne}
                  billingValueTwo={billingValueTwo}
                  billingValueThree={billingValueThree}
                  billingValueFour={billingValueFour}
                  billingValueFive={billingValueFive}

                  planCost={planCost}
                  totalPlanCost={totalPlanCost}
                  promotion={promotion}
                  discountName={discountName}
                  discountPercentage={discountPercentage}
                  discountedPlanCost={discountedPlanCost}
                  loadingPlanDetails={loadingPlanDetails}



                />
              </Stack>
            </>
          ) : (
            <>
              {/* If there is no active subscription, show the plan selection card */}
              <Stack spacing={2} direction="row" sx={{ mt: 1.5 }}>
                <ActivePlanCard
                  name={planDetails ? planDetails.plan_name : ""}
                  status={
                    subscriptionDetails
                      ? subscriptionDetails.subscriptionStatus
                          .charAt(0)
                          .toUpperCase() +
                        subscriptionDetails.subscriptionStatus.slice(1)
                      : ""
                  }
                  cost={
                    planDetails
                      ? "$" + (planDetails.plan_cost / 100).toFixed(2)
                      : ""
                  }
                  subscriptionValid={subscriptionValid}
                  subscriptionId={subscriptionId}
                  customerId={customerId}
                  priceId={planDetails ? planDetails.plan_price_id : ""}
                  onSubscriptionUpdate={handleSubscriptionUpdate}
                  planDetails={planDetails}
                  stripePromise={stripePromise}
                  subscriptionDetails={subscriptionDetails}
                  totalLocations={totalLocations}
                  totalUsers={totalUsers}
                  users={users}
                  locations={locations}
                  accountData={accountData}
                  handleSubscriptionUpdate={handleSubscriptionUpdate}
                  fetchUsers={fetchUsers}
                  fetchLocations={fetchLocations}
                />
                <BillingDataCard
                  title={"Activate Your Subscription"}
                  name={planDetails ? planDetails.plan_name : ""}
                  status={
                    subscriptionDetails
                      ? subscriptionDetails.subscriptionStatus
                          .charAt(0)
                          .toUpperCase() +
                        subscriptionDetails.subscriptionStatus.slice(1)
                      : ""
                  }
                  cost={
                    planDetails
                      ? "$" + (planDetails.plan_cost / 100).toFixed(2)
                      : ""
                  }
                  subscriptionValid={subscriptionValid}
                  subscriptionId={subscriptionId}
                  customerId={customerId}
                  priceId={planDetails ? planDetails.plan_price_id : ""}
                  onSubscriptionUpdate={handleSubscriptionUpdate}
                  planDetails={planDetails}
                  stripePromise={stripePromise}
                  subscriptionDetails={subscriptionDetails}
                  description={
                    "Activate your subscription to start sending notifications."
                  }
                  billingAddress={billingAddress}
                  subUserData={subUserData}
                  billingHistory={billingHistory}
                  billingContact={billingContact}
                  fetchBillingHistory={fetchBillingHistory}
                  paymentStatus={paymentStatus}
                  failedPaymentError={failedPaymentError}
                  refetchSubscriptionDetails={refetchSubscriptionDetails}
                  billingDetailOne={billingDetailOne}
                  billingDetailTwo={billingDetailTwo}
                  billingDetailThree={billingDetailThree}
                  billingDetailFour={billingDetailFour}
                  billingDetailFive={billingDetailFive}
                  billingValueOne={billingValueOne}
                  billingValueTwo={billingValueTwo}
                  billingValueThree={billingValueThree}
                  billingValueFour={billingValueFour}
                  billingValueFive={billingValueFive}



                  planCost={planCost}
                  totalPlanCost={totalPlanCost}
                  promotion={promotion}
                  discountName={discountName}
                  discountPercentage={discountPercentage}

                  discountedPlanCost={discountedPlanCost}
                  loadingPlanDetails={loadingPlanDetails}
                />
              </Stack>
            </>
          )}


          <Fade in={!loadingBilling} timeout={200}> 
                <Stack 
  direction="column" 
  spacing={2} 
  sx={{  
    flex: "1 1 auto",
    width: "100%",  
    padding: 1, 
    border: "1.25px solid rgba(173, 216, 230, 0.5)", 
    borderRadius: "6px", 
    mt: 2, 
    pt: 1.5, 
    maxHeight: "calc(100vh - 100px)", 
    overflow: "hidden",  // Prevents parent from scrolling
    position: "relative",
  }}
>
      <Box sx={{       position: "sticky",
      top: 0, 
      backgroundColor: "white", // Ensures it doesn’t overlap content
      zIndex: 10,  // Keeps it above scrollable content
      paddingBottom: 1,  }}>

<Stack
      direction="row"
      spacing={2}
      sx={{
        flexGrow: 1,
        whiteSpace: "nowrap",
        flexWrap: "wrap",
        padding: 0,
        pl: 1,
        pb:'5px'
      }}
    >
      {[
        { text: "Billing History", index: 0 },
        { text: "Your Monthly Plan", index: 1 },
        { text: "Manage Your Subscription", index: 2 },
      ].map(({ text, index }) => (
        <ButtonBase
          key={index}
          onClick={() => handleTabClick(index)}
          sx={{
            borderRadius: "8px",
            px: 1,
            py: 0.5,
            transition: "background 0.3s ease, transform 0.2s ease",
            backgroundColor: activeTab === index ? "rgba(108, 144, 162, 0.1)" : "transparent",
            "&:hover": {
              backgroundColor: "rgba(108, 144, 162, 0.1)", // Subtle hover effect
              transform: "scale(1.02)", // Slight scaling for interaction feedback
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: activeTab === index ? 600 : 500,
              whiteSpace: "nowrap",
              color: "#48768C",
              letterSpacing: "-0.015em",
              fontSize: "16px",
              textTransform: "none",
              transition: "color 0.2s ease",
            }}
          >
            {text}
          </Typography>
        </ButtonBase>
      ))}
    </Stack>
          
<Divider sx={{ mt: .5, width: "100%", display: "block", alignSelf: "flex-start", mb:-1 }} />          
</Box>


  {/* Scrollable Content */}
            <Box 
              sx={{   
                margin: 0, 
                padding: 0,  
                width: "100%",
                minWidth: "100%",  
                flexGrow: 1, 
                display: "flex", 
                overflowY: "auto",  // Allows scrolling
                maxHeight: "calc(100vh - 180px)",  // Adjust height based on sticky element
              }}
            >
                {activeTab === 0 && (
              <BillingHistory
                billingHistory={billingHistory}
                loadingBilling={loadingBilling}
                />
                )}
                {activeTab === 1 && (
                  <ViewPlanDetails
                  planDetails={planDetails}
                  subscriptionDetails={subscriptionDetails}
                  detailOne={detailOne}
                  detailTwo={detailTwo}
                  detailThree={detailThree}
                  billingDetailOne={billingDetailOne}
                  billingDetailTwo={billingDetailTwo}
                  billingDetailThree={billingDetailThree}
                  billingDetailFour={billingDetailFour}
                  billingDetailFive={billingDetailFive}
                  billingValueOne={billingValueOne}
                  billingValueTwo={billingValueTwo}
                  billingValueThree={billingValueThree}
                  billingValueFour={billingValueFour}
                  billingValueFive={billingValueFive}
                  planCost={planCost}
                  totalPlanCost={totalPlanCost}
                  subscriptionStatus={subscriptionStatus}
                  promotion={promotion}
                  discountName={discountName}
                  discountPercentage={discountPercentage}
                  couponExpires={couponExpires}
                  discountedPlanCost={discountedPlanCost}
                  loadingPlanDetails={loadingPlanDetails}
                    />
                )}
                {activeTab === 2 && (
                  <ManageSubscription
                  planDetails={planDetails}
                  subscriptionDetails={subscriptionDetails}
                  customerId={customerId}
                  paymentDetails={paymentDetails}
                  loadingPaymentDetails={loadingPaymentDetails}
                  paymentDetailsError={paymentDetailsError}
                  detailOne={detailOne}
                  detailTwo={detailTwo}
                  detailThree={detailThree}
                  billingDetailOne={billingDetailOne}
                  billingDetailTwo={billingDetailTwo}
                  billingDetailThree={billingDetailThree}
                  billingDetailFour={billingDetailFour}
                  billingDetailFive={billingDetailFive}
                  billingValueOne={billingValueOne}
                  billingValueTwo={billingValueTwo}
                  billingValueThree={billingValueThree}
                  billingValueFour={billingValueFour}
                  billingValueFive={billingValueFive}
                  planCost={planCost}
                  totalPlanCost={totalPlanCost}
                  subscriptionStatus={subscriptionStatus}
                  promotion={promotion}
                  discountName={discountName}
                  discountPercentage={discountPercentage}
                  couponExpires={couponExpires}
                  discountedPlanCost={discountedPlanCost}
                  onSubscriptionUpdate={handleSubscriptionUpdate}
                  priceId={priceId}
                  subscriptionId={subscriptionId}
                  billingAddress={billingAddress}
                  loadingPlanDetails={loadingPlanDetails}
                  handleCancelClick={handleCancelClick}
                  handleKeepClick={handleKeepClick} 
                  showNewSubscription={showNewSubscription}
                  setShowNewSubscription={setShowNewSubscription}
                  setShowCancelDialog={setShowCancelDialog}
                  showCancelDialog={showCancelDialog}
                  isCancelling={isCancelling}
                  isKeeping={isKeeping} 
                  showPauseDialog={showPauseDialog}
                  setShowPauseDialog={setShowPauseDialog}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  handlePauseClick={handlePauseClick}
                  isPausing={isPausing}
                  showDeleteDialog={showDeleteDialog}
                  setShowDeleteDialog={setShowDeleteDialog}
                  accountData={accountData}



                    />
                )}
          
                  </Box>
            </Stack>
            </Fade>
          
        </Box>
 )}

      






        



      
      </>
  );
}

export default Billing;
