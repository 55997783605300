import React, { useEffect } from "react";
import {
  Card,
  Grid,
  Typography,
  Button,
  Stack,
  IconButton,
  Box,
  CardMedia,
} from "@mui/material";
import NewSubscription from "src/components/payments/components/NewSubscription.js";
import EditSubscription from "src/components/payments/components/EditSubscription";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useCancelSubscription from "src/components/payments/hooks/useCancelSubscription.js";
import { useSubscriptionDetails } from "src/components/payments/routes/useSubscriptionDetails";
import { useValidateSubscription } from "src/components/payments/routes/validateSubscription";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import VisaCard from "src/public/assets/icons/icons/visaCard.png";
import MasterCard from "src/public/assets/icons/icons/masterCard.png";
import CreditCard from "src/public/assets/icons/icons/creditCard.png";


const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
);

const BillingDataCard = ({
  planDetails,
  name,
  status,
  cost,
  reoccurrence,
  paymentMethod,
  subscriptionId,
  customerId,
  priceId,
  onSubscriptionUpdate,
  subscriptionValid,
  stripePromise,
  subscriptionDetails,
  description,
  title,
  refetchSubscriptionDetails,
  billingAddress,
  subUserData,
  billingHistory,
  billingContact,
  fetchBillingHistory,
  checked,
  paymentStatus,
  failedPaymentError,
  handleSubscriptionUpdate,
  billingDetailOne, 
  billingValueOne, 
  billingDetailTwo, 
  billingValueTwo, 
  billingDetailThree, 
  billingValueThree, 
  billingDetailFour, 
  billingValueFour, 
  billingDetailFive, 
  billingValueFive,
  loadingPlanDetails,

  totalPlanCost,
  promotion,
  discountName,
  discountPercentage,
  planCost,
  discountedPlanCost, 

}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showNewSubscription, setShowNewSubscription] = React.useState(false);
  const [showEditSubscription, setShowEditSubscription] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleShowNewSubscription = () => {
    setShowNewSubscription(true);
  };

  const handleNewSubscriptionClose = () => {
    setShowNewSubscription(false);
  };

  const handleShowEditSubscription = () => {
    setShowEditSubscription(true);
  };

  const handleEditSubscriptionClose = () => {
    setShowEditSubscription(false);
  };

  const { isCancelling, cancelError, cancelSuccess, cancelSubscription } =
    useCancelSubscription();

  const handleCancelClick = () => {
    if (customerId && subscriptionId) {
      cancelSubscription(customerId, subscriptionId, onSubscriptionUpdate);
    }
  };

  useEffect(() => {
    if (!subscriptionDetails) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [name, status, planDetails, subscriptionDetails]);

  const renderLogo = (brand) => {

    if (!brand) return CreditCard; // Handle null or undefined early
    switch (brand.toLowerCase()) {
      case "visa":
        return VisaCard;
      case "mastercard":
        return MasterCard;
      default:
        return CreditCard;
    }
    
  };



  return (
    <>
      {!isLoading ? (

          <Card
            sx={{
              width: "100%", // Ensures full-width layout
              justifyContent: "center", // Centers the cards
              px: 1.25,
              py: 1.75,
              backgroundColor: "transparent",
              border: "1px solid rgba(173, 216, 230, 0.7)",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              flex:1,
              pb:2,
              borderRadius: "6px",
              
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
              }}
            >
              <Typography

                sx={{
                  ml: 1.25,
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                  fontSize: "1.13rem",
                  textTransform: "none",
                  color: "#48768C",
                  mt:'1px'

                }}
              >
                {title}
              </Typography>
            </Stack>

            <>
              {paymentStatus !== "failed" ? (
                <Typography
                  sx={{
                    ml: 1.25,
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    letterSpacing: "-0.02em",
                    fontSize: "14.5px",
                    textTransform: "none",

                    color: "#48768C",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    mt: "-2px",
                  }}
                >
                  {description}
                </Typography>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    ml: "10px",
                    mt: "1px",
                    mb: -1,

                  }}
                >
                  <Typography
                    sx={{
                      pr: 2,
                      fontWeight: 600,
                      letterSpacing: "0.02em",
                      fontSize: "13.75px",
                      color: "#48768C",
                      color: "#A65160",
                      whiteSpace: "wrap",
                    }}
                  >
                    {failedPaymentError}
                  </Typography>
                </Box>
              )}
            </>

            <Box sx={{ padding: 1 }}>
            <Box
  sx={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap", // Allows elements to wrap instead of overflowing
    mt: 0.5,
    width: "100%",
    border: "1px solid rgba(173, 216, 230, 0.7)",
    py: 1.5,
    borderRadius: "5px",
    pr: 2, // Reduce padding to give more space
    overflow: "hidden", // Prevents content from overflowing
    backgroundColor: "rgba(191, 218, 231, 0.1)",
  }}
>
  {subscriptionDetails && (
    <>
     <CardMedia
  sx={{
    border:
      renderLogo(subscriptionDetails?.card?.brand).src === CreditCard
        ? "1.95px solid rgba(173, 216, 230, 0.9)"
        : "1.5px solid rgba(173, 216, 230, 0.9)",
    width:
      renderLogo(subscriptionDetails?.card?.brand).src === CreditCard
        ? "70px"
        : "55px",
    height:
      renderLogo(subscriptionDetails?.card?.brand).src === CreditCard
        ? "70px"
        : "37px",
    borderRadius: "5px",
    ml: 1,
    backgroundColor:
      renderLogo(subscriptionDetails?.card?.brand).src === CreditCard
        ? "#FFFFFF"
        : "transparent",
    flexShrink: 0,
    display: "flex", // Ensures flex behavior
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically
    mr: 0.75,
  }}
>
  <img
    src={renderLogo(subscriptionDetails?.card?.brand ?? CreditCard)}
    alt="card"
    style={{
      width:
        renderLogo(subscriptionDetails?.card?.brand).src === CreditCard
          ? "70px"
          : "50px",
      height:
        renderLogo(subscriptionDetails?.card?.brand).src === CreditCard
          ? "70px"
          : "auto",
      objectFit: "contain",
      display: "block", // Prevents extra spacing issues
    }}
  />
</CardMedia>

      {/* Allow Stack to shrink properly */}
      <Stack direction="column" sx={{ mb: "2px", mr: 2, flex: 1, minWidth: 0 }}>
        <Typography
          align="left"
          sx={{
            color: "#48768C",
            fontWeight: 600,
            letterSpacing: "-0.025em",
            fontSize: subscriptionDetails?.card?.last4 ? "1rem" : "1.05rem",
            textTransform: "none",
            lineHeight: "1.5em",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            ml: subscriptionDetails?.card?.last4 ? 0 : ".5rem",
            minWidth: 0, // Prevents overflow
            mt:'1px'
          }}
        >
          {subscriptionDetails?.card?.last4
            ? `${subscriptionDetails.card.brand ? `${subscriptionDetails.card.brand.charAt(0).toUpperCase()}${subscriptionDetails.card.brand.slice(1)}` : ""} Ending in ${subscriptionDetails.card.last4}`
            : "No credit card on file"}
        </Typography>

        {subscriptionDetails?.card && (
          <Typography
            align="left"
            sx={{
              whiteSpace: "nowrap",
              color: "#48768CA8",
              fontWeight: 600,
              letterSpacing: "-0.015em",
              fontSize: ".85rem",
              textTransform: "none",
              lineHeight: "1.5em",
              minWidth: 0, // Prevents overflow
              overflow: "hidden",
              textOverflow: "ellipsis",
              mt:'-5px'
            }}
          >
            Expires: {`${subscriptionDetails?.card?.exp_month}/${subscriptionDetails.card?.exp_year}`}
          </Typography>
        )}
      </Stack>

      {/* Button should now stay within the box */}
      <Button
        onClick={
          subscriptionDetails?.card
            ? handleShowEditSubscription
            : handleShowNewSubscription
        }
        sx={{
          border: "1px solid #A4C5D2E8",
          borderRadius: "6px",
          color: "#48768C",
          backgroundColor: "#FFFFFF",
          fontSize: 13.76,
          ml: "auto",
          alignSelf: "center",
          px: 3, // Reduce padding to prevent overflow
          py: 0.5,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          maxWidth: "120px", // Prevent button from growing too much
          whiteSpace: "nowrap", // Prevent text from wrapping
          overflow: "hidden",
          textOverflow: "ellipsis",

          "&:hover": {
            backgroundColor: "#DBEAF0",
          },
          "&:active": {
            backgroundColor: "#DBEAF0",
          },
        }}
      >
        {subscriptionDetails?.card ? "Edit" : "Add"}
      </Button>
      </>
    )}
  </Box>
</Box>

            <Elements stripe={stripePromise}>
              <NewSubscription
                open={showNewSubscription}
                onClose={handleNewSubscriptionClose}
                customerId={customerId}
                priceId={priceId}
                onSubscriptionUpdate={onSubscriptionUpdate}
                planDetails={planDetails}
                stripePromise={stripePromise}
                subscriptionId={subscriptionId}
                billingAddress={billingAddress}
                subscriptionDetails={subscriptionDetails}
                billingDetailOne={billingDetailOne}
                billingDetailTwo={billingDetailTwo}
                billingDetailThree={billingDetailThree}
                billingDetailFour={billingDetailFour}
                billingDetailFive={billingDetailFive}
                billingValueOne={billingValueOne}
                billingValueTwo={billingValueTwo}
                billingValueThree={billingValueThree}
                billingValueFour={billingValueFour}
                billingValueFive={billingValueFive}

                planCost={planCost}
                totalPlanCost={totalPlanCost}
                promotion={promotion}
                discountName={discountName}
                discountPercentage={discountPercentage}
                discountedPlanCost={discountedPlanCost}
                loadingPlanDetails={loadingPlanDetails}
              />
              <EditSubscription
                open={showEditSubscription}
                onClose={handleEditSubscriptionClose}
                customerId={customerId}
                priceId={priceId}
                onSubscriptionUpdate={onSubscriptionUpdate}
                planDetails={planDetails}
                stripePromise={stripePromise}
                subscriptionId={subscriptionId}
                refetchSubscriptionDetails={refetchSubscriptionDetails}
                billingAddress={billingAddress}
                subUserData={subUserData}
                billingHistory={billingHistory}
                billingContact={billingContact}
                fetchBillingHistory={fetchBillingHistory}
                handleSubscriptionUpdate={handleSubscriptionUpdate}
                subscriptionDetails={subscriptionDetails}
                billingDetailOne={billingDetailOne}
                billingDetailTwo={billingDetailTwo}
                billingDetailThree={billingDetailThree}
                billingDetailFour={billingDetailFour}
                billingDetailFive={billingDetailFive}
                billingValueOne={billingValueOne}
                billingValueTwo={billingValueTwo}
                billingValueThree={billingValueThree}
                billingValueFour={billingValueFour}
                billingValueFive={billingValueFive}

                planCost={planCost}
                totalPlanCost={totalPlanCost}
                promotion={promotion}
                discountName={discountName}
                discountPercentage={discountPercentage}
                discountedPlanCost={discountedPlanCost}
                loadingPlanDetails={loadingPlanDetails}

              />
            </Elements>
            <Button
              onClick={handleShowNewSubscription}
              sx={{
                display: "none",
                border: "1px solid #48768C",
                borderRadius: "10px",
                padding: "8px",
                color: "#48768C",
                backgroundColor: "#FFFFFF",
                ml: 0.25,
                mt: 2,
                mb: 1,
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "#DBEAF0",
                },
                "&:active": {
                  backgroundColor: "#DBEAF0",
                },
              }}
              color="primary"
            >
              Activate Your Subscription
            </Button>
            <Button
              onClick={handleCancelClick}
              disabled={isCancelling}
              sx={{
                display: "none",
                border: "1px solid #48768C",
                borderRadius: "10px",
                padding: "8px",
                color: "#48768C",
                backgroundColor: "#FFFFFF",
                ml: 0.25,
                mt: 2,
                mb: 1,
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "#DBEAF0",
                },
                "&:active": {
                  backgroundColor: "#DBEAF0",
                },
              }}
              color="primary"
            >
              {isCancelling ? "Cancelling..." : "Cancel Subscription"}
            </Button>
          </Card>

      ) : (
        <Card
          sx={{
            width: "100%",
            padding: 1.5,
            backgroundColor: "rgba(191, 218, 231, 0.1)",
            border: "1px solid rgba(173, 216, 230, 0.7)",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "#48768CBF",
              mr: "5px",
              whiteSpace: "nowrap",
              fontWeight: 500,
              letterSpacing: "-0.03em",
              fontSize: "18px",
              textTransform: "none",
              justifyContent: "center",
              alignItems: "center",
              fontStyle: "italic",
            }}
          >
            Loading billing information...
          </Typography>
        </Card>
      )}
    </>
  );
};

export default BillingDataCard;
