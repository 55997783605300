import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Paper,
  IconButton,
  TextField,
  Typography,
  Stack,
  Popover,
  Icon,
  FormControlLabel,
  Switch,
  Fade
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import LightTooltip from "@mui/material/Tooltip"; // Make sure you have this imported
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useUserSelectedNotifications } from "src/context/UserNotificationsContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";
import { useAccountContext } from "src/context/AccountContext";
import { useStateManager } from "../hooks/useStateManager";
import IconGrid from "../../IconGrid.js";
import icons from "../../../../../../../public/assets/icons/messageModal";
import IconPopover from "./IconPopover";
import { customTheme } from "./textField";
import FieldSelectorPopover from "./FieldSelectorPopover";
import { RenderSelectedField } from "./RenderSelectedField";
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import WallpaperTwoToneIcon from '@mui/icons-material/WallpaperTwoTone';
import { styled } from "@mui/material/styles";

import "./styles.css";


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#48B09B",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#6595ab",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddField = ({
  handleKeyDown,
  fieldName,
  handleSetFieldName,
  selectedIcon,
  handleSelectedIcon,
  handleClearFieldName,
  handleSaveFieldData,
  selectedNotificationType,
  handleSelectFieldType,
  setSelectedNotificationType,
  handleClearFieldData,
  fieldDataSaved,
  fieldNamePlaceholder,
  colors,
  enableClearButton,
  contextLabel,
  showContextLabel,
  toggleRequiredContextLabel,
  fieldType,
  handleChangeContextLabel

}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isEditingIcon, setIsEditingIcon] = useState(false);
  const [newFieldData, setNewFieldData] = useState({ fieldName: "" });
  const [isAddedNewField, setIsAddedNewField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [newFieldAnchorEl, setNewFieldAnchorEl] = useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const [fieldAnchorEl, setFieldAnchorEl] = useState(null);
  const [isEditingFieldName, setIsEditingFieldName] = useState(false);
  const [isEditingContextLabel, setIsEditingContextLabel] = useState(false);
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const { accountData } = useAccountContext();
  const [iconOpen, setIconOpen] = useState(false);
  const [fieldOpen, setFieldOpen] = useState(false);
  const [fieldButtonEnabled, setFieldButtonEnabled] = useState(false);


  const trimValue = (value, allowPunctuation = false) => {
    if (typeof value !== "string") return value;
    let trimmedValue = value.trim(); // Remove leading and trailing whitespace
  
    // Check if the trimmed value is empty (i.e., only spaces were entered)
    if (trimmedValue === "") {
      return ""; // Return empty string if only spaces were entered
    }
  
    if (allowPunctuation) {
      return trimmedValue.replace(/\s+$/, ""); // Remove only whitespace from the end
    }
    
    return trimmedValue.replace(/[\s\.,;!?]+$/, ""); // Remove trailing whitespace and punctuation
  };

  useEffect(() => {
    const enableFieldButtons = fieldName.trim() !== "" && selectedIcon !== null;
    setFieldButtonEnabled(enableFieldButtons);
  }, [fieldName, selectedIcon]);

  const handleIconPopoverOpen = (event) => {
    setFieldAnchorEl(event.currentTarget); // Set the clicked element as the anchor
    setIconOpen(true); // Open the popover
  };

  // Handle closing of popover
  const handleIconPopoverClose = () => {
    setFieldAnchorEl(null);
    setIconOpen(false);
    setIsEditingIcon(false);
  };

  const handleFieldPopoverOpen = (event) => {
    setFieldAnchorEl(event.currentTarget); // Set the clicked element as the anchor
    setFieldOpen(true); // Open the popover
  };

  // Handle closing of popover
  const handleFieldPopoverClose = () => {
    setFieldAnchorEl(null);
    setFieldOpen(false);
  };

  const renderIcon = (iconName) => {
    if (iconName) {
      const iconPath = icons[iconName];
      return (
        <img src={iconPath} alt={iconName} className="default_field_icon" />
      );
    }
    return null;
  };



  return (
    <>

                <Typography
                  noWrap
                  sx={{
                    color: `#48768C` ,
                    borderRadius: "8px",
                    fontSize: 16,
                    fontWeight: 600,
                    textAlign: "left",
                    letterSpacing: "-.02em",
                    lineHeight: "-2%",
    
                    ml: 0.25,
                    mb: 0.5,
                    mt: 2,
                  }}
                >
               {fieldType}
                </Typography>
             <Box
                  sx={{
                    border: "1px solid rgba(173, 216, 230, 0.9)",
                    padding: 1,
                    borderRadius: "10px",
                    width:'100%'
                  }}>
               

   <Stack direction='row' sx={{alignItems:'center', width:'98%', justifyContent:'center', px:3, pt:1 }}>
       
  <Box
        onClick={(event) => {
          handleIconPopoverOpen(event);
          setIsEditingIcon(true);
        }}
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px",
      mr: 1,
      borderRadius: "3px",
      height: "37px",
      backgroundColor: "transparent",
      border: "1px solid rgba(173, 216, 230, 0.9)",
      minWidth: "37px",
      ml: .5,
      cursor:'pointer'
    }}
  >
      {!selectedIcon ? (
    <WallpaperTwoToneIcon

      sx={{ color: "#7589A2", fontSize: "28px" }}
    />

) : (
<>
    {renderIcon(selectedIcon)}
    </>
  )}

  </Box>

          {/* Import and use IconPopover */}
          <IconPopover
            fieldOpen={iconOpen}
            fieldAnchorEl={fieldAnchorEl}
            handleIconPopoverClose={handleIconPopoverClose}
            isEditingIcon={isEditingIcon}
            handleSelectedIcon={handleSelectedIcon}
          />
      {!fieldDataSaved && (
        
        <Box
          sx={{
            
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "1px",
            minWidth: "100%",
            justifyContent:'center',
            borderRadius: "4px",
            height: "37px",
            backgroundColor: "transparent",
            border: "1px solid rgba(173, 216, 230, 0.9)",




          }}
        >
 

          {/* TextField for New Field Name */}
          <ThemeProvider theme={customTheme}>
          <TextField
  hiddenLabel
  placeholder={fieldNamePlaceholder}
  variant="filled"
  size="small"
  name="fieldName"
  value={fieldName || ""}
  onChange={(e) => {
    let value = e.target.value;

    // Remove any special characters or numbers (allowing spaces)
    value = value.replace(/[^a-zA-Z\s]/g, "");

    // Limit the length to 20 characters
    if (value.length > 20) {
      value = value.slice(0, 20);
    }

    // Capitalize the first letter of the input
    handleSetFieldName(value.charAt(0).toUpperCase() + value.slice(1));
  }}
  onKeyDown={handleKeyDown}
  sx={{
    alignContent: "center",
    ml: 1,
    width: 411,
    mr: 0.5,
    input: {
      color: "#48768C",
      fontWeight: 600,
      fontSize: "13.5px",
      padding: 0,
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      fontFamily: "Source Sans Pro, sans-serif",
    },
  }}
/>

            {/* Action Buttons */}
            <Stack
  sx={{
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    justifyContent: "flex-end",
    pr: 0.85, // Corrected syntax issue
  }}
>
  <LightTooltip
    title="Select an icon and enter a field name to continue"
    disableHoverListener={fieldButtonEnabled}
  >
    <span>
      <Fade in={fieldName !== ""} timeout={400}>
        <IconButton
          disabled={!fieldButtonEnabled}
          onClick={handleSaveFieldData}
          className="field_save_button"
        >
          <CheckCircleTwoToneIcon
            sx={{
              ml: 0.5,
              fontSize: "21px",
              border: "1px solid #747C86",
              borderRadius: "5px",
              color: !fieldButtonEnabled ? "#BEBEBE" : "#48B09B",
            }}
          />
        </IconButton>
      </Fade>
    </span>
  </LightTooltip>

  <Fade in={fieldName !== ""} timeout={400}>
    <IconButton
      className="icon_cancel_button"
      onClick={handleClearFieldName}
    >
      <HighlightOffTwoToneIcon
        sx={{
          fontSize: "21px",
          border: "1px solid #747C86",
          borderRadius: "5px",
        }}
      />
    </IconButton>
  </Fade>
</Stack> 
          </ThemeProvider>
        </Box>
      )}

      {/* Added Field Display */}
      {fieldDataSaved && (
        <Box
          sx={{
        
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "5px",
            minWidth: "100%",
        
            borderRadius: "4px",
            height: "37px",
            backgroundColor: "#FFFFFF",
            border: "1px solid rgba(173, 216, 230, 0.9)",
          }}
        >




{selectedNotificationType === "TMR" ? (
  <Box sx={{ width: "100%", display: "flex", alignItems: "center", gap: 2 }}>
    <Typography sx={{ alignItems: "center" }} className="field_title">
      {fieldName}:
    </Typography>
    <Box sx={{ justifyContent: "flex-end", display: "flex", flexGrow: 1 }}>
      {RenderSelectedField(selectedNotificationType || "")}
    </Box>
  </Box>
) : (
  <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 2 }}>
    <Typography sx={{ alignItems: "center",  }} className="field_title">
      {fieldName}:
    </Typography>
    <Box>{RenderSelectedField(selectedNotificationType || "")}</Box>
  </Box>
)}

          {/* Action Buttons for Saving and Editing */}
          <Box
            sx={{ marginLeft: "auto", display: "flex", alignItems: "center" }}
          >
            {!selectedNotificationType && (
              <Typography
                onClick={(event) => handleFieldPopoverOpen(event)}
                sx={{
                  border: "1.5px solid #48768C",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  fontSize: "14px",
                  borderRadius: "4px",
                  fontWeight: 700,
                  flexShrink: 0,
                  cursor: "pointer",
                  color: "#48768C",
                  mr: 2,
                  transition: "box-shadow 0.3s, background-color 0.3s",
                  "&:hover": {
                    backgroundColor: "#E6EDF2", // Change background color on hover
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Add box shadow on hover
                  },
                }}
              >
                Select a field type
              </Typography>
            )}
            <Stack direction="row">
              {selectedNotificationType && (
                <IconButton onClick={(event) => handleFieldPopoverOpen(event)}>
                  <SettingsTwoToneIcon className="popover_edit_button" />
                </IconButton>
              )}
            </Stack>

            {/* Cancel Button */}
            <IconButton
              className="icon_cancel_button"
              onClick={handleClearFieldData}
            >
              <HighlightOffTwoToneIcon
                sx={{
                  fontSize: "21px",
                  border: "1px solid #747C86",
                  borderRadius: "5px",
                }}
              />
            </IconButton>
          </Box>
          <FieldSelectorPopover
            fieldOpen={fieldOpen}
            fieldAnchorEl={fieldAnchorEl}
            handleFieldPopoverClose={handleFieldPopoverClose}
            handleSelectFieldType={handleSelectFieldType}
            closePopover={handleFieldPopoverClose}
            onSelectField={handleSelectFieldType}
            selectedNotificationType={selectedNotificationType}
            setSelectedNotificationType={setSelectedNotificationType}
            colors={colors}
          />
        </Box>
      )}
    </Stack>


      {showContextLabel && (
                    <>
                  <Typography
                    noWrap
                    sx={{
                      color: `#48768C` ,
                      borderRadius: "8px",
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: "left",
                      letterSpacing: "-.02em",
                      lineHeight: "-2%",
                      mt: 1.5,
                      ml: 0.25,
                      mb: 0.5,
                    }}
                  >
                    Context Label
                  </Typography>
                  <ThemeProvider theme={customTheme}>
                    <Box
                      sx={{
                        border: "1px solid rgba(173, 216, 230, 0.9)",
                        padding: 1,
                        borderRadius: "6px",
                      }}
                    >
                      <TextField
                        hiddenLabel
                        placeholder="Add Context Label"
                        variant="filled"
                        size="small"
                   
                        rows={1} // Increase the number of rows to control height
                        multiline
                        value={contextLabel}
                        onKeyDown={handleKeyDown}
                        inputProps={{ maxLength: 55 }}
                        onChange={(e) => {
                          const value = e.target.value;
                          handleChangeContextLabel(
                            value.charAt(0).toUpperCase() + value.slice(1),
                          );
                        }}
                        sx={{
                          ml: 0.15,
                          width: "95%",
                          mr: 0.5,
    
                          padding: 0,
                          backgroundColor: "#FFFFFF",
    
                          "& .MuiInputBase-root": {
                            display: "flex",
                            alignItems: "center",
    
                            backgroundColor: "#FFFFFF",
    
                            padding: 0,
                          },
    
                          "& textarea": {
                            color: "#48768C",
                            fontWeight: 600,
                            fontSize: "13.5px",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "Source Sans Pro, sans-serif",
                            padding: 0,
                          },
                        }}
                      />
                    </Box>
                  </ThemeProvider>
                  </>
                  )}
    
    
                  <Stack direction="row" sx={{ alignItems: "center", mt: 1 }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 0.75, transform: "scale(0.75)", ml: 1.25 }}
                          checked={showContextLabel}
                          onChange={toggleRequiredContextLabel}
                        />
                      }
                    />
                    <Typography
                      noWrap
                      sx={{
                        color: `#48768C` ,
                        borderRadius: "8px",
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "left",
                        letterSpacing: "-.02em",
                        lineHeight: "-2%",
                        textAlign: "center",
                        ml: -1.75,
                      }}
                    >
                      Add Context Label
                    </Typography>
                  </Stack>
                </Box>
                </> 
  );
};

export default AddField;
