import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "./AuthContext";
import { useSingleUser } from "./SingleUserContext";
import { CustomSnackbar } from "../components/snackbar/snackbar.js"; // Import the CustomSnackbar component

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, getAccessTokenSilently } = useAuth0();
  const { username, setSubUserData } = useAuth();
  const { updateSingleUserData } = useSingleUser();

  const [reloadComponents, setReloadComponents] = useState(false);
  const [userSubData, setUserSubData] = useState({
    location_name: "",
    active_location: "",
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    success: "",
  });

  const handleReloadComponents = () => {
    setReloadComponents((prev) => !prev);
  };

  const updateUserData = (newUserData) => {
    setUserSubData(newUserData);
  };

  const updateActiveLocation = (newActiveLocation) => {
    setUserSubData((prevUserData) => ({
      ...prevUserData,
      active_location: newActiveLocation,
    }));
  };

  const fetchLatestUserData = async () => {
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/api/users/profile/${user.sub}/${username}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const userData = await response.json();

      if (response.ok && userData) {
        setUserSubData(userData);
        setSubUserData(userData);
        setReloadComponents((prev) => !prev);
      } else {
        console.error("Failed to fetch latest user data");
      }
    } catch (error) {
      console.error("Error fetching latest user data:", error);
    }
  };

  // Function to show Snackbar
  const showSnackBar = (message, success) => {
    // Set the Snackbar properties based on success or failure
    setSnackbar({
      open: true,
      message,
      success,
    });
  };

  return (
    <AppContext.Provider
      value={{
        reloadComponents,
        handleReloadComponents,
        userSubData,
        updateUserData,
        updateActiveLocation,
        fetchLatestUserData,
        showSnackBar,
      }}
    >
      {children}
      {/* Render the CustomSnackbar component */}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        success={snackbar.success}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return {
    ...context,
    fetchLatestUserData: context.fetchLatestUserData,
    showSnackBar: context.showSnackBar, // Include showSnackBar function in the hook return value
  };
};
