import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TransferList from "./TransferList";
import { useAccountContext } from "src/context/AccountContext";

const StepTwo = ({
  firstName,
  lastName,
  newUserName,
  setUserName,
  pin,
  userName,
  confirmNewPin,
  showPin,
  showConfirmNewPin,
  isAssignEmployeeVisible,
  isMoveUserVisible,
  setFirstName,
  setLastName,
  setPin,
  handleConfirmNewPinChange,
  handleTogglePinVisibility,
  toggleShowConfirmNewPin,
  setIsAssignEmployeeVisible,
  users,
  subUserData,
  isSaveButtonEnabled,
  isLoading,
  showNewUserPin,
  confirmNewUserNewPin,
  name,
  handleToggle,
  checked,
  left,
  right,
  handleCheckedRight,
  handleCreateNewEmployeeClick,
  handleCheckedLeft,
  isNewUserSaved,
  setNewUserSaved,
  isSaveUserButtonEnabled,
  handleSaveNewUserClick,
  handleNewUserNameChange,
  setLeft,
  userNameTaken,
  hideCreateUserButton,
  setHideCreateUserButton,
  setUserNameTaken,
}) => {
  const { accountData } = useAccountContext();

  useEffect(() => {
    if (accountData.single_user) setIsAssignEmployeeVisible(true);
  }, [accountData.single_location]);

  useEffect(() => {
    if ((right && right.length > 0) || isAssignEmployeeVisible) {
      setHideCreateUserButton(true);
    }
  }, [right, isAssignEmployeeVisible]);

  useEffect(() => {
    if (left && left.length > 0 && !isAssignEmployeeVisible)  {
      setHideCreateUserButton(false);
    }
  }, [left]);

  useEffect(() => {
    if (userName) {
      setHideCreateUserButton(true);
    }
  }, [userName]);



  return (
    <Box
      sx={{
        backgroundColor: "#F2F9FF",
        border: "1.25px solid rgba(173, 216, 230, 0.5)",
        borderRadius: "14px",
        padding: 2,
        mt: 2,
      }}
    >
      <Typography sx={{ color: "#48768C", mb: 1.5, fontWeight: 600, pt:1, pb:2 }}>
        {accountData.single_user
          ? "Create Your New Employee"
          : "Add Your New Location's First Employee"}
      </Typography>
      {accountData.single_user && (
        <Typography
          sx={{
            color: "#48768C",
            mb: 2.5,
            fontWeight: 600,
            lineHeight: "-2%",
            letterSpacing: "-2%",
            fontSize: "12.5px",
          }}
        >
          Each employee will have their own unique username and pin to use after
          loggging in using the email and password you already use.
        </Typography>
      )}

      {isAssignEmployeeVisible ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "97%",
              pr: 3,
              ml: 1,
              mt: 1,
              pb: 1,
            }}
          >
            <TextField
              placeholder="First Name*"
              variant="outlined"
              size="small"
              fullWidth
              value={firstName}
              onChange={(e) => {
                const newValue = e.target.value
                  .replace(/[^A-Za-z]/g, "")
                  .slice(0, 10);
                const capitalizedValue =
                  newValue.charAt(0).toUpperCase() + newValue.slice(1);
                setFirstName(capitalizedValue);
              }}
              sx={{
                mr: 2,
                color: "#48768C",
                "& input": {
                  height: "23.5px",
                  textAlign: "left",
                  color: "#48768C",
                },
              }}
            />
            <TextField
              placeholder="Last Name*"
              variant="outlined"
              size="small"
              fullWidth
              value={lastName}
              onChange={(e) => {
                const newValue = e.target.value
                  .replace(/[^A-Za-z]/g, "")
                  .slice(0, 10);
                const capitalizedValue =
                  newValue.charAt(0).toUpperCase() + newValue.slice(1);
                setLastName(capitalizedValue);
              }}
              sx={{
                color: "#48768C",
                "& input": {
                  height: "23.5px",
                  textAlign: "left",
                  color: "#48768C",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "97.5%",
              ml: 1,
              mb: 2,
              mt: 1,
              pr: 3,
            }}
          >
            <TextField
              error={userNameTaken}
              helperText={userNameTaken && "Username Taken"}
              placeholder="Username*"
              size="small"
              variant="outlined"
              fullWidth
              value={userName}
              onChange={(e) => {
                const newValue = e.target.value
                  .replace(/[^A-Za-z]/g, "")
                  .slice(0, 10);
                setUserName(newValue);

                // Check if the username is taken
                if (
                  Array.isArray(users) &&
                  accountData &&
                  !accountData.single_user
                ) {
                  const isTaken = users.some(
                    (user) => user?.username === newValue,
                  );
                  setUserNameTaken(isTaken);
                } else {
                  setUserNameTaken(false); // Default to false if users is not an array
                }
              }}
              inputProps={{
                maxLength: 15,
                pattern: "[A-Za-z0-9]*",
              }}
              sx={{
                color: "#48768C",
                "& input": {
                  height: "23.5px",
                  textAlign: "left",
                  color: "#48768C",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              width: "97%",
              ml: 1,
              mt: 1,
              mb: 0,
            }}
          >
            <TextField
              placeholder="Enter PIN*"
              variant="outlined"
              size="small"
              type={showPin ? "text" : "password"}
              value={pin}
              onChange={(e) => {
                let inputValue = e.target.value.replace(/\D/g, "").slice(0, 4);
                setPin(inputValue);
              }}
              sx={{
                color: "#48768C",
                "& input": {
                  height: "23.5px",
                  textAlign: "left",
                  color: "#48768C",
                  mr: 3,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePinVisibility} edge="end">
                      {showPin ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ width: "25px" }} />
            <TextField
              placeholder="Confirm PIN*"
              variant="outlined"
              size="small"
              type={showConfirmNewPin ? "text" : "password"}
              value={confirmNewPin}
              onChange={handleConfirmNewPinChange}
              sx={{
                color: "#48768C",
                "& input": {
                  height: "23.49px",
                  textAlign: "left",
                  color: "#48768C",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleShowConfirmNewPin} edge="end">
                      {showConfirmNewPin ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            visibility: accountData.single_location ? "visible" : "hidden", // Prevents shifting when hidden
            height: accountData.single_location ? "auto" : 0, // Collapses height when hidden
          }}
        >
          <Typography
            sx={{
              color: "#48768C",
              fontSize: 14.5,
              fontWeight: 500,
              textAlign: "center",
              mb: 2,
              letterSpacing: "-2%",
            }}
          >
            Would you like to assign an existing employee to this location?
          </Typography>
          <TransferList
            users={users}
            subUserData={subUserData}
            name={name}
            handleToggle={handleToggle}
            checked={checked}
            left={left}
            right={right}
            handleCheckedRight={handleCheckedRight}
            handleCheckedLeft={handleCheckedLeft}
            setLeft={setLeft}
          />
        </Box>
      )}

      {!accountData.single_user &&(
        <>
        <Typography    sx={{   fontSize: "18.5px",
                  fontWeight: 600,
                  color: "#48768C",
                  letterSpacing: "-.02em",
                  whiteSpace: "nowrap",
                  my:2}}> Or </Typography>
        <Button
          onClick={handleCreateNewEmployeeClick}
          sx={{
            border: "1px solid #48768C",
            borderRadius: "7px",
            padding: "5px",
            color: "#48768C",
            backgroundColor: "#FFFFFF",
            mb: 1,
         
            px: 3,

            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "#DBEAF0",
            },
            "&:active": {
              backgroundColor: "#DBEAF0",
            },
          }}
        >
        {!isAssignEmployeeVisible ? "Create New Employee Instead" : "Transfer Existing Employee Instead" }
        </Button>
        </>
      )}
    </Box>
  );
};

export default StepTwo;
