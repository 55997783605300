import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Paper,
  IconButton,
  Typography,
  Stack,
  Modal,
  Divider,
  Switch,
  FormControlLabel,
  TextField,
  Fade,
  Checkbox,
  Accordion
} from "@mui/material";
import EditIcon from "src/public/assets/icons/icons/cancel.png";
import PreviewIcon from "src/public/assets/icons/icons/preview.png";
import PreviewDisabledIcon from "src/public/assets/icons/icons/previewDisabled.png";
import ConfirmIcon from "src/public/assets/icons/icons/confirm.png";
import ConfirmDisabledIcon from "src/public/assets/icons/icons/confirmDisabled.png";
import AddField from "../components/AddField";
import NotificationTitle from "../components/NotificationTitle";
import RequiredField from "../components/RequiredField";
import AddRequiredField from "../components/AddField";
import OptionalField from "../components/OptionalField";
import PreviewNotification from "../components/PreviewNotification";
import { useNotificationState } from "../hooks/useNotificationState";
import { useStateManager } from "../hooks/useStateManager";
import { useAccountContext } from "src/context/AccountContext";
import { useAuth } from "src/context/AuthContext";
import { renderPreviewFieldTypes } from "../hooks/renderPreviewFieldTypes.js";
import useColorPicker from "src/components/AccountPage/Sections/UserNotifications/Components/AddNewNotification/components/ColorPicker/useColorPicker.js"; // Import the custom hook
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import PlusIcon from "src/public/assets/icons/icons/plus.png";
import HelpCenterTwoToneIcon from "@mui/icons-material/HelpCenterTwoTone";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { customTheme } from "../components/textField";
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import NewPreviewNotification from "../components/NewPreviewNotification";
import TipsAndTricksDialog from "src/pages/HelpCenter/Components/CustomerService/tipsDialog/TipsDialog";
import TemplateDialog from "../TemplateDialog";
import TwoWayPromptDialog from "../components/TwoWayPromptDialog";



const CreateNewModal = ({
  isModalOpen,
  handleCloseModal,
  createNew,
  setCreateNew,
  handleEnableClick,
  selectedNotifications,  
  existingNotificationName,
  setExistingNotificationName,


}) => {
  const [addRequiredField, setAddRequiredField] = React.useState(false);
  const [addOptionalField, setAddOptionalField] = React.useState(false);
  const {
    handleKeyDown,
    notificationTitle,
    requiredFieldName,
    optionalFieldName,
    handleSetTitle,
    handleSetRequiredFieldName,
    handleSetOptionalFieldName,
    setSelectedIcon,
    selectedIcon,
    handleCleaRequiredFieldName,
    requiredFieldDataSaved,
    selectedNotificationType,
    setSelectedNotificationType,
    handleSelectFieldType,
    handleSaveRequiredFieldData,
    handleSaveNewFieldData,
    handleClearRequiredFieldData,
    setOptionalIcon,
    optionalIcon,
    handleClearOptionalFieldName,
    optionalFieldDataSaved,
    optionalSelectedNotificationType,
    setOptionalSelectedNotificationType,
    handleSelectOptionalFieldType,
    handleClearOptionalFieldData,
    handleSaveOptionalFieldData,
    handleClearTitle,
    handlePrintFieldTitle,
    printedFieldTitle,
    isTitleDefault,
    isTitleValid,
    setIsTitleValid,
    contextMessage, 
    setContextMessage,
    requiredContextLabel, 
    setRequiredContextLabel,
    optionalContextLabel, 
    setOptionalContextLabel,
    handleSetOptionalContextLabel,
    handleSetRequiredContextLabel,
    handleSetContextMessage,
    handleResetFields,
    setNotificationTitle,
    isDefault, 
    setIsDefault,
    toggleIsDefault,
    toggleTwayWayEnabled, 
    twoWayEnabled,
    setTwoWayEnabled,
    setShowOptionalContextLabel,
    showOptionalContextLabel,
    setShowRequiredContextLabel,
    showRequiredContextLabel,
  } = useNotificationState();
  const [requiredFieldButtonEnabled, setRequiredFieldButtonEnabled] =
    useState(false);
  const [saveNotificationButtonEnabled, setSaveNotificationButtonEnabled] =
    React.useState(false);
  const [confirmClear, setConfirmClear] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { accountData, fetchOnboardingRecord } = useAccountContext();
  const { subUserData } = useAuth();
  const { selectedMockData, optionalMockData } = renderPreviewFieldTypes(
    selectedNotificationType,
    optionalSelectedNotificationType,
  );
  const [isLoading, setIsLoading] = useState(false);
  const { colors, handleIconClick, handleClearColors } = useColorPicker(); // Use the custom hook
  const [enableClearButton, setEnableClearButton] = useState(false);

  const companyName = accountData?.company_name;
  const phoneNumber = subUserData?.location_phone;
  const email = subUserData?.email;
  const website = accountData?.company_website;
  const [loading, setLoading] = useState(false)
  const [showPreviewNotificationDialog, setShowPreviewNotificationDialog] = useState(false)
  const [showTipsDialog, setShowTipsDialog] = useState(false)
    const [showConfirmTwoWayDialog, setShowConfirmTwoWayDialog] = useState(false);
  
  
    const handleCloseConfirmTwoWayDialog = () => {
      setShowConfirmTwoWayDialog(false);
    };


  const handleOpenTipsDialog = () => {
    setShowTipsDialog(true)
  }

  const handleCloseTipsDialog = () => {
    setShowTipsDialog(false)
  }

  

 const handleOpenPreview = () => {
  setShowPreviewNotificationDialog(true);
 }

 const handleClosePreviewNotificationDialog = () => {
  setShowPreviewNotificationDialog(false);
 }




  useEffect(() => {
    const enableRequiredFieldButtons =
      requiredFieldName !== "" && selectedIcon !== null;
    setRequiredFieldButtonEnabled(enableRequiredFieldButtons);
  }, [requiredFieldName, selectedIcon]);

  // Enable Save And Submit Notification To Backend API, with additional optional field logic
  useEffect(() => {
    const enableSaveNotificationButton =
      notificationTitle !== "" &&
      colors.isDefault !== true &&
      requiredFieldName !== "" &&
      selectedIcon !== null &&
      selectedNotificationType !== null &&
      !(
        (optionalIcon !== null || optionalFieldName !== "") &&
        optionalSelectedNotificationType === null
      );
  
    // Ensure button is disabled if optionalSelectedNotificationType is null but optionalContextLabel is not empty
    if (optionalSelectedNotificationType === null && optionalContextLabel !== "") {
      setSaveNotificationButtonEnabled(false);
    } else {
      setSaveNotificationButtonEnabled(enableSaveNotificationButton);
    }
  }, [
    notificationTitle,
    requiredFieldName,
    selectedIcon,
    selectedNotificationType,
    optionalIcon,
    optionalFieldName,
    optionalSelectedNotificationType,
    optionalContextLabel,
    colors.isDefault,
  ]);

  const handleConfirmClear = () => {
    setConfirmClear(true);
  };

  const handleCancelButtonClick = () => {
    setCreateNew(false);
  };

  const handleKeepEditing = () => {
    setConfirmClear(false);
  };



  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitFieldData = async () => {
    try {
      setIsLoading(true);
      await handleSaveNewFieldData(colors.titleColor, handleCloseModal, accountData, fetchOnboardingRecord ); // This will wait for handleSaveNewFieldData to finish

    } catch (error) {
      console.error("Error saving field data:", error);
      // Optionally handle the error, e.g. show a message to the user
    } finally {
      setIsLoading(false); // This will always run after handleSaveNewFieldData
      setCreateNew(false);
    }
  };

  const handleCancelOptionalField = () => {
    handleClearOptionalFieldData();
    setAddOptionalField(false);
  };

const [showAddContextMessage, setShowAddContextMessage] = useState(false)
const [showOptionalField, setShowOptionalField] = useState(false)

const toggleAddContextMessage = () => {
  setShowAddContextMessage((prev) => {
    if (prev) setContextMessage(''); // Clear state when turning off
    return !prev;
  });
};



const toggleOptionalField = () => {
  setShowOptionalField((prev) => {
    const newValue = !prev;

    // If setting it to false, clear the data
    if (!newValue) {
      handleClearOptionalFieldData();
      setOptionalContextLabel('');
      setShowOptionalContextLabel(false)
    }

    return newValue;
  });
};

const toggleRequiredContextLabel = () => {
  setShowRequiredContextLabel((prev) => {
    if (prev) setRequiredContextLabel(''); // Clear state when turning off
    return !prev;
  });
};

const toggleOptionalContextLabel = () => {
  setShowOptionalContextLabel((prev) => {
    if (prev) setOptionalContextLabel(''); // Clear state when turning off
    return !prev;
  });
};


useEffect(() => {
  setLoading(true);
  if(isModalOpen) {
    handleResetFields();
    handleClearColors();
    setShowOptionalContextLabel(false);
    setShowRequiredContextLabel(false);
    setShowAddContextMessage(false);
    setShowOptionalField(false);

    setLoading(false)
  }

}, [isModalOpen])





const [showCreateNew, setShowCreateNew] = useState(false);
const [activeTitle, setActiveTitle] = useState("");






const [showConfirmation, setShowConfirmation] = useState(false);  

 const checkTwoWayNotifications = async () => {
    if (
      !selectedNotifications ||
      !Array.isArray(selectedNotifications) ||
      selectedNotifications.length === 0
    ) {
      console.error(
        "selectedNotifications is not properly defined or is empty",
      );
      return;
    }

    // Flatten the selectedNotifications array to easily search all fields
    const allNotifications = selectedNotifications.flatMap((notification) => {
      const fields = notification.selected_data || {};
      return Object.keys(fields).map((notificationType) => ({
        notificationType,
        ...fields[notificationType].Configs,
        Fields: fields[notificationType].Fields,
      }));
    });

    // Find if there are any fields with twoWayEnabled: "true"
    const twoWayEnabledNotifications = allNotifications.filter((notification) =>
      Object.values(notification.Fields || {}).some(
        (field) => field.twoWayEnabled === "true",
      ),
    );

    if (twoWayEnabledNotifications.length === 0) {
      return;
    }

    // If there's already one two-way enabled notification, handle that
    const existingNotification = twoWayEnabledNotifications[0]; // Only one can be enabled
    if (existingNotification) {
      setShowConfirmation(true)
      setExistingNotificationName(existingNotification.notificationType);
            return;
    }
  }



  useEffect(() => {
    if(isModalOpen) {
      setShowCreateNew(false);
      setTwoWayEnabled(false);  
      checkTwoWayNotifications();

    }
  }, [isModalOpen]);


  useEffect(() => {
    if (Array.isArray(selectedNotifications) && 
        selectedNotifications.some(notification => notification?.selected_data === null)) {
      setIsDefault(true);
    }
  }, [selectedNotifications]);




const handleButtonClick = () => { 
  if (!showCreateNew && activeTitle === "Create New Notification Type") {
    setShowCreateNew(true);
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
  }
  if (activeTitle === "Ready For Pickup") {
    setNotificationTitle("Ready For Pickup")
    handleSetRequiredFieldName("Balance")
    handleIconClick("#2BBB9E")
    handleSelectFieldType("CUR")
    setSelectedIcon("balance")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
        handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("Your order is ready for pickup!")
    setRequiredContextLabel("Balance due at pickup")
    toggleRequiredContextLabel(true)
    toggleAddContextMessage(true)
  }
  if (activeTitle === "Out For Delivery") {
    setNotificationTitle("Out For Delivery")
    handleSetRequiredFieldName("Date/Time")
    handleIconClick("#7589A2")
    handleSelectFieldType("DATE/TIME")
    setSelectedIcon("deliver")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("Your order is out for delivery!")
    toggleAddContextMessage(true)
    setRequiredContextLabel("Estimated delivery by")
    toggleRequiredContextLabel(true)

  }
  if (activeTitle === "Item Shipped") {
    setNotificationTitle("Item Shipped")
    handleSetRequiredFieldName("Date Shipped")
    handleIconClick("#7589A2")
    handleSelectFieldType("DATE")
    setSelectedIcon("rocket")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("Your order is on it's way!")
    toggleAddContextMessage(true)
    setRequiredContextLabel("Shipped on")
    toggleRequiredContextLabel(true)
    handleSetOptionalFieldName("Delivery Date")
    handleSelectOptionalFieldType("DATE")
    setOptionalIcon("deliver")
    handleSaveOptionalFieldData()
    setShowOptionalField(true)
    setShowOptionalContextLabel(true)
    setOptionalContextLabel("Expected delivery by")
    setShowCreateNew(true);
  }
  if (activeTitle === "Delivery Scheduled") {
    setNotificationTitle("Delivery Scheduled")
    handleSetRequiredFieldName("Date")
    handleIconClick("#7589A2")
    handleSelectFieldType("DATE/TIME")
    setSelectedIcon("timer")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("Your delivery date has been scheduled!")
    toggleAddContextMessage(true)
    setRequiredContextLabel("Your delivery is scheduled for")
    toggleRequiredContextLabel(true)
    setShowCreateNew(true);
  }
  if (activeTitle === "Order Delivered") {
    setNotificationTitle("Order Delivered")
    handleSetRequiredFieldName("Date/Time")
    handleIconClick("#7589A2")
    handleSelectFieldType("DATE/TIME")
    setSelectedIcon("calendar")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("Your order has been delivered!")
    toggleAddContextMessage(true)
    setRequiredContextLabel("Delivered on")
    toggleRequiredContextLabel(true)
    setShowCreateNew(true);
  }
  if (activeTitle === "Shipment Delayed") {
    setNotificationTitle("Shipment Delayed")
    handleSetRequiredFieldName("Date")
    handleIconClick("#8C4350")
    handleSelectFieldType("DATE")
    setSelectedIcon("calendar")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("Your order is running a bit behind schedule.")
    toggleAddContextMessage(true)
    setRequiredContextLabel("New estimated delivery date")
    toggleRequiredContextLabel(true)
    setShowCreateNew(true);
  }
  if (activeTitle === "Delivery Failed") {
    setNotificationTitle("Delivery Failed")
    handleSetRequiredFieldName("Date/Time") 
    handleIconClick("#8C4350")
    handleSelectFieldType("DATE/TIME")
    setSelectedIcon("close")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("We were unable to deliver your order.")
    toggleAddContextMessage(true)
    setRequiredContextLabel("Attempted delivery on")
    toggleRequiredContextLabel(true)
    handleSetOptionalFieldName("Reattempt Date")
    handleSelectOptionalFieldType("DATE")
    setOptionalIcon("deliver")
    handleSaveOptionalFieldData()
    setShowOptionalField(true)
    setShowOptionalContextLabel(true)
    setOptionalContextLabel("We will attempt redelivery on")
    setShowCreateNew(true);
  }
  if (activeTitle === "Price Estimate") {
    setNotificationTitle("Price Estimate")
    handleSetRequiredFieldName("Cost") 
    handleIconClick("#a1848f")
    handleSelectFieldType("CUR")
    setSelectedIcon("balance")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("Your price estimate is ready!")
    setShowAddContextMessage(true)
    setRequiredContextLabel("Estimated cost")
    setShowRequiredContextLabel(true)
    setShowCreateNew(true);
  }
  if (activeTitle === "Turnaround Time") {
    setNotificationTitle("Time Estimate")
    handleSetRequiredFieldName("Est. Time") 
    handleIconClick("#D49A89")
    handleSelectFieldType("TMR")
    setSelectedIcon("timer")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("We have an update on your turnaround time!")
    setShowAddContextMessage(true)
    setRequiredContextLabel("Time needed to complete")
    setShowRequiredContextLabel(true)
    handleSetOptionalFieldName("Cost")
    handleSelectOptionalFieldType("CUR")
    setOptionalIcon("balance")
    handleSaveOptionalFieldData()
    setShowOptionalField(true)
    setShowOptionalContextLabel(true)
    setOptionalContextLabel("Total cost")
    setShowCreateNew(true);
  }
  if (activeTitle === "Pending Approval") {
    setNotificationTitle("Pending Approval")
    handleSetRequiredFieldName("Cost") 
    handleIconClick("#a1848f")
    handleSelectFieldType("CUR")
    setSelectedIcon("balance")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("We need your approval before proceeding")
    setShowAddContextMessage(true)
    setRequiredContextLabel("Total cost")
    setShowRequiredContextLabel(true)
    handleSetOptionalFieldName("Est. Time")
    handleSelectOptionalFieldType("TMR")
    setOptionalIcon("timeNeeded")
    handleSaveOptionalFieldData()
    setShowOptionalField(true)
    setShowOptionalContextLabel(true)
    setOptionalContextLabel("Time needed to complete")
    setShowCreateNew(true);
  }
  if (activeTitle === "Confirm Appointment") {
    setNotificationTitle("Confirm Appointment")
    handleSetRequiredFieldName("Date/Time") 
    handleIconClick("#a1848f")
    handleSelectFieldType("DATE/TIME")
    setSelectedIcon("calendar")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("Thanks for scheduling an appointment. Let us know if you can make it!")
    setShowAddContextMessage(true)
    setRequiredContextLabel("Your appointment is on")
    setShowRequiredContextLabel(true)
    setShowCreateNew(true);
  }
  if (activeTitle === "Payment Received") {
    setNotificationTitle("Payment Received")
    handleSetRequiredFieldName("Amount") 
    handleIconClick("#2BBB9E")
    handleSelectFieldType("CUR")
    setSelectedIcon("balance")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("Thank you for your payment!")
    toggleAddContextMessage(true)
    setRequiredContextLabel("Payment amount")
    toggleRequiredContextLabel(true)
    handleSetOptionalFieldName("Date")
    handleSelectOptionalFieldType("DATE")
    setOptionalIcon("calendar")
    handleSaveOptionalFieldData()
    setShowOptionalField(true)
    setShowOptionalContextLabel(true)
    setOptionalContextLabel("Processed on")
    setShowCreateNew(true);
  }
  if (activeTitle === "Refund Processed") {
    setNotificationTitle("Refund Processed")
    handleSetRequiredFieldName("Amount") 
    handleIconClick("#a1848f")
    handleSelectFieldType("CUR")
    setSelectedIcon("balance")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("Your refund has been processed. Thank you for your patience!")
    toggleAddContextMessage(true)
    setRequiredContextLabel("Refund amount")
    toggleRequiredContextLabel(true)
    handleSetOptionalFieldName("Date")
    handleSelectOptionalFieldType("DATE")
    setOptionalIcon("calendar")
    handleSaveOptionalFieldData()
    setShowOptionalField(true)
    setShowOptionalContextLabel(true)
    setOptionalContextLabel("Processed on")
    setShowCreateNew(true);
  }
  if (activeTitle === "Materials Delivered") {
    setNotificationTitle("Materials Delivered")
    handleSetRequiredFieldName("Turnaround Time") 
    handleIconClick("#D49A89")
    handleSelectFieldType("TMR")
    setSelectedIcon("mail")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setShowCreateNew(true);
    setContextMessage("The materials for your project have been delivered!")
    toggleAddContextMessage(true)
    setRequiredContextLabel("Estimated turnaround time")
    toggleRequiredContextLabel(true)
    handleSetOptionalFieldName("Balance")
    handleSelectOptionalFieldType("CUR")
    setOptionalIcon("cash")
    handleSaveOptionalFieldData()
    setShowOptionalField(true)
    setShowOptionalContextLabel(true)
    setOptionalContextLabel("Balance due at pickup")
    setShowCreateNew(true);
  }
  if (activeTitle === "Order Canceled") {
    setNotificationTitle("Order Canceled")
    handleSetRequiredFieldName("Date") 
    handleIconClick("#8C4350")
    handleSelectFieldType("DATE")
    setSelectedIcon("calendar")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setContextMessage("Your order has been cancelled")
    toggleAddContextMessage(true)
    setRequiredContextLabel("Cancelled on")
    toggleRequiredContextLabel(true)
    handleSetOptionalFieldName("Refund Amount")
    handleSelectOptionalFieldType("CUR")
    setOptionalIcon("cash")
    handleSaveOptionalFieldData()
    setShowOptionalField(true)
    setShowOptionalContextLabel(true)
    setOptionalContextLabel("Issued refund for")
    setShowCreateNew(true);
  }
  if (activeTitle === "Pending Shipment") {
    setNotificationTitle("Pending Shipment")
    handleSetRequiredFieldName("Ship Date") 
    handleIconClick("#D49A89")
    handleSelectFieldType("DATE")
    setSelectedIcon("calendar")
    if (selectedNotifications[0]?.selected_data === null) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
    handleSaveRequiredFieldData()
    setContextMessage("We're preparing your order for shipment")
    toggleAddContextMessage(true)
    setRequiredContextLabel("Your order will ship on")
    toggleRequiredContextLabel(true)
    handleSetOptionalFieldName("Delivery Date")
    handleSelectOptionalFieldType("DATE")
    setOptionalIcon("deliver")
    handleSaveOptionalFieldData()
    setShowOptionalField(true)
    setShowOptionalContextLabel(true)
    setOptionalContextLabel("Expected delivery by")
    setShowCreateNew(true);
  }

};




useEffect(() => {
  if (twoWayEnabled && existingNotificationName !== "") {
    setShowConfirmTwoWayDialog(true);
  } 
}, [twoWayEnabled, existingNotificationName]);




  return (

    <Modal open={isModalOpen} onClose={handleCloseModal}>
  <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      
      }}
    >
      {!showCreateNew ? (
   
    <TemplateDialog

    isModalOpen={isModalOpen}
    activeTitle={activeTitle}
    setActiveTitle={setActiveTitle}
    handleButtonClick={handleButtonClick}
    handleCloseModal={handleCloseModal}
    handleEnableClick={handleEnableClick}
    selectedNotifications={selectedNotifications}
    setTwoWayEnabled={setTwoWayEnabled}
    twoWayEnabled={twoWayEnabled}
    checkTwoWayNotifications={checkTwoWayNotifications}
    setShowConfirmation={setShowConfirmation}
    setExistingNotificationName={setExistingNotificationName}
    showConfirmation={showConfirmation}
    existingNotificationName={existingNotificationName}
    />

  ) : (
      <Fade in={true} timeout={300}>
      <Box
        sx={{
          display: showPreviewNotificationDialog ? "none" : 'block',
          flexDirection: "column",
          minWidth:'580px',
          maxWidth:'580px',
          margin: "auto",
          backgroundColor: "white",
          px: 3,
          pt: 3,
          pb: 1,
          borderRadius: "9px",
          boxShadow: 24,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "90vh",
          
        }}
      >
        <Box>
          <Box
            sx={{
              border: "1px solid rgba(173, 216, 230, 0.9)",
              width: "35px",
              alignItems: "center",
              justifyContent: "center",
              padding: "3px",
              borderRadius: "4px",
              mt: 1,
            }}
          >
            <img src={PlusIcon} style={{ height: "28px", width: "auto" }} />
          </Box>
          <Stack
            direction="row"
            sx={{
              width: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              noWrap
              sx={{
                color: `#48768C` ,
                borderRadius: "8px",
                fontSize: 20,
                fontWeight: 600,
                textAlign: "left",
                letterSpacing: "-.02em",
                lineHeight: "-2%",
                pt: 1,
                justifyContent: "flex-start",
        
              }}
            >
              New Notification Type
            </Typography>

            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 4,
                top: 7,
                color: "#7589A2",
                backgroundColor: "transparent",
                zIndex: 1,
                borderRadius: "12px",
                width: "35px",
                height: "35px",
                padding: "3px",
                mx: 0.5,
                my: 0.25,
              }}
            >
              <CloseIcon sx={{ fontSize: "28px" }} />
            </IconButton>
            <IconButton
              aria-label="close"
              onClick={handleOpenTipsDialog}
              sx={{
                position: "absolute",
                right: 50,
                top: 7.75,
                color: "#48768C",
                backgroundColor: "transparent",
                zIndex: 1,
                borderRadius: "12px",
                width: "33px",
                height: "33px",
                padding: "2px",
                mx: 0.5,
                my: 0.25,
              }}
            >
              <HelpCenterTwoToneIcon sx={{ fontSize: "28px" }} />
            </IconButton>
          </Stack>
        </Box>
        <Box sx={{ overflowY: "auto", maxHeight:'70vh' }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              mt: 2,
            }}
          >
            <Typography
              noWrap
              sx={{
                color: `#48768C`,
                borderRadius: "8px",
                fontSize: 17,
                fontWeight: 600,
                textAlign: "left",
                letterSpacing: "-.02em",
                lineHeight: "-2%",

                ml: 0.25,
                mb: -1,
              }}
            >
              Notification Name
            </Typography>
            <NotificationTitle
              notificationTitle={notificationTitle}
              handleSetTitle={handleSetTitle}
              handleKeyDown={handleKeyDown}
              handleIconClick={handleIconClick}
              colors={colors}
              handleClearTitle={handleClearTitle}
              handlePrintFieldTitle={handlePrintFieldTitle}
              isTitleDefault={isTitleDefault}
              isTitleValid={isTitleValid}
              setIsTitleValid={setIsTitleValid}
              printedFieldTitle={printedFieldTitle}
              handleClearColors={handleClearColors}
            />




<Fade in={showAddContextMessage} timeout={300}>
  <Box sx={{display:!showAddContextMessage ? "none" : "block" }}>
  <>
            <Typography
              noWrap
              sx={{
                color:`#48768C`,
                borderRadius: "8px",
                fontSize: 16,
                fontWeight: 600,
                textAlign: "left",
                letterSpacing: "-.02em",
                lineHeight: "-2%",
                mt: 1.5,
                ml: 0.25,
                mb: 0.5,
              }}
            >
              Context Message
            </Typography>
            <ThemeProvider theme={customTheme}>
              <Box
                sx={{
                  border: "1px solid rgba(173, 216, 230, 0.9)",
                  padding: 1,
                  borderRadius: "6px",
                }}
              >
                <TextField
                  hiddenLabel
                  placeholder="Add Context Message"
                  variant="filled"
                  size="small"
                  rows={1} // Increase the number of rows to control height
                  value={contextMessage}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleSetContextMessage(value.charAt(0).toUpperCase() + value.slice(1));
                  }}
                  multiline
                  onKeyDown={handleKeyDown}
                  inputProps={{ maxLength: 55 }}
                  sx={{
                    ml: 0.15,
                    width: "99%",
                    mr: 0.5,

                    padding: 0,
                    backgroundColor: "#FFFFFF",

                    "& .MuiInputBase-root": {
                      display: "flex",
                      alignItems: "center",

                      backgroundColor: "#FFFFFF",

                      padding: 0,
                    },

                    "& textarea": {
                      color: "#48768C",
                      fontWeight: 600,
                      fontSize: "13.5px",
                      backgroundColor: "#FFFFFF",
                      fontFamily: "Source Sans Pro, sans-serif",
                      padding: 0,
                    },
                  }}
                />
              </Box>
            </ThemeProvider>
            </>
            </Box>
</Fade>






              <AddField
                fieldNamePlaceholder="Select Icon & Enter Field Name"
                handleKeyDown={handleKeyDown}
                fieldName={requiredFieldName}
                handleSetFieldName={handleSetRequiredFieldName}
                handleSelectedIcon={setSelectedIcon}
                selectedIcon={selectedIcon}
                handleClearFieldName={handleCleaRequiredFieldName}
                handleSaveFieldData={handleSaveRequiredFieldData}
                fieldDataSaved={requiredFieldDataSaved}
                selectedNotificationType={selectedNotificationType}
                handleSelectFieldType={handleSelectFieldType}
                setSelectedNotificationType={setSelectedNotificationType}
                handleClearFieldData={handleClearRequiredFieldData}
                colors={colors}
                enableClearButton={enableClearButton}
                showContextLabel={showRequiredContextLabel}
                toggleRequiredContextLabel={toggleRequiredContextLabel}
                 fieldType="Notification Details"
                 contextLabel={requiredContextLabel}
                 setContextLabel={setRequiredContextLabel}
                 handleChangeContextLabel={handleSetRequiredContextLabel}
              />

<Box>
  <PreviewNotification
    open={showPreviewNotificationDialog}
    onClose={handleClosePreviewNotificationDialog}
    title={notificationTitle}
    contextMessage={contextMessage}
    phoneNumber={phoneNumber}
    locationEmail={email}
    companyWebsite={website}
    optionalMockData={optionalMockData}
    selectedMockData={selectedMockData}
    companyName={companyName}
    ticketNumber="123342"
    requiredFieldValue={requiredFieldName}
    requiredFieldName={requiredFieldName}
    requiredContextLabel={requiredContextLabel}
    optionalFieldName={optionalFieldName}
    optionalContextLabel={optionalContextLabel}
  />
</Box> 

<Box>
<TipsAndTricksDialog
open={showTipsDialog}
handleClose={handleCloseTipsDialog}
/>
</Box> 

       
       
<Fade in={showOptionalField} timeout={300}>
  <Box sx={{display:!showOptionalField ? "none" : "block" }}>
      <>   

              <AddField
                fieldNamePlaceholder="Select Icon & Enter Field Name"
                handleKeyDown={handleKeyDown}
                fieldName={optionalFieldName}
                handleSetFieldName={handleSetOptionalFieldName}
                handleSelectedIcon={setOptionalIcon}
                selectedIcon={optionalIcon}
                setSelectedIcon={setOptionalIcon}
                handleClearFieldName={handleCancelOptionalField}
                handleSaveFieldData={handleSaveOptionalFieldData}
                fieldDataSaved={optionalFieldDataSaved}
                handleSelectFieldType={handleSelectOptionalFieldType}
                setSelectedNotificationType={
                  setOptionalSelectedNotificationType
                }
                handleClearFieldData={handleClearOptionalFieldData}
                selectedNotificationType={optionalSelectedNotificationType}
                colors={colors}
                enableClearButton={!enableClearButton}
                toggleRequiredContextLabel={toggleOptionalContextLabel}
                showContextLabel={showOptionalContextLabel}
                contextLabel={optionalContextLabel}
                setContextLabel={setOptionalContextLabel}
                handleChangeContextLabel={handleSetOptionalContextLabel}

                fieldType="Optional Field"
              />
            </>
         
</Box>
</Fade>
<Accordion
  sx={{
    borderTop: "none", 
    mt: 2,
    "&:before": { display: "none" }, // Removes default MUI border
  }}
>
  <AccordionSummary
    sx={{
      display: "flex",
      flexDirection: "row-reverse", // Moves the icon next to the text
      alignItems: "center",
      width: "100%",
      borderTop: "none",
      gap: 1, // Adds spacing between the text and icon
      pl:'none', 
      ml:-2
    }}
    expandIcon={<ExpandMoreIcon sx={{ color: "#48768C" }} />} // Adjust icon color if needed
  >

    <Typography
      noWrap
      sx={{
        color: `#48768C`,
        borderRadius: "8px",
        fontSize: 16,
        fontWeight: 600,
        textAlign: "left",
        letterSpacing: "-.02em",
        lineHeight: "-2%",
        mr:2,
        borderTop:'none',
      }}
    >
      Additional Options
    </Typography>
  </AccordionSummary>
            <AccordionDetails sx={{mt:-2, ml:1.65 }}>

<Stack direction="column" spacing={0} sx={{ ml:-2.5  }}>
            <Stack
              direction="row"
              sx={{ alignItems: "center",ml: 0.25 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                  checked={showOptionalField}
                    onChange={toggleOptionalField}
                    icon={<CheckBoxOutlineBlankRoundedIcon />}
                    checkedIcon={<CheckBoxTwoToneIcon sx={{color:'#48768C'}} />}
                  />
                }
              />
              <Typography
                noWrap
                sx={{
                  color: `#48768C` ,
                  borderRadius: "8px",
                  fontSize: 16,
                  fontWeight: 600,
                  textAlign: "left",
                  letterSpacing: "-.02em",
                  ml: -1.75,
                }}
              >
                Add Optional Field
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{ alignItems: "center", ml: 0.25 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                  checked={showAddContextMessage}
                    onChange={toggleAddContextMessage}
                    icon={<CheckBoxOutlineBlankRoundedIcon />}
                    checkedIcon={<CheckBoxTwoToneIcon sx={{color:'#48768C'}} />}
                  />
                }
              />
              <Typography
                noWrap
                sx={{
                  color: `#48768C`,
                  borderRadius: "8px",
                  fontSize: 16,
                  fontWeight: 600,
                  textAlign: "left",
                  letterSpacing: "-.02em",
                  lineHeight: "-2%",
                  textAlign: "center",
                  ml: -1.75,
                }}
              >
                Add Context Message
              </Typography>
            </Stack>

            {selectedNotifications && selectedNotifications[0]?.selected_data !== null && (
  <Stack
    direction="row"
    sx={{ alignItems: "center", ml: 0.25 }}
  >
    <FormControlLabel
      control={
        <Checkbox
          icon={<CheckBoxOutlineBlankRoundedIcon />}
          checkedIcon={<CheckBoxTwoToneIcon sx={{ color: '#48768C' }} />}
          checked={isDefault}
          onChange={toggleIsDefault}
        />
      }
    />
    <Typography
      noWrap
      sx={{
        color: `#48768C`,
        borderRadius: "8px",
        fontSize: 16,
        fontWeight: 600,
        textAlign: "left",
        letterSpacing: "-.02em",
        lineHeight: "-2%",
        textAlign: "center",
        ml: -1.75,
      }}
    >
      Set As Default
    </Typography>
  </Stack>
)}

            <Stack
              direction="row"
              sx={{ alignItems: "center", ml: 0.25 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                  icon={<CheckBoxOutlineBlankRoundedIcon />}
                  checkedIcon={<CheckBoxTwoToneIcon sx={{color:'#48768C'}} />}
                  checked={twoWayEnabled}
                  onChange={toggleTwayWayEnabled}
                  />
                }
              />
              <Typography
                noWrap
                sx={{
                  color: `#48768C`,
                  borderRadius: "8px",
                  fontSize: 16,
                  fontWeight: 600,
                  textAlign: "left",
                  letterSpacing: "-.02em",
                  lineHeight: "-2%",
                  textAlign: "center",
                  ml: -1.75,
                }}
              >
               Enable Two Way Notifications
              </Typography>
            </Stack>
            </Stack>
            </AccordionDetails>
            </Accordion>

          </Box>
        </Box>

        <Stack
          direction="row"
          sx={{
            width: "100%",
            mt: 2,
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            disabled={!saveNotificationButtonEnabled || isLoading}
            className="previewNotificationButton"
            onClick={handleOpenPreview}
            sx={{
              marginLeft: "auto",
              mr: 3,
              mb: 2,
              display: "flex",
              height: "40px",
            }}
          >
            Preview
          </Button>

          <Button
            disabled={!saveNotificationButtonEnabled || isLoading}
            className="saveNotificationButton"
            onClick={handleSubmitFieldData}
            sx={{ mr: 1, mb: 2, height: "40px" }}
          >
            {isLoading ? "Adding New Notification" : "Create Notification"}
          </Button>
        </Stack>
      </Box>
      </Fade>

      
  )}
  {showConfirmTwoWayDialog && (
<TwoWayPromptDialog
open={showConfirmTwoWayDialog}
onClose={handleCloseConfirmTwoWayDialog}
checkTwoWayNotifications={checkTwoWayNotifications}
showConfirmation={showConfirmation}
existingNotificationName={existingNotificationName || ''}
handleButtonClick={handleButtonClick}
twoWayEnabled={twoWayEnabled}
setTwoWayEnabled={setTwoWayEnabled}
setShowConfirmation={setShowConfirmation}

/>
)}
 </Box>

    </Modal>
  

  );
};

export default CreateNewModal;
