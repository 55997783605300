import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Avatar,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Portal,
  Grid,
  Paper,
  Menu,
  Stack,
  FormControlLabel,
  ButtonBase,
  Fade,
  Divider,
  Radio
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import Popper from "@mui/material/Popper";
import addUserIcon from "src/public/assets/icons/icons/addUser.png";
import userAccountDetailsIcon from "src/public/assets/icons/icons/userDetailsIcon.png";
import { useAccountContext } from "src/context/AccountContext";
import { useAppContext } from "src/context/AppContext";
import { dismissItem } from "src/components/NewUserChecklist/dismissItem";
import { set } from "lodash";
import ReviewDialog from "src/components/AccountPage/AccountManagementComponents/ReviewDialog.js";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";

import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import AccountInfoIcon from "src/public/assets/icons/icons/AccountInfoIcon.png";
import PlusIcon from "src/public/assets/icons/icons/plus.png";
import NewNotificationIcon from "src/public/assets/icons/messageModal/bell.png";

import RadioButtonCheckedTwoToneIcon from "@mui/icons-material/RadioButtonCheckedTwoTone";
import RadioButtonUncheckedTwoToneIcon from "@mui/icons-material/RadioButtonUncheckedTwoTone";
import TemplatesList from "./components/TemplateList";

const apiUrl = process.env.REACT_APP_API_URL;

const TemplateDialog = ({  isModalOpen, activeTitle, setActiveTitle, handleButtonClick, handleCloseModal, handleEnableClick, selectedNotifications, twoWayEnabled, setTwoWayEnabled, checkTwoWayNotifications, setShowConfirmation, setExistingNotificationName, showConfirmation, existingNotificationName }) => {


  return (
  <Box sx={{
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    minWidth: "550px",
    maxWidth: "585px",
    p: 0.5,
    overflowY:'hidden'
    
    }} >
    <Box
        sx={{
          px: 3,
          pt: 2,
          ml: 0.5,
        }}
      >
        <img
          src={PlusIcon}
          alt="addUserIcon"
          style={{
            border: "1px solid #B0BEC5",
            width: "auto",
            height: "28px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "3px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
            marginLeft: "-4px",
          }}
        />
        <Typography
          sx={{
            color: "#48768C",
            fontSize: 21,
            fontWeight: 600,
            textAlign: "left",

            letterSpacing: "-.03em",

            mt: 1,
            ml: -0.65,
          }}
        >
          Create New Notification Type
        </Typography>
        <Typography
          color="textSecondary"
            variant="body1"
          sx={{
            mt:'-3px',

            fontWeight: 500,
            textAlign: "left",

            letterSpacing: "-.02em",

        

            ml: -0.65,
          }}
        >
          Create a new notification from scratch or start with one of our
          templates
        </Typography>
      </Box>
  

      <Box
        sx={{
          pb:3,
          pt:1.5,
          px: 3,
          width: "100%",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
          overflowY:'hidden'
        }}
      >


  
          



          
    

          <TemplatesList
          isModalOpen={isModalOpen}
          activeTitle={activeTitle}
          setActiveTitle={setActiveTitle}
          handleEnableClick={handleEnableClick}
          selectedNotifications={selectedNotifications}
          handleButtonClick={handleButtonClick}
          setTwoWayEnabled={setTwoWayEnabled}
          twoWayEnabled={twoWayEnabled}
          checkTwoWayNotifications={checkTwoWayNotifications}
          setShowConfirmation={setShowConfirmation}
          setExistingNotificationName={setExistingNotificationName}
          showConfirmation={showConfirmation}
          existingNotificationName={existingNotificationName}
          />


        

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            gap: 2,
            px: 0.65,
            mt:2
          }}
        >

          <SecondaryButton
          onClick={handleCloseModal}
            sx={{
              mt: 4,
              fontSize: 18,
              transition: "background-color 0.3s ease",
              width: "34%",
              justifyContent: "center",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              boxShadow: "none",

              "&:hover": {
                backgroundColor: "#faf5f7",
                border: "1px solid #f6edf0",
                color: "#bf7588",
              },
            }}
          >
            Cancel
          </SecondaryButton>
          <SecondaryButton
          onClick={handleButtonClick}
            sx={{
              mt: 4,
              fontSize: 18,
              transition: "background-color 0.3s ease",
              width: "64%",
              justifyContent: "center",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              color: "#48768c",
              backgroundColor: "#f2f7f9", 
              borderColor:'#97bac9',
              "&:hover": { backgroundColor: "#dfeaee" },
            }}
          >
            Create Notification 
          </SecondaryButton>
        </Box>
      </Box>
      </Box>

  );
};

export default TemplateDialog;
