
import React, { useState, useEffect } from "react"; // No need for React.useState
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Modal,
  Grid,
  Typography,
  Fade,
  Stack,
  Skeleton,
  CardMedia,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  IconButton,
  ButtonBase
  
} from "@mui/material";
import moment from "moment-timezone"; // Import moment-timezone
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import companyImage from "src/public/assets/companyImage.png";
import RestartSetupDialog from "src/components/modal/NewUserSignUp/Refresh/Components/RestartSetupDialog";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { set } from "lodash";









const theme = createTheme({
  palette: {
    primary: {
      main: "#48768C", // Set the main color to white
    },
  },
});



const CreateUserStep = ({user, loading, isSingleUser, setIsSingleUser, showCreateUser, isSingleLocation, setIsNextButtonDisabled,
  setUserFirstName, setUserLastName, setUsername, values, setValues, typing, handleChange, errors, setShowCreateUser, handleBlur, handleChangeTitle, handleRoleButtonClick, roleOpen, setRoleOpen
    }) => {


  const [isPinVisible, setIsPinVisible] = useState(false);












    const handleUserSelectChange = (event) => {
      setIsSingleUser(event.target.value === "single_user");
      setIsNextButtonDisabled(false);
    };
    



    

    






  const togglePinVisibility = () => {
    setIsPinVisible((prev) => !prev);
  };

    









return (
  !showCreateUser ? (
    <Fade in={true} timeout={400}>
      <Box
        sx={{
          height:'100%',
          width: "590px",
          justifyContent: "center",
          borderRadius: "40px",
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: "#FFFFFF",
          color: "#48768C",
          padding:1,
          alignItems:'center'
        }}
      >
      
            <Typography sx={{    fontSize: '1.22rem', textAlign: "left", mb: 1, fontWeight: 600, mt:1 }}>

        Lets get some information about the size of your team
        </Typography>
    
      <Typography
          sx={{
            mt: 1.75,
            fontSize: '1.05rem',
            textAlign: "left",
            fontWeight: 500,
            px:.25,
            letterSpacing:'-0.015em',
            lineHeight: '-0.04em',
            color:'#64697D'
          }}
        >
        Does your location have multiple employees?
    
        </Typography>
    
    
    <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 1, mb:1 }}>
    <FormControl component="fieldset">
      <RadioGroup name="userType" 
            value={isSingleUser ? "single_user" : "multiple_users"} // Ensure value reflects state

      onChange={handleUserSelectChange}>
        <FormControlLabel
          value="single_user"
          control={<Radio sx={{ color: "#48768c", '&.Mui-checked': { color: "#6595ab" } }} />}
          label="Just a single employee"
          sx={{
            alignItems: "center",
            "& .MuiTypography-root": {
              fontSize: '1.04rem',
              textAlign: "left",
              fontWeight: 500,
              px: 0.25,
              letterSpacing: '-0.01em',
              lineHeight: '1.5', // Adjust this value for vertical centering
              color: '#64697D'
            }
          }}
        />
        <FormControlLabel
          value="multiple_users"
          control={<Radio sx={{ color: "#48768c", '&.Mui-checked': { color: "#6595ab" } }} />}
          label="We have multiple employees"
          sx={{
            alignItems: "center",
            "& .MuiTypography-root": {
              fontSize: '1.04rem',
              textAlign: "left",
              fontWeight: 500,
              px: 0.25,
              letterSpacing: '-0.01em',
              lineHeight: '1.5', // Adjust this value for vertical centering
              color: '#64697D'
            }
          }}
        />
      </RadioGroup>
    </FormControl>
    </Box>
    
    </Box>
    </Fade>
  ) : (

  <Fade in={true} timeout={600}>
          <Box
            sx={{
              height: '100%',
              width: "100%",
              justifyContent: "center",
              borderRadius: "40px",
              fontSize: 17,
              fontWeight: 600,
              backgroundColor: "#FFFFFF",
              color: "#48768C",
              padding: 1,
              mt:1,
            }}
          >
            <Typography sx={{ fontSize: '1.28rem', textAlign: "left", fontWeight: 600, px: .15, letterSpacing: '-0.02em', lineHeight: '-0.02em' }}>

   {isSingleUser ?   "Tell Us A Little About Yourself" : "Let's Create Your Account Administrator"  }
  </Typography>


  <Typography color="textSecondary" sx={{ mt: 0, fontSize: '.87rem', textAlign: "left", mb: .75, fontWeight: 500, px: .15, letterSpacing: '-0.01em', lineHeight: '-0.04em' }}>
  {isSingleUser ? 
  "We just need some basic information about you so we can set up your profile." : 
  "Each employee gets a unique username and PIN. Account administrators can manage users, locations, account settings and create new notification types."
    }
</Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "600px" }}>

  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "550px",
      mt: 1,
      gap:1
    }}
  >
    <ThemeProvider theme={theme}>
      <TextField
        label="First Name"
        variant="outlined"
        size="small"
        fullWidth
        value={values.newFirstName}
        error={!typing && !!errors.newFirstName}
        onBlur={() => handleBlur("newFirstName")}

        onChange={(e) => {
          const lettersOnlyValue = e.target.value.replace(/[^a-zA-Z]/g, ""); // Remove numbers & special characters
        
          if (lettersOnlyValue.length <= 15) {
            const capitalizedValue =
              lettersOnlyValue.charAt(0).toUpperCase() + lettersOnlyValue.slice(1).toLowerCase(); // Capitalize first letter
        
            handleChange("newFirstName", capitalizedValue); // Allow only up to 15 letters
          }
        }}       InputProps={{
          style: { color: "#48768C", borderRadius: "6px" },
        }}
        InputLabelProps={{ style: { color: "#82879B" } }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#6595ab",
          },
          color: "#48768C",
        }}
      />
    </ThemeProvider>
    <ThemeProvider theme={theme}>
      <TextField
        label="Last Name"
        variant="outlined"
        size="small"
        fullWidth
        value={values.newLastName}
        error={!typing &&  !!errors.newLastName}
        onBlur={() => handleBlur("newLastName")}

        onChange={(e) => {
          const lettersOnlyValue = e.target.value.replace(/[^a-zA-Z]/g, ""); // Remove numbers & special characters
        
          if (lettersOnlyValue.length <= 15) {
            const capitalizedValue =
              lettersOnlyValue.charAt(0).toUpperCase() + lettersOnlyValue.slice(1).toLowerCase(); // Capitalize first letter
        
            handleChange("newLastName", capitalizedValue); // Allow only up to 15 letters
          }
        }}      
        InputProps={{
          style: { color: "#48768C", borderRadius: "6px" },
        }}
        InputLabelProps={{ style: { color: "#82879B" } }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#6595ab",
          },
          color: "#48768C",
        }}
      />
    </ThemeProvider>
  </Box>
  {!isSingleUser ? (
  <>

  <ThemeProvider theme={theme}>
 
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "550px",
      gap: 1,

    }}
  >


  <TextField
  label="Username"
  variant="outlined"
  size="small"
  fullWidth
  value={values.newUsername}
  error={!typing && !!errors.newUsername}
  onBlur={() => handleBlur("newUsername")}
  onChange={(e) => {
    const alphanumericValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ""); // Remove special characters
    if (alphanumericValue.length <= 15) {
      handleChange("newUsername", alphanumericValue); // Allow only up to 10 characters
    }
  }}
  InputProps={{
    style: { color: "#48768C", borderRadius: "6px" },
    endAdornment: errors.newUsername && (
      <InputAdornment position="end">
        <Typography sx={{ color: "#8C4350", fontSize: 13 }}>
          Username must be at least 5 characters
        </Typography>
      </InputAdornment>
    ),
  }}
  InputLabelProps={{ style: { color: "#82879B" } }}
  sx={{
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#6595ab",
    },
    color: "#48768C",
  }}
/>
</Box>
  </ThemeProvider>

  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "550px",
      gap: 1,
      mb:.5,
    }}
  >
  
<ThemeProvider theme={theme}>
  <TextField
    label="New PIN"
    size="small"
    variant="outlined"
    type={isPinVisible ? "text" : "password"}
    fullWidth
    value={values.newPin}
    error={!typing && !!errors.newPin}
    onChange={(e) => {
      const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
      if (numericValue.length <= 4) {
        handleChange("newPin", numericValue); // Allow only up to 4 digits
      }
    }}
    InputProps={{
      style: { color: "#48768C", borderRadius: "6px" },
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={togglePinVisibility} edge="end">
            {isPinVisible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    }}
    InputLabelProps={{ style: { color: "#82879B" } }}
    sx={{
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#6595ab",
      },
      color: "#48768C",
    }}
  />
</ThemeProvider>

<ThemeProvider theme={theme}>
  <TextField
    label="Confirm New PIN"
    size="small"
    variant="outlined"
    type={isPinVisible ? "text" : "password"}
    fullWidth
    value={values.confirmNewPin}
    error={!typing && !!errors.confirmNewPin}
    onChange={(e) => {
      const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
      if (numericValue.length <= 4) {
        handleChange("confirmNewPin", numericValue); // Allow only up to 4 digits
      }
    }}
    InputProps={{
      style: { color: "#48768C", borderRadius: "6px" },
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={togglePinVisibility} edge="end">
            {isPinVisible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    }}
    InputLabelProps={{ style: { color: "#82879B" } }}
    sx={{
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#6595ab",
      },
      color: "#48768C",
    }}
  />
</ThemeProvider>



  </Box>

  </>
  ) : (

                               <Stack spacing={0.25} alignItems="flex-start" sx={{width:'552px', mt:.5, ml:'-1px'}}>
                 
            <FormControl
              variant="outlined"
              sx={{
                width: "100%",
                mb: 1,
                ml: "7.5px",
                backgroundColor: "#FFFFFF",
              }}
            >
     
              <Select
                size="small"
                value={values.title}
                onChange={handleChangeTitle}
                open={roleOpen}
                onClose={() => setRoleOpen(false)} // Closes menu when clicking outside
                onOpen={() => setRoleOpen(true)} // Ensures menu can open naturally
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: "#576369",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#6595ab", // Default (idle) state border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2F5C71", // Hover state border color
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#48768CBA", // Focused border color
                  },
                  "& .Mui-focused .MuiInputLabel-root": {
                    color: "#2F5C71", // Focused label color
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: "#FFFFFF",
                      border: "1px solidrgb(148, 175, 187)",
                      borderTop: "none",
                    },
                  },
                }}
                startAdornment={
                  <InputAdornment position="start" sx={{ml: values?.title? -2.95 : 0 }}>
                          {!values?.title && (
                        <ButtonBase onClick={handleRoleButtonClick}>    
                    <Typography sx={{color: "#48768C", fontWeight:500, mx:-.5}}>
                   Your Role / Title With {values?.name || "Your Company"}
                    </Typography>
                    </ButtonBase>
                          )}
            
                  </InputAdornment>
                }
              >
                <MenuItem
                  value="Employee"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Employee
                </MenuItem>
                <MenuItem
                  value="Manager"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Manager
                </MenuItem>
                <MenuItem
                  value="Owner"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Owner
                </MenuItem>
                <MenuItem
                  value="Regional Manager"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Regional Manager
                </MenuItem>
                <MenuItem
                  value="Account Administrator"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Account Administrator
                </MenuItem>
                <MenuItem
                  value="Director of Operations"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Director of Operations
                </MenuItem>
                <MenuItem
                  value="Assistant Manager"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Assistant Manager
                </MenuItem>
                <MenuItem
                  value="Shift Lead"
                  sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                >
                  Shift Lead
                </MenuItem>
              </Select>
            </FormControl>
         </Stack>
           )}
</Box>

</Box>

</Fade>
  )
);
}

export default CreateUserStep;