import React, { useEffect, useState } from "react";
import { Dialog, Typography, IconButton, Box, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import bellIcon from "../../../../../../../../src/public/assets/icons/messageModal/bell.png";
import { useAccountContext } from "../../../../../../../context/AccountContext"; // Import useAccountContext from AccountContext
import { useUserSelectedNotifications } from "src/context/UserNotificationsContext.js";



const PreviewDialogTypography = ({ children, color }) => (
  <Typography
    color="textSecondary"
    sx={{
      color: "#48768C",
      fontSize: "15px",
      fontWeight: 600,
    }}
  >
    {children}
  </Typography>
);

function PreviewNotification({
  open,
  onClose,
  companyName,
  phoneNumber,
  ticketNumber,
  title,
  contextMessage,
  optionalMessagePrefix,
  locationEmail,
  companyWebsite,
  optionalMockData,
  selectedMockData,
  requiredFieldName,
  requiredContextLabel,
  optionalContextLabel,
  optionalFieldName
}) {
  const { selectedNotifications, businessHours } =
    useUserSelectedNotifications();

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
  });
  const businessHoursForCurrentDay = businessHours[currentDayOfWeek];
  const month = currentDate.getMonth() + 1; // January is 0, so add 1 to get the correct month
  const day = currentDate.getDate();
  // Format the date as "m/d"
  const userCurrentDate = `${month}/${day}`;

  let currentHours = "";
  if (businessHoursForCurrentDay) {
    const { start_time, end_time } = businessHoursForCurrentDay;
    currentHours = `${start_time} - ${end_time}`;
  } else {
    currentHours = "Not available";
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "9px",
          border: "1px solid rgba(173, 216, 230, 0.5)",
          width: "fit-content",
          whiteSpace: "nowrap",
          display: "flex",
          flexGrow: 1,
          maxWidth:'600px',
        },
      }}
    >
      <Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 2,
            top: 2,
            color: "#576369",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "8px",
            width: "32px",
            height: "32px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>

        <Box
          sx={{
            position: "relative",
            backgroundColor: "#FFFFFF",
            color: "#576369",
            padding: "20px",
            borderRadius: "10px",

            width: "100%",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
            p:2,
            px:2.5
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
      
            
              pb: 2,
              mx:.75,
              px:.25
            }}
          >
            <Typography
              sx={{
                color: `#48768C` ,
                borderRadius: "8px",
                fontSize: 20.5,
                fontWeight: 600,
                textAlign: "left",
                letterSpacing: "-.02em",
                lineHeight: "-2%",
                pt:1,
                justifyContent: "flex-start",
                mb:.25
              }}
            >
              Preview Notification{" "}
            </Typography>

     
<Typography
              sx={{
                color: `#6c90a2` ,
                borderRadius: "8px",
                fontSize: 15.45,
                fontWeight: 600,
                textAlign: "left",
                letterSpacing: "-.02em",
                lineHeight: "-2%",
              
                justifyContent: "flex-start",
                width:'99%',
                mb:.5,
                whiteSpace:'normal'
              }}
            >
We’ve added some example data to show how your new notification will look when it's delivered to your customer.        
         </Typography>
          </Box>

          <Paper elevation={5} sx={{ borderRadius: "9px",               maxWidth:'550px',mx:.25
 }}>
            <Box
              sx={{
                position: "relative",

                color: "#576369",
                padding: "10px",
                borderRadius: "7px",
                border: "1px solid #B0BEC5",
                maxWidth:'550px',
                backgroundColor: "rgba(191, 218, 231, 0.08)",
                border: "1px solid rgba(173, 216, 230, 0.7)",
                mb:2.75,
                pt:2,
                
   

              }}
            >
              <PreviewDialogTypography>
                {" "}
                {companyName} Status Update
              </PreviewDialogTypography>
              <PreviewDialogTypography>{`Ticket Number: ${ticketNumber}`}</PreviewDialogTypography>
              <PreviewDialogTypography>
              {`Status: ${title.toUpperCase()}`} 
              </PreviewDialogTypography>

              <Box
                gap={0.5}
                sx={{ mt: 1.5, mb: 2.75, display: "flex", flexDirection: "row" }}
              >
                <PreviewDialogTypography
                  sx={{ color: "#576369", fontWeight: 600, mb:2, mt:1 }}
                >
                  {contextMessage}
                </PreviewDialogTypography>
                </Box>

                <Box
                gap={0.5}
                sx={{ mt: -2, mb: 1, display: "flex", flexDirection: "row" }}
              >
                {requiredContextLabel ? (
                <PreviewDialogTypography>
                 {requiredContextLabel}:
                </PreviewDialogTypography>
                ) : (
                  <PreviewDialogTypography>
                  {requiredFieldName}:
                </PreviewDialogTypography>
               
                )}
               
               <PreviewDialogTypography>
                  {selectedMockData}
                </PreviewDialogTypography>
              </Box>
              <Box>
                {optionalFieldName && (
                       <Box
                       gap={0.5}
                       sx={{ mt: 1, mb: 1, display: "flex", flexDirection: "row" }}
                     >
                       {optionalContextLabel ? (
                       <PreviewDialogTypography>
                        {optionalContextLabel}:
                       </PreviewDialogTypography>
                       ) : (
                         <PreviewDialogTypography>
                         {optionalFieldName}:
                       </PreviewDialogTypography>
                      
                       )}
                      
                      <PreviewDialogTypography>
                         {optionalMockData}
                       </PreviewDialogTypography>
                     </Box>
                )}

                <Box sx={{ mt: 2.5, mb: 1 }}>
                  <PreviewDialogTypography
                    sx={{ color: "#576369", fontWeight: 600, width:'95%', whiteSpace:'normal'}}
                  >
                    This number is for automated alerts only and cannot receive
                    replies. 
                  </PreviewDialogTypography>
                  <PreviewDialogTypography
                    sx={{ color: "#576369", fontWeight: 600, width:'95%', whiteSpace:'normal'}}
                  >
                    If you have any questions or need assistance,
                    please call or email us directly.
                  </PreviewDialogTypography>
                </Box>
                <PreviewDialogTypography>
                  Contact Us: {phoneNumber}
                </PreviewDialogTypography>
                <PreviewDialogTypography>
                  {locationEmail} | {companyWebsite}
                </PreviewDialogTypography>
                <Box sx={{ mt: 1, mb: 1 }}>
                  <PreviewDialogTypography
                    sx={{ color: "#576369", fontWeight: 600 }}
                  >
                    Business hours for {currentDayOfWeek} {userCurrentDate}
                    :&nbsp;
                    {currentHours}
                  </PreviewDialogTypography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Dialog>
  );
}

export default PreviewNotification;
