import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Button,
  Box,
  Modal,
  IconButton,
  Skeleton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Fade,
  Divider, 
  Paper, 
} from "@mui/material";
import PauseIcon from "src/public/assets/icons/icons/pause.png";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";





//Pass icons as props to render different icons 
//Set accept to true to render confirm dialog with accept icon
//Set accept to false to render confirm dialog with decline icon

const PauseSubscriptionModal = ({
  open,
  onClose,
  subscriptionStatus,
  upcomingInvoiceDate,
  subscriptionDetails, 
  paymentDetails,
  formatDate,
  selectedDate,
  setSelectedDate,
  handlePauseClick,
  isPausing



}) => {




  // Get the upcoming payment timestamp from props
  const upcomingPaymentTimestamp = paymentDetails?.upcomingPayment?.timestamp || 0;
  const upcomingPaymentDate = dayjs.unix(upcomingPaymentTimestamp); // Convert Unix to Day.js
  const minSelectableDate = upcomingPaymentDate.add(31, "day"); // At least 31 days after the upcoming payment
  const today = dayjs(); // Get today's date
  const maxSelectableDate = today.add(6, "month"); // Limit to 6 months ahead

  // Ensure minDate is not in the past
  const effectiveMinDate = minSelectableDate.isBefore(today) ? today : minSelectableDate;

  // Handler for date selection
  const handleDateChange = (date) => {
    if (date) {
      // Convert the date to a Stripe-compatible Unix timestamp (in seconds)
      const stripeTimestamp = date.startOf("day").unix(); // Get the start of the day and convert to Unix timestamp (seconds)
      setSelectedDate(stripeTimestamp);
      
      // Optionally, log it for debugging purposes
    }
  };







  useEffect(() => {
    // Reset selected date when the modal is opened
    if (open) {
      setSelectedDate(null);
    }
  }
  , [open]);




  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      transitionDuration={{ enter: 300, exit: 300 }}
      PaperProps={{ sx: { borderRadius: "10px", width:'100%', display:"flex", flexGrow:1,     minWidth: "300px", // Prevents shrinking too much
        maxWidth: "970px", } }}
    >
    <Box
        sx={{
          ml: 0,
          width: "100%",
          borderRadius: "15px",
          justifyContent: "space-around",
          height:'100%',  
          flexDirection: "row",  
          overflowY:'auto' ,
          pl: 2.75, // Optional: Add padding-left for spacing


        }}
      >
<Stack 
  direction="column" 
  sx={{ 
    width: "100%", 
    flexWrap: "nowrap", 
    alignItems: "flex-start", // Move items to the left
    justifyContent: "flex-start", 
    padding:1,
    mt:2.5,
    ml:.75
  
  }}
>  

  <img
    src={PauseIcon}
    style={{
      border: "1.25px solid rgba(203, 213, 217, 0.7)",
      width: "auto",
      height: "30px",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      padding: "1px",
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
      borderRadius: "6px",
      display: "block", // Prevents unintended spacing

    }}
  />
  
  <Typography
    align="left"
    sx={{
      fontSize: "20px",
      fontWeight: 550,
      color: "#48768C",
      letterSpacing: "-.027em",
      whiteSpace: "nowrap",
      pt: 1.5,
      pr:1,
      mb: 2.5,
    }}
  >
    Pause Your Subscription
  </Typography>


</Stack>
<IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 7,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>

      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          minWidth: "100%",
          flexGrow: 1,

          width: "100%",
        }}
      >
        <Stack direction="row" sx={{ width: "100%", flexWrap: "nowrap", pb:3, mb:2, overflowY:'auto'   }}>
  

          {/* Calender Container */}


          <Paper
  elevation={5}
  sx={{
    display: "flex",


    border: "1px solid rgba(173, 216, 230, 0.7)",
    borderRadius: "8px",
    mx: 1.5,
    gap: 1, // Adds consistent spacing between elements
    pb:0,
    padding:1
  }}
>

<DateCalendar
  fixedWeekNumber={4}
  minDate={effectiveMinDate} // Ensure 31-day rule & no past selection
  maxDate={maxSelectableDate} // 6-month future limit
  views={['day']} // Removes the ability to pick a year
  onChange={handleDateChange}
  sx={{
    pt: 1,
    pb: 0,
    color: '#48768C', // Default text color for all elements
    '.MuiTypography-root': { color: '#48768C' }, // General typography styling
    '.MuiDayCalendar-weekDayLabel': { color: '#48768C' }, // Weekday label color
    '.MuiPickersDay-root': { 
      color: '#48768C', 
      fontWeight: 550 
    }, // Default day text color
    '.MuiPickersDay-root.Mui-selected': {
      color: '#48768C !important', // Ensure text color is white
      backgroundColor: '#c2d7df !important', // Ensure selected background color applies
      '&:hover': { backgroundColor: '#48768C80 !important' }, // Hover effect for selected day
    },
    '.MuiPickersDay-root.Mui-selected:focus': {
      backgroundColor: '#c2d7df !important', // Ensure focus state also applies background color
      color: '#48768C !important',
    },
    '.MuiPickersDay-today': { 
      borderColor: '#48768C69', 
      borderWidth: 2, 
      backgroundColor: '#48768C80' 
    }, // Today's date styling
  }}
/>
   </Paper>

            {/* Right  Column */}

          <Box
            sx={{
              width: "80%",
              display: "flex",
              pl: 3,
              flexDirection: "column",
              overflowX: "auto",
              pr: 2,
              ml:2.5,
              my:.25

          
            }}
          >
            <Stack direction="column" sx={{}}>
              <Typography
                align="left"
                sx={{
                  fontSize: "20.5px",
                  fontWeight: 550,
                  color: "#48768C",
                  letterSpacing: "-.02em",
                  lineHeight: "-2",
                  whiteSpace: "noWrap",
                }}
              >
Need a break? Pause your plan and restart seamlessly.
              </Typography>
              <Typography
                align="left"
                sx={{
                  fontSize: "14.5px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.02em",
                  lineHeight: "-2",
                  whiteSpace: "wrap",
                  mb:1
                }}
              >
               Select a date when you want your subscription to reactivate and we'll handle the rest. 
              </Typography>
             
            </Stack>

            <Divider sx={{ borderBottomWidth: "1.5px", mb: 2.5, mt: 1 }} />
            <Typography
              align="left"
              sx={{
                fontSize: "18.5px",
                fontWeight: 550,
                color: "#48768C",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                whiteSpace: "noWrap",
                mt: 1,
              }}
            >
              Billing & Plan Summary
            </Typography>
            <Typography
                align="left"
                sx={{
                  fontSize: "14.5px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.02em",
                  lineHeight: "-2",
                  whiteSpace: "wrap",
                  mb:1
                }}
              >
               Your subscription will remain active until the end of your current billing cycle. 
              </Typography>


            <Stack direction="row" sx={{ alignItems: "center", my:.5 }}>
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                }}
              >
                Subscription status:
              </Typography>
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color:
                    subscriptionStatus === "inactive" ? "#8C4350BD" : "#48B09B",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                  ml: 0.5,
                }}
              >
      {subscriptionDetails?.subscriptionStatus
        ? subscriptionDetails?.subscriptionStatus.charAt(0).toUpperCase() +
          subscriptionDetails?.subscriptionStatus.slice(1)
        : ""}
                      </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "center", mt:.5 }}>
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                }}
              >
                Current billing cycle ends:
              </Typography>
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#48768C",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                  ml: 0.5,
                }}
              >
             {upcomingInvoiceDate}
              </Typography>
            </Stack>
         
         {selectedDate && (
            <Stack direction="row" sx={{ alignItems: "center", mt:1, mb:4.75 }}>
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                }}
              >
                Subscription will reactivate on:
              </Typography>
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#48768C",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                  ml: 0.5,
                }}
              >
              {formatDate(selectedDate)}
              </Typography>
            </Stack>
          )}
            



           


            <Button
            onClick={handlePauseClick}
            disabled={isPausing || !selectedDate}
              align="left"
              sx={{
                fontSize: "18.5px",
                fontWeight: 550,
                color: "#48768C",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                whiteSpace: "noWrap",
                mt: 0,
                border: "1px solid rgba(173, 216, 230, 0.9)",    
                borderRadius: "9px",
                alignText:'center',
                py:2,
                px:1,
                width:'200px',
                ml:-.25,
                mt:'auto',
                
              }}
            >
            {isPausing ? "Saving..." : "Pause My Subscription"}
            </Button>

            
        
          </Box>
        </Stack>
      </Box>
      </Box>
    </Dialog>
    </LocalizationProvider>
  );
};

export default PauseSubscriptionModal;
