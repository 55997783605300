import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";

const useCancelSubscription = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancelError, setCancelError] = useState(null);
  const [cancelSuccess, setCancelSuccess] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { showSnackBar } = useAppContext();

  const cancelSubscription = async (
    customerId,
    refetchSubscriptionDetails,
  ) => {
    try {
      setIsCancelling(true);
      setCancelError(null);
      setCancelSuccess(null);

      const token = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/subscriptions/manage/cancel-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Passing the Auth0 token for secure communication
          },
          body: JSON.stringify({
            customerId
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to cancel subscription");
      }

      const data = await response.json();
      setCancelSuccess(data.message);
      showSnackBar( "Subscription Canceled", "success");
      refetchSubscriptionDetails(); // Trigger refetch in parent component
      return true; // Indicate success
    } catch (error) {
      showSnackBar("Error canceling subscription", "error");
      console.error("Error canceling subscription:", error);
      setCancelError("Error canceling subscription");
      return false; // Indicate failure
    } finally {
      setIsCancelling(false);
    }
  };

  return {
    isCancelling,
    cancelError,
    cancelSuccess,
    cancelSubscription,
  };
};

export default useCancelSubscription;
