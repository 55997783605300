import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Modal,
  Grid,
  Typography,
  Fade,
  Stack,
  Skeleton,
  CardMedia,
} from "@mui/material";
import moment from "moment-timezone"; // Import moment-timezone
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import companyImage from "src/public/assets/companyImage.png";
import RestartSetupDialog from "src/components/modal/NewUserSignUp/Refresh/Components/RestartSetupDialog";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import { useAccountContext } from "src/context/AccountContext";


const SetupComplete = ({isSingleLocation, isSingleUser, activeStep, handleClose  }) => {
const {setShowNewUserSignUp} = useAccountContext();





  return (  
 <Fade in={true} timeout={400}>
          <Box
            sx={{
              height: '100%',
              width: "auto",
              justifyContent: "center",
              borderRadius: "40px",
              fontSize: 17,
              fontWeight: 600,
              backgroundColor: "#FFFFFF",
              color: "#48768C",
              padding: 2,
            }}
          >
            <Typography sx={{ mt: 1, fontSize: '1.28rem', textAlign: "left", fontWeight: 600, px: .25, letterSpacing: '-0.02em', lineHeight: '-0.02em' }}>
            Your account is ready to go!
            </Typography>
            {!isSingleUser || !isSingleLocation ? (

            <Typography color="textSecondary" sx={{ fontSize: '.91rem', textAlign: "left", mb: 1, fontWeight: 500, px: .25, letterSpacing: '-0.01em', lineHeight: '-0.04em' }}>
            Head over to the Account Settings section to add more locations and
            employees or to make any other changes.{" "}
                        </Typography>

            ) : (
              <Typography color="textSecondary" sx={{  fontSize: '.91rem', textAlign: "left", mb: 1, fontWeight: 500, px: .25, letterSpacing: '-0.01em', lineHeight: '-0.04em' }}>
            Head over to your account settings & setup your first notification

                          </Typography>
            )}
           
           {!isSingleUser || !isSingleLocation ? (
            <Typography sx={{ textAlign: "left", fontSize: '.95rem', fontWeight: 600, mt: 2, }}>
            You can now login using the new username and pin you just created.
            </Typography>
            
           ) : (
<>
            <Typography sx={{ textAlign: "left", fontSize: '.95rem', fontWeight: 600, mt:3,  }}>
You can make any changes to your account & setup your locations hours of operations in the Account Settings section.
             </Typography>
             </>
            )}

<PrimaryButton
  sx={{ width: "125px", display: activeStep === 4 ? "block" : "none",
    width: activeStep === 0 ? "150px" : "140px" , 
    height: activeStep === 0 ? "45px" : "39px" , 
    borderRadius: "6px",
    ml:'auto',  
    mt:4 ,
    mb:-2

   }}
  variant="contained"
  onClick={() => {
    setShowNewUserSignUp(false);  
    handleClose();
    window.location.reload(); // Refresh the page
  }}
>
 Finish
</PrimaryButton>


          </Box>
        </Fade>

  );

};


export default SetupComplete;