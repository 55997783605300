import react, {useEffect, useState} from "react";
import {Modal, Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, IconButton, Button, Stack, Divider, Dialog, Paper, Fade} from "@mui/material";
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';

const ReviewStep = ({ companyName, companyAddress, companyCity, companyState, companyZip, companyEmail, 
    companyUrl, userFirstName, userLastName, username, locationName, locationEmail, locationPhone, isSingleLocation, isSingleUser, values   
 }) => {





    return (

  <Fade in={true} timeout={400}>
<Box
  sx={{
    height:'100%',
    width: "700px",
    justifyContent: "center",
    borderRadius: "40px",
    fontSize: 17,
    fontWeight: 600,
    backgroundColor: "#FFFFFF",
    color: "#48768C",
    padding:1,
    mt:1,
    mb:1,
  }}
>
             <Typography sx={{ fontSize: '1.28rem', textAlign: "left", fontWeight: 600,       
 letterSpacing: '-0.02em', lineHeight: '-0.02em',           mx:'-4px',
}}>
           Make Sure Everything Looks Good
             </Typography>
               <Typography
               color="textSecondary"
                 sx={{
                   mt: 0,
                   fontSize: '.87rem',
                   textAlign: "left",
                   mb: 1.5,
                   fontWeight: 500,

                         letterSpacing:'-0.01em',
                   lineHeight: '-0.04em',
                   mx:'-4px',
                    
                 }}
               >
             You can always update or make changes later in your account settings.
               </Typography>
            
            
            {/* Company Info Header */}
        

             <Box sx={{height:'23vh', overflowY:'auto',                 border: "1px solid rgba(173, 216, 230, 0.8)", borderRadius:'5px',
          pt:'3px', mt:'0px',
          mx:'-4px',
          padding:.25, backgroundColor: "rgba(173, 216, 230, 0.15)"
        }}>

        <Box sx={{}}>


<Typography

            sx={{ fontSize: "1.04rem", fontWeight: 600,       p: .5, 
                py:.5,    color: "#48768C",
                pt:1, letterSpacing:'-0.015em',  
            }}
          >
             Your Company Info
             </Typography>

          <Box sx={{px:.75,                 backgroundColor: "rgba(173, 216, 230, 0.4)", py:.5, borderRadius:'5px'
}}>
           

           
            <Typography

              sx={{
                fontSize: ".93rem",
                fontWeight: 600,
                height: "30px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                color: "#40657a",
                mb:'-5px'

              }}
            >
            {values?.name}
            </Typography>
            <Typography

sx={{
  fontSize: ".93rem",
  fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",

}}
>
{values?.address}
</Typography>
<Stack direction="row"  sx={{mt:'-5px' }}>
<Typography

sx={{
  fontSize: ".93rem",
  fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",
  mr:'3px'

}}
>
{values?.city}, 
</Typography>
<Typography

sx={{
  fontSize: ".93rem",
  fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",
  mr:'3px',
  ml:'2px'

}}
>
{values?.state} 
</Typography>
<Typography

sx={{
  fontSize: ".93rem",
  fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",
  p: .25, // Adds padding for better readability

}}
>
{values?.zip} 
</Typography>
</Stack>
{isSingleLocation ? (
    <>
    <Stack direction="row"  sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', 
}}>
<Box sx={{display:'flex', alignItems:'center', justifyContent:'center',   border:'1.5px solid #48768C', 
  borderRadius: "5px",  padding: '4px',
  mr:.5,   backgroundColor: "#FFFFFF",
  height:'20px', width:'20px',   
  opacity: .8
    }}> 
    
<PublicOutlinedIcon

sx={{
  fontSize: "16px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  color: "#48768C",
}}
/>

</Box>
<Typography

sx={{
  fontSize: ".93rem",
  fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",
  mr:1

}}
>
{values?.url}
</Typography>
</Stack>


<Stack direction="row"  sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', 
}}>
<Box sx={{display:'flex', alignItems:'center', justifyContent:'center',   border:'1.5px solid #48768C', 
  borderRadius: "5px",  padding: '4px',
  mr:.5,   backgroundColor: "#FFFFFF",
  height:'20px', width:'20px',   
  opacity: .8


  }}>   
<LocalPhoneRoundedIcon

sx={{
  fontSize: "16px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  color: "#48768C",
}}
/>
</Box>

<Typography

sx={{
  fontSize: ".93rem",
  fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",
  mr:1

}}
>
{values?.locationPhone}
</Typography>




<Box sx={{display:'flex', alignItems:'center', justifyContent:'center',   border:'1.5px solid #48768C', 
  borderRadius: "5px",  padding: '4px',
  mr:.5,   backgroundColor: "#FFFFFF",
  height:'20px', width:'20px',   
  opacity: .8
    }}> 
<AlternateEmailRoundedIcon

sx={{
  fontSize: "16px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  color: "#48768C",
}}
/>
</Box>
<Typography

sx={{
    fontSize: ".93rem",
    fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",

}}
>
{values?.locationEmail}
</Typography>
</Stack>
</>
) : (



<Stack direction="row"  sx={{mt:'-5px' }}>
<Typography

sx={{
  fontSize: ".93rem",
  fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",
  mr:'3px'

}}
>
{values?.email} | 
</Typography>
<Typography

sx={{
  fontSize: ".93rem",
  fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",

}}
>
{values?.url}
</Typography>

</Stack>
)}




          </Box>
        </Box>
        {!isSingleLocation && (   
        <Box sx={{}}>
        <Typography

            sx={{ fontSize: "1.06rem", fontWeight: 600,       p: .5, // Adds padding for better readability
                py:.5, ml:'.5px', color: "#48768C", pt:1, letterSpacing:'-0.015em', 
            }}
          >
            Your Location's Info
             </Typography>

             <Box sx={{px:.75,                backgroundColor: "rgba(173, 216, 230, 0.4)", py:.5, borderRadius:'5px'
}}>
           
          



            <Typography

              sx={{
                fontSize: ".93rem",
                fontWeight: 600,
                height: "30px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                color: "#40657a",
                mb:'-2px'

              }}
            >
            {values?.locationName}
            </Typography>
<Stack direction="column"  sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', 
}}>


<Box sx={{display:'flex', width:'100%', alignItems:'center'}}>

<Box sx={{display:'flex', alignItems:'center', justifyContent:'center',   border:'1.5px solid #48768C', 
  borderRadius: "5px",  padding: '4px',
  mr:.5,   backgroundColor: "#FFFFFF",
  height:'20px', width:'20px',   
  opacity: .8,   }}>   




<LocalPhoneRoundedIcon
sx={{fontSize: "16px", fontWeight: 500, display: "flex", alignItems: "center", color: "#48768C",
}}
/>

</Box>

<Typography

sx={{
  fontSize: ".93rem",
  fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",
  mr:1

}}
>
{values?.locationPhone}
</Typography>
</Box>


<Box sx={{display:'flex', width:'100%', alignItems:'center'}}>


<Box sx={{display:'flex', alignItems:'center', justifyContent:'center',   border:'1.5px solid #48768C', 
  borderRadius: "5px",  padding: '4px',
  mr:.5,   backgroundColor: "#FFFFFF",
  height:'20px', width:'20px',   
  opacity: .8
    }}> 
<AlternateEmailRoundedIcon

sx={{
  fontSize: "16px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  color: "#48768C",
}}
/>
</Box>
<Typography

sx={{
    fontSize: ".93rem",
    fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",

}}
>
{values?.locationEmail}
</Typography>
</Box>
</Stack>
          </Box>
        </Box>
        )}
      
      
      {!isSingleUser && (  
        <Box sx={{}}>
        <Typography

            sx={{ fontSize: "1.05rem", fontWeight: 600,       p: .5, // Adds padding for better readability
                py:.5, ml:'.5px', color: "#48768C", pt:1, letterSpacing:'-0.015em', 
            }}
          >
             Your Account Administrator
             </Typography>

             <Box sx={{px:.75,               backgroundColor: "rgba(173, 216, 230, 0.4)", py:.5, borderRadius:'5px'
}}>


<Stack direction="row"  sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', 
}}>

<Box sx={{display:'flex', alignItems:'center', justifyContent:'center',   border:'1.5px solid #48768C', 
  borderRadius: "5px",  padding: '4px',
  mr:.5,   backgroundColor: "#FFFFFF",
  height:'20px', width:'20px',   
  opacity: .8 }}> 



<BadgeOutlinedIcon

sx={{
  fontSize: "16px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  color: "#48768C",
}}
/>
</Box>
           
            <Typography

              sx={{
                fontSize: ".93rem",
                fontWeight: 500,
                height: "30px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                color: "#395465",
                pt:'2px',
                mr:'3px'   

              }}
            >
        {values?.newFirstName}
            </Typography>
            <Typography

sx={{
  fontSize: ".93rem",
  fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",
  pt:'2px'

}}
>
   {values?.newLastName}
</Typography>

</Stack>    




<Stack direction="row"  sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', 
}}>
<Box sx={{display:'flex', alignItems:'center', justifyContent:'center',   border:'1.5px solid #48768C', 
  borderRadius: "5px",  padding: '4px',
  mr:.5,   backgroundColor: "#FFFFFF",
  height:'20px', width:'20px',   
  opacity: .8
    }}>  
<AccountCircleRoundedIcon

sx={{
  fontSize: "16px",
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  color: "#48768C",
}}
/>
</Box>

<Typography

sx={{
  fontSize: ".93rem",
  fontWeight: 500,
  height: "30px",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  color: "#395465",
  mr:1

}}
>
{values?.newUsername}
</Typography>


</Stack>
          </Box>
        </Box>
        )}
     
      </Box>
           </Box>
         </Fade>
    );
};


export default ReviewStep;