import React, { useEffect, useState } from 'react';
import { Stack, Typography, Box, Paper } from "@mui/material";
import RadioButtonCheckedTwoToneIcon from "@mui/icons-material/RadioButtonCheckedTwoTone";
import RadioButtonUncheckedTwoToneIcon from "@mui/icons-material/RadioButtonUncheckedTwoTone";
 import DeliveryIcon from "src/public/assets/icons/messageModal/deliver.png";
 import OutForDeliveryIcon from "src/public/assets/icons/messageModal/outForDelivery.png";

 import DeliveredIcon from "src/public/assets/icons/messageModal/mail.png";
 import PickupIcon from "src/public/assets/icons/messageModal/cash.png";
 import TimeNeededIcon from "src/public/assets/icons/messageModal/timer.png";
 import CalenderIcon from "src/public/assets/icons/messageModal/calendar.png";
 import RocketIcon from "src/public/assets/icons/messageModal/rocket.png";
 import CloseIcon from "src/public/assets/icons/messageModal/cancel.png";
 import NewIcon from "src/public/assets/icons/messageModal/bell.png";
 import PriceIcon from "src/public/assets/icons/messageModal/price.png";
 import DelayIcon from "src/public/assets/icons/messageModal/delay.png";
 import ApproveIcon from "src/public/assets/icons/messageModal/approve.png";
 import DateIcon from "src/public/assets/icons/messageModal/date.png";
 import PaymentIcon from "src/public/assets/icons/messageModal/payment.png";
 import RefundIcon from "src/public/assets/icons/messageModal/refund.png";
 import OrderUpdateIcon from "src/public/assets/icons/messageModal/update.png";
 import DeliveryIcon2 from "src/public/assets/icons/messageModal/delivery2.png";
 import OrderIcon from "src/public/assets/icons/messageModal/order.png";
 import TwoWayPromptDialog from "../components/TwoWayPromptDialog";











 





 const TEMPLATES = [
    {
      icon: PickupIcon,
      title: "Ready For Pickup",
      description: "Includes balance due at pickup field",
      checked: false,
      category: "Popular Templates",
    },
    {
      icon: OutForDeliveryIcon,
      title: "Out For Delivery",
      description: "Includes date shipped & an estimated delivery date fields.",
      checked: false,
      category: "Popular Templates",
    },
    {
      icon: DeliveredIcon,
      title: "Order Delivered",
      description: "Includes date & time field showing when delivered",
      checked: false,
      category: "Deliveries",
    },
    {
        icon: PriceIcon,
        title: "Price Estimate",
        description: "Send a price estimate to your customer",
        checked: false,
        category: "Quotes",
        twoWay:true

      },
    {
      icon: TimeNeededIcon,
      title: "Turnaround Time",
      description: "Quote your customers on an expected turnaround time",
      checked: false,
      category: "Quotes",
      twoWay:true

    },

    {
      icon: ApproveIcon,
      title: "Pending Approval",
      description: "Alert your customer that you need approval before proceeding",
      checked: false,
      category: "Quotes",
      twoWay:true
    },
    {
        icon: DateIcon,
        title: "Confirm Appointment",
        description: "Remind customers to confirm their scheduled appointment.",
        checked: false,
        category: "Quotes",
        twoWay:true

      },
    {
      icon: PaymentIcon,
      title: "Payment Received",
      description: "Notify when a payment has been successfully processed",
      checked: false,
      category: "Orders",
    },
    {
      icon: DelayIcon,
      title: "Shipment Delayed",
      description: "Inform customers about a delay in their shipment",
      checked: false,
      category: "Deliveries",
    },
    {
      icon: OrderIcon,
      title: "Order Canceled",
      description: "Notify customers when their order is canceled",
      checked: false,
      category: "Statuses",
    },
    {
      icon: RocketIcon,
      title: "Item Shipped",
      description: "Update customers with the item shipping details",
      checked: false,
      category: "Popular Templates",
    },
    {
      icon: CloseIcon,
      title: "Delivery Failed",
      description: "Inform the customer when a delivery attempt fails",
      checked: false,
      category: "Deliveries",
    },
    {
      icon: RefundIcon,
      title: "Refund Processed",
      description: "Notify customers when a refund is processed",
      checked: false,
      category: "Orders",
    },

    {
      icon: OrderUpdateIcon,
      title: "Order Updated",
      description: "Notify when there is an update on the order status",
      checked: false,
      category: "Orders",
    },


    {
      icon: DeliveryIcon,
      title: "Pending Shipment",
      description: "Notify customers when their order is pending shipment",
      checked: false,
      category: "Statuses",
    },
    {
      icon: CalenderIcon,
      title: "Delivery Scheduled",
      description: "Let customers know when their delivery is scheduled",
      checked: false,
      category: "Popular Templates",
    },
    {
        icon: DeliveryIcon2,
        title: "Materials Delivered",
        description: "Materials needed for an order have been received",
        checked: false,
        category: "Orders",
      },
      {
        icon: NewIcon,
        title: "Create New Notification Type",
        description: "Create your own custom notification from scratch",
        checked: false,
        category: "New",
      },
  ];


  const TemplateCard = ({ icon, title, description, checked, twoWay, onCardClick }) => {
    
    
    const handleCardClick = () => {
      onCardClick(title, twoWay);  // Pass both title and twoWay status
    };
  





  
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "left",
          border: "1px solid #48768C",
          borderRadius: "8px",
          my: 1.5,
          cursor: "pointer", // Indicating that the card is clickable
        }}
        onClick={handleCardClick} // Attach click handler
      >
        <Stack direction="row" sx={{ alignItems: "flex-start", width: "100%", display: "flex" }}>
          <Paper
            elevation={6}
            sx={{
              width: 37,
              height: 37,
              backgroundColor: "#f2f7f9",
              borderRadius: "50%",
              padding: "3px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              marginLeft: "15px",
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 1.25,
            }}
          >
            <img src={icon} alt={title} style={{ width: "100%", height: "auto", maxHeight: 30 }} />
          </Paper>
  
          <Stack direction="column" spacing={0} sx={{ p: 2, width: "100%" }}>
            <Stack direction="row" sx={{ width: "100%", alignItems: "center", display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ color: "#48768C", fontWeight: 600, fontSize: 15, mb: -0.5 }}>
                {title}
              </Typography>
  
              <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
                {checked ? (
                  <RadioButtonCheckedTwoToneIcon sx={{ color: "#48768C" }} />
                ) : (
                  <RadioButtonUncheckedTwoToneIcon sx={{ color: "#48768C" }} />
                )}
              </Box>
            </Stack>
  
            <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 500, mt: -0.25, width: "90%" }}>
              {description}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  };

const TemplatesList = ({isModalOpen, activeTitle, setActiveTitle, handleEnableClick, selectedNotifications, handleButtonClick, twoWayEnabled, setTwoWayEnabled, checkTwoWayNotifications, setShowConfirmation, setExistingNotificationName, showConfirmation, existingNotificationName }) => {
  const [showConfirmTwoWayDialog, setShowConfirmTwoWayDialog] = useState(false);


  const handleCloseConfirmTwoWayDialog = () => {
    setShowConfirmTwoWayDialog(false);
  };

  const handleCardClick = (title, twoWay) => {
    setActiveTitle(title);
    if (twoWay) {
      setShowConfirmation(false);
      setShowConfirmTwoWayDialog(true);
    }
  };

  // Group templates by category
  const groupedTemplates = TEMPLATES.reduce((categories, template) => {
    const { category } = template;
    
    if (!categories[category]) {
      categories[category] = [];
    }
    categories[category].push(template);

    return categories;
  }, {});

  // Get the category keys
  const categoryKeys = Object.keys(groupedTemplates);

 



  useEffect(() => {
    if (isModalOpen) {
        setActiveTitle("Create New Notification Type"); // Reset active title when the component mounts
        }
    }, [isModalOpen]);


    useEffect(() => {
      if (showConfirmTwoWayDialog) {
        setTwoWayEnabled(false);
        setShowConfirmation(false);
      }
    }, [showConfirmTwoWayDialog]);




    return (
      <>
        <Stack direction="column" sx={{ height: "100%" }}>
          {/* Sticky "New" Section */}
          <Stack
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 2,
              paddingBottom: 1,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                color: "#48768C",
                padding: "8px 0",
                mb: -2,
              }}
            >
              New
            </Typography>
            {groupedTemplates["New"].map((template, index) => (
              <TemplateCard
                key={index}
                {...template}
                sx={{ mb: 2 }}
                checked={template.title === activeTitle} // Only the active title will have checked as true
                onCardClick={handleCardClick}
              />
            ))}
          </Stack>
    
          {/* Scrollable Categories (excluding "New") */}
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              overflowY: "auto",
              height: "auto",
              maxHeight: "350px", // Controls scroll area
              mt: 1,
            }}
          >
            {categoryKeys
              .filter((category) => category !== "New")
              .map((category) => (
                <div key={category}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 600, mt: 1, mb: -1, color: "#48768C" }}
                  >
                    {category}
                  </Typography>
                  {groupedTemplates[category].map((template, index) => (
                    <TemplateCard
                      key={index}
                      {...template}
                      sx={{ mb: 2 }}
                      checked={template.title === activeTitle} // Only the active title will have checked as true
                      onCardClick={handleCardClick}
                      twoWay={template.twoWay || false} // Ensure a boolean value

                    />
                  ))}
                </div>
              ))}
          </Stack>
        </Stack>
    
        <TwoWayPromptDialog
          open={showConfirmTwoWayDialog}
          onClose={handleCloseConfirmTwoWayDialog}
          checkTwoWayNotifications={checkTwoWayNotifications}
          showConfirmation={showConfirmation}
          existingNotificationName={existingNotificationName || ''}
          handleButtonClick={handleButtonClick}
          twoWayEnabled={twoWayEnabled}
          setTwoWayEnabled={setTwoWayEnabled}
          setShowConfirmation={setShowConfirmation}
          
        />
      </>
  );
};

export default TemplatesList;