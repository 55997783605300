import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Modal,
  Select,
  MenuItem,
  CircularProgress,
  InputLabel,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "../../context/AppContext";
import CloseIcon from "@mui/icons-material/Close";
import ChangeLocationIcon from "../../public/assets/icons/icons/location2.png";
import { useAuth } from "../../context/AuthContext";
import { useAccountContext } from "../../context/AccountContext";
import { useUserSelectedNotifications } from "../../../src/context/UserNotificationsContext";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";

const ChangeLocationModal = ({
  isOpen,
  onClose,
  onSave,
  editData,
  setEditData,
  subUserData,
  initialLocation,
  loading,
  setLoading,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, getAccessTokenSilently } = useAuth0();
  const [locations, setLocations] = useState([]);
  const { updateActiveLocation } = useAppContext();
  const [selectedLocation, setSelectedLocation] = useState("");
  const { isAuthenticated } = useAuth0();
  const { accountData } = useAccountContext();
  const { handleReloadComponents } = useAppContext();
  const { username } = useAuth();

  const fetchLocations = async () => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      const locationsResponse = await fetch(
        `${apiUrl}/api/locations/${user.sub}`,
        {
          headers: {
            "Cache-Control": "no-cache",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (!locationsResponse.ok) {
        console.error("Failed to fetch locations");
        setLoading(false);

        return;
      }

      const data = await locationsResponse.json();
      setLocations(data.rows);

      setSelectedLocation(initialLocation || data.rows[0]?.location_id || "");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching locations:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (isOpen) {
      fetchLocations();
    }
    setLoading(false);
  }, [isOpen]);

  const handleLocationChange = (event) => {
    const selectedLocationId = event.target.value;
    setSelectedLocation(selectedLocationId);
    setEditData({ ...editData, assigned_location: selectedLocationId });
    updateActiveLocation(selectedLocationId);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth={true} // Make the dialog take up the full width
      maxWidth="md" // Set the maximum width to medium
      PaperProps={{ sx: { 
          borderRadius: "10px", // Add your custom styles here
          width: "100%", // Adjust the width as needed
          maxWidth: "475px", // Set a maximum width if needed
          pt:2,
          pb:1
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 2,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.75,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "26px" }} />
      </IconButton>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          height: "60px",
          backgroundColor: "#FFFFFF",
          color: "#374247",
          mt:.5
          

        }}
      >
        <Stack direction="column" sx={{display:'flex', width:'100%',mt:2}}>
          <Box>
        <img
          src={ChangeLocationIcon}
          alt="addLocationIcon"
          style={{

            border: "1px solid rgba(173, 216, 230, 0.5)",

            width: "auto",
            height: "33px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "2px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
            display:'flex',
            
          }}
        />
        </Box>
                <Typography
     
          sx={{
            color: "#48768C",
            fontSize: 20.5,
            fontWeight: 600,
            textAlign:'left',
       
    
            letterSpacing: '-.03em',
            mt:2
            
          }}
        >
          Change Your Active Location{" "}
        </Typography>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: "#FFFFFF", width: "100%",  alignItems:'center', justifyContent:'center', mt:2 }}>


        <Box
          sx={{
            backgroundColor: "#FFFFFF",
       
            width: "100%",
            justifyContent: "left",
          }}
        >
          <Select
            size="small"
            sx={{
              backgroundColor: "#FFFFFF",
              color: "#576369",
              borderRadius:'5px',
              width:'100%',
              mt:3,
         
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#2F5C71", // Hover state border color
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#48768CBA", // Focused border color
              },
              "& .Mui-focused .MuiInputLabel-root": {
                color: "#2F5C71", // Focused label color
              },
                 "& .MuiOutlinedInput-notchedOutline": {
                   border:'2px solid #97bac9', // Default border color

                 },
            }}
            value={selectedLocation}
            onChange={handleLocationChange}
          >
            {locations.map((location) => (
              <MenuItem
                sx={{ color: "#48768C" }}
                key={location.location_id}
                value={location.location_id}
              >
                {loading ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress size={16} sx={{ marginLeft: 1, mr: 2 }} />

                    <Typography variant="body1" sx={{ color: "#48768C" }}>
                      Loading...
                    </Typography>
                  </Box>
                ) : (
                  location.name
                )}
              </MenuItem>
            ))}
          </Select>
        </Box>


<Stack direction="row" spacing={2} sx={{marginTop:4}}>
          <SecondaryButton
            disabled={loading}
            sx={{
              mt: 4,
              fontSize: 18,
              transition: "background-color 0.3s ease",
              width: "64%",
              justifyContent: "center",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              borderRadius:'6px',
              color: '#48768C',
              backgroundColor: '#f2f7f9',
              boxShadow:'none',
              '&:hover': { backgroundColor: ' #dfeaee'  },

      
             
            }}
            onClick={onSave}
            variant="contained"
          >
            {loading ? "Updating Location" : "Switch Location"}
          </SecondaryButton>
          <SecondaryButton
            sx={{

              fontSize: 18,
              transition: "background-color 0.3s ease",
              width: "34%",
              justifyContent: "center",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              borderRadius:'6px',
              boxShadow:'none',
             
              '&:hover': { backgroundColor: '#faf5f7', border:'1px solid #f6edf0', color:'#bf7588' },


            }}
            onClick={onClose}
          >
            Cancel
          </SecondaryButton>
          </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeLocationModal;
