import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Button,
  IconButton,
  TextField,
  Box,
  Stack,
  Chip,
  Popover,
  Paper,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import enableIcon from "src/public/assets/icons/icons/enableTwoWay.png";
import disableIcon from "src/public/assets/icons/icons/disableTwoWay.png";

function ConfirmChangesDialog({
  open,
  handleDialogClose,
  dialogMessage,
  dialogSubText,
  buttonAction,
  isLoading,
  onClose,
  enableTwoWay,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      transitionDuration={{ enter: 300, exit: 300 }}
      PaperProps={{
        style: {
          backgroundColor: "#747C86",
          width: "200px",
          border: "1px solid rgba(173, 216, 230, 0.7)",
          minWidth: "400px",
          borderRadius: "18px",
          bottom: "5%",
          maxWidth: "600px",
        },
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          padding: 3,
          color: "#374247",
          alignItems: "center",
        }}
      >
        <Box>
          {enableTwoWay ? (
            <img
              src={enableIcon}
              style={{
                border: "transparent",
                width: "auto",
                height: "45px",
                backgroundColor: "#d5f2ea",
                borderRadius: "6px",
                padding: "1px",
                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                marginBottom: "5px",
              }}
            />
          ) : (
            <img
              src={disableIcon}
              style={{
                border: "transparent",
                width: "auto",
                height: "45px",
                backgroundColor: "#8C435036",
                borderRadius: "6px",
                padding: "1px",
                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                marginBottom: "5px",
              }}
            />
          )}
        </Box>
        <Typography
          sx={{
            color: "#48768C",
            mb: 0.5,
            fontWeight: 600,
            mt: 1.5,
            fontSize: "18px",
          }}
        >
          Confirm Changes To Your Notification
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          mt: -1,
          fontWeight: 600,
          letterSpacing: "0.02em",
          backgroundColor: "#FFFFFF",
          color: "#48768C",
          padding: ".75rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(191, 218, 231, 0.1)",
            border: "1px solid rgba(173, 216, 230, 0.7)",
            borderRadius: "10px",
            fontSize: 15,
            padding: ".75rem",
            pt: 2,
            px: 1.5,
          }}
        >
          <Typography
            sx={{ fontSize: "15.5px", fontWeight: "bold", textAlign: "center" }}
          >
            {dialogMessage}
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              mt: 1.5,
              textAlign: "center",
              width: "auto",
            }}
          >
            {dialogSubText}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              mt: 2,
              pt: 1,
              pb: 2,
            }}
          >
            <Stack
              direction="column"
              spacing={2.5}
              sx={{ width: "100%", display: "flex" }}
            >
              <Button
                onClick={buttonAction}
                disabled={isLoading}
                sx={{
                  display: "flex",
                  border: enableTwoWay
                    ? ".5px solid #66c3aa"
                    : "1px solid #8C435045",
                  borderRadius: "10px",
                  padding: "8px",
                  color: enableTwoWay ? "#FFFFFF" : "#7A3A45",
                  width: "100%",
                  backgroundColor: enableTwoWay ? "#72C5B4" : "#8C435029",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  "&:hover": {
                    backgroundColor: enableTwoWay ? "#72C5B4D6" : "#8C435033",
                  },
                  "&:active": { backgroundColor: "#94b6c020" },
                }}
                color="primary"
              >
                {enableTwoWay
                  ? "Enable Two-Way Notifications"
                  : "Disable Two-Way Notifications"}
              </Button>
              <Button
                onClick={onClose}
                sx={{
                  marginRight: "10px",
                  border: "1px solid #A4C5D2E8",
                  borderRadius: "10px",
                  padding: "8px",
                  color: "#48768C",
                  width: "100%",
                  backgroundColor: "#FFFFFF",
                  "&:hover": { backgroundColor: "#48768C12" },
                  "&:active": { backgroundColor: "#DBEAF0" },
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmChangesDialog;
