import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Modal,
  Grid,
  Typography,
  Fade,
  Stack,
  Skeleton,
  CardMedia,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import moment from "moment-timezone"; // Import moment-timezone
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import companyImage from "src/public/assets/companyImage.png";
import RestartSetupDialog from "src/components/modal/NewUserSignUp/Refresh/Components/RestartSetupDialog";



const theme = createTheme({
  palette: {
    primary: {
      main: "#48768C", // Set the main color to white
    },
  },
});






const CreateLocationStep = ({setIsNextButtonDisabled, isNextButtonDisabled, isSingleLocation, setIsSingleLocation, showCreateLocation,
  values, setValues, errors, setErrors, handleChange, setWarning, warning, validateField, handleBlur, handleStateChange,
 isSingleUser, setIsSingleUser, typing, setShowCreateLocation
 }) => {



  const handleLocationChange = (event) => {
    setIsSingleLocation(event.target.value === "single_location");
    if (!isSingleLocation) {
      setIsSingleUser(false);
    }
    setIsNextButtonDisabled(false);
  };






  useEffect(() => {

    setShowCreateLocation(false);
    if (!showCreateLocation) {
    setIsNextButtonDisabled(false);
    }
    }, []);




  
  return (  
    !showCreateLocation ? (
      <Fade in={true} timeout={400}>
      <Box
        sx={{
          height:'100%',
          width: "auto",
          justifyContent: "center",
          borderRadius: "40px",
          fontSize: 17,
          fontWeight: 600,
          backgroundColor: "#FFFFFF",
          color: "#48768C",
          padding:1,
          alignItems:'center',
          mb:1

        }}
      >
      
            <Typography sx={{ mt: 1,       fontSize: '1.22rem', textAlign: "left", mb: 1, fontWeight: 600 }}>

            NanoNotify works great with companies that have multiple
            locations!
          </Typography>
      
        <Typography
          sx={{
            mt: 1.75,
            fontSize: '1.05rem',
            textAlign: "left",
            fontWeight: 500,
            px:.25,
                  letterSpacing:'-0.015em',
            lineHeight: '-0.04em',
            color:'#64697D'
          }}
        >
            Does your company have multiple locations you need to
            manage?
          </Typography>
      
      
          <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 1.5, mb: 0.5, alignItems: 'center' }}>
  <FormControl component="fieldset">
    <RadioGroup name="locationType"
      value={isSingleLocation ? "single_location" : "multiple_locations"} // Ensure value reflects state
    onChange={handleLocationChange}>
      <FormControlLabel
        value="single_location"
        control={<Radio sx={{ color: "#48768c", '&.Mui-checked': { color: "#6595ab" } }} />}
        label="Just a single location"
        sx={{
          alignItems: "center",
          "& .MuiTypography-root": {
            fontSize: '1.04rem',
            textAlign: "left",
            fontWeight: 500,
            px: 0.25,
            letterSpacing: '-0.01em',
            lineHeight: '1.5', // Adjust this value for vertical centering
            color: '#64697D'
          }
        }}
      />
      <FormControlLabel
        value="multiple_locations"
        control={<Radio sx={{ color: "#48768c", '&.Mui-checked': { color: "#6595ab" } }} />}
        label="We have multiple locations"
        sx={{
          alignItems: "center",
          "& .MuiTypography-root": {
            fontSize: '1.04rem',
            textAlign: "left",
            fontWeight: 500,
            px: 0.25,
            letterSpacing: '-0.01em',
            lineHeight: '1.5', // Adjust this value for vertical centering
            color: '#64697D'
          }
        }}
      />
    </RadioGroup>
  </FormControl>
</Box>
      
      </Box>
      </Fade>
    ) : (
    
<Fade in={true} timeout={400}>
<Box
  sx={{
    height:'100%',
    width: "auto",
    justifyContent: "center",
    borderRadius: "40px",
    fontSize: 17,
    fontWeight: 600,
    backgroundColor: "#FFFFFF",
    color: "#48768C",
    padding:1,
    mt:1,
  }}
>
<Typography
    sx={{
      fontSize: '1.28rem',
      textAlign: "left",
      fontWeight: 600,
      letterSpacing:'-0.02em',
      lineHeight: '-0.02em'

    }}
  >
    {isSingleLocation ?  "Let's get some information about your location" : "Let's Add Your First Location"} 
  </Typography>


  <Typography
  color="textSecondary"
    sx={{
      mt: 0,
      fontSize: '.87rem',
      textAlign: "left",
      mb: 1.75,
      fontWeight: 500,
            letterSpacing:'-0.01em',
      lineHeight: '-0.04em'
    }}
  >
Your locations’ hours, contact info, and today’s business hours are automatically included in your notifications.
  </Typography>

        <Stack
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%",}} // Ensure it spans the available width
        >
   
    {!isSingleLocation && (

  <ThemeProvider theme={theme}>
    <TextField
      label="Location Nickname (Example: Main St.)"
      variant="outlined"
      size="small"
      value={values.locationName || ""}
      onBlur={() => handleBlur("locationName")}
      onChange={(e) => {
        let value = e.target.value;
      
        // Allow letters, numbers, and spaces, but trim leading and trailing spaces
        value = value.replace(/[^a-zA-Z0-9 ]/g, "").trimStart();
      
        // Ensure no trailing spaces
        if (value.trim().length <= 15) {
          handleChange("locationName", value);
        }
      }}
      fullWidth
      error={!typing && errors?.locationName}
      InputProps={{
        style: { color: "#48768C", borderRadius: "6px" },
      }}
      InputLabelProps={{ style: { color: "#82879B" } }}
      sx={{

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#6595ab",
        },
        color: "#48768C",
      }}
    />
  </ThemeProvider>
)}  
  <ThemeProvider theme={theme}>
    <TextField
      label="Location Email"
      variant="outlined"
      size="small"
      value={values.locationEmail || ""}
      onBlur={() => handleBlur("locationEmail")}
      onChange={(e) => handleChange("locationEmail", e.target.value)}
      fullWidth
      error={!typing && errors.locationEmail}
      InputProps={{
        style: { color: "#48768C", borderRadius: "6px" },
      }}
      InputLabelProps={{ style: { color: "#82879B" } }}
      sx={{

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#6595ab",
        },
        color: "#48768C",
      }}
    />
  </ThemeProvider>
  <ThemeProvider theme={theme}>
    <TextField
      label="Location Phone Number"
      variant="outlined"
      size="small"
      mb={2}
      value={values.locationPhone || ""}
        onChange={(e) => {
          const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
          if (numericValue.length <= 10) {
            let formattedValue = numericValue;
            if (numericValue.length > 6) {
              formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6)}`;
            } else if (numericValue.length > 3) {
              formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
            } else if (numericValue.length > 0) {
              formattedValue = `(${numericValue}`;
            }
            handleChange("locationPhone", formattedValue); // Update with formatted number
          }
        }}       
      onBlur={() => handleBlur("locationPhone")}
      error={!typing &&   errors?.locationPhone}
      fullWidth
      InputProps={{
        style: { color: "#48768C", borderRadius: "6px" },
      }}
      InputLabelProps={{ style: { color: "#82879B" } }}
      sx={{

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#6595ab",
        },
        color: "#48768C",
      }}
    />
  </ThemeProvider>
 
  </Stack>
</Box>
</Fade>
)  
  );
}

export default CreateLocationStep;  
