import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Stack,
  IconButton,
  DialogActions,
  Button,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import hoursIcon from "../../public/assets/icons/icons/hours.png";
import dayjs from "dayjs";
import LocationIcon from "../../public/assets/icons/icons/location.png";
import { useAppContext } from "src/context/AppContext";



// AfterHoursDialog component
export default function UpdateLocationDialog({
  open,
  onClose,

}) {
const [loading, setLoading] = useState(false);
    const {
      fetchLatestUserData,
    } = useAppContext();



    const updateAndClose = async () => {
      setLoading(true);
      await fetchLatestUserData();
      onClose();
      setLoading(false);
    }
 

  return (
    <Dialog
      open={open}
      onClose={updateAndClose}
      TransitionComponent={Fade}
      transitionDuration={{ enter: 300, exit: 300 }}
      PaperProps={{
        style: {
          backgroundColor: "#747C86",
          width: "200px",
          border: "1px solid rgba(173, 216, 230, 0.7)",
          minWidth: "400px",
          borderRadius: "10px",
          bottom: "5%",
          maxWidth: "600px",
        },
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          padding: 3,
          color: "#374247",
          alignItems: "center",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={updateAndClose}
          sx={{
            position: "absolute",
            right: 2,
            top: 4,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>
        <Box sx={{}}>
          <img
            src={LocationIcon}
            style={{
              border: "transparent",
              width: "auto",
              height: "40px",
              backgroundColor: "#ebf0f3",
              borderRadius: "6px",
              padding: "1px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              marginBottom: "5px",
            }}
          />
        </Box>
        <Typography
          sx={{
            color: "#48768C",
            mb: 0.5,
            fontWeight: 600,
            mt: 1.5,
            fontSize: "18px",
          }}
        >
         Your Active Location Has Changed
        </Typography>
        <Typography
          sx={{
            color: "#48768C",
            fontSize: "14.5px",
            alignItems: "center",
            textAlign: "center",
            fontWeight: 600,
            mb: 1,
          }}
        >
      
 We couldnt send your notification because you have been assigned to a different location
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          mt: -1,
          fontWeight: 500,
          letterSpacing: "0.02em",
          backgroundColor: "#FFFFFF",
          color: "#48768C",
          padding: ".75rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(191, 218, 231, 0.1)",
            border: "1px solid rgba(173, 216, 230, 0.7)",
            borderRadius: "10px",
            fontSize: 15,
            padding: ".75rem",
            pt: 1,
            px: 1.5,
          }}
        >





  
            <Box>

                <Typography
                  sx={{
                    fontSize: "14.5px",
                    mt: 1.5,
                    alignItems: "center",
                    textAlign: "center",
                    width: "auto",
                    fontWeight: 600,
                  }}
                >
 Once your active location has been updated, try sending your notification again
                </Typography>
       
            </Box>
 

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              mt: 2,
              pt: 1,
              pb: 1,
            }}
          >

              <Stack
                direction="column"
                spacing={2.5}
                sx={{ width: "100%", display: "flex" }}
              >
     
        
  
                <Button
                onClick={updateAndClose}
                  sx={{
                    marginRight: "10px",
                    border: "1px solid #A4C5D2E8",
                    borderRadius: "8px",
                    padding: "8px",
                    color: "#48768C",
                    width: "100%",
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",

                    "&:hover": { backgroundColor: "#48768C12" },
                    "&:active": { backgroundColor: "#DBEAF0" },
                  }}
                  color="primary"
                >
  {loading ?  "Updating Location..."  :  "Update Active Location" }
                </Button>
              </Stack>

          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
