import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Card,
  Typography,
  Paper,
  TableFooter,
  Button,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardMedia,
  Skeleton,
  Fade,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "../../context/AppContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { useChart } from "../../components/chart";
import LinearProgress from "@mui/material/LinearProgress";
import { useAccountContext } from "../../context/AccountContext"; // Import AppPr
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { set } from "lodash";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import DeleteIcon from "../../public/assets/icons/icons/delete.png";
import DetailsIcon from "../../public/assets/icons/icons/details.png";
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MessageDetails from "../../../src/components/modal/MessageDetails.js";
import { useWidgetContext } from "src/context/WidgetContext";
import Scheduled from "../../public/assets/illustrations/scheduled.png";
import StatusTag from "src/components/color-utils/statusTags";
import CreateCustomerModal from "src/components/modal/CreateCustomerModal";
import saveCustomerData from "../../components/customers/saveCustomerData";
import { useAuth } from "src/context/AuthContext";
import ScheduledDialog from "src/components/modal/ScheduledDialog";
import TooltipWrapper from "src/components/Tooltip/LightTooltip";

const StatusIcon = ({ status }) => {
  let icon;
  let color;

  switch (status) {
    case null:
      icon = <HelpOutlineIcon />;
      color = "#7589A2"; // Blue color
      break;
    case "Sent":
      icon = <CheckCircleIcon />;
      color = "#2BBB9E"; // Light green color
      break;
    case "Failed":
      icon = <CancelPresentationIcon />;
      color = "#8C4350"; // Red color
      break;
    case "Scheduled":
      icon = <AlarmOnIcon />;
      color = "#5B5B62"; // Red color
      break;
    default:
      icon = null;
  }

  return icon ? <span style={{ color }}>{icon}</span> : null;
};

export default function ScheduledMessages({
  loadingScheduledNotifications,
  scheduledNotificationsData,
  pauseAutoRefresh,
  resumeAutoRefresh,
  fetchAllWidgetTypes,
  showMonthlyMessages,
}) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { reloadComponents, showSnackBar } = useAppContext();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { accountData } = useAccountContext(); // Use the accountData from the context

  const [loading, setLoading] = useState(false);
  const isSingleLocation = accountData?.single_location; // Check if single_user is true in accountData
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { handleReloadComponents } = useAppContext();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isMessageDetailsOpen, setIsMessageDetailsOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // Step 1: State variable to track selected row index

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [customerFeedData, setCustomerFeedData] = useState([]);
  const { subUserData } = useAuth();

  const [imageLoaded, setImageLoaded] = useState(false);

  const [visibleRows, setVisibleRows] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [emptyRowCount, setEmptyRowCount] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const filteredRows = scheduledNotificationsData?.filter(
        (activity) => activity?.location_id === subUserData?.active_location,
      );

      const screenWidth = window.innerWidth;

      let visibleRowCount = 0;

      if (scheduledNotificationsData) {
        if (screenWidth <= 1440) {
          visibleRowCount = 9;
          setTotalRowCount(9); // Maximum of 9 rows for smaller screens
        } else if (screenWidth <= 1599) {
          visibleRowCount = 12; // Maximum of 12 rows for screens between 1400px and 1599px
          setTotalRowCount(12); // Maximum of 12 rows
        } else {
          visibleRowCount = scheduledNotificationsData.length; // For larger screens, show all available rows
          setTotalRowCount(12); // Update the max rows for larger screens
        }

        setVisibleRows(filteredRows.slice(0, visibleRowCount)); // Slice the data according to the row count
        setEmptyRowCount(totalRowCount - visibleRows.length);
      }
    };

    handleResize(); // Call initially to set rows on load

    window.addEventListener("resize", handleResize); // Handle resizing dynamically

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, [
    scheduledNotificationsData,
    showMonthlyMessages,
    totalRowCount,
    visibleRows.length,
    subUserData,
  ]);

  useEffect(() => {
    const img = new Image();
    img.src = Scheduled;
    img.onload = () => setImageLoaded(true);

    // Optional: Cleanup if the component unmounts before the image loads
    return () => {
      img.onload = null;
    };
  }, []);

  // Handle saving customer
  const handleSaveCustomer = async (firstName, lastName, location_name) => {
    const success = await saveCustomerData(
      selectedPhoneNumber,
      firstName,
      lastName,
      user.sub,
      location_name,
    );

    if (success) {
      handleCloseModal();
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDeleteScheduledMessage = async (selectedRow) => {
    if (!selectedRow) return; // Check if a row is selected
    const messageId = selectedRow.scheduled; // Assuming 'id' is the property containing the message ID
    const accessToken = await getAccessTokenSilently();

    try {
      const response = await fetch(
        `${apiUrl}/hooks/scheduled-messages/${messageId}/cancel`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.ok) {
        showSnackBar("Scheduled message deleted successfully!", true);
        // Refresh the scheduled messages after deletion
        handleReloadComponents(); // Reload components after successful update
      } else {
        console.error("Failed to delete scheduled message");
        showSnackBar(
          "Failed to delete scheduled message. Please try again",
          false,
        );
      }
    } catch (error) {
      console.error("Error deleting scheduled message:", error);
      showSnackBar(
        "Failed to delete scheduled message. Please try again",
        false,
      );
    }
  };

  const handleOpenPopover = (event, rowData) => {
    if (event && rowData) {
      pauseAutoRefresh();
      setAnchorEl(event.currentTarget);
      setSelectedRow(rowData);
      setPopoverOpen(true);
    }
  };

  const handleClosePopover = () => {
    setSelectedRow(null);
    setPopoverOpen(false);
    resumeAutoRefresh();
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSelectedRow(null);
    setSnackbarOpen(false);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const formatTime = (timeStr) => {
    const time = new Date(timeStr);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return time.toLocaleTimeString(undefined, options);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setVisibleRows(
        screenWidth <= 1440 ? customerFeedData.slice(0, 8) : customerFeedData,
      );
    };

    handleResize(); // Call initially

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [customerFeedData]);

  const handleCloseDialog = () => {
    resumeAutoRefresh();
    setConfirmationOpen(false);
    setSelectedRow(null);
  };

  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: "#FFFFFF",
            height: "42.5px",
            letterSpacing: "-1%",
            lineHeight: "-1%",
            minHeight: "65px",
          },
        },
      },
    },
  });

  const StyledHeader = styled(TableCell)({
    color: "#447c92",
    borderBottom: "none",
    letterSpacing: "-2%",
    lineHeight: "-2%",
    minHeight: "65px",
  });

  const StyledRow = styled(TableCell)({
    color: "#5e9fbc",
    fontWeight: 600,
    letterSpacing: "-2%",
    lineHeight: "-2%",
  });

  const StyledHeaderText = styled(Typography)({
    fontWeight: 500,
    fontSize: 13.5,
    align: "center",
    margin: "auto",
    letterSpacing: "-2%",
    lineHeight: "-2%",
    minHeight: "20px",
  });

  const handleOpenMessageDetails = (row) => {
    setSelectedRow(row);
    setIsMessageDetailsOpen(true);
    setPopoverOpen(false);
  };

  const handleOpenDialog = (row) => {
    setSelectedRow(row);
    setConfirmationOpen(true);
    setPopoverOpen(false); // Close the popover when opening the dialog
  };

  const handleCloseMessageDetails = () => {
    setIsMessageDetailsOpen(false);
    resumeAutoRefresh();
  };

  const handleUpdateScheduledMessage = async () => {
    if (!selectedRow) return; // Check if a row is selected

    setLoading(true); // Set loading to true when the button is clicked

    try {
      // Call the delete function
      await handleDeleteScheduledMessage(selectedRow);

      setConfirmationOpen(false);
      fetchAllWidgetTypes();
      setLoading(false);
      // Reset loading state after the operation is complete
    } catch (error) {
      console.error("Error deleting scheduled message:", error);
    } finally {
      setLoading(false); // Reset loading state after the operation is complete
    }
  };

  return (
    <Box
      sx={{
        background: "#f4fbfd",
        height: "100%",
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      {(loadingScheduledNotifications || !scheduledNotificationsData) && (
        <Fade
          in={loadingScheduledNotifications || !scheduledNotificationsData}
          timeout={400}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%", // Full height of the viewport
              width: "100%",
            }}
          >
            <Typography
              align="center"
              sx={{
                width: "100%",
                color: "#447c92",
                fontSize: "1.1rem",
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Center text vertically within the Typography
                letterSpacing: "-2%",
              }}
            >
              Loading Your Scheduled Notifications...
            </Typography>

            <LinearProgress
              color="secondary"
              sx={{
                height: 3,
                width: "20%",
                mt: 2, // Add some space between Typography and LinearProgress
                mb: 8,
                "& .MuiLinearProgress-barColorSecondary": {
                  backgroundColor: "#2BBB9E",
                },
              }}
            />
          </Box>
        </Fade>
      )}

      {!loadingScheduledNotifications &&
        scheduledNotificationsData &&
        visibleRows.length === 0 && (
          <Fade in={showMonthlyMessages} timeout={400}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: "100%",
                borderRadius: "10px",
                padding: "20px",
                overflow: "hidden",
                flex: 1, // Ensures it takes up all available space
                minHeight: 0, // Prevents content from expanding beyond limits
                transition: "opacity 0.3s ease-in-out",
                background: "radial-gradient(circle, #EEF6F9, #D4E9F2)",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  mt: -5,
                  mb: -1,
                  position: "relative",
                  width: {
                    xs: "60vw",
                    sm: "40vw",
                    md: "30vw",
                    lg: "21.29vw", // 15% decrease from 25.05vw
                  },
                  transition: "opacity 0.3s ease-in-out",

                  height: {
                    xs: "60vw",
                    sm: "40vw",
                    md: "30vw",
                    lg: "22.47vw", // 15% decrease from 26.44vw
                  },
                }}
              >
                {/* Skeleton loader */}
                {!imageLoaded && (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: "75%",
                      height: {
                        xs: "70vw",
                        sm: "50vw",
                        md: "35vw",
                        lg: "35.06vw",
                      }, // 15% decrease from 41.25vw
                      borderRadius: "10px",
                      backgroundColor: "transparent",
                      objectFit: "contain",
                      transition: "opacity 0.3s ease-in-out",
                    }}
                  />
                )}

                {/* Image with fade-in effect */}
                <CardMedia
                  component="img"
                  src={Scheduled}
                  sx={{
                    width: {
                      xs: "70vw",
                      sm: "50vw",
                      md: "35vw",
                      lg: "35.06vw",
                    }, // 15% decrease from 41.25vw
                    height: "auto",
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                    transition: "opacity 0.3s ease-in-out",
                    opacity: imageLoaded ? 1 : 0,
                  }}
                  onLoad={() => setImageLoaded(true)}
                />
              </Box>

              <Typography
                align="center"
                sx={{
                  mt: -4,
                  fontSize: { xs: 16, sm: 18 },
                  color: "#447c92",
                  mb: "15px",
                  fontWeight: 600,
                }}
              >
                No Notifications Scheduled For Delivery
              </Typography>

              <Typography
                align="center"
                sx={{
                  fontSize: { xs: 14, sm: 16 },
                  color: "#447c92",
                  mb: "15px",
                  fontWeight: 600,
                }}
              >
                Notifications that are scheduled for delivery will automatically
                be displayed here.
              </Typography>
            </Box>
          </Fade>
        )}

      {!loadingScheduledNotifications &&
        scheduledNotificationsData &&
        visibleRows.length > 0 && (
          <Fade in={true} timeout={500}>
            <TableContainer
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                overflowY: "auto",
                background: "#f4fbfd",
              }}
            >
              <Table
                size="small"
                sx={{
                  tableLayout: "fixed", // Ensures columns align correctly
                  width: "100%",
                  height: "100%",
                }}
              >
                <ThemeProvider theme={theme}>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TableRow>
                      {[
                        { label: "Date Scheduled", width: "12%" },
                        { label: "Time Scheduled", width: "14%" },
                        { label: "Sent To", width: "14%" },
                        { label: "Ticket Number", width: "14%" },
                        { label: "Notification", width: "14%" },
                        ...(accountData && !accountData?.single_location
                          ? [{ label: "Location", width: "14%" }]
                          : []),
                        { label: "Status", width: "10%" },
                      ].map((header, index) => (
                        <StyledHeader
                          key={index}
                          align="center"
                          sx={{ width: header?.width, textAlign: "center" }}
                        >
                          <StyledHeaderText>{header?.label}</StyledHeaderText>
                        </StyledHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                </ThemeProvider>

                <TableBody
                  sx={{
                    height: "100%",
                    width: "100%",
                    pb: 1,
                  }}
                >
                  {visibleRows.map((activity, index) => {
                    const rowData = [
                      formatDate(activity.scheduled_time),
                      formatTime(activity.scheduled_time),
                      activity.customer_name ||
                        formatPhoneNumber(activity.phone_number),
                      activity.ticket_number,
                      <Box
                        height="32px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <StatusTag status={activity.status} />
                      </Box>,
                      accountData && !accountData?.single_location
                        ? activity.location_name
                        : null,
                      <TooltipWrapper status={activity.message_status}>
                        <StatusIcon status={activity.message_status} />
                      </TooltipWrapper>,
                    ].filter(Boolean);

                    return (
                      <TableRow
                        key={index}
                        onClick={(event) => handleOpenPopover(event, activity)}
                        sx={{
                          height: `calc(85% / ${totalRowCount})`, // Use totalRowCount to ensure consistent height
                          borderBottom: "none",
                          boxShadow: "none",
                          cursor: "pointer",

                          "&:last-child td, &:last-child th": {
                            border: "none",
                            pb: 1.5,
                          },
                          "&:hover": {
                            backgroundColor: "rgba(173, 216, 230, 0.4)",
                            cursor: "pointer",
                          },
                        }}
                      >
                        {rowData.map((cell, idx) => (
                          <StyledRow
                            key={idx}
                            align="center"
                            sx={{
                              textAlign: "center",
                              borderBottom: "none",
                              boxShadow: "none",
                            }}
                          >
                            {cell}
                          </StyledRow>
                        ))}
                      </TableRow>
                    );
                  })}

                  {visibleRows.length < totalRowCount &&
                    Array.from({ length: emptyRowCount }).map((_, i) => (
                      <TableRow
                        key={`empty-${i}`}
                        sx={{
                          height: `calc(85% / ${totalRowCount})`, // Ensures empty rows match data rows
                          backgroundColor: "#FFFFFF",
                          borderBottom: "none",
                          boxShadow: "none",
                        }}
                      >
                        <TableCell
                          colSpan={accountData?.single_location ? 6 : 7}
                          sx={{
                            borderBottom: "none",
                          }}
                        />
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Fade>
        )}
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl} // Use the anchor element state variable
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom", // Render Popover below the anchor element
          horizontal: "center", // Center horizontally
        }}
        transformOrigin={{
          vertical: "top", // Position Popover to the top of the anchor element
          horizontal: "center", // Center horizontally
        }}
        PaperProps={{
          sx: {
            border: "1.25px solid rgba(173, 216, 230, 0.6)",

            borderRadius: "8px", // Adjust the value to control the roundness
          },
        }}
      >
        <Box sx={{ backgroundColor: "#F2F9FF" }}>
          <Paper sx={{ backgroundColor: "#F2F9FF" }}>
            <Box
              onClick={() => {
                handleOpenMessageDetails(selectedRow);
              }} // Open message details on row click
              sx={{
                pt: "5px",
                px: 1,
                flexDirection: "row",
                display: "flex",
                backgroundColor: "#F2F9FF",
                color: "#8A5D6E",
                "&:hover": {
                  backgroundColor: "#E6F1FF", // Adjusted hover color to a lighter shade
                },
                "&.Mui-selected": {
                  backgroundColor: "#E6F1FF", // Adjusted selected color to a darker shade
                },
                alignItems: "center", // Center vertically
                cursor: "pointer", // Change cursor to pointer on hover
              }}
            >
              <img
                src={DetailsIcon}
                alt="bell"
                style={{
                  width: "auto",
                  height: "30px",
                  borderRadius: "10px",
                  padding: "3px",
                  borderRadius: "6px",
                }}
              />
              <Typography
                sx={{
                  display: "flex",
                  color: "#5e9fbc",
                  fontWeight: 600,
                  fontSize: 14,
                  textAlign: "center", // Fix typo here: 'textAlign' instead of 'alignText'
                }}
              >
                View Notification Details
              </Typography>
            </Box>
            <Box
              onClick={() => handleOpenDialog(selectedRow)}
              sx={{
                px: 1,
                pb: "5px",

                flexDirection: "row",
                display: "flex",
                backgroundColor: "#F2F9FF",
                color: "#8A5D6E",
                "&:hover": {
                  backgroundColor: "#E6F1FF", // Adjusted hover color to a lighter shade
                },
                "&.Mui-selected": {
                  backgroundColor: "#E6F1FF", // Adjusted selected color to a darker shade
                },
                alignItems: "center", // Center vertically
                cursor: "pointer", // Change cursor to pointer on hover
              }}
            >
              <img
                src={DeleteIcon}
                alt="bell"
                style={{
                  width: "auto",
                  height: "30px",
                  borderRadius: "10px",
                  padding: "3px",
                  borderRadius: "6px",
                }}
              />
              <Typography
                sx={{
                  display: "flex",
                  color: "#D59EA8",
                  fontSize: 14,
                  fontWeight: 600,
                  textAlign: "center", // Fix typo here: 'textAlign' instead of 'alignText'
                }}
              >
                Cancel Notification
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Popover>

      <ScheduledDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        header="Cancel Scheduled Notification"
        message="Are you sure you want to cancel this scheduled notification?"
        subText="This notification will be erased from your message history and cannot be undone."
        buttonText={loading ? "Deleting..." : "Delete"}
        onConfirm={handleUpdateScheduledMessage}
        isLoading={loading}
        buttonLoadingText="Deleting..."
      />
      <MessageDetails
        open={isMessageDetailsOpen}
        selectedRow={selectedRow}
        onClose={handleCloseMessageDetails}
        handleOpenModal={handleOpenModal}
        setSelectedPhoneNumber={setSelectedPhoneNumber}
      />

      <CreateCustomerModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSaveCustomer={handleSaveCustomer}
        selectedPhoneNumber={selectedPhoneNumber}
        location_name={subUserData?.location_name || ""} // Check if subUserData exists before accessing location_name
      />
    </Box>
  );
}
