import { useEffect, useState } from "react";
import { Typography, TextField, InputAdornment } from "@mui/material";

const StepsToReproduceForm = ({ bugDetails, handleChange }) => {
  const [charCount, setCharCount] = useState(bugDetails.stepsToReproduce.length);
  const charLimit = 500;
  const warningThreshold = 25; // Show warning when 50 characters are left

  useEffect(() => {
    setCharCount(bugDetails.stepsToReproduce.length);
  }, [bugDetails.stepsToReproduce]);

  return (
    <>
      <Typography
        sx={{
          fontSize: "1.15rem",
          fontWeight: 600,
          pb: "5px",
          width: "100%",
          letterSpacing: "-0.029em",
          color: "#48768c",
        }}
      >
        How can we reproduce the issue?
      </Typography>

      <Typography
        color="textSecondary"
        sx={{ fontSize: ".93rem", fontWeight: 600, pl: "2px", pb: "10px", mt: "15px", pt: "5px" }}
      >
        Tell us step by step how we can make the problem happen again so we can ensure that the
        issue is resolved.
      </Typography>

      <TextField
        sx={{
          marginBottom: 3.5,
          border: "none",
          "& textarea": {
            minHeight: "50px",
          },
          "& .MuiOutlinedInput-root": {
            border: "none",
            "&:hover": {
              borderColor: "none",
            },
          },
        }}
        placeholder="How can we make the problem happen again? (Tell us step by step)"
        name="stepsToReproduce"
        value={bugDetails.stepsToReproduce}
        onChange={handleChange}
        multiline
        rows={1}
        fullWidth
        required
        inputProps={{
          maxLength: charLimit, // Prevents more than 500 characters
        }}
        InputProps={{
          endAdornment: (
            <>
              {charCount > 0 && charCount < 20 && (
                <InputAdornment
                  position="end"
                  sx={{
                    fontSize: "0.75rem",
                    color: "#d32f2f",
                    position: "absolute",
                    bottom: "15px",
                    right: "10px",
                  }}
                >
                  <Typography color="textSecondary" sx={{ fontSize: ".93rem", fontWeight: 600 }}>
                    Your response needs to be at least 20 characters.
                  </Typography>
                </InputAdornment>
              )}

              {charCount >= charLimit - warningThreshold && (
                <InputAdornment
                  position="end"
                  sx={{
                    fontSize: "0.75rem",
                    color: charCount >= charLimit ? "#d32f2f" : "#f57c00",
                    position: "absolute",
                    bottom: "15px",
                    right: "10px",
                  }}
                >
                    <Typography  sx={{ fontSize: ".9rem", fontWeight: 600, color:'#8C4350', pt:'10px' }}>
                    {charLimit - charCount} / 500
                  </Typography>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </>
  );
};

export default StepsToReproduceForm;