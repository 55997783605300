import React, { useEffect, Suspense, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  Button,
  Stack,
  IconButton,
  Box,
  Chip,
  Avatar,
  Fade,
  Divider,
  Paper,
} from "@mui/material";
import NewSubscription from "src/components/payments/components/NewSubscription.js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useCancelSubscription from "src/components/payments/hooks/useCancelSubscription.js";
import { useSubscriptionDetails } from "src/components/payments/routes/useSubscriptionDetails";
import { useValidateSubscription } from "src/components/payments/routes/validateSubscription";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useAccountContext } from "src/context/AccountContext";
import ArrowOutwardRoundedIcon from "@mui/icons-material/ArrowOutwardRounded";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import AddUserModal from "../../modal/AddUserModal/AddUserModal.js";
import AddLocationModal from "../../modal/AddNewLocation/AddLocationModal.js";
import UpgradePlanDialog from "src/components/modal/UpgradePlanDialog/UpgradePlanDialog.js";
import MultiUserUpgrade from "src/components/Account-Upgrades/components/MultiUserUpgrade/multiUserUpgrade";
import MultiLocationUpgrade from "src/components/Account-Upgrades/components/MultiLocationUpgrade/multiLocationUpgrade";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
);

const ActivePlanCard = ({
  planDetails,
  name,
  status,
  cost,
  reoccurrence,
  paymentMethod,
  subscriptionId,
  customerId,
  priceId,
  onSubscriptionUpdate,
  subscriptionValid,
  stripePromise,
  totalLocations,
  totalUsers,
  users,
  locations,
  fetchUsers,
  handleSubscriptionUpdate,
  fetchLocations,
  subscriptionDetails,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showNewSubscription, setShowNewSubscription] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const { accountData } = useAccountContext();
  const [showAddUserModal, setShowAddUserModal] = React.useState(false);
  const [showAddLocationModal, setShowAddLocationModal] = React.useState(false);
  const [showUpgradeDialog, setShowUpgradeDialog] = React.useState(false);
  const [showMultiUserUpgrade, setShowMultiUserUpgrade] = useState(false);
  const [showMultiLocationUpgrade, setShowMultiLocationUpgrade] =
    useState(false);

  const handleOpenMultiUserUpgrade = () => {
    setShowMultiUserUpgrade(true);
  };

  const handleCloseMultiUserUpgrade = () => {
    setShowMultiUserUpgrade(false);
    setShowUpgradeDialog(false);
  };

  const handleOpenMultiLocationUpgrade = async () => {
    setShowMultiLocationUpgrade(true);
  };

  const handleCloseMultiLocationUpgrade = () => {
    setShowMultiLocationUpgrade(false);
    setShowUpgradeDialog(false);
  };

  const handleAddUserModalOpen = () => {
    setShowAddUserModal(true);
  };

  const handleAddUserModalClose = () => {
    setShowAddUserModal(false);
  };

  const handleAddLocationModalOpen = () => {
    setShowAddLocationModal(true);
  };

  const handleAddLocationModalClose = () => {
    setShowAddLocationModal(false);
  };

  const handleOpenUpgradeDialog = () => {
    setShowUpgradeDialog(true);
  };

  const handleCloseUpgradeDialog = () => {
    setShowUpgradeDialog(false);
  };

  useSubscriptionDetails(apiUrl); // Assuming useSubscriptionDetails has a refetch function

  const handleShowNewSubscription = () => {
    setShowNewSubscription(true);
  };

  const handleNewSubscriptionClose = () => {
    setShowNewSubscription(false);
  };

  const { isCancelling, cancelError, cancelSuccess, cancelSubscription } =
    useCancelSubscription();



  useEffect(() => {
    if (
      !name ||
      !status ||
      !users ||
      !totalUsers ||
      !totalLocations ||
      !planDetails
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [name, status, users, totalUsers, totalLocations, planDetails]);

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0) : "";
    const lastInitial = lastName ? lastName.charAt(0) : "";
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };




  return (
    <>
      {!isLoading ? (
        <Fade in={!isLoading} timeout={10}>

            <Card
              sx={{
                width: "fit-content",
                padding: .5,
                backgroundColor: "transparent",
                border: "1px solid rgba(173, 216, 230, 0.7)",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                whiteSpace: "nowrap", // Prevents wrapping for horizontal scroll
                flex:1,
                borderRadius: "6px"

              }}
            >
              <Box sx={{}}>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: "center",
                  }}
                >
                  {/* Plan Name and Status */}
                  <Suspense fallback={<Typography>Loading...</Typography>}>
                    <Fade in={!isLoading} timeout={800}>
                      <Typography
                
                        sx={{
                          ml: 1.25,
                          whiteSpace: "nowrap",
                          fontWeight: 600,
                          fontSize: "1.19rem",
                          textTransform: "none",
                          letterSpacing: "-0.01em",
                          color: "#48768C",
                        }}
                      >
                        {name}
                      </Typography>
                    </Fade>
                  </Suspense>

               

                  {/* Cost and Per Month */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: "auto", // Push to the right if space allows
                      padding: 0.5,
                    }}
                  >
                    <Suspense fallback={<Typography>Loading...</Typography>}>
                      <Fade in={!isLoading} timeout={300}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            ml: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#48768C",
                              mr: "2px",
                              fontWeight: 600,
                              letterSpacing: "-0.01em",
                              fontSize: "21.5px",
                              textTransform: "none",
                              width: "fit-content",
                              ml: "auto",
                              mb: 0.25,
                            }}
                          >
                            ${" "}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#48768C",
                              mr: "5px",
                              fontWeight: 600,
                              letterSpacing: "-0.02em",
                              fontSize: "30px",
                              textTransform: "none",
                              width: "fit-content",
                              ml: "auto",
                            }}
                          >
                            {isNaN(Number(cost.replace(/[^0-9.]/g, "")))
                              ? "0"
                              : Math.ceil(Number(cost.replace(/[^0-9.]/g, "")))}
                          </Typography>
                          <Typography
                            sx={{
                              mt: { xs: "0.5rem", sm: ".75rem" },
                              whiteSpace: "nowrap",
                              fontWeight: 600,
                              letterSpacing: "-0.015em",
                              fontSize: { xs: "14.5px", sm: "16px" },
                              textTransform: "none",
                              mr: 1,
                              color: "#48768C",
                              mt: -1,
                            }}
                          >
                            / month
                          </Typography>
                        </Box>
                      </Fade>
                    </Suspense>
                  </Box>
                </Stack>
                <Box
                  sx={{
                    flexDirection: "column",
                    alignItems: "center",
                    display: "flex-start",
                  }}
                >
                  <Stack direction="column" sx={{ mt: -2 }}>
                    <Typography
                      sx={{
                        ml: 1.25,
                        whiteSpace: "nowrap",
                        fontWeight: 500,
                        letterSpacing: "-0.02em",
                        fontSize: "14.5px",
                        textTransform: "none",

                        color: "#48768C",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        mt: "2px",
                      }}
                    >
                      {planDetails ? planDetails.description : ""}
                    </Typography>
                
                  </Stack>
                  <Box
                    sx={{
                      flexDirection: "column",
                      alignItems: "center",
                      display: "flex-start",
                      mt: 2,
                      width: "100%",
                    }}
                  >
                    {accountData && !accountData.single_user && (
                      <>
                        {Array.isArray(users) && users.length > 0 && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-end", // Align items to the bottom
                              width: "100%",
                              ml: 1,
                              alignItems: "center",
                            }}
                          >
                            {users.slice(0, 5).map((user, index) => (
                              <Avatar
                                key={index}
                                sx={{
                                  color: "#48768C",
                                  fontSize: "14px",
                                  width: 35,
                                  height: 35,
                                  background:
                                    "radial-gradient(circle, #EEF6F9, #FFFFFF)",
                                  border: "2px solid rgba(173, 216, 230, 0.9)",
                                  borderRadius: "20px",
                                  padding: "4px",
                                  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.01)",
                                  fontWeight: 600,
                                  mr: -1.25,
                                  alignItems: "center",
                                }}
                              >
                                {getInitials(user.first_name, user.last_name)}
                              </Avatar>
                            ))}
                            <Paper
                              elevation={5}
                              sx={{
                                ml: 0.25,
                                backgroundColor: "#FFFFFF",
                                border: "1px solid rgba(173, 216, 230, 0.5)",
                                borderRadius: "50%", // Make the Paper a perfect circle

                                display: "flex", // Use flexbox for centering
                                justifyContent: "center", // Center horizontally
                                alignItems: "center", // Center vertically
                                padding: 0, // Remove padding to maintain circular shape
                              }}
                            >
                              <Avatar
                                onClick={handleAddUserModalOpen}
                                sx={{
                                  color: "#48768CCC",
                                  fontSize: "18px",
                                  width: 30,
                                  height: 30,
                                  backgroundColor: "#FFFFFF",
                                  fontWeight: 600,
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%", // Ensure Avatar is also circular
                                  border: "1px solid rgba(173, 216, 230, 0.5)",
                                }}
                              >
                                +
                              </Avatar>
                            </Paper>
                            <Suspense
                              fallback={<Typography>Loading...</Typography>}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    display: "flex",
                                    color: "#48768CBD",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    whiteSpace: "nowrap",
                                    fontWeight: 600,
                                    letterSpacing: "-0.01em",
                                    fontSize: "15.5px",
                                    marginLeft: "auto",
                                    mr: 3,
                                    ml: 5,
                                  }}
                                >
                                  Total Users: {totalUsers}
                                </Typography>
                              </Box>
                            </Suspense>
                          </Box>
                        )}
                      </>
                    )}

                    <Box
                      sx={{
                        flexDirection: "column",
                        alignItems: "center",
                        display: "flex-start",
                        mt: 1.5,
                        width: "100%",
                        mb: 2,
                      }}
                    >
                      {accountData && !accountData.single_user && !accountData.single_location && (
                        <>
                          {Array.isArray(locations) && locations.length > 0 && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end", // Align items to the bottom
                                width: "100%",
                                ml: 1,
                                alignItems: "center",
                              }}
                            >
                              {locations.slice(0, 5).map((location, index) => (
                                <Avatar
                                  key={index}
                                  sx={{
                                    color: "#48768C",
                                    fontSize: "14px",
                                    width: 35,
                                    height: 35,
                                    background:
                                      "radial-gradient(circle, #EEF6F9, #FFFFFF)",
                                    border:
                                      "2px solid rgba(173, 216, 230, 0.9)",
                                    borderRadius: "20px",
                                    padding: "4px",
                                    boxShadow:
                                      "0px 1px 1px rgba(0, 0, 0, 0.01)",
                                    fontWeight: 600,
                                    mr: -1.25,
                                    alignItems: "center",
                                  }}
                                >
                                  <StorefrontIcon sx={{ fontSize: "19px" }} />
                                </Avatar>
                              ))}

                              <Paper
                                elevation={5}
                                sx={{
                                  ml: 0.25,
                                  backgroundColor: "#FFFFFF",
                                  border: "1px solid rgba(173, 216, 230, 0.5)",
                                  borderRadius: "50%", // Make the Paper a perfect circle

                                  display: "flex", // Use flexbox for centering
                                  justifyContent: "center", // Center horizontally
                                  alignItems: "center", // Center vertically
                                  padding: 0, // Remove padding to maintain circular shape
                                }}
                              >
                                <Avatar
                                  onClick={handleAddLocationModalOpen}
                                  sx={{
                                    color: "#48768CCC",
                                    fontSize: "18px",
                                    width: 30,
                                    height: 30,
                                    backgroundColor: "#FFFFFF",
                                    fontWeight: 600,
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "50%", // Ensure Avatar is also circular
                                    border:
                                      "1px solid rgba(173, 216, 230, 0.5)",
                                  }}
                                >
                                  +
                                </Avatar>
                              </Paper>
                              <Suspense
                                fallback={<Typography>Loading...</Typography>}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#48768CBD",
                                      alignItems: "center",

                                      whiteSpace: "nowrap",
                                      fontWeight: 600,
                                      letterSpacing: "-0.01em",
                                      fontSize: "15.5px",
                                      marginLeft: "auto",
                                      mr: 3,
                                    }}
                                  >
                                    Total Locations: {totalLocations}
                                  </Typography>
                                </Box>
                              </Suspense>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>

                    {accountData &&
  ((accountData.single_user || accountData.single_location) ) && (
                        <>
                          <Divider
                            sx={{
                              borderBottomWidth: "1.5px",
                              mt: "auto",
                              pt: accountData.single_location ? .5 : 3,
                            }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              mt: 1,
                              width: "100%",
                              
                              
                            }}
                          >
                            <Button
                              onClick={handleOpenUpgradeDialog}
                              sx={{
                                ml: "auto",
                                border: "1px solid #A4C5D2E8",
                                borderRadius: "6px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                padding: "1px 0",
                                px: "10px",
                                height: "35px",
                                backgroundColor: "#FFFFFF",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mb: "7px",
                                mt: accountData?.single_user  ? "10px" : "2px",
                                mr: "7px",

                                "&:hover": {
                                  backgroundColor: "#DBEAF0",
                                },
                                "&:active": {
                                  backgroundColor: "#DBEAF0",
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#48768C",
                                  fontSize: 14.26,
                                  mr: 0.5,
                                  fontWeight: 600,
                                  letterSpacing: "-0.01em",
                                }}
                              >
                                Upgrade Plan
                              </Typography>
                              <ArrowOutwardRoundedIcon
                                sx={{
                                  fontSize: "19px",
                                  color: "#48768C",
                                  mb: "2px",
                                }}
                              />
                            </Button>
                            <>
                              {showMultiUserUpgrade && (
                                <MultiUserUpgrade
                                  open={showMultiUserUpgrade}
                                  onClose={handleCloseMultiUserUpgrade}
                                  users={users}
                                  locations={locations}
                                  singleUser={accountData.single_user}
                                  multiUserUpgradeDialogOpen={
                                    showMultiUserUpgrade
                                  }
                                  handleMultiUserUpgradeDialogClose={
                                    handleCloseMultiUserUpgrade
                                  }
                                />
                              )}

                              {showMultiLocationUpgrade && (
                                <MultiLocationUpgrade
                                  open={showMultiLocationUpgrade}
                                  onClose={handleCloseMultiLocationUpgrade}
                                  users={users}
                                  locations={locations}
                                  singleUser={accountData.single_user}
                                  multiLocationUpgradeDialogOpen={
                                    showMultiLocationUpgrade
                                  }
                                  handleMultiLocationUpgradeDialogClose={
                                    handleCloseMultiLocationUpgrade
                                  }
                                />
                              )}
                            </>
                          </Box>
                        </>
                      )}
                  </Box>
                </Box>
              </Box>
              <Elements stripe={stripePromise}>
                <NewSubscription
                  open={showNewSubscription}
                  onClose={handleNewSubscriptionClose}
                  customerId={customerId}
                  priceId={priceId}
                  onSubscriptionUpdate={onSubscriptionUpdate}
                  planDetails={planDetails}
                  stripePromise={stripePromise}
                />
              </Elements>
              <Button
                onClick={handleShowNewSubscription}
                sx={{
                  display: "none",
                  border: "1px solid #48768C",
                  borderRadius: "10px",
                  padding: "8px",
                  color: "#48768C",
                  backgroundColor: "#FFFFFF",
                  ml: 0.25,
                  mt: 2,
                  mb: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  "&:hover": {
                    backgroundColor: "#DBEAF0",
                  },
                  "&:active": {
                    backgroundColor: "#DBEAF0",
                  },
                }}
                color="primary"
              >
                Activate Your Subscription
              </Button>
           
            </Card>

        </Fade>
      ) : (
        <>
          <Card
            sx={{
              width: "100%",
              padding: 0.5,
              backgroundColor: "rgba(191, 218, 231, 0.1)",
              border: "1px solid rgba(173, 216, 230, 0.7)",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#48768CBF",
                mr: "5px",
                whiteSpace: "nowrap",
                fontWeight: 500,
                letterSpacing: "-0.03em",
                fontSize: "18px",
                textTransform: "none",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                alignItems: "center",
                fontStyle: "italic",
              }}
            >
              Loading your subscription details...
            </Typography>
          </Card>
        </>
      )}

      <AddUserModal
        open={showAddUserModal}
        onClose={handleAddUserModalClose}
        locations={locations}
        onAddUser={() => {}} // Pass an empty function here
        fetchUsers={fetchUsers}
        users={users}
        handleSubscriptionUpdate={handleSubscriptionUpdate}
        planDetails={planDetails}
        subscriptionDetails={subscriptionDetails}
      />
      <AddLocationModal
        open={showAddLocationModal}
        onClose={handleAddLocationModalClose}
        singleUser={accountData.single_user}
        fetchLocations={fetchLocations}
        users={users}
        locations={locations}
        handleSubscriptionUpdate={handleSubscriptionUpdate}
        fetchUsers={fetchUsers}
        planDetails={planDetails}
        subscriptionDetails={subscriptionDetails}
      />
      <UpgradePlanDialog
        open={showUpgradeDialog}
        onClose={handleCloseUpgradeDialog}
        planDetails={planDetails}
        users={users}
        locations={locations}
        accountData={accountData}
        handleOpenMultiLocationUpgrade={handleOpenMultiLocationUpgrade}
        handleOpenMultiUserUpgrade={handleOpenMultiUserUpgrade}
        showMultiLocationUpgrade={showMultiLocationUpgrade}
        showMultiUserUpgrade={showMultiUserUpgrade}
      />
    </>
  );
};

export default ActivePlanCard;
