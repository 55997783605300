import { set } from "lodash";
import { useState, useEffect } from "react";    

export const usePaymentSchedule = (customerId, getAccessTokenSilently) => {
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [loadingPaymentDetails, setLoadingPaymentDetails] = useState(true);
    const [paymentDetailsError, setPaymentDetailsError] = useState(null);
    const [refetchPaymentDetails, setRefetchPaymentDetails] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (!customerId) {
            setPaymentDetailsError("Customer ID is required");
            setLoadingPaymentDetails(false);
            return;
        }

        const fetchPaymentDetails = async () => {
            try {
                setLoadingPaymentDetails(true);


                const token = await getAccessTokenSilently();
                
                const response = await fetch(`${apiUrl}/subscriptions/plans/manage-subscription-details`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ customerId }),
                });

                const data = await response.json();

                if (!response.ok) {
                    throw new Error(data.error || "Failed to fetch payment details");
                }

                setPaymentDetails(data);
            } catch (err) {
                console.error("❌ Error Fetching Payment Details:", err);
                setPaymentDetailsError(err.message);
            } finally {
                setLoadingPaymentDetails(false);
                setRefetchPaymentDetails(false);
            }
        };

        fetchPaymentDetails();
    }, [customerId, getAccessTokenSilently, refetchPaymentDetails]);

    return { paymentDetails, loadingPaymentDetails, paymentDetailsError, setRefetchPaymentDetails };
};