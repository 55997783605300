// ChangePinModal.js

import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  Typography,
  TextField,
  Button,
  Box,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SuccessDialog from "./SuccessDialog";
import LockIcon from "src/public/assets/icons/icons/lock.png";


const ChangePinModal = ({ isOpen, onClose, onSave, userName, newPin, confirmNewPin, setNewPin, setConfirmNewPin, isLoading }) => { 

  const [showNewPin, setShowNewPin] = useState(false);
  const [showConfirmNewPin, setShowConfirmNewPin] = useState(false);
  
  const newPinRefs = useRef([]);
  const confirmPinRefs = useRef([]);
  const [loading, setLoading] = useState(false)

  const handleNewPinChange = (e, index) => {
    const value = e.target.value;
    const updatedPin = [...newPin];
  
    if (value.length === 1) { // Only proceed if it's a valid single character
      updatedPin[index] = value;
      setNewPin(updatedPin);
  
      // Focus on the next field after a valid input
      if (index < 3 && value !== "") {
        newPinRefs.current[index + 1].focus();
      }
  
      // If the user enters the 4th character, focus on the first field of confirmNewPin
      if (index === 3) {
        confirmPinRefs.current[0].focus();
      }
    } else if (value.length === 0) { // Handle backspace input
      updatedPin[index] = ""; 
      setNewPin(updatedPin);
  
      // Focus on previous field if the current is empty after backspace
      if (index > 0) {
        newPinRefs.current[index - 1].focus();
      }
    }
  };
  
  const handleConfirmNewPinChange = (e, index) => {
    const value = e.target.value;
    const updatedPin = [...confirmNewPin];
  
    if (value.length === 1) { // Only proceed if it's a valid single character
      updatedPin[index] = value;
      setConfirmNewPin(updatedPin);
  
      // Focus on next field after a valid input
      if (index < 3 && value !== "") {
        confirmPinRefs.current[index + 1].focus();
      }
    } else if (value.length === 0) { // Handle backspace input
      updatedPin[index] = "";
      setConfirmNewPin(updatedPin);
  
      // Focus on previous field if the current is empty after backspace
      if (index > 0) {
        confirmPinRefs.current[index - 1].focus();
      }
    }
  };
  
  const handleFocusNewPin = (e, index) => {
    if (e.key === "Backspace" && newPin[index] === "") {
      // Move focus to the previous field if the current field is empty
      if (index > 0) {
        newPinRefs.current[index - 1].focus();
      }
    }
  };
  
  const handleFocusConfirmNewPin = (e, index) => {
    if (e.key === "Backspace" && confirmNewPin[index] === "") {
      // Move focus to the previous field if the current field is empty
      if (index > 0) {
        confirmPinRefs.current[index - 1].focus();
      }
    }
  };

  const toggleShowNewPin = () => {
    setShowNewPin(!showNewPin);
  };

  const toggleShowConfirmNewPin = () => {
    setShowConfirmNewPin(!showConfirmNewPin);
  };



  const [showSuccessDialog, setShowSuccessDialog] = useState(false); // New state for success dialog
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false);



  useEffect(() => {
    const isValidInput =
      newPin.length === 4 &&
      confirmNewPin.length === 4 &&
      /^\d+$/.test(newPin) &&
      /^\d+$/.test(confirmNewPin) &&
      newPin === confirmNewPin;

    setSaveButtonEnabled(isValidInput);
  }, [newPin, confirmNewPin]);




  const handleSuccessDialogClose = () => {
    setShowSuccessDialog(false);
    onClose(); // Close the modal after success dialog is closed
  };


  const handleSave = async () => {
    try {
      const { success, error } = await onSave({ newPin, confirmNewPin });
      if (success) {
        setShowSuccessDialog(true); // Show success dialog if response is successful
      } else {
        console.error("Error while saving:", error);
        // Optionally show an error dialog or message
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      // Optionally handle unexpected errors
    }
  };



  return (
    <>
<Modal open={isOpen} onClose={onClose}
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: 3,
      minWidth: '120px',
      maxWidth: '480px',
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      display: showSuccessDialog ? 'none' : 'flex',
      flexDirection: 'column',
      alignItems: 'center',  // Center horizontally
      justifyContent: 'center',  // Center vertically

    }}
  >

 <Box sx={{}}>
          <img
            src={LockIcon}
            style={{
              border: "transparent",
              width: "auto",
              height: "50px",
              backgroundColor: "#c6d4dc",
              borderRadius: "6px",
              padding: "1px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              marginBottom: "15px",
              marginTop:'5px'
            }}
          />
        </Box>
    <Stack spacing={1} sx={{ paddingBottom: 2, textAlign: 'center' }}>
<Typography
  sx={{
    fontSize: '19.5px',
    fontWeight: 550,
    color: '#48768C',
    letterSpacing: '-.02em',
  }}
>
  {userName ? `Change ${userName === "Your" ? "Your" : `${userName}'s`} PIN` : "Change PIN"}
</Typography>
      <Typography
        sx={{
          fontSize: "14.5px",
          fontWeight: 550,
          color: "#6c90a2",
          letterSpacing: "-.02em",
          lineHeight: "-2",
          whiteSpace: "noWrap",
        }}
      >
        Enter a new 4-digit PIN to update {userName}'s security settings.
      </Typography>
    </Stack>

    {/* Input Fields */}
    <Stack spacing={2} sx={{ width: '100%' }}>
      {/* New PIN */}
      <Stack spacing={0.5}>
        <Typography  sx={{          fontSize: "14.5px",
          fontWeight: 550,
          color: "#6c90a2",
          letterSpacing: "-.02em",
          lineHeight: "-2",
          whiteSpace: "noWrap", textAlign: 'center', pt:1 }}>
          New 4 Digit PIN
        </Typography>
        <Stack direction="row" spacing={4} justifyContent="center" alignItems="center">
  {newPin.map((digit, index) => (
    <TextField
      key={index}
      size="small"
      variant="outlined"
      type={showNewPin ? "text" : "password"}
      value={digit}
      onChange={(e) => handleNewPinChange(e, index)}
      onKeyDown={(e) => handleFocusNewPin(e, index)}
      inputProps={{
        maxLength: 1,
        style: {
          textAlign: "center",
          width: "2rem", // Adjust the width as needed
          height:'2rem'
        },
      }}
      inputRef={(el) => (newPinRefs.current[index] = el)} // Assign ref for each input
      sx={{
        "& input": {
          textAlign: "center",
          color: "#48768C",
        },
      }}
    />
  ))}

</Stack>
      </Stack>

      {/* Confirm PIN */}
      <Stack spacing={0.5}>
      <Typography  sx={{          fontSize: "14.5px",
          fontWeight: 550,
          color: "#6c90a2",
          letterSpacing: "-.02em",
          lineHeight: "-2",
          whiteSpace: "noWrap", textAlign: 'center' }}>
          Confirm PIN
        </Typography>
        <Stack direction="row" spacing={4} justifyContent="center" alignItems="center">
        {confirmNewPin.map((digit, index) => (
            <TextField
              key={index}
              size="small"
              variant="outlined"
              type={showConfirmNewPin ? "text" : "password"}
              value={digit}
              onChange={(e) => handleConfirmNewPinChange(e, index)}
              onKeyDown={(e) => handleFocusConfirmNewPin(e, index)}
              inputProps={{
                maxLength: 1,
                style: {
                  textAlign: "center",
                     width: "2rem", // Adjust the width as needed
          height:'2rem'
                },
              }}
              inputRef={(el) => (confirmPinRefs.current[index] = el)} // Assign ref for each input
              sx={{
                "& input": {
                  textAlign: "center",
                  color: "#48768C",
                },
              }}
            />
          ))}
 
        </Stack>
      </Stack>
    </Stack>

    {/* Save Button */}
    <Button
      variant="contained"
      sx={{
        mt: 5,
        fontSize: '1.1rem',
        color: '#F0F0F0',
        backgroundColor: '#46A793',
        width: '100%',
        borderRadius:"8px",
        '&:hover': { backgroundColor: '#079E80' },
        '&:active': { backgroundColor: '#2E7D32' },
      }}
      onClick={() => handleSave(newPin, confirmNewPin)}
      disabled={!newPin.every((digit) => digit) || !confirmNewPin.every((digit) => digit) || isLoading}
    >
   {isLoading ? "Saving..." : "Save"}   
    </Button>
    <Button
      variant="contained"
      sx={{
        mt: 3,
        fontSize: '1rem',
        color: '#7589A2',
        backgroundColor: "#e8ecf1",
        width: '100%',
        mb:1,
        border:'1px solid #BDCAD9',
        boxShadow: 'none !important', // Remove box-shadow and ensure it overrides any other styles
        borderRadius:"8px",
        '&:hover': { 
          backgroundColor: '#C9D2DD', // Adjust hover to a color that matches the text color
          color: '#7C8FA1', // Light text color for better contrast on hover
        },
      }}
      onClick={onClose}
    >
      Cancel
    </Button>
  </Box>
</Modal>
      <SuccessDialog 
  open={showSuccessDialog}
  onClose={handleSuccessDialogClose}
  userName={userName} 
/>
    </>
  );
};

export default ChangePinModal;
