import { useEffect, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Divider,
  Stack,
} from "@mui/material";

import {PrimaryButton,CancelButton, SecondaryButton,} from "src/components/Buttons/ModalButtons";
import AccountInfoStep from "../Steps/AccountInfoStep";
import CreateLocationStep from "../Steps/CreateLocationStep";
import CreateUserStep from "../Steps/CreateUserStep";
import ReviewStep from "../Steps/ReviewStep";  

import SetupComplete from "../Steps/SetupComplete";




export default function NewAccountSetupStepper({  onClose, isLoading, user, activeStep, setActiveStep, isNextButtonDisabled, setIsNextButtonDisabled, steps, setIsSingleLocation, setIsSingleUser, isSingleLocation, 
  isSingleUser, hideWelcome, showCreateUser, showCreateLocation, values, setValues, errors, setErrors, handleChange, setWarning, warning, validateField, handleBlur, handleStateChange,
  typing, setHideWelcome, setShowCreateUser, setShowCreateLocation, handleClose, handleChangeTitle, handleRoleButtonClick, roleOpen, setRoleOpen

}) {



  const [companyName, setCompanyName] = useState('')
  const [companyAddress, setCompanyAddress] = useState('')  
  const [companyCity, setCompanyCity] = useState('')
  const [companyState, setCompanyState] = useState('')  
  const [companyZip, setCompanyZip] = useState('')
  const [companyEmail, setCompanyEmail] = useState('')
  const [companyUrl, setCompanyUrl] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')


  const [userFirstName, setUserFirstName] = useState ('')
  const [userLastName, setUserLastName] = useState ('')
  const [username, setUsername] = useState ('') 



  const [locationName, setLocationName] = useState('');
  const [locationEmail, setLocationEmail] = useState(''); 
  const [locationPhone, setLocationPhone] = useState('')  





  










  return (
    <Stack direction="row" sx={{ height: "auto", width: "100%",flex:1, flexGrow: 1, }}>
      {/* Left Stepper Section (30% Width, 60% Height) */}
      <Box
  sx={{
    mt:2,
    minWidth: "30%", // Width based on viewport width, will shrink and grow with the window
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch", // Center the Stepper horizontally in the Box
    justifyContent: "flex-start", // Center content vertically if needed
    p: 2,

    overflowY: "auto",
    flexShrink: 1, // Prevent the box from shrinking
    height: "210px",      
    

  }}
>
      

<Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
      <Step key={label}>
        <StepLabel
          sx={{
            height: "23.1px",  // Reduced height by 30% (33px -> ~23.1px)
            width: "100%", // Reduced width by 30% (100% -> 70%)
            backgroundColor: index === activeStep ? "#d6e7f7" : "transparent",
            borderRadius: "3.5px",  // Reduced border-radius by 30% (5px -> ~3.5px)
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            mr: 2.1,  // Reduced margin-right by 30% (3px -> ~2.1px)
            whiteSpace: "nowrap",

            "& .MuiStepLabel-label": {
              fontSize: ".90rem",  // Reduced font size by 30% (0.94rem -> ~0.66rem)
            },
            "& .MuiStepLabel-completed": {
              color: "#48768C",
            },
            "& .MuiStepLabel-label.Mui-completed": {
              color: "#48768C",
              fontWeight: 600,
            },
            "& .MuiStepLabel-label.Mui-active": {
              color: "#48768C",
              fontWeight: 600,
            },
            "& .MuiStepIcon-text": {
              fill: "#48768C",
              transform: "scale(1)", // Reduced scale of icon text by 30%
              transformOrigin: "center",
            },
            "& .MuiStepIcon-root.Mui-completed": {
              color: "#c2d7df",
              transform: "scale(1.138)", // Reduced scale by 30% (1.628 -> ~1.138)
              transformOrigin: "center",
              borderRadius: "50%",
              fontWeight: 600,
              border: "1.9px solid #97bac9",  // Reduced border thickness by 30%
            },
            "& .MuiStepIcon-root.Mui-active": {
              color: "#FFFFFF",
              border: index === activeStep ? "1.5px solid #48768C" : "0.7px solid #97bac9",  // Reduced border thickness
              transform: "scale(1.21)",  // Reduced scale by 30% (1.728 -> ~1.21)
              transformOrigin: "center",
              borderRadius: "50%",
              fontWeight: 600,
            },
            "& .MuiStepIcon-root": {
              color: "#FFFFFF",
              border: "1.9px solid #c2d7df",  // Reduced border thickness by 30%
              transform: "scale(1.21)", // Reduced scale by 30% (1.728 -> ~1.21)
              transformOrigin: "center",
              borderRadius: "50%",
            },
          }}
        >
          {label}
        </StepLabel>
      </Step>
    ))}
</Stepper>
      </Box>

      {/* Vertical Divider */}
      <Divider
  orientation="vertical"
  flexItem
  sx={{ boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.3)",my:2 }} 
/>
      {/* Right Form Section (70%) */}
      <Box
        sx={{
          width: "70%",
          backgroundColor: "#FFFFFF",
          p: 1,
          alignItems: "center", // Center the Stepper horizontally in the Box
          justifyContent: "flex-start", // Center content vertically if needed
          pl:3,
          overflowY: "auto",
          height: "50%",
          
        }}
      >
        <form >

            

        {activeStep === 0 &&
        <AccountInfoStep 
        user={user} 
        isNextButtonDisabled={isNextButtonDisabled} 
        setIsNextButtonDisabled={setIsNextButtonDisabled} 
        hideWelcome={hideWelcome}
        setCompanyName = {setCompanyName}
        setCompanyAddress = {setCompanyAddress}
        setCompanyCity = {setCompanyCity}
        setCompanyState = {setCompanyState} 
        setCompanyZip = {setCompanyZip} 
        setCompanyEmail = {setCompanyEmail}
        setCompanyUrl = {setCompanyUrl}
        setCompanyPhone = {setCompanyPhone}
        values={values}
        setValues={setValues}
        errors={errors}
        setErrors={setErrors}
        setWarning={setWarning}
        warning={warning}
        validateField={validateField}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleStateChange={handleStateChange} 
        typing={typing}
        setHideWelcome={setHideWelcome}
        
        />}


        {activeStep === 1 && 
        <CreateLocationStep 
        isNextButtonDisabled={isNextButtonDisabled} 
        setIsNextButtonDisabled={setIsNextButtonDisabled} 
        setIsSingleLocation={setIsSingleLocation} 
        showCreateLocation={showCreateLocation}  
        isSingleLocation={isSingleLocation}
        setLocationName = {setLocationName}
        setLocationEmail = {setLocationEmail}
        setLocationPhone = {setLocationPhone}
        values={values}
        setValues={setValues}
        handleChange={handleChange}
        errors={errors}
        isSingleUser={isSingleUser}
        setIsSingleUser={setIsSingleUser}
        typing={typing}
        setShowCreateLocation={setShowCreateLocation} 
        handleBlur={handleBlur}
        />}


        {activeStep === 2 && <CreateUserStep 
        user={user} 
        isNextButtonDisabled={isNextButtonDisabled} 
        setIsNextButtonDisabled={setIsNextButtonDisabled} 
        setIsSingleUser={setIsSingleUser} 
        isSingleUser={isSingleUser}
        showCreateUser={showCreateUser} 
        setUserFirstName = {setUserFirstName}
        setUserLastName = {setUserLastName}
        setUsername = {setUsername}
        isSingleLocation={isSingleLocation}
        values={values}
        setValues={setValues}
        typing={typing}
        handleChange={handleChange}
        errors={errors} 
        setShowCreateUser={setShowCreateUser} 
        handleBlur={handleBlur}
        handleChangeTitle={handleChangeTitle}
        handleRoleButtonClick={handleRoleButtonClick}
        roleOpen={roleOpen}
        setRoleOpen={setRoleOpen}


        
        
        />}

        {activeStep === 3 && <ReviewStep  
        user={user} 
        isNextButtonDisabled={isNextButtonDisabled} 
        setIsNextButtonDisabled={setIsNextButtonDisabled} 
        companyName={companyName}
        companyAddress={companyAddress}
        companyCity={companyCity}
        companyState={companyState}
        companyZip={companyZip}
        companyEmail={companyEmail}
        companyUrl={companyUrl}
        companyPhone={companyPhone}
        userFirstName={userFirstName}
        userLastName={userLastName}
        username={username}
        locationName={locationName}
        locationEmail={locationEmail}
        locationPhone={locationPhone} 
        isSingleLocation = {isSingleLocation}
        isSingleUser = {isSingleUser}
        values={values}
        setValues={setValues}
        


        
        />}

        {activeStep === 4 && <SetupComplete
       isSingleLocation = {isSingleLocation}
        isSingleUser = {isSingleUser}
        activeStep={activeStep}
        handleClose={handleClose}

        
        />}







        </form>

        {/* Navigation Buttons */}
      
      </Box>
    </Stack>
  );
}