// AffectedAreasForm.js
import {
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Stack,
} from "@mui/material";
import { FormControl } from "@mui/material";
import { styled } from "@mui/system";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#E9EDF2",
  marginBottom: 3,
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -1.5px) scale(0.75)",
    paddingLeft: "7px", // Adjust the translateY value as needed
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#48768c", // Set the border color here
    },
    "&:hover fieldset": {
      borderColor: "#6fa3b7", // Set the hover border color here (optional)
    },
    "&.Mui-focused fieldset": {
      borderColor: "#48768c", // Set the focused border color here
    },
  },
}));

const ReviewForm = ({ bugDetails, handleChange }) => {


  const severityLabels = {
    Low: "Causes issues, but is still functional",
    Medium: "Requires workaround, but still works",
    High: "Does not work at all",
  };

  return (
      <Stack
        direction="column"
        spacing={0}
        sx={{
          width: "100%",
          display: "flex",
          whiteSpace: "wrap",
          alignItems: "fit-contain",
          mt:-1
        }}
      >
          <Box
    sx={{
      position: "sticky",
      top: 0,
      backgroundColor: "#ffffff", // Ensures it's visible over content
      zIndex: 1000, // Keeps it on top
      paddingTop: "5px",
      paddingBottom: "5px",
    }}
  >
        <Typography
          sx={{
            fontSize: "1.15rem",
            fontWeight: 600,

            width: "100%", // Makes sure it takes up full width
            letterSpacing: "-0.029em", // Adds letter spacing
            color: "#48768c",
          }}
        >
          Make sure everything is correct before submitting it to us.
        </Typography>
        </Box>

        <Box sx={{height:'20vh', overflowY:'auto',                 border: "1.25px solid rgba(173, 216, 230, 0.8)", borderRadius:'5px',
          pt:'3px', mt:'15px'
        }}>

        <Box sx={{}}>
          <Typography
            color="textSecondary"
            sx={{ fontSize: ".95rem", fontWeight: 600,       p: 1, // Adds padding for better readability
            }}
          >
            What is the issue your experiencing?
          </Typography>

          <Box sx={{mx:.5}}>
            <Typography

              sx={{
                fontSize: ".93rem",
                fontWeight: 500,
                backgroundColor: "rgba(173, 216, 230, 0.4)",
                height: "30px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                color: "#395465",
                p: 1, // Adds padding for better readability

              }}
            >
              {bugDetails.bugType}
            </Typography>
          </Box>
        </Box>

        <Box sx={{}}>
          <Typography
            color="textSecondary"
            sx={{ fontSize: ".93rem", fontWeight: 600,       p: 1, // Adds padding for better readability
            }}
          >
            How severe is the issue?
          </Typography>

          <Box sx={{mx:.5}}>
            <Typography
                    sx={{
                      fontSize: ".93rem",
                      fontWeight: 500,
                      backgroundColor: "rgba(173, 216, 230, 0.4)",
                      height: "30px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      color: "#395465",
                      p: 1, // Adds padding for better readability

              }}
            >
              {severityLabels[bugDetails.severity] || bugDetails.severity}
            </Typography>
          </Box>
        </Box>
        <Box sx={{}}>
          <Typography
            color="textSecondary"
            sx={{ fontSize: ".95rem", fontWeight: 600,       p: 1, // Adds padding for better readability
            }}
          >
            Describe the issue you're having.
            </Typography>

            <Box sx={{ width: "100%", height: "auto", flexGrow: 1, mx:.5 }}>
  <Typography
    sx={{
      fontSize: ".93rem",
      fontWeight: 500,
      backgroundColor: "rgba(173, 216, 230, 0.4)",
      minHeight: "30px",
      borderRadius: "5px",
      display: "block",
      alignItems: "center",
      whiteSpace: "normal", // Allows wrapping
      wordBreak: "break-word", // Ensures long words don't overflow
      width: "100%",
      p: 1, // Adds padding for better readability
      color: "#395465",

    }}
  >
    {bugDetails.description}
  </Typography>
</Box>
        </Box>
        <Box sx={{}}>
          <Typography
            color="textSecondary"
            sx={{ fontSize: ".95rem", fontWeight: 600,       p: 1, // Adds padding for better readability
            }}
          >
            How can we reproduce the issue?
          </Typography>

          <Box sx={{ width: "100%", height: "auto", flexGrow: 1, mx:.5  }}>
  <Typography
    sx={{
      fontSize: ".93rem",
      fontWeight: 500,
      backgroundColor: "rgba(173, 216, 230, 0.4)",
      minHeight: "30px",
      borderRadius: "5px",
      display: "block",
      alignItems: "center",
      whiteSpace: "normal", // Allows wrapping
      wordBreak: "break-word", // Ensures long words don't overflow
      width: "100%",
      p: 1, // Adds padding for better readability
      color: "#395465",
    }}
  >
    {bugDetails.stepsToReproduce}
  </Typography>
</Box>
        </Box>
      </Box>
      </Stack>
  );
};

export default ReviewForm;
