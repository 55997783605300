import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";

// Create the Customers context
const CustomersContext = createContext();

// Create a custom hook to use the CustomersContext
export const useCustomersContext = () => {
  return useContext(CustomersContext);
};

// Create the CustomersProvider to wrap around components that need access to customer data
export const CustomersProvider = ({ children }) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [lastMessages, setLastMessages] = useState({});
  const [reloadComponents, setReloadComponents] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [loadingCustomerData, setLoadingCustomerData] = useState(false);


// Fetch customers
const fetchCustomers = async () => {
  setLoadingCustomerData(true);
  try {
    if (user?.sub) { // ✅ Ensure user.sub is available
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/api/customers/list/${encodeURIComponent(user.sub)}`, { // ✅ Encode user.sub for safety
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setCustomers(data);
      setTotalCustomers(data.length);
    }
  } catch (error) {
    console.error("Failed to fetch customer data:", error);
  } finally {
    setLoadingCustomerData(false);
  }
};

  // Update a customer
  const handleUpdateCustomer = async (updatedCustomer) => { // Change to location_id
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const requestBody = {
        first_name: updatedCustomer.first_name,
        last_name: updatedCustomer.last_name,
        ...(updatedCustomer.phone_number && {
          phone_number: updatedCustomer.phone_number,
        }),
        location_id: updatedCustomer.location_id, // Change to location_id
      };
  
      const response = await fetch(
        `${apiUrl}/api/customers/${updatedCustomer.customer_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(requestBody),
        }
      );
  
      if (response.ok) {
        setCustomers((prevCustomers) =>
          prevCustomers.map((customer) =>
            customer.customer_id === updatedCustomer.customer_id
              ? { ...customer, ...updatedCustomer, } // Ensure UI updates correctly
              : customer
          )
        );
        handleReloadComponents();
        setLoading(false);
        showSnackBar("Customer Updated Successfully", true);
        return true;
      } else {
        throw new Error("Failed to update customer");
      }
    } catch (error) {
      console.error("Error updating customer:", error);
      setLoading(false);
      showSnackBar("Failed to update Customer. Please try again", false);
    }
  };

  // Delete a customer
  const handleDeleteCustomer = async (customer_id) => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${apiUrl}/api/customers/${customer_id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setCustomers((prevCustomers) =>
          prevCustomers.filter(
            (customer) => customer.customer_id !== customer_id,
          ),
        );
        handleReloadComponents();
        setLoading(false);
        showSnackBar("Customer Deleted", true);
        return true; // Return true to indicate success
      } else {
        throw new Error("Failed to delete customer");
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
      setLoading(false);
      showSnackBar("Failed to Delete Customer. Please try again ", false);
    }
  };

  // Fetch last messages for each customer
  const fetchLastMessages = async () => {
    try {
      if (user && user.sub) {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(
          `${apiUrl}/api/customers/last-messages/${user.sub}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const data = await response.json();
        if (Array.isArray(data.lastMessages)) {
          const lastMessagesObject = data.lastMessages.reduce(
            (acc, message) => {
              acc[message.phone_number] = message.last_message_sent_date;
              return acc;
            },
            {},
          );
          setLastMessages(lastMessagesObject);
        } else {
          setLastMessages(data.lastMessages || {});
        }
      }
    } catch (error) {
      console.error("Failed to fetch last messages:", error);
    }
  };

  // Fetch customers on component mount or reload
  useEffect(() => {
    fetchCustomers();
    fetchLastMessages();
  }, [user]);

  return (
    <CustomersContext.Provider
      value={{
        loading,
        customers,
        totalCustomers,
        lastMessages,
        fetchCustomers,
        handleUpdateCustomer,
        handleDeleteCustomer,
        setReloadComponents,
        loadingCustomerData,
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
};
