import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Fade,
  Stack,
  Card,
  CardContent,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "src/public/assets/icons/icons/deleteRed.png";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import { useAppContext } from "src/context/AppContext";
import { useAuth } from "src/context/AuthContext";
import { useAccountContext } from "src/context/AccountContext";
import { useUserNotificationsContext } from "src/context/UserNotificationsContext";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useUserSelectedNotifications } from "src/context/UserNotificationsContext";
import openSign from "src/public/assets/icons/icons/openSign.png";
import closedSign from "src/public/assets/icons/icons/closedSign.png";
import { customTheme } from "./textField";

const LocationHoursCard = ({ locationHours }) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [loadingHours, setLoadingHours] = useState(false);
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const { fetchCurrentHours } = useUserSelectedNotifications();

  const { accountData, fetchOnboardingRecord } = useAccountContext();
  const [isClosed, setIsClosed] = useState(false);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { username, subUserData } = useAuth();

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      color: "#647D9E", // Unchecked thumb (circle) color
      "&.Mui-checked": {
        color: "#4C5F7D", // Checked thumb color
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "#B0BFD1", // Unchecked track (background) color
        "&.Mui-checked": {
          backgroundColor: "#768AA6", // Checked track color
        },
      },
    },
  }));

  const apiUrl = process.env.REACT_APP_API_URL;

  const WhiteTimePicker = styled(TimePicker)(({ isAdmin }) => ({
    "& input": {
      color: "#48768C",
      fontWeight: 600,
      fontSize: 12.36, // 7.8 * 1.2
      textAlign: "center",
      padding: "1.8px 3.6px", // 4px * 1.2 and 8px * 1.2
      height: "23.04px", // 19.2px * 1.2
    },
    "& .MuiSvgIcon-root, & .MuiIconButton-root, & .MuiInputAdornment-root": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      // Adding focus behavior to clear the field when clicked
      "&:focus": {
        caretColor: "transparent", // Optional: hides the caret to prevent a blinking cursor
      },
      // Clearing the input on focus
      "&:focus-within": {
        value: "",
      },
    },
  }));

  const OpenIconSwitch = styled(Switch)(({ theme }) => ({
    width: 40.71, // 35.4 * 1.15
    height: 22.08, // 19.2 * 1.15
    padding: 4.83, // 4.2 * 1.15
    "& .MuiSwitch-switchBase": {
      margin: 0.69, // 0.6 * 1.15
      padding: 0,
      transform: "translateX(4.14px)", // 3.6 * 1.15
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(15.18px)", // 13.2 * 1.15
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url(${closedSign})`,
          backgroundSize: "contain",
          width: "15.18px", // 13.2 * 1.15
          height: "15.18px", // 13.2 * 1.15
          content: "''",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
      "&.Mui-checked + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#647D9E" : "#647D9E",
        "& .MuiSwitch-thumb:before": {},
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#236381 " : "#236381",
      width: 19.32, // 16.8 * 1.15
      height: 19.32, // 16.8 * 1.15
      "&::before": {
        backgroundImage: `url(${openSign})`,
        backgroundSize: "contain",
        width: "16.56px", // 14.4 * 1.15
        height: "16.56px", // 14.4 * 1.15
        content: "''",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#AAC5E7" : "#AAC5E7",
      borderRadius: 6.9, // 6 * 1.15
    },
  }));

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const formatTime12Hour = (time) => {
    if (time) {
      const [hours, minutes] = time.split(":");
      const parsedTime = dayjs()
        .set("hour", parseInt(hours))
        .set("minute", parseInt(minutes));

      return parsedTime.format("h:mm A"); // Format in 12-hour format with AM/PM
    }
    // If time is not available, use a default value
    return dayjs().set("hour", 0).set("minute", 0).format("h:mm A");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mb: 2 }}>
        {daysOfWeek.map((dayOfWeek) => (
          <Box
            key={dayOfWeek}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: 2,
              mb: 1,
              pl: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: 14.36,
                color: "#48768C",
                fontWeight: 600,
                width: "130px",
              }}
            >
              {dayOfWeek}
            </Typography>

            {locationHours[dayOfWeek]?.is_closed ? (
              <Fade in={locationHours[dayOfWeek]?.is_closed} timeout={300}>
                <Typography
                  align="center"
                  sx={{
                    width: "57%",
                    color: "#48768C",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    backgroundColor: "#48768C3D",
                    borderRadius: "4px",
                    fontSize: 15.36,
                  }}
                >
                  Closed
                </Typography>
              </Fade>
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%", mx: 7 }}
              >
                <Typography
                  sx={{
                    fontSize: 13.36,
                    fontWeight: 600,
                    color: "#48768CE6",
                    padding: "3px 15px",
                    border: "1px solid rgba(173, 216, 230, 0.9)",
                    borderRadius: "5px",
                  }}
                >
                  {formatTime12Hour(locationHours[dayOfWeek]?.start_time)}
                </Typography>
                <Typography
                  sx={{ fontWeight: 600, color: "#48768C", fontSize: 18.36 }}
                >
                  -
                </Typography>
                <Typography
                  sx={{
                    fontSize: 13.36,
                    fontWeight: 600,
                    color: "#48768CE6",
                    mr: 2,
                    padding: "3px 15px",
                    border: "1px solid rgba(173, 216, 230, 0.9)",
                    borderRadius: "5px",
                  }}
                >
                  {formatTime12Hour(locationHours[dayOfWeek]?.end_time)}
                </Typography>
              </Stack>
            )}
          </Box>
        ))}
      </Box>
    </LocalizationProvider>
  );
};

export default LocationHoursCard;
