import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Box, Stack } from "@mui/material";
import TipAccordion from './components/TipAccordion.js'
import CloseIcon from "@mui/icons-material/Close";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { styled } from "@mui/material/styles";
import sections from './components/sections.js'


import LabelIcon from 'src/public/assets/icons/icons/tips/label.png'
import RocketIcon from 'src/public/assets/icons/icons/tips/rocket.png'
import LogoIcon from 'src/public/assets/icons/icons/tips/logo.png'
import NotifyIcon from 'src/public/assets/icons/icons/tips/notification.png'
import TeamIcon from 'src/public/assets/icons/icons/tips/team.png'
import ShieldIcon from 'src/public/assets/icons/icons/tips/shield.png'
import LocationIcon from 'src/public/assets/icons/icons/tips/location.png'
import CashIcon from 'src/public/assets/icons/icons/tips/cash.png'
import FieldIcon from 'src/public/assets/icons/icons/tips/field.png'
import OptionalIcon from 'src/public/assets/icons/icons/tips/optional.png'
import DefaultIcon from 'src/public/assets/icons/icons/tips/default.png'
import TwoWayIcon from 'src/public/assets/icons/icons/tips/twoWay.png'
import EnableIcon from 'src/public/assets/icons/icons/tips/enable.png'
import RefreshIcon from 'src/public/assets/icons/icons/tips/refresh.png'

















const StyledHeader = styled(Typography)({
  fontSize: "20px",
  fontWeight: 550,
  color: "#6595ab",
  letterSpacing: "-.01em",
  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.03)",
  lineHeight: "1.2",
  wordSpacing: "3px",
  paddingBottom: 0,
  paddingTop: 1,
  width: "100%",
  textAlign: "left",
  paddingBottom:'20px'
});

const TipsAndTricksDialog = ({ open, handleClose }) => {

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth 
            PaperProps={{ sx: { borderRadius: "10px", backgroundColor: "#FFFFFF", minWidth:'700px', minHeight:'500px', maxHeight:'60vh', p:4 } }}

      
      >
              <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>
    
    
    
      <Box sx={{alignItems:'left', display:'flex', flexDirection:'column', width:'100%', px:1.85}}>
        <Typography sx={{      fontSize: 24.5,
      fontWeight: 550,
      color: "#48768C",
      letterSpacing: -1.8,
      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.01)",

      lineHeight: -.02,
      wordSpacing: "3.5px",
      pb: 0,
     
 
      width:'100%',
      textAlign:'left' }}>
          
          Tips & Tricks</Typography>
         
          </Box>

        
        <DialogContent sx={{pt:3,px:1.95}}>
<Stack direction="column" spacing={4}>
          <Box>
        <StyledHeader>
          
       Personalize Your Notifications With Context   </StyledHeader>
         <TipAccordion
         title={"Add personalized context messages to your notifications"}
         keyword={"Context messages"}
         content={"are a powerful way to make your notifications stand out. You can add a quick short sentance at the beginning of any notification type by clicking 'Edit Context Message' in the Manage Notifications section on the notification you want to edit. "}
       example={true}
         additionalContent={"If you had a notification with the status of 'Ready for Pickup', you can add a context message to the start of your notification saying 'Your order has been completed and is ready for pickup!'"}
          footer={"Context messages are automatically added to every notification you send for that type and each notification can have its own unique context message"}
         topicIcon={LabelIcon}

         />
                  <TipAccordion
         title={"Create personalized context labels for your customers"}
          topicIcon={RocketIcon}
          keyword={"Context labels"}
          content={"give you the ability to add extra detail to the fields in your notifications, providing a more personalized experience for your customers. These labels replace the standard field name when the notification is sent to the customer, while the original field label remains visible on your side to keep things simple. "}
        example={true}
          additionalContent={"If you have a field labeled ‘Balance’, you can create a context label like ‘Balance due at pickup’ that the customer will see. On your end, the field will still show as ‘Balance’, keeping things clear and easy to manage. "}
           footer={"Each field can have its own unique context label, and you can add, edit, or remove them whenever you need to, giving you full control over the level of personalization in your notifications."}
  
         />
         </Box>


         <Box>

          <StyledHeader >
          
        Customize Your Account And Dashboard  </StyledHeader>
        <TipAccordion
         title={"Upload your company’s logo to make NanoNotify your own."}
         content={"Personalize your dashboard by uploading your company’s logo."}
   additionalContent={"Your logo will be automatically resized for the best fit, and you can upload a PNG or JPEG file."}
   footer={" Need to make a change? You can edit or remove your logo anytime to keep your branding up to date."}     
   topicIcon={LogoIcon}
         />
                  <TipAccordion
         title={"Fine tune your in-app notifications"}
         content={"Tailor your in-app notifications to focus on what matters most to you. "}
         additionalContent={"Toggle two-way notification responses, get notified when scheduled notifications are sent or if a notifcation fails to send. "}
   keyword={""}
   footer={"You can even toggle in-app notifications to stay on top of upcoming payments or subscription renewals."}      
   topicIcon={NotifyIcon}
         />
         </Box>


          <Box>

<StyledHeader>
          
       Managing Your Team </StyledHeader>
        <TipAccordion
         title={"Understanding how multi user accounts work"}
         content={"No matter the size of your company or team, signing in is simple—everyone uses the same email and password, while each user has a unique username and PIN for secondary login."}
         additionalContent={'Individual access levels keep things organized, ensuring the right people have the right permissions.'}
         topicIcon={TeamIcon}
         footer={"For multi-location accounts, users can be assigned to specific locations and seamlessly switch between them as needed, making it easy to stay connected across different locations."}
         
         />
                  <TipAccordion
         title={"Manage your team with roles and permissions"}
         content={"Keep your account secure and organized by assigning the right level of access to your team."}
   keyword={"Your team members can be assigned one of two roles: Account Administrator or Standard User."}
         topicIcon={ShieldIcon}
         additionalContent={"Standard Users can send notifications and use the account as set up by administrators but cannot modify notification types or account settings, ensuring everything stays secure."}
         footer={"Account administrators can create new notification types, manage your subscription, create, edit, delete locations and users."}
         />
                  <TipAccordion
         title={"Assigning your team members to locations"}
         content={"allow you to assign team members to specific locations, so they’re automatically ready to send notifications from their active location when they sign in."}
         keyword={"Multi-Location Accounts"}
         additionalContent={'To reassign employees to a different location, you can either edit their user profile in the Manage Users section.'}
         footer={'You can also update multiple team members at once by modifying the location in the Manage Locations section. '}
         topicIcon={LocationIcon}
         />
         </Box>

<Box>
          <StyledHeader >
          
      Enhance Your Custom Notifications </StyledHeader>
        <TipAccordion
         title={"Understanding The Different Field Types"}
         showFieldTypeHelp={true}
   
         topicIcon={FieldIcon}
         />
                  <TipAccordion
         title={"Editing / Adding Custom Icons To Your Fields"}
         content={"Make your notification fields more distinguishable by selecting from a variety of icons."}
          additionalContent={"Whether you’re sending balance details, dates, or times, there’s an icon that fits."}
        footer={"These icons can be easily changed at any time by clicking the “edit field icon” in the Manage Notifications section."}
         topicIcon={CashIcon}
         />
                  <TipAccordion
         title={"Add Optional Fields"}
         content={"If your notification sometimes requires additional information, you can easily add an optional field to include it when needed."}
          example={true}
          additionalContent={"If you create a notification that is used to send price quotes, but occasionally need to include an estimated time of completion, you can add an optional field for that."}
          footer={'If no information is entered in the optional field when sending the notification, it will be automatically hidden, and only the details in the primary field will be included.'}
          topicIcon={OptionalIcon}
         />
                        <TipAccordion
         title={"Change Your Default Notification"}
         content={"If there’s a notification you send more frequently than others, you can set it as your default. This way, when you click “New Notification,” it will automatically appear, allowing you to send it even faster. "}
          additionalContent={"You can change your default notification at any time to suit your needs."}
         topicIcon={DefaultIcon}
         />
         </Box>

         <Box>                      
          <StyledHeader >
          
       Mastering Two Way Notifications  </StyledHeader>
        <TipAccordion
         title={"Understanding Two-Way Notifications"}
         keyword={"Two-way notifications enable your customers to respond with a simple “yes” or “no”"}
         content={" making them perfect for scenarios like confirming an appointment, approving a quote, or requesting approval for something."}
          additionalContent={"Notifications that are pending a response will appear on your dashboard and automatically update to “Approved” or “Declined” once your customer responds, keeping you informed in real time."}
         topicIcon={TwoWayIcon}
         />
                  <TipAccordion
         title={"Enabling Two Way Notifications"}
         content={"By default, two-way notifications are turned off when a new notification type is created. To enable them, simply edit the notification type and select “Enable two-way notifications.” Once enabled, your customers can respond without any additional setup."}
          additionalContent={"You can disable two-way notifications at any time, but keep in mind that your customers will still be able to respond to notifications sent while they were enabled."}
         footer={"**Note that only one notification type can have two-way notifications enabled at a time."}
          topicIcon={EnableIcon}
         />
                  <TipAccordion
         title={"Manually Update A Notification Status"}
         content={"If a customer provides an update on a two-way notification outside of the usual response method, such as through a phone call or in person, you can manually update the status. Simply click on the notification in your dashboard and select either “Manually Approve” or “Manually Decline.”"}
          additionalContent={"Once the notification status is manually updated, the two-way notification will be closed, and responses to that notification will be discarded."}
         topicIcon={RefreshIcon}
         />
         </Box>
         </Stack>
        </DialogContent>
        
        <DialogActions>
  
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TipsAndTricksDialog;