import react, { useState } from "react";   
import { IconButton, Fade,Box  } from "@mui/material";  
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";


const ClearSearchButton = ({ setFieldData }) => {   
    

    const handleClearSearch = () => {   
        setFieldData("");
    }
    


    
    return (

    <Fade in={true} timeout={200}>
    <IconButton
      onClick={handleClearSearch}
      sx={{ color: "#6595ab", padding: 0, display: "flex", alignItems: "center", justifyContent: "center",  }}
    >
      <CancelOutlinedIcon
        sx={{ fontSize: 21.5, opacity: 0.85,  }}
      />
    </IconButton>
  </Fade>
  
    );

};

export default ClearSearchButton;

