import React from "react";
import { Dialog, DialogContent, DialogActions, Typography, Button, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "src/public/assets/icons/icons/check.png";


const SuccessDialog = ({ open, onClose, userName }) => {
    return (
<Dialog
  open={open}
  onClose={onClose}
  PaperProps={{
    sx: {
      borderRadius: "10px",
      px:3,
      pt:1,
      pb:3,
      minWidth: "320px",
      backgroundColor: "white",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }}
>

  <DialogContent
    sx={{
      paddingBottom: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    }}
  >
            <Box sx={{}}>
          <img
            src={CheckIcon}
            style={{
              border: "transparent",
              width: "auto",
              height: "45px",
              backgroundColor: "#D6F5EE",
              borderRadius: "6px",
              padding: "1px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              marginBottom: "5px",
            }}
          />
        </Box>
    {/* Success Message */}
    <Typography
      sx={{
        fontSize: "20.5px",
        fontWeight: 550,
        color: "#48768C",
        letterSpacing: "-.02em",
        mt:1
      }}
    >
      Success!
    </Typography>
    <Typography
      sx={{
        fontSize: "15.5px",
        fontWeight: 550,
        color: "#6c90a2",
        letterSpacing: "-.02em",
        lineHeight: "-2",
        whiteSpace: "noWrap",
        mt:1
      }}
    >
  {userName ? ` ${userName === "Your" ? "Your" : `${userName}'s`} PIN has been changed successfully.` : " PIN has been changed successfully."}

</Typography>
  </DialogContent>

  <DialogActions
    sx={{
      justifyContent: "center",
      width: "100%",
    }}
  >
    <Button
      variant="contained"
      sx={{
        mt:.5,
        px:4,
        fontSize: "1rem",
        color: "#F0F0F0",
        backgroundColor: "#46A793",
        "&:hover": { backgroundColor: "#079E80" },
        "&:active": { backgroundColor: "#2E7D32" },
      }}
      onClick={onClose}
    >
      OK
    </Button>
  </DialogActions>
</Dialog>
  );
};

export default SuccessDialog;