import { Typography, TextField, InputAdornment, Stack, Box } from "@mui/material";

const ContactForm = ({ ticketDetails, handleChange }) => {
  return (
    <>
     <Typography
                   

                   sx={{
                    fontSize: "1.29rem",
                    fontWeight: 600,
                 
                
                    width: "100%", // Makes sure it takes up full width
                    letterSpacing: "-0.03em", // Adds letter spacing
                    color: "#48768c",
                    lineHeight:'-.02em',
                 
                   }}
                 >       
                Who should we contact to follow up?
                       </Typography>
     
                       <Typography 
         color="textSecondary"
         sx={{ 
          fontSize: "1rem",
          fontWeight: 600,
      
      
          width: "100%", // Makes sure it takes up full width
          letterSpacing: "-0.029em", // Adds letter spacing
          color: "#6c90a2",
          mb:2
          
          }}>
       We will reach out as soon as we are able to get your issue resolved. Typically responses take up to 24 hours
      </Typography>

<Box sx={{width:'80%', }}>
<Stack direction="row" spacing={1} sx={{}}>
      <TextField
      size="small"
      value={ticketDetails.firstName}
      onChange={handleChange} 
        sx={{
          marginBottom: 3.5,
          border:'none',
          "& textarea": {
          },
          "& .MuiOutlinedInput-root": {
            border:'none',  
            borderRadius:'3px',
            "&:hover": {
              borderColor: "none", // Remove the border on hover

            },
          },
        
        }}
        placeholder="First Name"
        name="firstName" // Fix this


        fullWidth
        required
        InputProps={{
        }}
      />
        <TextField
      size="small"
      value={ticketDetails.lastName}
      onChange={handleChange} 

        sx={{
          marginBottom: 3.5,
          border:'none',
          "& textarea": {
            minHeight: "1px", // Set the minimum height of the textarea
          },
          "& .MuiOutlinedInput-root": {
            border:'none',  
            borderRadius:'3px',

            "&:hover": {
              borderColor: "none", // Remove the border on hover
            },
          },
        
        }}
        placeholder="Last Name"
        name="lastName" // Fix this

       
        fullWidth
        required
        InputProps={{
        }}
      />
      </Stack>
      <TextField
      size="small"
      value={ticketDetails.email}
      onChange={handleChange} 
        sx={{
          marginBottom: 6.15,
          mt:1,
          border:'none',
          "& textarea": {
          },
          "& .MuiOutlinedInput-root": {
            border:'none',  
            borderRadius:'3px',

            "&:hover": {
              borderColor: "none", // Remove the border on hover
            },
          },
        
        }}
        placeholder="Email Address"
        name="email"


        fullWidth
        required
        InputProps={{
        }}
      />
       

</Box>
      
    </>
  );
};

export default ContactForm;