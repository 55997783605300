import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Fade,
  Stack,
} from "@mui/material";
import ReviewDialog from "src/components/AccountPage/AccountManagementComponents/ReviewDialog.js";
import DeleteIcon from "../../public/assets/icons/icons/deleteRed.png";

const DeleteLocationDialog = ({
  open,
  onClose,
  onConfirm,
  itemName,
  item,
  isLoading,
  header,
  message,
  subText,
  buttonAction,
  addedUserPrice,
  hideReviewDialog,
  buttonText,
  deletedLocation,
  reviewButtonText,
  reviewButtonLoadingText,
  addedUser,
  deletedUser,
  buttonLoadingText,
  handleCancel,
  totalUsers, 
  totalLocations, 
  planDetails, 
  subscriptionDetails,
  label,
  value
}) => {
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(false);  
  const [updatedLocation, setUpdatedLocation] = useState(false);
  const [updatedUserPrice, setUpdatedUserPrice] = useState('');  







  const handleCloseReviewDialog = () => {
    setOpenReviewDialog(false);
  };

  const handleOpenReviewDialog = () => {
    if (hideReviewDialog === false) {
      setOpenReviewDialog(true);
    } else {
      onConfirm();
    }
  };

  useEffect(() => {
    if (open === false) {
      setOpenReviewDialog(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      transitionDuration={{ enter: 300, exit: 300 }}
      PaperProps={{
        style: {
          backgroundColor: "#747C86",
          width: "200px",
          border: "1px solid rgba(173, 216, 230, 0.7)",
          minWidth: "400px",
          borderRadius: "18px",
          bottom: "5%",
          maxWidth: "600px",
        },
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          padding: 3,
          color: "#374247",
          alignItems: "center",
        }}
      >
        <Box sx={{}}>
          <img
            src={DeleteIcon}
            alt="detailsIcon"
            style={{
              border: "transparent",
              width: "auto",
              height: "40px",
              backgroundColor: "#8C435036",
              borderRadius: "6px",
              padding: "1px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              marginBottom: "5px",
            }}
          />
        </Box>
        <Typography
          sx={{
            color: "#48768C",
            mb: 0.5,
            fontWeight: 600,
            mt: 1.5,
            fontSize: "18px",
          }}
        >
          {header}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          mt: -1,
          fontWeight: 600,
          letterSpacing: "0.02em",
          backgroundColor: "#FFFFFF",
          color: "#48768C",
          padding: ".75rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(191, 218, 231, 0.1)",
            border: "1px solid rgba(173, 216, 230, 0.7)",
            borderRadius: "10px",
            fontSize: 15,
            padding: ".75rem",
            pt: 2,
            px: 1.5,
          }}
        >
          <Typography
            sx={{ fontSize: "15px", alignItems: "center", textAlign: "center" }}
          >
            {message}
          </Typography>
          <Typography
            sx={{
              fontSize: "14.5px",
              mt: 1.5,
              fontWeight: "bold",
              alignItems: "center",
              textAlign: "center",
              width: "auto",
            }}
          >
            {subText}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              mt: 2,
              pt: 1,
              pb: 2,
            }}
          >
            <Stack
              direction="column"
              spacing={2.5}
              sx={{ width: "100%", display: "flex" }}
            >
              <Button
                onClick={handleOpenReviewDialog}
                disabled={isLoading}
                sx={{
                  display: "flex",
                  border: "1px solid #8C435029",
                  borderRadius: "10px",
                  padding: "8px",
                  color: "#8C4350",
                  width: "100%",
                  backgroundColor: "#8C435021",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",

                  "&:hover": {
                    backgroundColor: "#8C435017",
                  },
                  "&:active": {
                    backgroundColor: "#A4C5D230",
                  },
                }}
                color="primary"
              >
                {isLoading ? buttonLoadingText : buttonText || ""}
              </Button>
              <Button
                onClick={onClose}
                sx={{
                  marginRight: "10px",
                  border: "1px solid #A4C5D2E8",
                  borderRadius: "10px",
                  padding: "8px",
                  color: "#48768C",
                  width: "100%",
                  backgroundColor: "#FFFFFF",
                  "&:hover": { backgroundColor: "#48768C12" },
                  "&:active": { backgroundColor: "#DBEAF0" },
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </DialogContent>

      <ReviewDialog
        open={openReviewDialog}
        onClose={handleCloseReviewDialog}
        buttonAction={onConfirm}
        isLoading={isLoading}
        CancelCloseMessage="Are you sure you want to close?"
        CancelCloseSubText="Your new user will not be created."
        subscriptionDetails={subscriptionDetails}
        planDetails={planDetails}
        addedUser={false}  // Pass this dynamically based on the action
        addedLocation={false}
        deletedUser={deletedUser}
        deletedLocation={deletedLocation}
        upgradedPlan={false}
        downgradedPlan={false}
        label={label}
        value={value}


        buttonText={reviewButtonText}
        item={item}
        buttonLoadingText={reviewButtonLoadingText}
        firstName={item?.first_name}
        lastName={item?.last_name}
        userName={item?.username}
        role={item?.title}
        handleCancel={handleCancel}
        plan="singleUserMonthly"
        updatedUser={updatedUser}
        updatedLocation={updatedLocation}
        updatedUserPrice={updatedUserPrice} 
      />
    </Dialog>
  );
};

export default DeleteLocationDialog;
