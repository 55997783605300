import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";

const ProtectedRoute = ({ component, isAllowed = true, ...args }) => {

  // Redirect if user is not allowed
  if (!isAllowed) {
    return <Navigate to="/dashboard/app" replace />;
  }
  

  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

export default ProtectedRoute;