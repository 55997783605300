import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Button,
  Box,
  Modal,
  IconButton,
  Skeleton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Fade,
} from "@mui/material";



//Pass icons as props to render different icons 
//Set accept to true to render confirm dialog with accept icon
//Set accept to false to render confirm dialog with decline icon

const ConfirmDialog = ({
  open,
  onClose,
  onCancel,
  confirmHeader,
  confirmMessage,
  confirmMessageSubText,    
  buttonText,
  buttonAction,

  confirmButtonText,
  confirmMessageSubTextTwo,
  accept,
  acceptIcon,
declineIcon,
isLoading,
buttonLoadingText,

}) => {




  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      transitionDuration={{ enter: 300, exit: 300 }}
      PaperProps={{
        style: {
          backgroundColor: "#747C86",
          width: "200px",
          border: "1px solid rgba(173, 216, 230, 0.7)",
          minWidth: "455px",
          borderRadius: "10px",
          bottom: "5%",
          maxWidth: "455px",
        },
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          padding: 3,
          color: "#374247",
          alignItems: "center",
          pt:4
          
        }}
      >
         {accept ? (
  <Box
    sx={{
      border: "transparent",
      width: "auto",
      height: "45px",
      backgroundColor: "#d5f2ea",
      borderRadius: "6px",
      padding: "1px",
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
      marginBottom: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: '#2A8E7A',

    }}
  >
    {/* Render the acceptIcon directly */}
    {React.createElement(acceptIcon, { style: { width: "45px", height: "35px" } })}
  </Box>
) : (
  <Box
    sx={{
      border: "transparent",
      width: "auto",
      height: "45px",
      backgroundColor: "#8C435036",
      borderRadius: "6px",
      padding: "1px",
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
      marginBottom: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: '#8C4350F0',
    }}
  >
    {/* Render the declineIcon directly */}
    {React.createElement(declineIcon, { style: { width: "45px", height: "35px" } })}
  </Box>
)}
        
        <Typography
          sx={{
            fontSize: "19.5px",
            fontWeight: 550,
            color: "#48768C",
            letterSpacing: "-.02em",
            lineHeight: "-2",
            mt: 1.5,
            whiteSpace: "noWrap",
            mb: 0.5,
            textAlign: "center", // Center the wrapped text
          }}
        >
          {confirmHeader}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          mt: -1,
          fontWeight: 600,
          letterSpacing: "0.02em",
          backgroundColor: "#FFFFFF",
          color: "#48768C",
          padding: ".75rem",
          px:2,
          pb:2.5

        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(191, 218, 231, 0.1)",
            border: "1px solid rgba(173, 216, 230, 0.7)",
            borderRadius: "10px",
            fontSize: 16,
            padding: ".75rem",
            pt: 3,
            px: 1.5,
          }}
        >
          <Typography sx={{       fontSize: "18px",
                      fontWeight: 550,
                      color: "#5e7f94",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      wordWrap: "break-word", // Allow breaking long words
                      overflowWrap: "break-word", // Ensures words can break and prevent overflow
                      whiteSpace: "normal", // Prevent text from staying in a single line
                      textAlign: "center", // Center the wrapped text

                      
                      
                      }}>
            {confirmMessage || ""}
          </Typography>
          <Typography
            sx={{
                fontSize: "15.25px",
                fontWeight: 550,
                color: "#5e7f94",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                mt: 2,
                wordWrap: "break-word", // Allow breaking long words
                overflowWrap: "break-word", // Ensures words can break and prevent overflow
                whiteSpace: "normal", // Prevent text from staying in a single line
                                      textAlign: "center", // Center the wrapped text

            }}
          >
            {confirmMessageSubText || ""}
          </Typography>
          <Typography
            sx={{
                fontSize: "16.5px",
                fontWeight: 550,
                color: "#5e7f94",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                whiteSpace: "noWrap",
                mt: 2.5,
            }}
          >
            {confirmMessageSubTextTwo || ""}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              mt: 3,
              pt: 1,
              pb: 1,
            }}
          >
            <Stack
              direction="column"
              spacing={3}
              sx={{ width: "100%", display: "flex" }}
            >
              <Button
                onClick={buttonAction}
                disabled={isLoading}
                sx={{
                  display: "flex",
                  border: "1px solid #35566533",
                  borderRadius: "10px",
                  padding: "8px",
                  color:  accept ? "#48768C" : "#FFFFFF",
                  width: "100%",
                  backgroundColor: accept ? "#3556651A" : "#8C4350C7",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  alignText: "center",  

                  "&:hover": {
                    backgroundColor: accept ? "#35566529" : "#8C4350C4",
                    color: accept ? "#48768C" : "#FFFFFF",
                  },


                  "&:active": {
                    backgroundColor: "#35566529",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: accept ? "#3556651A" : "#8C4350E6",
                    color:  accept ? "#a0a0a0" : "#D7D7D7",
                    boxShadow: "none",           // Remove shadow on disabled
                    cursor: "not-allowed",      // Show a not-allowed cursor
                  },
                }}
                color="primary"
              >
             { isLoading ?   buttonLoadingText :  confirmButtonText}
              </Button>
              <Button
                onClick={onClose}
                sx={{
                  marginRight: "10px",
                  border: "1px solid #A4C5D2E8",
                  borderRadius: "10px",
                  padding: "8px",
                  color: "#48768C",
                  width: "100%",
                  backgroundColor: "#FFFFFF",
                  "&:hover": { backgroundColor: "#48768C12" },
                  "&:active": { backgroundColor: "#DBEAF0" },
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
