import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  Button,
  Stack,
  IconButton,
  Box,
  Chip,
  Avatar,
  Fade,
  Divider,
  Paper,
  ButtonBase
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import NewSubscription from "src/components/payments/components/NewSubscription";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ConfirmDialog from "src/components/modal/ConfirmDialog";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import PauseSubscriptionModal from "./PauseSubscriptionModal";
import DeleteAccountModal from "./DeleteAccountModal";




const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
);

const ManageSubscription = ({subscriptionDetails, planDetails, customerId, paymentDetails, loadingPaymentDetails, paymentDetailsError,
billingDetailOne, billingValueOne, billingDetailTwo, billingValueTwo, billingDetailThree, billingValueThree, billingDetailFour, billingValueFour, billingDetailFive, billingValueFive,
subscriptionStatus, onSubscriptionUpdate, priceId, subscriptionId, billingAddress, planCost, totalPlanCost, promotion, discountName, discountPercentage, discountedPlanCost, handleCancelClick, handleKeepClick,
  showNewSubscription,  
    setShowNewSubscription,
    showCancelDialog,   
    setShowCancelDialog,
    isCancelling, isKeeping,
    showPauseDialog,
    setShowPauseDialog,
    selectedDate,
    setSelectedDate,
    handlePauseClick,
    isPausing,
    setShowDeleteDialog,
    showDeleteDialog,
    accountData,


}) => {


  const { user } = useAuth0();

  const userId = user.sub;  




const billingDetails = [
  { label: billingDetailOne, value: billingValueOne },
  { label: billingDetailTwo, value: billingValueTwo },
  { label: billingDetailThree, value: billingValueThree },
  { label: billingDetailFour, value: billingValueFour },
  { label: billingDetailFive, value: billingValueFive },
];





const formatDate = (timestamp) => {
  if (!timestamp || isNaN(timestamp)) {
      return "N/A"; // Handle null, undefined, or invalid timestamps
  }

  return new Date(timestamp * 1000).toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
  });
};


const formatCurrency = (amount) => {
  if (typeof amount !== "number" || isNaN(amount)) {
      return "$0.00"; // Handle invalid values
  }

  return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
  });
};

// Example Usage
const upcomingInvoiceDate = formatDate(paymentDetails?.upcomingPayment?.timestamp);
const lastPaymentDate = formatDate(paymentDetails?.lastPayment?.timestamp);
const lastPaymentAmount = formatCurrency(paymentDetails?.lastPayment?.amount);
const upcomingPaymentAmount = formatCurrency(paymentDetails?.upcomingPayment?.amount);
const cancelAtDate = formatDate(paymentDetails?.cancelAtDate);
const scheduledStartDate = formatDate(paymentDetails?.scheduledStartDate);



const handleShowNewSubscription = () => {
  setShowNewSubscription(true);
};

const handleNewSubscriptionClose = () => {
  setShowNewSubscription(false);
};

const handleCancelSubscriptionOpen = () => {
  setShowCancelDialog(true);
}

const handleCancelDialogClose = () => {
  setShowCancelDialog(false);
}


const handlePauseSubscriptionOpen = () => { 
  setShowPauseDialog(true);
}

const handlePauseDialogClose = () => {
  setShowPauseDialog(false);
}


const handleDeleteDialogOpen = () => {  
  setShowDeleteDialog(true);
};



const handleDeleteDialogClose = () => { 
  setShowDeleteDialog(false);
};


const handleSubscriptionChange = () => {

  if (subscriptionStatus === "inactive") {
    setShowNewSubscription(true);
  } else if (subscriptionStatus === "active") {
    if (paymentDetails?.pendingCancel) {
      setShowCancelDialog(true);
      // Handle pending cancellation logic here
    } else {
      setShowCancelDialog(true);
    }
  }
};





  return (
    <Fade in={!loadingPaymentDetails} timeout={200}>
   <Box 
                sx={{  
                  flexGrow: 1, 
                  width: "100%",  
                  minWidth: "100%",  
                  flexGrow: 1, 
                  overflowY: "auto", 
           
                  width: "100%",
                  
                }}
              >      
<Stack direction="row" sx={{ width: "100%", flexWrap: "nowrap",  }}>
<Box sx={{ width: "50%", display: "flex", pl:2,flexDirection: "column", overflowX:'auto',   pr:4,  }}>


<Stack direction="column" sx={{}}>
<Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                    }}
                  >
                    Manage Your Subscription
                  </Typography>
                  <Typography
                    align="left"
                    sx={{
                      fontSize: "14.5px",
                      fontWeight: 550,
                      color: "#6c90a2",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                    }}
                  >
                    Cancel or pause your subscription at any time.
                  </Typography>
                  </Stack>
                


                <Divider sx={{ borderBottomWidth: "1.5px", mb:1.5, mt:1 }} />
                <Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                    }}
                  >
                   {planDetails?.plan_name}
                  </Typography>

                


                  <Stack direction="row" sx={{ alignItems: "center", mt: 0.75 }}>
  <Typography
    align="left"
    sx={{
      fontSize: "15px",
      fontWeight: 550,
      color: "#6c90a2",
      letterSpacing: "-.2px",
      lineHeight: "1.2", // Adjusted to a valid value
    }}
  >
    Subscription Status:
  </Typography>

  {paymentDetails?.pendingCancel ? (
  <Typography
    align="left"
    sx={{
      fontSize: "15px",
      fontWeight: 550,
      color: "#8C4350BD",
      letterSpacing: "-.2px",
      lineHeight: "1.2",
      ml: 0.5,
    }}
  >
    {paymentDetails?.scheduledStart ? "Reactivation Scheduled" : "Pending Cancellation"}
  </Typography>
  ) : (
    <Typography
      align="left"
      sx={{
        fontSize: "15px",
        fontWeight: 550,
        color: subscriptionStatus === "inactive" ? "#8C4350BD" : "#48B09B",
        letterSpacing: "-.2px",
        lineHeight: "1.2",
        ml: 0.5,
      }}
    >
      {subscriptionDetails?.subscriptionStatus
        ? subscriptionDetails.subscriptionStatus?.charAt(0).toUpperCase() +
          subscriptionDetails.subscriptionStatus?.slice(1)
        : ""}
    </Typography>
  )}
</Stack>
                


                <Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap", 
                      mt: 3,
                    }}
                  >
                    Make Changes to Your Subscription
                  </Typography>
                 
                 
                 
{subscriptionStatus === "active" && !paymentDetails?.pendingCancel &&  (
                  <ButtonBase
                  onClick={handlePauseSubscriptionOpen}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          px: 2,
          py: 1,
          borderRadius: "8px",
          transition: "background 0.3s ease, transform 0.2s ease",
          "&:hover": {
            backgroundColor: "rgba(108, 144, 162, 0.1)", // Subtle hover effect
            transform: "scale(1.02)", // Slight scaling for interaction feedback
          },
        }}
      >
        <Typography
          align="left"
          sx={{
            fontSize: "15px",
            fontWeight: 550,
            color: "#6c90a2",
            letterSpacing: "-.2px",
            ml:-2,

          }}
        >
          Pause my subscription
        </Typography>
      </ButtonBase>
      )}


{paymentDetails?.pendingCancel || paymentDetails?.scheduledStart ? (
  <>
    <Typography
      align="left"
      sx={{
        fontSize: "15px",
        fontWeight: 600,
        color: "#6c90a2",
        letterSpacing: "-.2px",
        lineHeight: "1.4",
        mt: 0.75,
        mb: 0.75,
      }}
    >
      {paymentDetails.scheduledStart ? (
        <>Your subscription is still active but is scheduled to pause on {cancelAtDate}. It will automatically reactivate again on {scheduledStartDate}.</>
      ) : (
        <>Your subscription is still active but is pending cancellation. Your subscription will no longer be active on {cancelAtDate}.</>
      )}
    </Typography>

   <ButtonBase
   onClick={handleSubscriptionChange}
     sx={{
       display: "flex",
       alignItems: "center",
       justifyContent: "flex-start",
     width: "100%",
       py: 1,
       borderRadius: "8px",
       mt:.25,
       transition: "background 0.3s ease, transform 0.2s ease",
       "&:hover": {
         backgroundColor: "rgba(108, 144, 162, 0.1)",
         transform: "scale(1.02)",
       },
     }}
   >
     <Typography
       align="left"
       sx={{
         fontSize: "15px",
         fontWeight: 550,
         color: "#6c90a2",
         letterSpacing: "-.2px"
         
       }}
     >
Keep my subscription active
</Typography>
   </ButtonBase>
   </>
  ) : (

      <ButtonBase
      onClick={handleSubscriptionChange}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          px: 2,
          py: 1,
          borderRadius: "8px",
          transition: "background 0.3s ease, transform 0.2s ease",
          "&:hover": {
            backgroundColor: "rgba(108, 144, 162, 0.1)",
            transform: "scale(1.02)",
          },
        }}
      >
        <Typography
          align="left"
          sx={{
            fontSize: "15px",
            fontWeight: 550,
            color: "#6c90a2",
            letterSpacing: "-.2px",
            ml:-2,
          }}
        >
         {subscriptionStatus === "inactive" ? " Activate my subscription" : " Cancel my subscription"}   
        </Typography>
      </ButtonBase>
      )}


  <Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap", 
                      mt: 2,
                    }}
                  >
                    Make Changes to Your Account
                  </Typography>
                 
                 
                 
                  <ButtonBase
                  onClick={handleDeleteDialogOpen}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          px: 2,
          py: 1,
          mt:.25,
          borderRadius: "8px",
          transition: "background 0.3s ease, transform 0.2s ease",
          "&:hover": {
            backgroundColor: "rgba(108, 144, 162, 0.1)",
            transform: "scale(1.02)",
          },
        }}
      >
  <Typography
    align="left"
    sx={{
      fontSize: "15px",
      fontWeight: 550,
      color: "#8C4350BD",
      letterSpacing: "-.2px",
      lineHeight: "-2",
      ml:-2,

    }}
  >
   Delete my account
  </Typography>
  </ButtonBase>




                


            </Box>
            


                  {/* Right Container */}


            <Paper elevation={5} sx={{ width: "50%", display: "flex", pl: .75,flexDirection: "column", overflowX:'auto',   pr:4,
                      border: "1px solid rgba(173, 216, 230, 0.7)", padding:2, mx:4, my:.75, borderRadius:'10px',pb:5,
                      height:'100%'


             }}>
<Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                    }}
                  >
               Subscription Payment Schedule
                  </Typography>

                


                <Divider sx={{ borderBottomWidth: "1.5px", mb:1.5, mt:1 }} />
                <Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                      mt:.5,

                    }}
                  >
                    Last Payment Received
                  </Typography>

                
                  <Stack direction="row" sx={{ alignItems: "center" }}>
  <Typography
    align="left"
    sx={{
      fontSize: "15px",
      fontWeight: 550,
      color: "#6c90a2",
      letterSpacing: "-.2px",
      lineHeight: "-2",
      whiteSpace: "nowrap", // Prevent wrapping
    }}
  >
{lastPaymentDate}  </Typography>
  
  <Divider 
  sx={{
    flexGrow: 1,          // Takes up remaining width
    borderBottomWidth: 1.5,
    borderBottomStyle: "dashed",  // Change to "dashed" style
    borderColor: "#6c90a2",
    opacity: 0.5,
    mt: "12px", 
    ml: 1,
    mx: 1
  }} 
/>
    <Typography
    align="left"
    sx={{
      fontSize: "15px",
      fontWeight: 550,
      color: "#6c90a2",
      letterSpacing: "-.2px",
      lineHeight: "-2",
      whiteSpace: "nowrap", // Prevent wrapping
      ml: 1,
      mr:1,
    }}
  >
{lastPaymentAmount}
  </Typography>
</Stack>

<Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                      mt:2,
                    }}
                  >
                    Your Upcoming Payment
                  </Typography>

                
                  <Stack direction="row" sx={{ alignItems: "center" }}>
  <Typography
    align="left"
    sx={{
      fontSize: "15px",
      fontWeight: 550,
      color: "#6c90a2",
      letterSpacing: "-.2px",
      lineHeight: "-2",
      whiteSpace: "nowrap", // Prevent wrapping
    }}
  >
   {upcomingInvoiceDate}  
  </Typography>
  
  <Divider 
  sx={{
    flexGrow: 1,          // Takes up remaining width
    borderBottomWidth: 1.5,
    borderBottomStyle: "dashed",  // Change to "dashed" style
    borderColor: "#6c90a2",
    opacity: 0.5,
    mt: "12px", 
    ml: 1,
    mx: 1
  }} 
/>
    <Typography
    align="left"
    sx={{
      fontSize: "15px",
      fontWeight: 550,
      color: "#6c90a2",
      letterSpacing: "-.2px",
      lineHeight: "-2",
      whiteSpace: "nowrap", // Prevent wrapping
      ml: 1,
      mr:1,
    }}
  >
   {upcomingPaymentAmount}
  </Typography>
</Stack>  
                 
             

                


            </Paper>

</Stack>  
<Elements stripe={stripePromise}>

<NewSubscription
                open={showNewSubscription}
                onClose={handleNewSubscriptionClose}
                customerId={customerId}
                priceId={priceId}
                onSubscriptionUpdate={onSubscriptionUpdate}
                planDetails={planDetails}
                stripePromise={stripePromise}
                subscriptionId={subscriptionId}
                billingAddress={billingAddress}
                subscriptionDetails={subscriptionDetails}
                billingDetailOne={billingDetailOne}
                billingDetailTwo={billingDetailTwo}
                billingDetailThree={billingDetailThree}
                billingDetailFour={billingDetailFour}
                billingDetailFive={billingDetailFive}
                billingValueOne={billingValueOne}
                billingValueTwo={billingValueTwo}
                billingValueThree={billingValueThree}
                billingValueFour={billingValueFour}
                billingValueFive={billingValueFive}

                planCost={planCost}
                totalPlanCost={totalPlanCost}
                promotion={promotion}
                discountName={discountName}
                discountPercentage={discountPercentage}
                discountedPlanCost={discountedPlanCost}
              />
              <ConfirmDialog
                open={showCancelDialog}
                onClose={handleCancelDialogClose}
                buttonAction={paymentDetails?.pendingCancel ? handleKeepClick : handleCancelClick}                
                isLoading={isCancelling || isKeeping}
                confirmHeader={paymentDetails?.pendingCancel ? "Keep Subscription Active" : "Cancel Your Subscription"}
                confirmMessage={paymentDetails?.pendingCancel ? "Are you sure you want to keep your subscription active?" : "Are you sure you want to cancel your subscription?"}
                confirmMessageSubText={paymentDetails?.pendingCancel ? `You will be automatically charged on your next renewal date` : `Your subscription will automatically cancel at the end of your current billing cycle.`}
                confirmMessageSubTextTwo={paymentDetails?.pendingCancel ? ` Your subscription will renew on ${cancelAtDate}` : `Your subscription will end on ${upcomingInvoiceDate}`}
                confirmButtonText={paymentDetails?.pendingCancel ? "Keep My Subscription" : "Cancel My Subscription"}
                accept = {paymentDetails?.pendingCancel ? true : false}
                acceptIcon = {RestartAltIcon}
                declineIcon = {DisabledByDefaultRoundedIcon}
                buttonLoadingText={isKeeping || isCancelling ? (isKeeping ? "Renewing Your Subscription..." : "Cancelling Subscription...") : ""}

                />
                <PauseSubscriptionModal
                open={showPauseDialog}
                onClose={handlePauseDialogClose}
                customerId={customerId}
                priceId={priceId}
                subscriptionStatus={subscriptionStatus}
                upcomingInvoiceDate={upcomingInvoiceDate}
                subscriptionDetails={subscriptionDetails}
                paymentDetails={paymentDetails}
                formatDate={formatDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                handlePauseClick={handlePauseClick}
                isPausing={isPausing}
                />

                <DeleteAccountModal
                open={showDeleteDialog}
                onClose={handleDeleteDialogClose}
                subscriptionStatus={subscriptionStatus}
                accountData={accountData}
                customerId={customerId} 
                userId={userId} 
                  />

              </Elements>
    </Box>
    </Fade>
  );
};

export default ManageSubscription;