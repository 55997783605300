import React, { useState, useEffect } from "react";
import { useAppContext } from "src/context/AppContext";
import useAddLocation from "src/components/payments/hooks/useAddLocation";
import useAddNewUser from "src/components/payments/hooks/useAddNewUser.js";
import usePlanDetails from "src/components/payments/hooks/usePlanDetails";
import {
  Button,
  Box,
  Fade,
  Stack,
  Typography,
  Divider,
  Dialog,
  Paper,
  IconButton,
} from "@mui/material";
import CreditCard from "src/public/assets/icons/icons/creditCard.png";
import { useGeneratePlanDetails } from "../billingComponents/useGeneratePlanDetails";

import { useAuth0 } from "@auth0/auth0-react";
import { useAccountContext } from "src/context/AccountContext";
import { useCalculateChanges } from "src/components/AccountPage/AccountManagementComponents/useCalculateChanges.js";
import CancelCloseDialog from "./CancelCloseDialog";
import CloseIcon from "@mui/icons-material/Close";

const ReviewDialog = ({
  open,
  onClose,
  planDetails,
  subscriptionDetails,
  isLoading,

  addedUser = false, // Default to false
  addedLocation = false,
  deletedUser = false,
  deletedLocation = false,
  upgradedPlan = false,
  downgradedPlan = false,
  label,
  value,
  labelTwo,
  valueTwo,
  buttonAction,
  buttonLoadingText,
  buttonText,
  handleCancel,
  
}) => {
  const { accountData } = useAccountContext();

  const {
    totalUsers,
    totalLocations,
    additionalUserCost,
    includedUser,
    additionalLocationCost,
    totalNewCost,
    planName,
    newPlanName,
    planCost,
    planDifference,
    newPlanCost,
    isMultiUser,
    isMultiLocation,
    additionalUserIncrease,
    oldPlanCost,
    includedLocation,
    includedPlanUser
  } = useCalculateChanges({
    accountData,
    addedUser,
    addedLocation,
    deletedUser,
    deletedLocation,
    upgradedPlan,
    downgradedPlan,
  });

  const {
    detailOne,
    detailTwo,
    detailThree,
    billingDetailOne,
    billingDetailTwo,
    billingDetailThree,
    billingDetailFour,
    billingDetailFive,
    billingValueOne,
    billingValueTwo,
    billingValueThree,
    billingValueFour,
    billingValueFive,
    totalPlanCost,
    loadingPlanDetails,
    subscriptionStatus,
    totalUserCost,
    promotion,
    discountName,
    discountPercentage,
    couponExpires,
  } = useGeneratePlanDetails(planDetails, subscriptionDetails);


  const billingDetails = [
    { label: billingDetailOne, value: billingValueOne },

    { label: billingDetailTwo, value: billingValueTwo },
    { label: billingDetailThree, value: billingValueThree },
    { label: billingDetailFour, value: billingValueFour },
    { label: billingDetailFive, value: billingValueFive },

    downgradedPlan && isMultiUser && { label: "Total Locations", value: 1 },
  ];



  const updatedBillingDetails = billingDetails.map((detail) => {
    if (detail.label === "Total Users:") {
      let updatedValue = parseInt(detail.value) || 0;
      if (addedUser) updatedValue += 1;
      if (deletedUser) updatedValue -= 1;
      if (addedLocation) updatedValue += 0;
      return { ...detail, value: updatedValue };
    }

    if (detail.label === "Total Locations:") {
      let updatedValue = parseInt(detail.value) || 0;
      if (addedLocation) updatedValue += 1;
      if (deletedLocation) updatedValue -= 1;

      return { ...detail, value: updatedValue };
    }

    if (detail.label === "Additional Monthly User Cost") {
      let effectiveTotalUsers = parseInt(totalUsers, 10) || 0;
      let effectiveTotalLocations = parseInt(totalLocations, 10) || 0;

      // Apply changes based on added or deleted users/locations
      if (addedUser) effectiveTotalUsers += 1;
      if (deletedUser) effectiveTotalUsers -= 1;
      if (deletedLocation) effectiveTotalLocations -= 1;
      if (addedLocation) effectiveTotalLocations += 1;

      // Determine additional user cost
      let additionalUserCost = 0;

      if (isMultiUser) {
        if (effectiveTotalUsers > 2 && !downgradedPlan) {
          additionalUserCost = (effectiveTotalUsers - 2) * 5;
        }

        if (downgradedPlan) {
          additionalUserCost = 0;
        }

      } else if (isMultiLocation) {
        let freeUsers = effectiveTotalLocations; // Each location gets one free user

        if (effectiveTotalUsers > freeUsers && accountData.total_employees < 1) {
          additionalUserCost = (effectiveTotalUsers - freeUsers) * 5;
        }
        if (effectiveTotalUsers > freeUsers && accountData.total_employees === 1) {
          additionalUserCost = 0;
        }

      }

      if (deletedLocation && effectiveTotalUsers > effectiveTotalLocations) {
        additionalUserCost =
          (effectiveTotalUsers - effectiveTotalLocations) * 5;
      }

      if (downgradedPlan && isMultiLocation) {
        additionalUserCost = (effectiveTotalUsers - 2) * 5;
      }

      // Return the calculated additional user cost in the desired format
      return {
        ...detail,
        value: `$${additionalUserCost.toFixed(2)}`,
      };
    }

    if (detail.label === "Additional Monthly Location Cost") {
      let additionalLocationCost = 0;
      let effectiveTotalLocations = parseInt(totalLocations, 10) || 0;
      if (deletedLocation) effectiveTotalLocations -= 1;

      if (isMultiLocation) {
        if (totalLocations > 2 && !deletedLocation) {
          additionalLocationCost = (totalLocations - 2) * 10;
        }


        
        if (deletedLocation && effectiveTotalLocations > 2) {
          additionalLocationCost = (effectiveTotalLocations - 2) * 10;
        }
      }

      return { ...detail, value: `$${additionalLocationCost.toFixed(2)}` };
    }

    return detail;
  });






// Round UP the plan cost from cents to the nearest dollar
const roundedPlanCost = Math.ceil(planDetails?.plan_cost / 100);

// Add the new cost
const totalUpdatedPlanCost = roundedPlanCost + totalNewCost;

// Format as USD currency (ensuring ".00" at the end)
const formattedTotalUpdatedPlanCost = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2, // Ensure ".00" at the end
  maximumFractionDigits: 2
}).format(totalUpdatedPlanCost);




  const filteredBillingDetails = updatedBillingDetails.filter(
    (detail) =>
      !(
        (detail.label === "Additional Monthly Location Cost" &&
          (downgradedPlan || !isMultiLocation || isMultiUser)) ||
        (detail.label === "Additional Monthly User Cost" &&
          downgradedPlan &&
          isMultiUser) ||
        (downgradedPlan && totalUsers < 2)
      ),
  );

  const formatCurrency = (amount) => {
    if (typeof amount !== "number" || isNaN(amount)) {
      return "$0.00"; // Handle invalid values
    }

    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };





  const planChanges = [
    addedLocation && {
      label: "Total Locations Added",
      value: 1,
      isCost: false,
    },
    deletedLocation &&
      !downgradedPlan && {
        label: "Locations Deleted",
        value: 1,
        isCost: false,
      },

    additionalLocationCost &&
      !downgradedPlan && {
        label: "Additional Location Cost",
        value: additionalLocationCost,
        isCost: true,
      },

    planDifference && {
      label: "Plan Difference",
      value: planDifference,
      isCost: true,
    },
    totalNewCost && {
      label: "Monthly cost",
      value: totalNewCost,
      isCost: true,
    },

    deletedUser &&
      !downgradedPlan &&
      !isMultiUser &&
      !additionalUserCost &&
      !includedUser && {
        label: "Users Deleted",
        value: 1,
        isCost: false,
      },
    addedUser && {
      label: "Users Added",
      value: 1,
      isCost: false,
    },
    deletedUser && isMultiUser &&   !downgradedPlan &&
    totalNewCost < 0 && {
      label: "Users Deleted",
      value: 1,
      isCost: false,
    },
    deletedUser &&
      totalNewCost < 0 &&  {
        label: "Additional User Cost",
        value: totalNewCost,
        isCost: true,
      },


    deletedUser &&
      includedUser &&
      !downgradedPlan && {
        label: "Included User w/ Location Removed",
        value: 1,
        isCost: false,
      },
    deletedUser &&
      includedUser &&
      !downgradedPlan && {
        label: "Additional User Cost (Included With Location)",
        value: "$0.00",
        isCost: false,
      },

    additionalUserCost > 0 && !downgradedPlan
      ? {
          label: "Additional User Cost",
          value: additionalUserCost,
          isCost: true,
        }
      : addedLocation &&
        includedUser  && {
          label: "Additional User Cost (Included With Location)",
          value: "$0.00",
          isCost: false,
        },
    isMultiLocation &&
      addedUser &&
      includedUser &&
      !addedLocation && {
        label: "Additional User Cost (Included With Location)",
        value: "$0.00",
        isCost: false,
      },
      isMultiLocation &&
      addedUser &&
      includedPlanUser &&
      !addedLocation && {
        label: "Additional User Cost (Included With Plan)",
        value: "$0.00",
        isCost: false,
      },
      isMultiLocation &&
      addedLocation &&
      includedLocation &&

           {
        label: "Additional Location Cost (Included With Plan)",
        value: "$0.00",
        isCost: false,
      },
    downgradedPlan && { label: "Removed Plan", value: "", isCost: false },
    downgradedPlan && { label: planName, value: oldPlanCost, isCost: true },
    downgradedPlan && { label: "Added Plan", value: "", isCost: false },
    downgradedPlan && { label: newPlanName, value: newPlanCost, isCost: true },
  ].filter(Boolean); // Remove falsy values

  const totalCost = planChanges
    .filter((change) => change.isCost)
    .reduce((sum, change) => sum + change.value, 0);

  const formattedTotalCost = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalNewCost);
  const formattedPlanCost = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(newPlanCost);

  const newCost =
    downgradedPlan && isMultiUser
      ? planCost // If downgrading from multi-user, only charge the base plan cost
      : totalPlanCost + totalNewCost;

  // Apply discount if applicable
  const discountedPlanCost =
    promotion && discountPercentage
      ? newCost * (1 - discountPercentage / 100)
      : newCost;

  const [openCancelCloseDialog, setOpenCancelCloseDialog] = useState(false);
  const [cancelCloseMessage, setCancelCloseMessage] = useState("");
  const [cancelCloseSubText, setCancelCloseSubText] = useState("");
  const [cancelClosebuttonText, setCancelCloseButtonText] = useState("");

  const handleOpenCancelCloseDialog = () => {
    setOpenCancelCloseDialog(true);
  };

  const handleCloseCancelCloseDialog = () => {
    if (addedUser || addedLocation) {
      setOpenCancelCloseDialog(false);
      onClose();
    } else {
      setOpenCancelCloseDialog(false);
    }
  };

  useEffect(() => {
    if (addedUser && addedLocation) {
      setCancelCloseMessage("Are you sure you want to close?");
      setCancelCloseSubText(
        "Your location will not be created and your subscription will not be changed",
      );
      setCancelCloseButtonText("Go Back & Edit Location");
    }
    if (addedUser && !addedLocation) {
      setCancelCloseMessage("Are you sure you want to close?");
      setCancelCloseSubText(
        "Your user will not be added and your subscription will not be changed",
      );
      setCancelCloseButtonText("Go Back & Edit User");
    }
    if (addedLocation) {
      setCancelCloseMessage("Are you sure you want to close?");
      setCancelCloseSubText(
        "Your location will not be created and your subscription will not be changed",
      );
      setCancelCloseButtonText("Go Back & Edit Location");
    }
    if (deletedUser) {
      setCancelCloseMessage("Are you sure you want to close?");
      setCancelCloseSubText(
        "Your user will not be deleted and your subscription will not be changed",
      );
      setCancelCloseButtonText("Review Changes and Delete User");
    }
    if (deletedLocation) {
      setCancelCloseMessage("Are you sure you want to close?");
      setCancelCloseSubText(
        "Your location will not be deleted and your subscription will not be changed",
      );
      setCancelCloseButtonText("Review Changes and Delete Location");
    }
    if (downgradedPlan) {
      setCancelCloseMessage("Are you sure you want to close?");
      setCancelCloseSubText(
        "Your changes will not be saved and your subscription type will remain the same",
      );
      setCancelCloseButtonText("Review Changes and Downgrade Subscription");
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={
        isLoading
          ? null
          : () => {
              handleOpenCancelCloseDialog();
            }
      }
      PaperProps={{
        sx: {
          borderRadius: "10px",
          backgroundColor: "#FFFFFF",
          minWidth: "1050px",
          minHeight: "500px",
          padding: 3,
          maxHeight: "570px",
          overflowY: "hidden",
        },
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: "100%",
          flexWrap: "nowrap",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          pl: 0.75,
          pt: 0.5,
          mb: 3,
        }}
      >
        <img
          src={CreditCard}
          alt="Credit Card"
          style={{
            border: "1.25px solid rgba(173, 216, 230, 0.7)",
            width: "auto",
            height: "29px",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            padding: "1px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            display: "flex",
          }}
        />
        <Typography
          align="left"
          sx={{
            fontSize: "20px",
            fontWeight: 550,
            color: "#48768C",
            letterSpacing: "-.027em",
            whiteSpace: "nowrap",
            mt: 1.5,
            pr: 1,
          }}
        >
          Review Changes To Your Subscription
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 7,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>
      </Stack>

      <Stack direction="row" sx={{ width: "100%", flexWrap: "nowrap" }}>
        <Box
          sx={{
            width: "100%",

            flexGrow: 1,
            overflowY: "auto",
            display: "flex",
          }}
        >
          <Stack direction="row" sx={{ width: "100%", flexWrap: "nowrap" }}>
            <Fade in={!loadingPlanDetails} timeout={1000}>
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  pl: 0.75,
                  pt: 1,
                  flexDirection: "column",
                  overflowX: "auto",
                  my: 0.5,
                }}
              >
                <Typography
                  align="left"
                  sx={{
                    fontSize: "18.5px",
                    fontWeight: 550,
                    color: "#48768C",
                    letterSpacing: "-.02em",
                    whiteSpace: "nowrap",
                  }}
                >
                  Your New Subscription Summary
                </Typography>
                <Divider sx={{ borderBottomWidth: "1.5px", mb: 1.5, mt: 1 }} />

                <Typography
                  align="left"
                  sx={{
                    fontSize: "18.5px",
                    fontWeight: 550,
                    color: "#48768C",
                    letterSpacing: "-.02em",
                    whiteSpace: "nowrap",
                    mt: 1,
                  }}
                >
                  Monthly Plan Cost
                </Typography>

                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <Typography
                    align="left"
                    sx={{
                      fontSize: "15px",
                      fontWeight: 550,
                      color: "#6c90a2",
                      letterSpacing: "-.2px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {newPlanName || planName || "Plan Name Not Found"}
                  </Typography>

                  <Divider
                    sx={{
                      flexGrow: 1,
                      borderBottomWidth: 1.5,
                      borderBottomStyle: "dashed",
                      borderColor: "#6c90a2",
                      opacity: 0.5,
                      mt: "12px",
                      ml: 1,
                      mx: 1,
                    }}
                  />
                  <Typography
                    align="left"
                    sx={{
                      fontSize: "15px",
                      fontWeight: 550,
                      color: "#6c90a2",
                      letterSpacing: "-.2px",
                      whiteSpace: "nowrap",
                      ml: 1,
                      mr: 1,
                    }}
                  >
                    {planCost ? `$${planCost}.00` : "$0.00"}
                  </Typography>
                </Stack>

                <Box>
                  {billingDetailOne && (
                    <Typography
                      align="left"
                      sx={{
                        fontSize: "18.5px",
                        fontWeight: 550,
                        color: "#48768C",
                        letterSpacing: "-.02em",
                        mt: 2,
                      }}
                    >
                      {downgradedPlan && isMultiUser
                        ? "Account Summary"
                        : "Additional Charges"}
                    </Typography>
                  )}

                  {filteredBillingDetails.map((detail, index) => (
                    <Stack
                      direction="row"
                      sx={{ alignItems: "center" }}
                      key={index}
                    >
                      <Typography
                        align="left"
                        sx={{
                          fontSize: "15px",
                          fontWeight: 550,
                          color: "#6c90a2",
                          letterSpacing: "-.2px",
                        }}
                      >
                        {detail.label}
                      </Typography>
                      <Divider
                        sx={{
                          flexGrow: 1,
                          borderBottomWidth: 1.5,
                          borderBottomStyle: "dashed",
                          borderColor: "#6c90a2",
                          opacity: 0.5,
                          mt: "12px",
                          ml: 1,
                          mx: 1,
                          display: detail.label ? "block" : "none",
                        }}
                      />
                      <Typography
                        align="left"
                        sx={{
                          fontSize: "15px",
                          fontWeight: 550,
                          color: "#6c90a2",
                          letterSpacing: "-.2px",
                          ml: 1,
                          mr: 1,
                        }}
                      >
                        {detail.value}
                      </Typography>
                    </Stack>
                  ))}
                </Box>

                {promotion && (
                  <Stack direction="row" sx={{ alignItems: "center" }}>
                    <Typography
                      align="left"
                      sx={{
                        fontSize: "15px",
                        fontWeight: 550,
                        color: "#6c90a2",
                        letterSpacing: "-.2px",
                      }}
                    >
                      {discountName || "Discount Name"}
                    </Typography>
                    <Divider
                      sx={{
                        flexGrow: 1,
                        borderBottomWidth: 1.5,
                        borderBottomStyle: "dashed",
                        borderColor: "#6c90a2",
                        opacity: 0.5,
                        mt: "12px",
                        ml: 1,
                        mx: 1,
                      }}
                    />
                    <Typography
                      align="left"
                      sx={{
                        fontSize: "15px",
                        fontWeight: 550,
                        color: "#6c90a2",
                        letterSpacing: "-.2px",
                        ml: 1,
                        mr: 1,
                      }}
                    >
                      {"-" + discountPercentage + "%" || "Discount Percentage"}
                    </Typography>
                  </Stack>
                )}
                {promotion && (
                  <Stack sx={{}}>
                    {[
                      { label: "Promotion:", value: discountName || "" },
                      {
                        label: "Promotion Expires:",
                        value: couponExpires || "",
                      },
                    ].map(({ label, value }) => (
                      <Stack
                        key={label}
                        direction="row"
                        sx={{ alignItems: "center" }}
                      >
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: 550,
                            color: "#6c90a2",
                            letterSpacing: "-.2px",
                          }}
                        >
                          {label}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: 550,
                            color: "#48768C",
                            letterSpacing: "-.2px",
                            ml: 0.5,
                          }}
                        >
                          {value}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                )}
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    width: "100%",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 2,
                    mb: 1,
                    pr: 1,
                    pb: promotion ? 2 : 0,
                  }}
                >
                  <Typography
                    align="left"
                    sx={{
                      fontSize: "20px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                    }}
                  >
                    Your New Monthly Cost
                  </Typography>

                  <Typography
                    align="left"
                    sx={{
                      fontSize: "20px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                      mr: 3,
                      mb: 1,
                    }}
                  >
                    {promotion
                      ? formatCurrency(discountedPlanCost)
                      : formattedTotalUpdatedPlanCost}
                  </Typography>
                </Stack>
              </Box>
            </Fade>
          </Stack>
        </Box>

        {/* Right Container */}

        <Paper
          elevation={5}
          sx={{
            width: "100%",
            display: "flex",
            pl: 0.75,
            flexDirection: "column",
            overflowX: "auto",

            pr: 4,
            border: "1px solid rgba(173, 216, 230, 0.7)",
            padding: 2,

            borderRadius: "10px",

            py: 2,
            mt: -0.5,

            maxHeight: downgradedPlan
              ? "295px"
              : (addedLocation && addedUser) || additionalUserIncrease
                ? "300px"
                : "245px",
          }}
        >
          <Typography
            align="left"
            sx={{
              fontSize: "18.5px",
              fontWeight: 550,
              color: "#48768C",
              letterSpacing: "-.02em",
              lineHeight: "-2",
              whiteSpace: "noWrap",
            }}
          >
            Review Changes To Your Monthly Cost
          </Typography>

          <Divider sx={{ borderBottomWidth: "1.5px", mb: 1.5, mt: 1.25 }} />

          {billingDetailOne && (
            <Typography
              align="left"
              sx={{
                fontSize: "18.5px",
                fontWeight: 550,
                color: "#48768C",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                whiteSpace: "noWrap",
                mt: 2,
              }}
            >
              Changes To Your Monthly Subscription
            </Typography>
          )}

          {planChanges
            ?.filter(
              (change) =>
                change.show !== false && change.label !== "Monthly cost",
            ) // Filter out the 'Monthly cost' entry
            .map((change, index) => (
              <Stack direction="row" sx={{ alignItems: "center" }} key={index}>
                {/* Billing detail */}
                {change.label && (
                  <Typography
                    align="left"
                    sx={{
                      fontSize:
                        change.label === "Added Plan" ||
                        change.label === "Removed Plan"
                          ? "16px"
                          : "15px",
                      fontWeight: 550,
                      color: "#6c90a2",
                      letterSpacing: "-.2px",
                      lineHeight: "-2",
                      mt:
                        change.label === "Added Plan" ||
                        change.label === "Removed Plan"
                          ? 1
                          : 0,
                    }}
                  >
                    {change.label}
                  </Typography>
                )}

                {/* Divider (Only shows if label exists) */}
                {change.label && change.value && (
                  <Divider
                    sx={{
                      flexGrow: 1,
                      borderBottomWidth: 1.5,
                      borderBottomStyle: "dashed",
                      borderColor: "#6c90a2",
                      opacity: 0.5,
                      mt: "12px",
                      mx: 1,
                    }}
                  />
                )}

                {/* Billing value */}
                <Typography
                  align="left"
                  sx={{
                    fontSize: "15px",
                    fontWeight: 550,
                    color: "#6c90a2",
                    letterSpacing: "-.2px",
                    lineHeight: "-2",
                    pt: "2px",
                    mx: 1,
                  }}
                >
                  {change.isCost
                    ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(change.value)
                    : change.value}
                </Typography>
              </Stack>
            ))}

          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,

              pr: 1,
            }}
          >
            <Typography
              align="left"
              sx={{
                fontSize: "20px",
                fontWeight: 550,
                color: "#48768C",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                whiteSpace: "noWrap",
              }}
            >
              {downgradedPlan
                ? "Your New Plan Cost"
                : " Your New Monthly Charges"}
            </Typography>

            <Typography
              align="left"
              sx={{
                fontSize: "20px",
                fontWeight: 550,
                color: "#48768C",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                whiteSpace: "noWrap",
                mr: 3,
                mb: 1,
              }}
            >
              {downgradedPlan ? formattedPlanCost : formattedTotalCost}
            </Typography>
          </Stack>
        </Paper>
        <CancelCloseDialog
          open={openCancelCloseDialog}
          onClose={handleCloseCancelCloseDialog}
          buttonAction={handleCancel}
          cancelCloseMessage={cancelCloseMessage}
          cancelCloseSubText={cancelCloseSubText}
          cancelClosebuttonText={cancelClosebuttonText}
        />
      </Stack>
      <Box sx={{ display: "flex", my: 1, mt: promotion ? 0 : 6 }}>
        <Button
          type="submit"
          disabled={isLoading}
          onClick={buttonAction}
          sx={{
            display: "flex",
            border: "1px solid #48768C",
            borderRadius: "6px",
            padding: "8px",
            color: "#48768C",
            backgroundColor: "#FFFFFF",
            alignItems: "center",

            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            width: "50%",
            height: "40px",
            mt: downgradedPlan
              ? -0.25
              : (addedLocation && addedUser) || additionalUserIncrease
                ? -5
                : -6,
            mb: 3,
            ml: "auto",
            mr: 0.75,

            "&:hover": {
              backgroundColor: "#DBEAF0",
            },
            "&:active": {
              backgroundColor: "#DBEAF0",
            },
          }}
          color="primary"
        >
          {isLoading ? buttonLoadingText : buttonText}
        </Button>
      </Box>
    </Dialog>
  );
};

export default ReviewDialog;
