import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
  Stack,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";
import bugIcon from "../../../../public/assets/icons/navbar/bugSmall.png";
import styled from "@mui/system/styled";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import AffectedAreasForm from "./Forms/AffectedAreasForm";
import BugDescriptionForm from "./Forms/BugDescriptionForm";
import SeverityForm from "./Forms/SeverityForm";
import StepsToReproduceForm from "./Forms/StepsToReproduceForm";
import BugReportStepper from "./BugReportStepper";


const apiUrl = process.env.REACT_APP_API_URL;



const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#E9EDF2",
  marginBottom: theme.spacing(2),
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -1.5px) scale(0.75)",
    paddingLeft: "7px", // Adjust the translateY value as needed
  },
}));

const SeverityFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 400,
  marginBottom: theme.spacing(2),
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -3.5px) scale(0.79)",
    paddingLeft: "10px",
    paddingTop: "5px", // Adjust the translateY value as needed
  },
}));

const BugReportDialog = ({ isOpen, onClose, onSubmit }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const [bugDetails, setBugDetails] = useState({
    bugType: "",
    description: "",
    severity: "",
    stepsToReproduce: "",
    UserId: user.sub,
  });


  useEffect(() => {
    if (isOpen) {
      setBugDetails({
        bugType: "",
        description: "",
        severity: "",
        stepsToReproduce: "",
      });
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBugDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to submit a bug report
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const accessToken = await getAccessTokenSilently(); // Get access token silently

      const report = {
        bugType: bugDetails.bugType,
        description: bugDetails.description,
        severity: bugDetails.severity,
        stepsToReproduce: bugDetails.stepsToReproduce,
        userId: user.sub,
      };

      const response = await fetch(`${apiUrl}/service/bug-report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Add authorization header with access token
        },
        body: JSON.stringify(report),
      });

      if (!response.ok) {
        showSnackBar("Failed to submit bug report. Please try again", false);
        handleReloadComponents();
        setIsLoading(false);


        throw new Error("Failed to submit bug report");
      }

      const data = await response.json();
      setBugDetails({
        bugType: "",
        description: "",
        severity: "",
        stepsToReproduce: "",
      });
      showSnackBar("Bug report submitted successfully", true);
      handleReloadComponents();
      setIsLoading(false);
      onClose();
    } catch (error) {
      showSnackBar("Failed to submit bug report. Please try again", false);
      console.error("Error submitting bug report:", error);
      handleReloadComponents();
      setIsLoading(false);

    }
  };

  return (
    < Modal open={isOpen} onClose={onClose} >


<Box
  sx={{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '900px',
    // Ensures it doesn't shrink too much
    maxWidth: "1100px", // Prevents it from getting too large
    height: "45vh", // Adapts to content but can be adjusted
    minHeight: "410px", // Prevents excessive shrinking
    maxHeight: "90vh", // Ensures it doesn't exceed the viewport height
    bgcolor: "background.paper",
    boxShadow: 24,
    pt:0,
    pb: 2,
px:3,
    borderRadius: "12px",
  }}
>
      

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 6,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "37px",
            height: "37px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "90px",
            backgroundColor: "#FFFFFF",
            color: "#374247",
          }}
        >
          <Stack
            direction="row"
            sx={{
              width: "30%",
      display: "flex",
      alignItems: "flex-start", // Align to the top
      justifyContent: "flex-start", // Align to the top
      px: 1,
      mt:-1

         
       
            }}
          >
      
            <Typography
              sx={{ fontSize: '1.35rem', fontWeight: 600, marginRight: "auto", ml: .5, letterSpacing:'-0.034em', lineHeight: '1em', alignSelf: 'center',    color: "#48768C",
              }}
            >
              Report a Bug
            </Typography>
          </Stack>
        </Box>
      
  <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', height:'80%', overflowY:'auto', width:'100%'}}>
<BugReportStepper
bugDetails={bugDetails}
handleChange={handleChange}
handleSubmit={handleSubmit}
onClose={onClose}
isLoading={isLoading}
setBugDetails={setBugDetails}

/>



  </Box>
     </Box>
      </Modal>
  );
};

export default BugReportDialog;
