import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  Button,
  Stack,
  IconButton,
  Box,
  Chip,
  Avatar,
  Fade,
  Divider,
  Paper,
} from "@mui/material";
import { usePlanDetails } from "./useGeneratePlanDetails"; // Assuming this is the correct path

const ViewPlanDetails = ({ subscriptionDetails, planDetails,  detailOne,
  detailTwo,
  detailThree,
  billingDetailOne,
  billingDetailTwo,
  billingDetailThree,
  billingDetailFour,
  billingDetailFive,
  billingValueOne,
  billingValueTwo,
  billingValueThree,
  billingValueFour,
  billingValueFive,
  loadingPlanDetails,
  planCost,
  totalPlanCost,
  subscriptionStatus,
  promotion, 
  discountName,
  discountPercentage,
  couponExpires,
  discountedPlanCost, 

}) => {
  

  const billingDetails = [
    { label: billingDetailOne, value: billingValueOne },
    { label: billingDetailTwo, value: billingValueTwo },
    { label: billingDetailThree, value: billingValueThree },
    { label: billingDetailFour, value: billingValueFour },
    { label: billingDetailFive, value: billingValueFive },
  ];

  const promotionDetails = [
    { label: "Active promotion:", value: "Promotion name" },
    { label: "Promotion discount:", value: "Promotion discount" },
    { label: "Promotion expires on:", value: "Expires date" },
  ];


  const formatCurrency = (amount) => {
    if (typeof amount !== "number" || isNaN(amount)) {
        return "$0.00"; // Handle invalid values
    }

    return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    });
};



  return (
    <Fade in={!loadingPlanDetails} timeout={200}>
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          minWidth: "100%",
          flexGrow: 1,
          overflowY: "auto",

          width: "100%",
        }}
      >
        <Stack direction="row" sx={{ width: "100%", flexWrap: "nowrap" }}>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              pl: 2,
              flexDirection: "column",
              overflowX: "auto",
              pr: 4,
            }}
          >
            <Stack direction="column" sx={{}}>
              <Typography
                align="left"
                sx={{
                  fontSize: "18.5px",
                  fontWeight: 550,
                  color: "#48768C",
                  letterSpacing: "-.02em",
                  lineHeight: "-2",
                  whiteSpace: "noWrap",
                }}
              >
                Your Monthly Subscription
              </Typography>
              <Typography
                align="left"
                sx={{
                  fontSize: "14.5px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.02em",
                  lineHeight: "-2",
                  whiteSpace: "noWrap",
                }}
              >
                Your monthly subscription is based on your company and team
                size.
              </Typography>
            </Stack>

            <Divider sx={{ borderBottomWidth: "1.5px", mb: 1.5, mt: 1 }} />
            <Typography
              align="left"
              sx={{
                fontSize: "18.5px",
                fontWeight: 550,
                color: "#48768C",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                whiteSpace: "noWrap",
                mt: 1,
              }}
            >
              Your Subscription Details
            </Typography>

            <Stack direction="row" sx={{ alignItems: "center", mt:.5 }}>
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                }}
              >
                Your Plan:
              </Typography>
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#48768C",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                  ml: 0.5,
                }}
              >
                {planDetails?.plan_name}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ alignItems: "center", my:.5 }}>
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                }}
              >
                Subscription status:
              </Typography>
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color:
                    subscriptionStatus === "inactive" ? "#8C4350BD" : "#48B09B",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                  ml: 0.5,
                }}
              >
                {subscriptionDetails?.subscriptionStatus
                  ? subscriptionDetails.subscriptionStatus
                      .charAt(0)
                      .toUpperCase() +
                    subscriptionDetails.subscriptionStatus.slice(1)
                  : ""}
              </Typography>
            </Stack>

            {/* Promotion Details */}


            {promotion && (
  <Stack spacing={0.5}>
    {[
      { label: "Promotion:", value: discountName || "" },
      { label: "Monthly Discount:", value: discountPercentage ? `${discountPercentage}%` : "" },
      { label: "Promotion Expires:", value: couponExpires || "" },
    ].map(({ label, value }) => (
      <Stack key={label} direction="row" sx={{ alignItems: "center" }}>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: 550,
            color: "#6c90a2",
            letterSpacing: "-.2px",
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: 550,
            color: "#48768C",
            letterSpacing: "-.2px",
            ml: 0.5,
          }}
        >
          {value}
        </Typography>
      </Stack>
    ))}
  </Stack>
)}

            <Typography
              align="left"
              sx={{
                fontSize: "18.5px",
                fontWeight: 550,
                color: "#48768C",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                whiteSpace: "noWrap",
                mt: 2,
              }}
            >
              Plan Details
            </Typography>

            {[detailOne, detailTwo, detailThree].map((detail, index) => (
              <Typography
                key={index}
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                  my:'2px',
                  
                }}
              >
                {detail || ""}
              </Typography>
            ))}
          </Box>

          {/* Right Container */}

          <Paper
            elevation={5}
            sx={{
              width: "50%",
              display: "flex",
              pl: 0.75,
              flexDirection: "column",
              overflowX: "auto",
              my: 0.5,
              pr: 4,
              border: "1px solid rgba(173, 216, 230, 0.7)",
              padding: 2,
              mx: 4,
              my: 1,
              borderRadius: "10px",
            }}
          >
            <Typography
              align="left"
              sx={{
                fontSize: "18.5px",
                fontWeight: 550,
                color: "#48768C",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                whiteSpace: "noWrap",
              }}
            >
              Current Monthly Charges
            </Typography>

            <Divider sx={{ borderBottomWidth: "1.5px", mb: 1.5, mt: 1 }} />
            <Typography
              align="left"
              sx={{
                fontSize: "18.5px",
                fontWeight: 550,
                color: "#48768C",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                whiteSpace: "noWrap",
              }}
            >
              Monthly Plan Cost
            </Typography>

            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                  whiteSpace: "nowrap", // Prevent wrapping
                }}
              >
                {planDetails?.plan_name}
              </Typography>

              <Divider
                sx={{
                  flexGrow: 1, // Takes up remaining width
                  borderBottomWidth: 1.5,
                  borderBottomStyle: "dashed", // Change to "dashed" style
                  borderColor: "#6c90a2",
                  opacity: 0.5,
                  mt: "12px",
                  ml: 1,
                  mx: 1,
                }}
              />
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                  whiteSpace: "nowrap", // Prevent wrapping
                  ml: 1,
                  mr: 1,
                }}
              >
                ${planCost ? planCost + ".00" : "Unable to retrieve cost"}
              </Typography>
            </Stack>

            {billingDetailOne && (
              <Typography
                align="left"
                sx={{
                  fontSize: "18.5px",
                  fontWeight: 550,
                  color: "#48768C",
                  letterSpacing: "-.02em",
                  lineHeight: "-2",
                  whiteSpace: "noWrap",
                  mt: 2,
                }}
              >
                Additional Charges
              </Typography>
            )}

            {billingDetails.map((detail, index) => (
              <Stack direction="row" sx={{ alignItems: "center" }} key={index}>
                {/* Billing detail */}
                <Typography
                  align="left"
                  sx={{
                    fontSize: "15px",
                    fontWeight: 550,
                    color: "#6c90a2",
                    letterSpacing: "-.2px",
                    lineHeight: "-2",
                  }}
                >
                  {detail.label || ""}
                </Typography>

                {/* Divider */}
                <Divider
                  sx={{
                    flexGrow: 1, // Takes up remaining width
                    borderBottomWidth: 1.5,
                    borderBottomStyle: "dashed", // Change to "dashed" style
                    borderColor: "#6c90a2",
                    opacity: 0.5,
                    mt: "12px",
                    ml: 1,
                    mx: 1,
                    display: detail.label ? "block" : "none", // Only show if label is not empty
                  }}
                />

                {/* Billing value */}
                <Typography
                  align="left"
                  sx={{
                    fontSize: "15px",
                    fontWeight: 550,
                    color: "#6c90a2",
                    letterSpacing: "-.2px",
                    lineHeight: "-2",
                    pt: "2px",
                    ml: 1,
                    mr: 1,
                  }}
                >
                  {detail.value || ""}
                </Typography>
              </Stack>
            ))}

{promotion && (
            <Stack direction="row" sx={{ alignItems: "center" }}>
              {/* Billing detail */}
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                }}
              >
                {discountName}
              </Typography>

              {/* Divider */}
              <Divider
                sx={{
                  flexGrow: 1, // Takes up remaining width
                  borderBottomWidth: 1.5,
                  borderBottomStyle: "dashed", // Change to "dashed" style
                  borderColor: "#6c90a2",
                  opacity: 0.5,
                  mt: "12px",
                  ml: 1,
                  mx: 1,
                }}
              />

              {/* Billing value */}
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                  pt: "2px",
                  ml: 1,
                  mr: 1,
                }}
              >
               { "-" + discountPercentage + "%"}
              </Typography>
            </Stack>
)}

            <Stack
              direction="row"
              spacing={2}
              sx={{
                width: "100%",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
                mb: 1,
                pr: 1,
              }}
            >
              <Typography
                align="left"
                sx={{
                  fontSize: "20px",
                  fontWeight: 550,
                  color: "#48768C",
                  letterSpacing: "-.02em",
                  lineHeight: "-2",
                  whiteSpace: "noWrap",
                }}
              >
                Your Monthly Cost
              </Typography>

              <Typography
                align="left"
                sx={{
                  fontSize: "20px",
                  fontWeight: 550,
                  color: "#48768C",
                  letterSpacing: "-.02em",
                  lineHeight: "-2",
                  whiteSpace: "noWrap",
                  mr: 3,
                  mb: 1,
                }}
              >
                { promotion ?  formatCurrency(discountedPlanCost) : totalPlanCost }
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </Fade>
  );
};

export default ViewPlanDetails;
