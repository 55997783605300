import { useEffect, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import CategorySelect from "./Forms/CategorySelect";
import MessageForm from "./Forms/MessageForm";
import ContactForm from "./Forms/ContactForm";
import {
    PrimaryButton,
    CancelButton,
    SecondaryButton,
  } from "src/components/Buttons/ModalButtons";
  import ReviewForm from "./Forms/ReviewForm";
  import SubmitSuccess from "./Forms/SubmitSuccess";

const steps = ["Select A Topic", "Send Us A Message", "Your Contact Info", "Review & Submit"];

export default function BugReportStepper({ ticketDetails, handleChange, handleFormSubmit, onClose, isLoading, activeStep, setActiveStep }) {

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };


  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  useEffect(() => {
    const checkButtonDisabled = () => {  
      if (activeStep === 0) {
        return !ticketDetails?.category;
      } else if (activeStep === 1) {
        return ticketDetails?.description.length < 20;
      } else if (activeStep === 2) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return (
          !ticketDetails?.firstName ||
          !ticketDetails?.lastName ||
          !ticketDetails?.email ||
          !emailRegex.test(ticketDetails.email)
        );
      }
      return true; // Default case if no step matches
    };
  
    setIsNextButtonDisabled(checkButtonDisabled());
  }, [activeStep, ticketDetails]); // Re-run effect when either activeStep or ticketDetails changes




  return (
    <Stack direction="row" sx={{ width: "100%", }}>
      {/* Left Stepper Section (30% Width, 60% Height) */}
      <Box
  sx={{
    width: "29%", // Width based on viewport width, will shrink and grow with the window
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start", // Center the Stepper horizontally in the Box
    justifyContent: "flex-start", // Center content vertically if needed
    p: 2,
    height: "20vh", // 45% of viewport height
    mt:-1,
    
    

  }}
>
      
        <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
      <Step key={label}>
        <StepLabel
          sx={{
            height: "25px", 
            width: "17vw", 
            backgroundColor: index === activeStep ? "#d6e7f7" : "transparent", // Background color for active step
            borderRadius: "5px", 
            alignItems: "center", 
            display: "flex", // Make the content flex   
            justifyContent: "center", // Center the content horizontally
      
     
            
                "& .MuiStepLabel-label": {
                    marginLeft: "10px", // Add margin-left to all labels
                    fontSize: ".98rem", // Font size for all labels
                    whiteSpace:'noWrap',
                    
                  },
               "& .MuiStepLabel-completed": {
                 color: "#48768C", // Text color for completed steps
               },
               "& .MuiStepLabel-label.Mui-completed": {
                 color: "#48768C", // Text color for completed labels
                    fontWeight: 600, // Bold font for completed labels
               },
               "& .MuiStepLabel-label.Mui-active": {
                 color: "#48768C", // Text color for active labels
                 fontWeight: 600, // Bold font for active labels
                 marginLeft: "10px", 
               },
               "& .MuiStepIcon-text": {
                 fill: "#48768C", // Icon text color for the active step
                 fontSize:'14px',
                 transformOrigin: "center",
               },
               "& .MuiStepIcon-root.Mui-completed": {
                color: "#c2d7df", // Icon color for active step
                border:  'none'  , // Border color for active step
                transform: "scale(1.35)", 
                transformOrigin: "center", 
                borderRadius: "50%", 
                fontWeight: 600, // Bold font for active step
                border:  "2px solid #97bac9"  , // Border color for active step

                

               },
               // Active step icon color and background
               "& .MuiStepIcon-root.Mui-active": {
                 color: "#FFFFFF", // Icon color for active step
                 border:  index === activeStep ? "1.5px solid #48768C" : "1px solid #97bac9"  , // Border color for active step
                 transform: "scale(1.40)", 
                 transformOrigin: "center", 
                 borderRadius: "50%", 
                 fontWeight: 600, // Bold font for active step
               },
               
               // Icon color for non-active and non-completed steps
               "& .MuiStepIcon-root": {
                color: "#FFFFFF", // Icon color for active step
                border: "1px solid #c2d7df", // Border color for active step
                transform: "scale(1.40)", 
                transformOrigin: "center", 
                borderRadius: "50%", 
               },
             }}
           >
             {label}
           </StepLabel>
         </Step>
          ))}
        </Stepper>
      </Box>

      {/* Vertical Divider */}
      <Divider
  orientation="vertical"
  flexItem
  sx={{ boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.3)",mb:5, }} 
/>
      {/* Right Form Section (70%) */}
      <Box
        sx={{
          width: "70%",
          backgroundColor: "#FFFFFF",
          p: 1,
          alignItems: "center", // Center the Stepper horizontally in the Box
          justifyContent: "flex-start", // Center content vertically if needed
          mt:-.75,
          pl:3,
          overflowY: "auto",
          pb:4,
   
        }}
      >
        <form onSubmit={handleFormSubmit}>
        {activeStep === 0 && (
            <>
            <CategorySelect ticketDetails={ticketDetails} handleChange={handleChange} />
            </>
        )}
       {activeStep === 1 && <MessageForm ticketDetails={ticketDetails} handleChange={handleChange} />}
        {activeStep === 2 && <ContactForm ticketDetails={ticketDetails} handleChange={handleChange} />}
        {activeStep === 3 && <ReviewForm ticketDetails={ticketDetails} handleChange={handleChange} />}
        {activeStep === 4 && <SubmitSuccess ticketDetails={ticketDetails}  />}
        </form>

        {/* Navigation Buttons */}
       
       
  
        <Box sx={{ display: activeStep !== 4 ? "flex" : "none", justifyContent: "flex-start", mt: -1.5, alignItems: "left", gap: 2,     backgroundColor: "#FFFFFF",mb:1  }}>
          <CancelButton
          sx={{ width: "125px" }}
          disabled={activeStep === 0} onClick={handleBack}>
            Back
          </CancelButton>
          {activeStep < steps.length - 1 ? (
            <PrimaryButton sx={{ width: "125px" }}
            disabled={isNextButtonDisabled}
              onClick={handleNext}>
              Next
            </PrimaryButton>
          ) : (
            <PrimaryButton
            sx={{width: "125px"}}
              variant="contained"
              onClick={handleFormSubmit}
              disabled={
                !ticketDetails.category ||
                !ticketDetails.description ||
                !ticketDetails.firstName ||
                !ticketDetails.lastName ||
                !ticketDetails.email ||
                isLoading
              }
            >
              {isLoading ? "Submitting..." : "Submit"}
            </PrimaryButton>
          )}
  
        </Box>

      </Box>
    </Stack>
  );
}