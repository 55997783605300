export const handleSubmit = async (ticketDetails, getAccessTokenSilently, userId) => {
    // Prepare the contact object from firstName, lastName, and email

    const contact = {
      firstName: ticketDetails.firstName,
      lastName: ticketDetails.lastName,
      email: ticketDetails.email,
    };
  
    // Get the access token using Auth0
    try {
      const accessToken = await getAccessTokenSilently();
      const apiUrl = process.env.REACT_APP_API_URL;
      // Send POST request to create service ticket
      const response = await fetch(`${apiUrl}/service/service-tickets`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          userId: userId,
          category: ticketDetails.category,
          issue: ticketDetails.description,
          contact: contact,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        return { success: true, data }; // Return success and data
      } else {
        const errorData = await response.json();
        console.error("Error creating service ticket:", errorData);
        return { success: false, error: errorData }; // Return error
      }
    } catch (error) {
      console.error("Error sending request:", error);
      return { success: false, error: "Internal server error" }; // Return error
    }
  };