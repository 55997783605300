import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogActions, Typography, Button, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "src/public/assets/icons/icons/enableTwoWay.png";


const TwoWayPromptDialog = ({ open, onClose, checkTwoWayNotifications, showConfirmation, existingNotificationName, handleButtonClick, twoWayEnabled, setTwoWayEnabled, setShowConfirmation }) => {
const [confirmClose, setConfirmClose] = useState(false);  

const handleClose = () => {
  handleButtonClick();
  onClose();
  setTwoWayEnabled(false);  
}

const handleConfirmClose = () => {
  handleButtonClick();
  onClose();
  setTwoWayEnabled(true);
}

const handleConfirmTwoWayClick = async () => {
  if (!confirmClose) {
    setTwoWayEnabled(true);
    handleButtonClick();
  } else {
    setShowConfirmation(true);
 
  }

};


useEffect(() => {
  checkTwoWayNotifications(); 
  if (existingNotificationName !== "" && twoWayEnabled) {
    setConfirmClose(true);
  } else {
   setConfirmClose(false);
  }
}, [open]);




  
    return (
<Dialog
  open={open}
  onClose={onClose}
  PaperProps={{
    sx: {
      borderRadius: "8px",
      px:3,
      pt:1,
      pb:3,
      minWidth: "420px",
      backgroundColor: "white",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }}
>
<IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 4,
              top: 7,
              color: "#7589A2",
              backgroundColor: "transparent",
              zIndex: 1,
              borderRadius: "12px",
              width: "35px",
              height: "35px",
              padding: "3px",
              mx: 0.5,
              my: 0.25,
            }}
          >
            <CloseIcon sx={{ fontSize: "28px" }} />
          </IconButton>
{!showConfirmation ? (
  <DialogContent
    sx={{
      paddingBottom: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    }}
  >


            <Box sx={{mt:1.5}}>
          <img
            src={CheckIcon}
            style={{
              border: "transparent",
              width: "auto",
              height: "45px",
              backgroundColor: "#D6F5EE",
              borderRadius: "6px",
              padding: "1px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              marginBottom: "5px",
            }}
          />
        </Box>
    {/* Success Message */}
    <Typography
      sx={{
        fontSize: "20px",
        fontWeight: 550,
        color: "#48768C",
        letterSpacing: "-.02em",
        mt:2.5,
        whiteSpace:'nowrap',
      }}
    >
      This Notification Works Best With Two-way Notifications Enabled
    </Typography>
    <Typography
      sx={{
        fontSize: "15.5px",
        fontWeight: 550,
        color: "#6c90a2",
        letterSpacing: "-.02em",
        lineHeight: "-2",
       
        mt:3.5
      }}
    >
Two-way notifications allow your customers to respond with a simple "yes" or "no" reply, making it easier for them to engage with your notifications.
</Typography>
<Typography
      sx={{
        fontSize: "16.5px",
        fontWeight: 550,
        color: "#48768C",
        letterSpacing: "-.02em",
        lineHeight: "-2",
       
        mt:3
      }}
    >
Would you like to enable two-way notifications for this notification type?
</Typography>
  </DialogContent>
) : (
  <DialogContent
  sx={{
    paddingBottom: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",

  }}
>


          <Box sx={{mt:1.5}}>
        <img
          src={CheckIcon}
          style={{
            border: "transparent",
            width: "auto",
            height: "45px",
            backgroundColor: "#D6F5EE",
            borderRadius: "6px",
            padding: "1px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            marginBottom: "5px",
          }}
        />
      </Box>
  {/* Success Message */}
  <Typography
    sx={{
      fontSize: "19.5px",
      fontWeight: 550,
      color: "#48768C",
      letterSpacing: "-.02em",
      mt:2.5,
      
    }}
  >
    You Already Have Two-Way Notifications Enabled On {existingNotificationName}
  </Typography>
  <Typography
    sx={{
      fontSize: "15.5px",
      fontWeight: 550,
      color: "#6c90a2",
      letterSpacing: "-.02em",
      lineHeight: "-2",
     
      mt:2,
      width:'600px',
    }}
  >
You can only have one active two-way notification at a time. If you enable two-way notifications for this message, it will disable it on {existingNotificationName} .
</Typography>
</DialogContent>
)}



  <DialogActions
    sx={{
      justifyContent: "center",
      width: "100%",
      gap:5,
      mt:1
    }}
  >
    <Button
      variant="contained"
      sx={{
        mt:.5,
        px:4,
        fontSize: "1rem",
        color: "#46A793",
        backgroundColor: "#FFFFFF",
        border: "1px solid #46A793",
        boxShadow: "none",
        "&:hover": { backgroundColor: "#f2fbf8", borderColor:'#d5f2ea' },
      }}
      onClick={showConfirmation ? handleConfirmClose : handleConfirmTwoWayClick}
    >
      OK
    </Button>
    <Button
      variant="contained"
      sx={{
        mt:.5,
        px:4,
        fontSize: "1rem",

        color: "#bf7588",
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        border: "1px solid #d29aa7",

        "&:hover": {
          backgroundColor: "#faf5f7",
          border: "1px solid #f6edf0",
          color: "#bf7588",
        },
      }}
    
      onClick={handleClose}
    >
  { showConfirmation  ? "Nevermind" :  "No, thanks"}
    </Button>
  </DialogActions>
</Dialog>
  );
};

export default TwoWayPromptDialog;