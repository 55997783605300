import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Typography,
  Divider,
  Button,
  Box,
  Badge,
  Fade,
} from "@mui/material";
import { common, blueGrey, lightBlue } from "@mui/material/colors";
import { styled, width } from "@mui/system";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Routes, Route, NavLink, useMatch } from "react-router-dom";
import Profile from "../components/AccountPage/Sections/Profile/Profile";
import AccountSecurity from "../components/AccountPage/Sections/AccountSecurity/AccountSecurity";
import Notifications from "../components/AccountPage/Sections/InAppNotifications/Notifications";
import Billing from "../components/AccountPage/Sections/Billing";
import ExportData from "../components/AccountPage/Sections/ExportData";
import AccountManagement from "../components/AccountPage/Sections/AccountManagement/AccountManagement";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../context/AuthContext";
import MobileWarningPopup from "../components/modal/MobileWarningPopup";
import UserNotifications from "src/components/AccountPage/Sections/UserNotifications/UserNotifications";
import { useAccountContext } from "src/context/AccountContext.js";
import ChecklistModal from "src/components/NewUserChecklist/checklistModal";
import { useSubscriptionDetails } from "src/components/payments/routes/useSubscriptionDetails";
import { useValidateSubscription } from "src/components/payments/routes/validateSubscription";
import UserLocationCheck from "src/components/alerts/hooks/UserLocationCheck";
import MultiAccountWarning from "src/components/alerts/components/MultiAccountWarning";
import useCancelSubscription from "src/components/payments/hooks/useCancelSubscription.js";
import usePlanDetails from "src/components/payments/hooks/usePlanDetails";
import WarningAlert from "src/components/alerts/components/ErrorAlert";
import LoginModal from "src/components/modal/LoginModal";
import ProfileImage from "src/public/assets/icons/profile/profile.png";
import useKeepSubscription from "src/components/payments/hooks/useKeepSubscription";  
import usePauseSubscription from "src/components/payments/hooks/usePauseSubscription";

const FullPageCard = styled(Card)({
  position: "relative", // Add this line
  width: "100%",
  borderRadius: "10px",
  background: "white",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  overflow: "auto",
  paddingLeft: "2%",
  paddingBottom: "2%",
  paddingRight: "2%",
  paddingTop: "1.25%",

});

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  alignItems: "center",
}));

const Header = styled(Typography)({
  position: "absolute", // Add this line
  top: "0", // Add this line
  left: "0", // Add this line
  width: "95%",
  textAlign: "left",
  mb: 4,
});

const ContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingLeft: "2%",
  width: "100%",
  height: "100%",
  flexGrow: 1,
  overflowY: "auto", // Enable content scrolling
});

const Sidebar = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  alignText: "left",
  height: "95%",
  minHeight: "100%", // Add this line
  marginRight: "1%",
  padding:2
});

const NavButton = styled(Button)(({ theme, active }) => ({
  whiteSpace: "nowrap",
  minWidth: "195px",

  marginBottom: "0.5rem",
  textTransform: "none",
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: "0.02em",
  padding: "5px 10px",
  transition: "background-color 0.3s, color 0.3s, box-shadow 0.3s",
  justifyContent: "flex-start",
  background: active ? "rgba(173, 216, 230, 0.4)" : "transparent",
  color: active ? "#48768C" : "#48768C",
  boxShadow: active ? "0px 4px 8px rgba(72, 118, 140, 0.3)" : "none",
  border: active ? "1.25px solid rgba(173, 216, 230, 0.5)" : "none",
  "&:hover": {
    background: active ? "rgba(173, 216, 230, 0.4)" : "rgba(77, 96, 139, 0.1)",
    color: active ? "#48768C" : "#48768C",
    width: "100%",
  },
}));

function AccountPage() {
  const [showPopup, setShowPopup] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const basePath = "/dashboard/Account";
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isAccountSecurityModalOpen, setIsAccountSecurityModalOpen] =
    useState(false);
  const isProfileActive = useMatch(`${basePath}/Profile`);
  const isAccountManagementActive = useMatch(`${basePath}/AccountManagement`);
  const isUserNotificationsActive = useMatch(`${basePath}/UserNotifications`);
  const isNotificationsActive = useMatch(`${basePath}/Notifications`);
  const isBillingActive = useMatch(`${basePath}/Billing`);
  const isExportDataActive = useMatch(`${basePath}/Export-Data`);
  const {
    username,
    subUserData,
    isLoggedIn,
    shouldShowLoginModal,
    setShouldShowLoginModal,
  } = useAuth();
  const {
    accountData,
    onboardingData,
    fetchOnboardingRecord,
    fetchAccountData,
    setAccountData,
    singleUser, singleLocation,
    notificationSettings
  } = useAccountContext();
  const [users, setUsers] = useState([]);
  const [adminCount, setAdminCount] = useState(0);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [locations, setLocations] = useState([]);
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0); // State to store total number of users
  const [totalLocations, setTotalLocations] = useState(0); // State to store total number of locations
  const [showChecklistModal, setShowChecklistModal] = useState(false);

  const { isCancelling, cancelError, cancelSuccess, cancelSubscription } = useCancelSubscription();
  const { isKeeping, keepError, keepSuccess, keepSubscription,} = useKeepSubscription();
  const { isPausing, pauseError, pauseSuccess, pauseSubscription } = usePauseSubscription();

  const { subscriptionDetails, refetch: refetchSubscriptionDetails } =
    useSubscriptionDetails(apiUrl); // Assuming useSubscriptionDetails has a refetch function
  const { subscriptionValid, refreshSubscription, verifying } =
    useValidateSubscription();
  const { planDetails, planError, fetchPlanDetails } = usePlanDetails();
  const [billingHistory, setBillingHistory] = useState([]);
  const [loadingBilling, setLoadingBilling] = useState(true);
  const [billingError, setBillingError] = useState(null);
  const customerId = accountData ? accountData?.customer_id : null;
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [messageSubText, setMessageSubText] = useState("");
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [cardTitle, setCardTitle] = React.useState("Your Profile");
  const [cardSubTitle, setCardSubTitle] = React.useState(
    "Your personal information, company details & location information",
  );
  const [billingContact, setBillingContact] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [failedPaymentError, setFailedPaymentError] = useState("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);  
  const [emailSent, setEmailSent] = useState(false);

  const isAdmin = subUserData?.is_admin;

  const handleClose = () => {
    setIsErrorModalOpen(false);
  };

  useEffect(() => {
    if (!isAccountManagementActive) {
      handleClearWarnings();
      setIsErrorModalOpen(false);
    }
  }, [isAccountManagementActive]);

  useEffect(() => {
    if (isErrorModalOpen) {
      const timer = setTimeout(() => {
        setIsErrorModalOpen(false);
      }, 7000);

      // Cleanup the timeout when the component unmounts or `isErrorModalOpen` changes
      return () => clearTimeout(timer);
    }
  }, [isErrorModalOpen, setIsErrorModalOpen]);

  useEffect(() => {
    if (subscriptionValid) {
      refetchSubscriptionDetails();
      refreshSubscription();
    }
  }, [subscriptionValid, refetchSubscriptionDetails, refreshSubscription]);

  // Callback function to refresh subscription details after update
  const handleSubscriptionUpdate = () => {
    refetchSubscriptionDetails(); // Refresh subscription details after an update
    refreshSubscription(); // Refresh the subscription status
    fetchPlanDetails(); // Fetch the updated plan details
  };

  const handleChecklistModalOpen = () => {
    setShowChecklistModal(true);
  };

  const handleClosedChecklistModal = () => {
    setShowChecklistModal(false);
  };

  const fetchUsers = async () => {
    handleClearWarnings();
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/api/all-users/${user.sub}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();

      const usersData = Array.isArray(data.users)
        ? data.users.map((user) => ({
            ...user,
            location_name: user.assigned_location, // Update to match the property name in the server response
            location_id: user.location_id, // Update to match the property name in the server response
            is_admin: user.is_admin, // Update to match the property name in the server response
          }))
        : [];

      const adminCount = usersData.filter((user) => user.is_admin).length;

      setTotalUsers(usersData.length); // Set total number of users
      if (usersData.length >= 2) handleClearWarnings();

      setUsers(usersData);
      setAdminCount(adminCount); // Assuming you have a state for this
      setLoadingUsers(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };



  const fetchLocations = async () => {
    handleClearWarnings();
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/api/locations/${user.sub}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setLocations(data.rows);
      setTotalLocations(data.rows.length); // Set total number of locations
      if (data.rows.length >= 2) handleClearWarnings();

      setLoadingLocations(false);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchLocations(); 

  }, [totalUsers, totalLocations ]);



  useEffect(() => {
    // Access userData and subUserData wherever you need in your component
    // ... (rest of the code)
  }, [subUserData]);

  const fetchBillingHistory = async (customerId) => {
    try {
      setLoadingBilling(true);

      // Get the access token
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/subscriptions/plans/billing-history?customer_id=${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Send the token in the Authorization header
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch billing history");
      }

      const data = await response.json();
      setBillingHistory(data.billing_history);
      setBillingAddress(data.billing_address);
      setBillingContact(data.billing_contact);
      setPaymentStatus(data.last_payment_status);
      setFailedPaymentError(data.last_payment_failure_reason);
    } catch (error) {
      setBillingError("Error fetching billing history");
      console.error("Error fetching billing history:", error);
    } finally {
      setLoadingBilling(false);
    }
  };

  // Fetch billing history when the component mounts
  useEffect(() => {
    if (customerId) {
      fetchBillingHistory(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        // Adjust the width as needed for your definition of "mobile"
        setShowPopup(true);
      } else {
        setShowPopup(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it once on mount to check initial width
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scrollbar
    return () => {
      document.body.style.overflow = ""; // Re-enable scrollbar on component unmount
    };
  }, []);

  const [warnings, setWarnings] = useState({
    shouldAddUser: false,
    shouldAddLocation: false,
    shouldAddUserLocation: false,
  });


  const [alertData, setAlertData] = useState({
    title: "",
    alertText: "",
  });

  const handleWarningsChange = useCallback((newWarnings) => {
    setWarnings(newWarnings);
  }, []);

  const handleAlertUpdate = useCallback((newAlertData) => {
    setAlertData(newAlertData);
  }, []);

  const handleClearWarnings = () => {
    setAlertData({
      title: "",
      alertText: "",
    });

    setWarnings({
      shouldAddUser: false,
      shouldAddLocation: false,
      shouldAddUserLocation: false,
    });
  };









  if (!isLoggedIn && accountData && singleUser === false) {
    return (
      <LoginModal
        open={shouldShowLoginModal}
        onClose={() => setShouldShowLoginModal(false)} // Close the modal
        onLogin={() => {
          setShouldShowLoginModal(false); // Close the modal when login is successful
        }}
        accountData={accountData} // Pass the account data if needed
      />
    );
  }

  return (
    <Box sx={{ width: "100%", px: "4px", mt: -0.5 }}>
      <FullPageCard
        sx={{
          width: "100%",
          boxShadow:
            "0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Header
          sx={{
            mt: 1.55,
            color: "#48768C",
            fontWeight: 700,
            fontSize: 17,
            pl: "18px",
          }}
        >
          Account Settings
        </Header>
        <Header
          sx={{
            color: "#48768C",
            fontSize: ".9rem",
            mt: 5,
            letterSpacing: "-.01em",
            pl: "18px",
            mb: 2,
          }}
        >
          Manage account preferences
        </Header>
        <Sidebar sx={{ mt: 8.5 }}>
          {accountData && accountData?.onboarding_complete == false && (
            <Box
              style={{
                display: "flex",
                alignItems: "center", // Centers vertically
              }}
            >
              <StyledNavLink to={`${basePath}/Profile`}>
                <NavButton
                  variant="contained"
                  onClick={() => setShowChecklistModal(true)}
                >
                  Finish Setting Up Your Account
                </NavButton>
              </StyledNavLink>
   
            </Box>
          )}
          <StyledNavLink to={`${basePath}/Profile`}>
            <NavButton
              onClick={() => {
                setCardTitle("Your Profile");
                setCardSubTitle(
                  "Your personal information, company details & location information",
                );
              }}
              variant="contained"
              active={isProfileActive}
            >
              Profile
            </NavButton>
          </StyledNavLink>

          <NavButton
            sx={{ display: isAdmin ? "flex" : "none" }}
            variant="contained"
            onClick={() => setIsAccountSecurityModalOpen(true)}
          >
            Account Security
          </NavButton>
          <StyledNavLink to={`${basePath}/UserNotifications`}>
            <NavButton
              sx={{ display: isAdmin ? "flex" : "none" }}
              variant="contained"
              active={isUserNotificationsActive}
            >
              {"Manage Your Notifications"}
            </NavButton>
          </StyledNavLink>

{!accountData?.single_user && (
          <StyledNavLink to={`${basePath}/AccountManagement`}>
            <NavButton
              sx={{ display: isAdmin ? "flex" : "none" }}
              variant="contained"
              active={isAccountManagementActive}
            >
              { accountData?.single_location ? "Manage Your Users" : "Users and Locations"}
            </NavButton>
          </StyledNavLink>
)}
          <StyledNavLink to={`${basePath}/Notifications`}>
            {subUserData  && (
              <NavButton
                sx={{ display: isAdmin ? "flex" : "flex" }}
                variant="contained"
                active={isNotificationsActive}
              >
                In App Notifications
              </NavButton>
            )}
          </StyledNavLink>

          <StyledNavLink to={`${basePath}/Billing`}>
            <NavButton
              sx={{ display: isAdmin ? "flex" : "none" }}
              variant="contained"
              active={isBillingActive}
            >
              Billing
            </NavButton>
          </StyledNavLink>
        </Sidebar>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mb: 2, border: "1px solid", color: "#C0CAD1" }}
        />
        {showPopup && <MobileWarningPopup />}

        <ContentContainer>
          <Routes>
            <Route
              path="Profile"
              element={
                <Profile
                  accountData={accountData}
                  onboardingData={onboardingData}
                  fetchOnboardingRecord={fetchOnboardingRecord}
                  fetchAccountData={fetchAccountData}
                  setAccountData={setAccountData}
                  ProfileImage={ProfileImage}
                />
              }
            />
            <Route
              path="AccountManagement"
              element={
                <AccountManagement
                  fetchUsers={fetchUsers}
                  loadingUsers={loadingUsers}
                  adminCount={adminCount}
                  users={users}
                  fetchLocations={fetchLocations}
                  loadingLocations={loadingLocations}
                  locations={locations}
                  setLocations={setLocations}
                  setLoadingLocations={setLoadingLocations}
                  totalUsers={totalUsers}
                  totalLocations={totalLocations}
                  setTotalUsers={setTotalUsers}
                  setTotalLocations={setTotalLocations}
                  handleAlertUpdate={handleAlertUpdate}
                  alertData={alertData}
                  handleWarningsChange={handleWarningsChange}
                  handleClearWarnings={handleClearWarnings}
                  warnings={warnings}
                  setIsErrorModalOpen={setIsErrorModalOpen}
                  setErrorHeader={setErrorHeader}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  setMessageSubText={setMessageSubText}
                  locationToDelete={locationToDelete}
                  setLocationToDelete={setLocationToDelete}
                  cardTitle={cardTitle}
                  cardSubTitle={cardSubTitle}
                  handleSubscriptionUpdate={handleSubscriptionUpdate}
                  refetchSubscriptionDetails={refetchSubscriptionDetails}
                  refreshSubscription={refreshSubscription}
                  planDetails={planDetails}
                  subscriptionDetails={subscriptionDetails}
                />
              }
            />
            <Route
              path="UserNotifications"
              element={
                <UserNotifications
                  cardTitle={cardTitle}
                  cardSubTitle={cardSubTitle}
                />
              }
            />
            <Route
              path="Notifications"
              element={
                <Notifications
                  cardTitle={cardTitle}
                  cardSubTitle={cardSubTitle}
                />
              }
            />
            <Route
              path="Billing"
              element={
                <Billing
                  subscriptionDetails={subscriptionDetails}
                  subscriptionValid={subscriptionValid}
                  totalUsers={totalUsers}
                  totalLocations={totalLocations}
                  users={users}
                  locations={locations}
                  verifying={verifying}
                  planDetails={planDetails}
                  refetchSubscriptionDetails={refetchSubscriptionDetails}
                  fetchBillingHistory={fetchBillingHistory}
                  billingHistory={billingHistory}
                  loadingBilling={loadingBilling}
                  cardTitle={cardTitle}
                  cardSubTitle={cardSubTitle}
                  billingAddress={billingAddress}
                  subUserData={subUserData}
                  billingContact={billingContact}
                  handleSubscriptionUpdate={handleSubscriptionUpdate}
                  fetchUsers={fetchUsers}
                  fetchLocations={fetchLocations}
                  paymentStatus={paymentStatus}
                  failedPaymentError={failedPaymentError}
                  cancelSubscription={cancelSubscription}
                  keepSubscription={keepSubscription}
                  isCancelling={isCancelling}
                  isKeeping={isKeeping}
                  isPausing={isPausing}
                  pauseSubscription={pauseSubscription}
                />
              }
            />
          </Routes>
        </ContentContainer>

        {isAccountSecurityModalOpen && (
          <AccountSecurity
            closeModal={() => setIsAccountSecurityModalOpen(false)}
            setIsAccountSecurityModalOpen={setIsAccountSecurityModalOpen}
            setIsConfirmDialogOpen={setIsConfirmDialogOpen}
            setSendingEmail={setSendingEmail}
            setEmailSent={setEmailSent}
            sendingEmail={sendingEmail}
            emailSent={emailSent}
            isConfirmDialogOpen={isConfirmDialogOpen}
          />
        )}
        {showChecklistModal && (
          <ChecklistModal
            open={showChecklistModal}
            onClose={handleClosedChecklistModal}
            fetchUsers={fetchUsers}
            fetchLocations={fetchLocations}
          />
        )}
      </FullPageCard>
      <UserLocationCheck
        accountData={accountData}
        totalUsers={totalUsers}
        totalLocations={totalLocations}
        onWarningsChange={handleWarningsChange}
        onAlertUpdate={handleAlertUpdate}
      />
      {alertData.title && alertData.alertText && isAccountManagementActive && (
        <MultiAccountWarning
          title={alertData.title}
          alertText={alertData.alertText}
        />
      )}

      {isErrorModalOpen && (
        <WarningAlert
          title={errorHeader}
          alertText={errorMessage}
          messageSubText={messageSubText}
          locationToDelete={locationToDelete}
          open={open}
          handleClose={handleClose}
          setOpen={setOpen}
        />
      )}
    </Box>
  );
}

const ProtectedRoute = withAuthenticationRequired(AccountPage, {
  onRedirecting: () => <div>Redirecting you to the login page...</div>,
});

export default AccountPage;
