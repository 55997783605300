import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Avatar,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Portal,
  Grid,
  Paper,
  Menu,
  Stack,
  FormControlLabel,
  ButtonBase,
  Fade
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import Popper from "@mui/material/Popper";
import addUserIcon from "src/public/assets/icons/icons/addUser.png";
import userAccountDetailsIcon from "src/public/assets/icons/icons/userDetailsIcon.png";
import { useAccountContext } from "../../../context/AccountContext";
import { useAppContext } from "../../../context/AppContext";
import { dismissItem } from "src/components/NewUserChecklist/dismissItem";
import { set } from "lodash";
import ReviewDialog from "src/components/AccountPage/AccountManagementComponents/ReviewDialog.js";
import useAddNewUser from "src/components/payments/hooks/useAddNewUser.js";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import useNoCostNewUser from "src/components/payments/hooks/useNoCostNewUser";
import { useCalculateChanges } from "../../AccountPage/AccountManagementComponents/useCalculateChanges";
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import AccountInfoIcon from 'src/public/assets/icons/icons/AccountInfoIcon.png'
import ConfirmAdminDialog from "./ConfirmAdminDialog";




const apiUrl = process.env.REACT_APP_API_URL;

const AddUserModal = ({
  open,
  onClose,
  onAddUser,
  locations,
  fetchUsers,
  users,
  handleSubscriptionUpdate,
  refetchSubscriptionDetails,
  subscriptionDetails,
  planDetails
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [pin, setPin] = useState("");
  const [showPin, setShowPin] = useState(false);
  const [role, setRole] = useState(""); // Initial role
  const [location, setLocation] = useState(""); // Initial role
  const [isAdmin, setIsAdmin] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [showConfirmNewPin, setShowConfirmNewPin] = useState(false);
  const [confirmNewPin, setConfirmNewPin] = useState("");
  const [showNewPin, setShowNewPin] = useState(false);
  const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const defaultLocation = locations?.length > 0 ? locations[0].name : "";
  const {
    accountData,
    onboardingData,
    fetchOnboardingRecord,
    fetchAccountData,
  } = useAccountContext();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [item, setItem] = useState("addUser");
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const { addNewUser, addUserLoading, addUserError, success } = useAddNewUser();
  const { addNoCostNewUser, success: newUserSuccess } = useNoCostNewUser();
  const [price, setPrice] = useState(null);
  const [userNameTaken, setUserNameTaken] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false); // Controls Select dropdown
  const [locationOpen, setLocationOpen] = useState(false)





  const handleCloseReviewDialog = () => {
    setOpenReviewDialog(false);
    setSaveButtonEnabled(true);
  };

  const handleOpenReviewDialog = async () => {
    setPrice(null);
    setSaveButtonEnabled(false);
    try {
      // Get access token from Auth0
      const accessToken = await getAccessTokenSilently();

      // Make API request to check location users and get price
      const checkResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/subscriptions/plans/checkLocationUsers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ auth0UserId: user.sub }), // Send user ID to check locations and users
        },
      );

      const checkData = await checkResponse.json();

      // Set the price based on the response
      setPrice(checkData.price);

      // Wait for the price to be set before opening the review dialog
      setOpenReviewDialog(true);
    } catch (error) {
      console.error("Error fetching location and user data:", error);
      showSnackBar("Unable to check user/locations. Please try again");
    }
  };

  useEffect(() => {
    if (open) {
      setOpenReviewDialog(false);
    }
  }, [open]);

  const scaleFactor = 0.75;

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42 * scaleFactor,
    height: 27.5 * scaleFactor,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: `translateX(${16 * scaleFactor}px)`,
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2BBB9E" : "#2BBB9E",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: `${6 * scaleFactor}px solid #fff`,
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22 * scaleFactor,
      height: 22 * scaleFactor,
    },
    "& .MuiSwitch-track": {
      borderRadius: (26 * scaleFactor) / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const { user } = useAuth0();

  useEffect(() => {
    const isValidInput =
      pin.length >= 4 &&
      pin.length <= 6 &&
      /^\d+$/.test(pin) &&
      confirmNewPin.length === 4 &&
      /^\d+$/.test(confirmNewPin) &&
      pin === confirmNewPin &&
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      userName.trim() !== "" &&
      role !== "" &&
      (accountData.total_locations < 2 || location !== "");

    setSaveButtonEnabled(isValidInput);
  }, [
    pin,
    confirmNewPin,
    firstName,
    lastName,
    userName,
    location,
    role,
    accountData.total_locations,
  ]);

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setUserName("");
    setPin("");
    setRole("");
    setLocation("");
    setIsAdmin(false);
    setConfirmNewPin("");
    setShowPin(false);
    setShowNewPin(false);
    setShowConfirmNewPin(false);
    setUserNameTaken(false);
  };

  useEffect(() => {
    if (!open) {
      clearForm();
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleTogglePinVisibility = () => {
    setShowPin(!showPin);
  };

  const handleNewPinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "").substr(0, 4);
    setPin(inputValue);
  };

  const handleConfirmNewPinChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "").substr(0, 4);
    setConfirmNewPin(inputValue);
  };

  const toggleShowNewPin = () => {
    setShowNewPin((prev) => !prev);
  };

  const toggleShowConfirmNewPin = () => {
    setShowConfirmNewPin((prev) => !prev);
  };

  const handleUpdateChecklist = async () => {
    const userId = user.sub;
    if (accountData.onboarding_complete === false) {
      await dismissItem(item, getAccessTokenSilently, userId);
      await fetchOnboardingRecord();
      return;
    } else {
      return;
    }
  };

  const singleUser =
    accountData &&
    accountData.single_user === false &&
    accountData.total_employees === 1;
  const singleLocation =
    accountData &&
    accountData.single_location === false &&
    accountData.total_locations === 1;
  const userId = user.sub;

  const handleAddUserClick = async () => {
    const selectedLocation =
      location.trim() !== "" ? location : defaultLocation;
    const customerId = accountData?.customer_id;
    const userData = {
      firstName,
      lastName,
      userName,
      pin,
      isAdmin,
      role,
      location: selectedLocation,
      auth0UserId: user.sub, // Auth0 user ID
    };

    try {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();

      // Send the API request
      const response = await fetch(
        `${apiUrl}/subscriptions/manage/addAdditional`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            customerId, // customerId should be included directly in the body
            userData, // Pass the userData as an object
          }),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();

        if (errorData.usernameTaken) {
          setUserNameTaken(true); // Show error for taken username
          showSnackBar("Username is already taken", false);
          setIsLoading(false);
          setOpenReviewDialog(false);
          setSaveButtonEnabled(true);
          return; // Stop further execution
        } else {
          showSnackBar("Unable to add user. Please try again", false);
          setOpenReviewDialog(false);
          setIsLoading(false);
        }
        throw new Error("Error adding user");
      }

      // On success
      const data = await response.json();

      handleUpdateChecklist();


        await fetchAccountData(userId);

        handleReloadComponents();
      

      await handleSubscriptionUpdate();
  
      showSnackBar("User added successfully!", true);

      setIsLoading(false);
      onClose(); // Close the modal
      clearForm();
      fetchUsers(); // Refresh the user list
    } catch (error) {
      showSnackBar("Unable to add user. Please try again", false);
      setIsLoading(false);
      clearForm();
    }
  };

  const handleToggleSwitch = () => {
    setIsAdmin(!isAdmin);
    // Open the dialog when the switch is checked
    if (!isAdmin) {
      setInfoDialogOpen(true);
    }
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };


  const handleRoleButtonClick = () => {
    setRoleOpen(true); // Open the dropdown when button is clicked
  };


  const handleLocationButtonClick = () => {
    setLocationOpen(true); // Open the dropdown when button is clicked
  };









  return (
    <Dialog
      open={open}
      onClose={isLoading ? null : onClose} // Disable closing while loading
      PaperProps={{ sx: { borderRadius: "10px", backgroundColor: "#FFFFFF", display: openReviewDialog || infoDialogOpen ? "none" : "block", minWidth:'550px', maxWidth:'565px', p:.5 } }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>
      <DialogTitle
        sx={{
          px: 3,
          pt:2,
          ml:.5


        }}
      >
        <img
          src={addUserIcon}
          alt="addUserIcon"
          style={{
            border: "1px solid #B0BEC5",
            width: "auto",
            height: "28px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "3px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
            marginLeft:"-5px"
        
          }}
        />
                <Typography
      sx={{
     
        color: "#48768C",
        fontSize: 21,
        fontWeight: 600,
        textAlign:'left',
   

        letterSpacing: '-.03em',
        
        mt:1,
        ml:-.65

      }}
    >
     Add New Employee
    </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: 3,
          width: "100%",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >



           
<Stack direction="row" sx={{alignItems:'center', mb:-.5, ml:-.5,  mt:1}}> 

<img
    src={userAccountDetailsIcon}
    style={{
      border: "transparent",
      width: "auto",
      height: "28.5px",
      borderRadius: "6px",
      marginBottom: "15px",
      marginTop:'5px'
    }}
  />
    <Typography
      sx={{
     
        color: "#48768C",

        fontSize: 18.5,
        fontWeight: 600,
        textAlign:'center',
        ml:.5,

        alignItems:'center',
        letterSpacing: '-.02em',
        mb:1

      }}
    >
     Employee Details
    </Typography>

</Stack>
            <Stack direction="column" spacing={1} sx={{}}>

      
          <Stack
          spacing={1} direction="row" 
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
   

   
            }}
          >
             <Stack spacing={0.25}  sx={{width:'100%'}}>
      <Typography 
        variant="body2" 
        sx={{ color: "#48768C", fontWeight: 600, marginLeft: "5px" }}
      >
       First Name
      </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              mb={2}
              value={firstName}
              onChange={(e) => {
                // Only allow alphabetical characters
                const newValue = e.target.value.replace(/[^A-Za-z]/g, "");

                // Capitalize the first letter and set the rest as lowercase
                const capitalizedValue =
                  newValue.charAt(0).toUpperCase() +
                  newValue.slice(1).toLowerCase();

                // Limit the length to 10 characters
                const limitedValue = capitalizedValue.slice(0, 10);

                // Set the state with the sanitized, capitalized, and limited value
                setFirstName(limitedValue);
              }}
              sx={{
                color: "#000",
     

                "& input": { width: "100%", pr: 3, textAlign: "left" },
              }}
            />
            </Stack>
            <Stack spacing={0.25} alignItems="flex-start" sx={{width:'100%'}}>
      <Typography 
        variant="body2" 
        sx={{ color: "#48768C", fontWeight: 600, marginLeft: "5px" }}
      >
       Last Name
      </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              mb={2}
              value={lastName}
              onChange={(e) => {
                // Only allow alphabetical characters
                const newValue = e.target.value.replace(/[^A-Za-z]/g, "");

                const capitalizedValue =
                  newValue.charAt(0).toUpperCase() +
                  newValue.slice(1).toLowerCase();

                // Limit the length to 10 characters
                const limitedValue = capitalizedValue.slice(0, 10);

                // Set the state with the sanitized and limited value
                setLastName(limitedValue);
              }}
              sx={{
                color: "#000",
             
                "& input": { width: "100%", pr: 3, textAlign: "left" },
              }}
            />
            </Stack>
          </Stack>
         
          <Stack
          spacing={1} direction="row" 
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
   
 

   
            }}
          >
          <Stack spacing={0.25} alignItems="flex-start" sx={{width:'100%'}}>
      <Typography 
        variant="body2" 
        sx={{ color: "#48768C", fontWeight: 600, }}
      >
       Role
      </Typography>
          <FormControl
  variant="outlined"
  sx={{
    width: "100%",
    mb: 2,

    backgroundColor: "#F0F0F0",
    borderRadius:'13px',
  }}
>
  <Select
    size="small"
    value={role}
    onChange={(e) => setRole(e.target.value)}
    open={roleOpen}
    onClose={() => setRoleOpen(false)} // Closes menu when clicking outside
    onOpen={() => setRoleOpen(true)} // Ensures menu can open naturally


    sx={{
      backgroundColor: "#FFFFFF",
      color: "#48768C",
      display: "flex",
      alignItems: "center",
      

    }}
    MenuProps={{
      PaperProps: {
        style: {
          backgroundColor: "#FFFFFF",
          

       

        },
      },
    }}
   
    startAdornment={
      <InputAdornment position="start" sx={{ml: role? -2.95 : 0 }}>
              {!role && (
            <ButtonBase onClick={handleRoleButtonClick}>    
        <Typography sx={{color: "#48768C", fontWeight:500, ml:-.5}}>
       Select A Role
        </Typography>
        </ButtonBase>
              )}

      </InputAdornment>
    }
  >
    <MenuItem
      value="Employee"
      sx={{ backgroundColor: "#FFFFFF", color: "#48768C" }}
    >
      Employee
    </MenuItem>
    <MenuItem
      value="Manager"
      sx={{ backgroundColor: "#FFFFFF", color: "#48768C" }}
    >
      Manager
    </MenuItem>
    <MenuItem
      value="Owner"
      sx={{ backgroundColor: "#FFFFFF", color: "#48768C" }}
    >
      Owner
    </MenuItem>
    <MenuItem
      value="Regional Manager"
      sx={{ backgroundColor: "#FFFFFF", color: "#48768C" }}
    >
      Regional Manager
    </MenuItem>
    <MenuItem
      value="Operations Manager"
      sx={{ backgroundColor: "#FFFFFF", color: "#48768C" }}
    >
      Operations Manager
    </MenuItem>
    <MenuItem
      value="Assistant Manager"
      sx={{ backgroundColor: "#FFFFFF", color: "#48768C" }}
    >
      Assistant Manager
    </MenuItem>
    <MenuItem
      value="Shift Lead"
      sx={{ backgroundColor: "#FFFFFF", color: "#48768C" }}
    >
      Shift Lead
    </MenuItem>
  </Select>
</FormControl>
</Stack>
</Stack>

          {!(accountData.single_location || accountData.total_locations <= 1) && (
                        <Stack spacing={0.25} alignItems="flex-start" sx={{width:'100%', }}>
                        <Typography 
                          variant="body2" 
                          sx={{ color: "#48768C", fontWeight: 600,pt:.45 }}
                        >
                        Assigned Location
                        </Typography>
              <FormControl
                sx={{
                  width: "100%",
   
                  backgroundColor: "#F0F0F0",
                  borderRadius:'13px'

                }}
              >

                <Select
                  size="small"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  open={locationOpen}
                  onClose={() => setLocationOpen(false)} // Closes menu when clicking outside
                  onOpen={() => setLocationOpen(true)} // Ensures menu can open naturally
                  sx={{ backgroundColor: "#FFFFFF", color: "#48768C" }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root": {
                          color: "#2F5C71", // Text color
                          "&:hover": {
                            backgroundColor: "#ecf1f3", // Hover background color
                            color: "#2F5C71", // Text color on hover
                            borderRadius: "4px", // Rounded corners
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#d9e2e7", // Selected background color
                            color: "#48768c", // Selected text color
                            borderRadius: "4px", // Rounded corners
                            fontWeight: "bold", // Bold text
                          },
                        },
                      },
                    },
                  }}
                  startAdornment={
                    <InputAdornment position="start" sx={{ml: location? -2.95 : 0 }}>
                            {!location && (
                              <ButtonBase onClick={handleLocationButtonClick}>
                      <Typography sx={{color: "#48768C", fontWeight:500, ml:-.5}}>
                     Assign Employee To Location
                      </Typography>
                      </ButtonBase>
                            )}
              
                    </InputAdornment>
                  }
                >
                  {locations?.map((location) => (
                    <MenuItem key={location.location_id} value={location.name}>
                      {location.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Stack>

          )}

</Stack>

<Stack direction="row" sx={{alignItems:'center',   mt:2.5, mb:-1, ml:-.5}}> 

<img
    src={AccountInfoIcon}
    style={{
      border: "transparent",
      width: "auto",
      height: "28.5px",
      borderRadius: "6px",
      marginBottom: "15px",
      marginTop:'5px'
    }}
  />
    <Typography
      sx={{
     
        color: "#48768C",

        fontSize: 18.5,
        fontWeight: 600,
        textAlign:'center',
        ml:.25,

        alignItems:'center',
        letterSpacing: '-.02em',
        mb:1

      }}
    >
   Account Information
    </Typography>

</Stack>

            

            <Stack direction="column" spacing={1} sx={{}}>
            <Stack spacing={0.25}  sx={{width:'100%'}}>
      <Typography 
        variant="body2" 
        sx={{ color: "#48768C", fontWeight: 600, marginLeft: "5px" }}
      >
      Username
      </Typography>

            <TextField
              error={userNameTaken}
              size="small"
              variant="outlined"
              sx={{ mb: 0, width: "100%" }}
              InputProps={{
                endAdornment: userNameTaken && (
                  <InputAdornment position="end">
                    <Typography sx={{ color: "#8C4350", fontSize: 13 }}>
                      Username Already Exists
                    </Typography>
                  </InputAdornment>
                ),
              }}
              value={userName}
              onChange={(e) => {
                // Only allow alphabetical characters
                const newValue = e.target.value.replace(/[^A-Za-z]/g, "");

                // Limit the length to 10 characters
                const limitedValue = newValue.slice(0, 10);

                const isUsernameTaken = users?.some(
                  (user) => user.username === limitedValue,
                );
                // Update the state
                setUserName(limitedValue);
                setUserNameTaken(isUsernameTaken); // Set the error state based on the check

                // Set the state with the sanitized and limited value
                setUserName(limitedValue);

                // Clear the 'userNameTaken' state when the user types a new username
                if (userNameTaken) {
                  setUserNameTaken(false);
                }
              }}
            />
      </Stack>


          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
 
            }}
          >
<Stack spacing={0.25}  sx={{width:'100%'}}>
      <Typography 
        variant="body2" 
        sx={{ color: "#48768C", fontWeight: 600, marginLeft: "5px" }}
      >
      Employee PIN
      </Typography>
            <TextField
              variant="outlined"
              size="small"
              type={showPin ? "text" : "password"}
              value={pin}
              onChange={handleNewPinChange}
              sx={{
                color: "#48768C",
                "& input": {
                  height: "23.5px",

                  width: "100%",
                  textAlign: "left",
                  color: "#48768C",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                  <Fade in={pin.length !== 0}>
                    <IconButton 
                    onClick={handleTogglePinVisibility} edge="end" sx={{color:'#c6d4dc'}}>
                      {showPin ? <VisibilityOff  /> : <Visibility />}
                    </IconButton>
                    </Fade>
                  </InputAdornment>
                ),
              }}
            />
            </Stack>
            <Box sx={{ width: "25px" }} />{" "}
            {/* Add space between the text fields */}
            <Stack spacing={0.25}  sx={{width:'100%'}}>
      <Typography 
        variant="body2" 
        sx={{ color: "#48768C", fontWeight: 600, marginLeft: "5px" }}
      >
      Confirm PIN
      </Typography>
            <TextField
              variant="outlined"
              size="small"
              type={showConfirmNewPin ? "text" : "password"}
              value={confirmNewPin}
              onChange={handleConfirmNewPinChange}
              sx={{
                mb: 1,
                color: "#48768C",
                "& input": {
                  height: "23.49px",

                  width: "100%",
                  textAlign: "left",
                  color: "#48768C",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Fade in={confirmNewPin.length !== 0}>
                    <IconButton onClick={toggleShowConfirmNewPin} edge="end"  sx={{color:'#c6d4dc'}}>
                      {showConfirmNewPin ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    </Fade>
                  </InputAdornment>
                ),
              }}
            />
            </Stack>
          </Box>
          <Stack
              direction="row"
              sx={{ alignItems: "center", }}
            >
              <FormControlLabel
                control={
                  <Checkbox sx={{ml:"1px"}}
                  checked={isAdmin}
                  onChange={handleToggleSwitch}
                    icon={<CheckBoxOutlineBlankRoundedIcon />}
                    checkedIcon={<CheckBoxTwoToneIcon sx={{color:'#48768C'}} />}
                  />
                }
              />
              <Typography
                noWrap
                sx={{
                  color: `#48768C` ,
                  borderRadius: "8px",
                  fontSize: 16,
                  fontWeight: 600,
                  textAlign: "left",
                  letterSpacing: "-.02em",
                  ml: -1.75,
                }}
              >
                      Make this user an administrator

              </Typography>
            </Stack>
     
          </Stack>


       
       
          <Box
            sx={{ width: "100%", display: "flex",justifyContent:'flex-start', gap:2, pr:.5 }}
          >
                        <SecondaryButton
              sx={{
                mt: 4,
                fontSize: 18,
                transition: "background-color 0.3s ease",
                width: "64%",
                justifyContent: "center",
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
                borderRadius:'6px',
                color: '#F0F0F0',
                backgroundColor: '#46A793',
                '&:hover': { backgroundColor: '#079E80' },
                '&:active': { backgroundColor: '#2E7D32' },
            
              }}
              onClick={handleOpenReviewDialog}
              disabled={isLoading || !isSaveButtonEnabled || userNameTaken} // Disable the button when loading
            >
              {openReviewDialog ? "Loading..." : "Add User"}
            </SecondaryButton>
                     <SecondaryButton
              sx={{
                mt: 4,
                fontSize: 18,
                transition: "background-color 0.3s ease",
                width: "34%",
                justifyContent: "center",
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
                borderRadius:'6px',
                boxShadow:'none',

                '&:hover': { backgroundColor: '#faf5f7', border:'1px solid #f6edf0', color:'#bf7588' },

              }}
              onClick={onClose}
            >
             Cancel
            </SecondaryButton>

          </Box>




      </DialogContent>
      {openReviewDialog && (
        <Portal>
          <ReviewDialog
            open={openReviewDialog}
            onClose={handleCloseReviewDialog}
     
            buttonText="Confirm & Add User"
            firstName={firstName}
            lastName={lastName}
            userName={userName}
            role={role}
            location={location}
            plan="singleUserMonthly"
            buttonAction={handleAddUserClick}
            isLoading={isLoading}
            buttonLoadingText="Adding User..."
            handleCancel={onClose}
            CancelCloseMessage="Are you sure you want to close?"
            CancelCloseSubText="Your new user will not be created."
            subscriptionDetails={subscriptionDetails}
            planDetails={planDetails}
            addedUser={true}  // Pass this dynamically based on the action
            addedLocation={false}
            deletedUser={false}
            deletedLocation={false}
            upgradedPlan={false}
            downgradedPlan={false}
 

         
          />
        </Portal>
      )}
      <ConfirmAdminDialog
                open={infoDialogOpen}
                onClose={handleInfoDialogClose}
/>
    </Dialog>
  );
};

export default AddUserModal;
