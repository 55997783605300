export const handleSubmit = async (
  notificationTitle,
      requiredFieldName,
      selectedIcon,
      selectedNotificationType,
      optionalFieldName,
      optionalIcon,
      optionalSelectedNotificationType,
      contextMessage,
      requiredContextLabel,
      optionalContextLabel,
      isDefault,
      user,
      apiUrl,
      getAccessTokenSilently,
      handleReloadComponents,
      showSnackBar,
      setIsLoading,
      titleColor,
      twoWayEnabled,
      handleCloseModal,
      accountData, 
      fetchOnboardingRecord
) => {
  try {
    setIsLoading(true); // Set loading state to true before making the API call

    const accessToken = await getAccessTokenSilently();

    // Initialize the Fields object
    const requestBody = {
      [notificationTitle]: {
        Fields: {
          // Required field
          [requiredFieldName]: {
            icon: selectedIcon,
            value: "",
            fieldType: selectedNotificationType,
            valueType: "INT",
            isRequired: true,
            messagePrefix: requiredContextLabel,
            notificationType: requiredFieldName,
            twoWayEnabled: String(twoWayEnabled), // Ensure it's always a string
          },
          // Optional field - only add if optionalFieldName is defined
          ...(optionalFieldName && {
            [optionalFieldName]: {
              tag: "", // Ensure to include the 'tag' if needed
              icon: optionalIcon,
              value: "",
              fieldType: optionalSelectedNotificationType,
              valueType: "INT",
              isRequired: false,
              messagePrefix: optionalContextLabel, // Adjust this as needed
              notificationType: optionalSelectedNotificationType,
            },
          }),
        },
        Configs: {
          Tag: notificationTitle,
          Color: titleColor,
          is_default: isDefault,
          notificationId: "",
          "Context Message": contextMessage,
        },
      },
    };

    const response = await fetch(
      `${apiUrl}/api/user-notifications/add-new-notification/${user.sub}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      },
    );

    if (!response.ok) {
      const data = await response.json(); // Get the response body to check for the specific error message
    
      if (data.error) {
        if (data.error === "You can only have up to 5 notifications.") {
          showSnackBar("Cannot exceed 5 notification types", false);
        } else if (data.error === "A notification with this name already exists.") {
          showSnackBar("Notification name is already in use", false);
        } else {
          showSnackBar("Failed to create notification. Please try again", false);
        }
      }
    
      setIsLoading(false); // Set loading state to false when request fails
      throw new Error("Failed to save or update data");
    }
    
    handleReloadComponents();
    showSnackBar("New Notification Added", true);
    if (!accountData?.onboarding_complete) {
      await fetchOnboardingRecord();
    }
    handleCloseModal();
  } catch (error) {
    console.error("Error saving data:", error);
  } finally {
    setIsLoading(false); // Ensure loading state is reset in both success and failure cases
  }
};
