import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Avatar,
  Box,
  TextField,
  CircularProgress,
  LinearProgress,
  Paper,
  Fade,
  IconButtonm,
  InputAdornment,
} from "@mui/material";
import ContactsIcon from "../../public/assets/icons/icons/contactList.png";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";
import SearchIcon from "@mui/icons-material/Search";
import phoneIcon from "../../public/assets/icons/messageModal/phone.png";
import Tooltip from "@mui/material/Tooltip";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Icon as Iconify } from "@iconify/react";

function ContactListDialog({ open, handleClose, handlePhoneNumberChange }) {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const { user, getAccessTokenSilently } = useAuth0();
  const { reloadComponents } = useAppContext();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchCustomers() {
      try {
        if (user && user.sub) {
          setLoading(true);
          const apiUrl = process.env.REACT_APP_API_URL;
          const accessToken = await getAccessTokenSilently();

 
            const response = await fetch(`${apiUrl}/api/customers/list/${encodeURIComponent(user.sub)}`, { // ✅ Encode user.sub for safety
   
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          const data = await response.json();

          setCustomers(data);
        }
      } catch (error) {
        console.error("Failed to fetch customer feed data", error);
      } finally {
        setLoading(false);
      }
    }
    fetchCustomers();
  }, [getAccessTokenSilently, user, reloadComponents]);

  // Filter customers based on search query
  const filteredCustomers = customers.filter(
    (customer) =>
      customer.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.last_name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0) : "";
    const lastInitial = lastName ? lastName.charAt(0) : "";
    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const handleCustomerClick = (phoneNumber) => {
    // Create a synthetic event object with the target.value set to the phone number
    const event = { target: { value: phoneNumber } };
    // Call the handlePhoneNumberChange function from props
    // Pass the synthetic event object as an argument
    handlePhoneNumberChange(phoneNumber, event);
    // Close the dialog if needed
    handleClose();
  };

  const longestNameLength = filteredCustomers.reduce((max, customer) => {
    const fullName = `${customer.first_name} ${customer.last_name}`;
    return Math.max(max, fullName.length);
  }, 0);

  // Calculate the maximum width for the name box based on the length of the longest name
  const maxNameWidth = longestNameLength * 4; // Adjust the multiplier as needed

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "20px", backgroundColor: "#FFFFFF" } }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>

      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          height: "70px",
          backgroundColor: "#FFFFFF",
          pt: 4,
          pb: 3.25,
          color: "#48768C",
          padding: "25px",
        }}
      >
        <Paper
          sx={{
            border: "1.5px solid #B0BEC5",
            padding: "5px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
            borderRadius: "20%",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            mr: "2px",
          }}
        >
          <i
            style={{
              display: "flex",
              color: "#647D9E",
              fontSize: "19px",
              backgroundColor: "#FFFFFF",
            }}
            className="fa-solid fa-address-book"
          ></i>
        </Paper>
      </DialogTitle>

      <DialogContent
        sx={{
          width: "510px",
          backgroundColor: "#FFFFFF",
          padding: "25px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: "#48768C", mb: 2.5, fontWeight: 600, lineHeight: "-2%" }}
        >
          Notify From Contacts
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            paddingBottom: "10px",
          }}
        >
          <TextField
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            variant="outlined"
            size="small"
            sx={{
              width: "250px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px", // Ensure border radius is applied
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2F5C71", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#48768CBA", // Border color on focus
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#8fabb9", // Default border color
              },
              "& input::placeholder": {
                color: "#B0BEC5",
                fontWeight: 600,
                fontFamily: "Source Sans Pro, sans-serif",
              },
            }}
            InputProps={{
              style: { color: "#2A3D4F" },
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="bi:search" style={{ color: "#90A4AE" }} />
                </InputAdornment>
              ),
              endAdornment: searchQuery && (
                <InputAdornment position="end" sx={{ marginRight: "3px" }}>
                  <Fade in={true} timeout={200}>
                    <IconButton
                      onClick={handleClearSearch}
                      edge="end"
                      sx={{ color: "#6595ab", padding: 0 }}
                    >
                      <CancelOutlinedIcon
                        sx={{ fontSize: 20, opacity: 0.85, mr: "4px" }}
                      />
                    </IconButton>
                  </Fade>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box
          sx={{
            border: "1px solid rgba(173, 216, 230, 0.6)",
            borderRadius: "10px",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
            width: "100%",
            pt: 1,
            pb: 1,
            overflowY: "auto",
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
              }}
            >
              <LinearProgress />
            </Box>
          ) : filteredCustomers.length > 0 ? (
            filteredCustomers.map((customer, index) => (
              <Box
                key={customer.customer_id}
                onClick={() => handleCustomerClick(customer.phone_number)}
                sx={{
                  overflowY: "auto",
                  pl: "5px",
                  paddingTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  borderBottom:
                    index === customers.length - 1
                      ? "none"
                      : "1px solid #E0E0E0",
                  paddingBottom: "10px",
                  cursor: "pointer", // Add this line to set the cursor to pointer
                  transition: "background-color 0.3s", // Add transition for smoother effect
                  "&:hover": {
                    borderRadius: "10px",
                    backgroundColor: "rgba(191, 218, 231, 0.25)",
                  },
                }}
              >
                <Avatar
                  sx={{
                    fontSize: 16,
                    width: 40,
                    height: 40,
                    background: "radial-gradient(circle, #EEF6F9, #FFFFFF)",
                    border: "1.5px solid rgba(173, 216, 230, 0.9)",
                    borderRadius: "20px",
                    padding: "4px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
                    mr: "15px",
                    fontWeight: 600,
                    letterSpacing: "-2%",
                    ml: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                      letterSpacing: "-2%",
                      textAlign: "center",
                      lineHeight: "-2%",
                      color: "#48768C",
                    }}
                  >
                    {getInitials(customer.first_name, customer.last_name)}
                  </Typography>
                </Avatar>
                <Box
                  sx={{
                    py: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center", // Align items vertically
                    gap: "20px",
                  }}
                >
                  {customer.first_name.length + customer.last_name.length >
                  20 ? (
                    <Tooltip
                      title={`${customer.first_name} ${customer.last_name}`}
                      placement="top"
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          width: "190px",

                          fontWeight: 600,
                          color: "#48768C",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {customer.first_name} {customer.last_name}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      variant="subtitle3"
                      sx={{
                        width: "190px",
                        fontWeight: 600,
                        color: "#48768C",
                        lineHeight: "-2%",
                      }}
                    >
                      {customer.first_name} {customer.last_name}
                    </Typography>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                      marginLeft: 2,
                    }}
                  >
                    <img
                      src={phoneIcon}
                      alt="phone"
                      style={{
                        width: "auto",
                        height: "24px",
                        padding: "1px",
                        border: "1px solid #B0BEC5",
                        borderRadius: "8px",
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                    <Typography
                      color="textSecondary"
                      sx={{
                        fontSize: 13.25,
                        fontWeight: 600,
                        lineHeight: "-2%",
                      }}
                    >
                      {customer.phone_number}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            <Box sx={{}}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#48768C",
                  fontWeight: 600,
                  lineHeight: "-2%",
                  letterSpacing: "-2%",
                  padding: "20px",
                }}
              >
                No contacts found
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ContactListDialog;
