import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  Typography,
  Divider,
  IconButton,
  Box,
  Modal,
  CircularProgress,
  Button,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import lockIcon from "../../../../public/assets/icons/icons/lock.png";
import { useAuth } from "../../../../context/AuthContext";
import { is } from "date-fns/locale";
import ChangePinModal from "src/components/modal/ChangePinModal/ChangePinModal";
import { useAccountContext } from "src/context/AccountContext";
import ConfirmDialog from "./ConfirmDialog";
import { useAppContext } from "src/context/AppContext";



function AccountSecurity({ closeModal,
  isConfirmDialogOpen,
  setIsConfirmDialogOpen,
  setEmailSent,
  emailSent,
  sendingEmail,
  setSendingEmail,


 }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { username, subUserData, setSubUserData } = useAuth();
  const { accountData } = useAccountContext(); // Use the accountData from the context
  const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);
  const user_id = subUserData.user_id;
  const {showSnackBar} = useAppContext(); 
      const [newPin, setNewPin] = useState(["", "", "", ""]);
      const [confirmNewPin, setConfirmNewPin] = useState(["", "", "", ""]);
      const [isLoading, setIsLoading] = useState(false)



  const handleOpenConfirmDialog = () => { 
    setIsConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCloseChangePinModal = () => {
    setIsChangePinModalOpen(false);
  };

  const checkEmailVerification = () => {
    setEmailVerified(user.email_verified);
  };

  useEffect(() => {
    checkEmailVerification();

  }, []);

  const handleVerifyEmail = async () => {
    if (!emailVerified) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await axios.post(
          `${apiUrl}/auth/send-verification-email`,
          {
            userId: user.sub,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        // Optionally, show a success message or update UI to reflect email verification sent
      } catch (error) {
        console.error("Error sending verification email:", error);
        showSnackBar("Unable to send. Please try again later", false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleResetPassword = async () => {
    try {
      setSendingEmail(true);
  
      // Get Auth0 access token
      const token = await getAccessTokenSilently();
  
      // Send the request using fetch
      const response = await fetch(`${apiUrl}auth/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include Auth0 token
        },
        body: JSON.stringify({ email: user.email }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        showSnackBar("Unable to sent reset request. Please try again later", false);
        throw new Error(errorData.message || "Failed to reset password");
      }
  
     setIsConfirmDialogOpen(true);
      setEmailSent(true);
    } catch (error) {
      console.error("Error resetting password:", error.message);
      showSnackBar("Unable to sent reset request. Please try again later", false);
    } finally {
      setSendingEmail(false);
    }
  };

  const handleChangePinSave = async ({ newPin, confirmNewPin }) => {
    setIsLoading(true);
    try {
      // Convert the PIN arrays to strings
      const newPinString = newPin.join("");  // Convert array to a string "1111"
      const confirmNewPinString = confirmNewPin.join("");  // Convert array to a string "1111"
  
      // Validate that PINs match
      if (newPinString === confirmNewPinString) {
        const apiUrl = process.env.REACT_APP_API_URL;
        const accessToken = await getAccessTokenSilently();
  
        // Update user PIN
        const response = await fetch(
          `${apiUrl}/api/users/${user_id}/change-pin`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              newPin: newPinString,  // Pass the string version of the PIN
            }),
          }
        );
  
        const responseData = await response.json();
  
        if (response.ok) {
          // Optionally return a success message or the response data
          return { success: true, data: responseData };
        } else {
          console.error("Failed to change PIN:", responseData.error);
          return { success: false, error: responseData.error };
        }
      } else {
        console.error("Entered PINs do not match");
        return { success: false, error: "Entered PINs do not match" };
      }
    } catch (error) {
      console.error("Error changing PIN:", error);
      return { success: false, error: error.message };
    } finally {
      setIsLoading(false);
    }
  };


    useEffect(() => {
      if (!isChangePinModalOpen) {
        const timeout = setTimeout(() => {
          // Reset the PIN arrays to empty values
          setNewPin(["", "", "", ""]);
          setConfirmNewPin(["", "", "", ""]);
        }, 300); // 300ms timeout, you can adjust this value as needed
  
        // Clear the timeout if the modal is opened again before the timeout completes
        return () => clearTimeout(timeout);
      }
    }, [isChangePinModalOpen, setNewPin, setConfirmNewPin]); // Trigger the effect when `isOpen` changes
  

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      PaperProps={{ sx: { borderRadius: "10px", padding:0} }} 
    >
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>

      <DialogTitle
        sx={{

          alignItems: "center",
          height: "55px",
          p:2.85,
          color: "#374247",
        }}
      >
        <Box sx={{ display: "flex", flexDisplay: "column", ml: 0.5 }}>
          <img
            src={lockIcon}
            alt="lock"
            style={{
              border: "1.25px solid rgba(173, 216, 230, 0.7)",
              width: "auto",
              height: "30px",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "3px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              borderRadius: "6px",
            }}
          />
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "center",
          zIndex: 0,
          mb:1
        }}
      >
        <Typography
          sx={{
            display: "flex",
            fontSize: "20.5px",
            fontWeight: 550,
            color: "#48768C",
            letterSpacing: "-.02em",
            lineHeight: "-2",
            mb: 2.5,
            alignContent: "left",
            ml: 0.5,
            mt:1
          }}
        >
          Account Security{" "}
        </Typography>
        <Box
          sx={{
            backgroundColor: "rgba(191, 218, 231, 0.1)",
            border: "1px solid rgba(173, 216, 230, 0.7)",
            borderRadius: "4px",
            padding: 2,
            width: "450px",
          }}
        >
          {subUserData.is_admin && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Typography
                  sx={{ color: "#48768C", fontSize: "16px", fontWeight: 600, }}
                >
                  Verify Email
                </Typography>
                <Button
                  sx={{
                    backgroundColor: "#2BBB9E",
                    color: "#F0F0F0",
                    padding: "12px 24px",
                    "&:hover": { backgroundColor: "#249e86" },
                    "&:disabled": {
                      color: "#DDEBFD",
                      backgroundColor: "#249e86",
                    },
                  }}
                  onClick={handleVerifyEmail}
                  disabled={!isAuthenticated || emailVerified || loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : emailVerified ? (
                    <>
                      <VerifiedUserIcon
                        style={{ marginRight: "5px", color: "#CCDFF8" }}
                      />
                      Verified
                    </>
                  ) : (
                    "Verify Email"
                  )}
                </Button>
              </Box>

              <Divider sx={{ color: "1px solid rgba(173, 216, 230, 0.3)" }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  marginBottom: !accountData.single_user ? "1rem" : ".25rem",
                }}
              >
                <Typography
                  sx={{ color: "#48768C", fontSize: "16px", fontWeight: 600 }}
                >
                  Reset Account Password
                </Typography>
                <Button
  sx={{
    backgroundColor: "#647D9E",
    color: "#F0F0F0",
    padding: "12px 24px",
    "&:hover": { backgroundColor: "#566f8f" },
    "&:disabled": {
      color: "#B0D0FB",
    },
  }}
  onClick={handleResetPassword}
  disabled={!isAuthenticated || sendingEmail || loading || emailSent}
>
  {emailSent ? (
    "Reset email sent"
  ) : sendingEmail ? (
    <>
      <CircularProgress size={24} sx={{ color: "#F0F0F0", marginRight: "8px" }} />
      Loading
    </>
  ) : (
    "Reset Password"
  )}
</Button>
              </Box>
            </>
          )}
          {!accountData.single_user && (
            <>
              <Divider sx={{ color: "1px solid rgba(173, 216, 230, 0.3)" }} />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                  mb: 0.5,
                }}
              >
                <Typography
                  sx={{ color: "#48768C", fontSize: "16px", fontWeight: 600 }}
                >
                  Change Your Personal Pin{" "}
                </Typography>
                <Button
                  sx={{
                    backgroundColor: "#8C4350",
                    color: "#F0F0F0",
                    padding: "12px 24px",
                    "&:hover": { backgroundColor: "#783944" },
                  }}
                  disabled={!isAuthenticated || loading}
                  onClick={() => setIsChangePinModalOpen(true)}
                >
                  {loading ? <CircularProgress size={24} /> : "Change Pin"}
                </Button>

                <ChangePinModal
                  isOpen={isChangePinModalOpen}
                  onClose={handleCloseChangePinModal}
                  userName="Your" 
                  newPin={newPin}
                  confirmNewPin={confirmNewPin}
                  isLoading={isLoading}
                  setNewPin={setNewPin} // Optionally pass setter if you want child to update the state
                  setConfirmNewPin={setConfirmNewPin} // Optionally pass setter if you want child to update the state
                          onSave={handleChangePinSave}
                  

                />
                <ConfirmDialog
                  open={isConfirmDialogOpen}
                  onClose={handleCloseConfirmDialog}
  
                  confirmHeader="Your password reset link has been sent to your email."
                  declineHeader="You will lose any unsaved changes."
                  />
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AccountSecurity;
