// AffectedAreasForm.js
import { Box, Typography, Select, MenuItem, InputLabel, Stack, Fade } from "@mui/material";
import { FormControl } from "@mui/material";
import { styled } from "@mui/system";


const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#E9EDF2",
  marginBottom: 3,
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -1.5px) scale(0.75)",
    paddingLeft: "7px", // Adjust the translateY value as needed
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#48768c", // Set the border color here
    },
    "&:hover fieldset": {
      borderColor: "#6fa3b7", // Set the hover border color here (optional)
    },
    "&.Mui-focused fieldset": {
      borderColor: "#48768c", // Set the focused border color here
    },
  },
}));

const CategorySelect = ({ ticketDetails, handleChange }) => {



    
  return (

          <Box sx={{}}>
        <Stack direction="column" spacing={3} sx={{width:'100%', display:'flex', whiteSpace:'wrap', alignItems:'fit-contain',}} > 
      
      <Stack spacing={0}>
        <Typography
                   

  sx={{
    fontSize: "1.29rem",
    fontWeight: 600,
 

    width: "100%", // Makes sure it takes up full width
    letterSpacing: "-0.03em", // Adds letter spacing
    color: "#48768c",
    lineHeight:'-.02em'

  }}
>       
Need assistance? We'd love to help
      </Typography>
      <Typography
                   

                   sx={{
                     fontSize: "1.06rem",
                     fontWeight: 600,
                 
                 
                     width: "100%", // Makes sure it takes up full width
                     letterSpacing: "-0.029em", // Adds letter spacing
                     color: "#6c90a2",
                 
                   }}
                 >       
                 Send us a message and we'll get in touch within 24 hours
                       </Typography>
                       </Stack>
      <Typography 
         color="textSecondary"
      sx={{ fontSize: ".93rem", fontWeight: 600, pl: "2px", pb: "5px", }}>
       What can we help with today?
      </Typography>
        </Stack>

      <StyledFormControl sx={{ backgroundColor: "#FFFFFF" }}>
        {!ticketDetails.category && (
          <InputLabel 
          shrink={false} sx={{ marginTop: "-6px", backgroundColor: "#FFFFFF", border:'none',
            "&.Mui-focused": { 
              color: "#48768c", fontWeight:600, // Change to your desired active color
              border:'none'
            }
          }}
        >
            Choose a category that best describes the issue
          </InputLabel>
        )}
        <Select value={ticketDetails.category} 
        onChange={handleChange} name="category" required size="small" sx={{ backgroundColor: "#FFFFFF", padding: 0,     color: "#48768c",               border:'none', mb:7
 
 
 }} MenuProps={{
  PaperProps: {
    sx: {
      maxHeight: '20vh', // Set the desired height for the dropdown
      backgroundColor: "#FFFFFF",
      overflowY: 'scroll', // This ensures the scrollbar is always visible
      scrollbarWidth: 'thin', // Make the scrollbar thinner (optional)
    },
  },
}}
>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }}value="Technical Support">Technical Support</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Billing & Payments">Billing & Payments</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="My Monthly Subscription">My Monthly Subscription</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Account Security">Account Security</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="My Notifications">My Notifications</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Troubleshooting Issues">Troubleshooting Issues</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Feature Requests & Feedback">Feature Requests & Feedback</MenuItem>
          <MenuItem sx={{ backgroundColor: "#FFFFFF", color:'#48768c' }} value="Something Else">Something Else</MenuItem>

        </Select>
      </StyledFormControl>
    </Box>


  );
};

export default CategorySelect;