import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";

const usePauseSubscription = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { showSnackBar } = useAppContext();
  const [isPausing, setIsPausing] = useState(false);
  const [pauseError, setPauseError] = useState(null);
  const [pauseSuccess, setPauseSuccess] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const pauseSubscription = async (customerId, resumeDate, refetchSubscriptionDetails) => {
    try {
      setIsPausing(true);
      setPauseError(null);
      setPauseSuccess(null);

      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiUrl}/subscriptions/manage/pause-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ customerId, resumeDate }),
      });

      if (!response.ok) {
        throw new Error("Failed to pause subscription");
      }

      const data = await response.json();
      setPauseSuccess(data.message);
      showSnackBar("Subscription Paused", "success");
      refetchSubscriptionDetails(); // Trigger refetch in parent component
      return true; // Indicate success
    } catch (error) {
      showSnackBar("Error pausing subscription", "error");
      console.error("Error pausing subscription:", error);
      setPauseError("Error pausing subscription");
      return false; // Indicate failure
    } finally {
      setIsPausing(false);
    }
  };

  return {
    isPausing,
    pauseError,
    pauseSuccess,
    pauseSubscription,
  };
};

export default usePauseSubscription;