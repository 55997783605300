import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";

const useKeepSubscription = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isKeeping, setIsKeeping] = useState(false);
  const [keepError, setKeepError] = useState(null);
  const [keepSuccess, setKeepSuccess] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { showSnackBar } = useAppContext();

  const keepSubscription = async (
    customerId,
    refetchSubscriptionDetails,
  ) => {
    try {
      setIsKeeping(true);
      setKeepError(null);
      setKeepSuccess(null);

      const token = await getAccessTokenSilently();

      const response = await fetch(
        `${apiUrl}/subscriptions/manage/keep-subscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            customerId
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to keep subscription active");
      }

      const data = await response.json();
      setKeepSuccess(data.message);
      showSnackBar("Subscription Kept Active", "success");
      refetchSubscriptionDetails(); // Trigger refetch in parent component
      return true; // Indicate success
    } catch (error) {
      showSnackBar("Error keeping subscription active", "error");
      console.error("Error keeping subscription active:", error);
      setKeepError("Error keeping subscription active");
      return false; // Indicate failure
    } finally {
      setIsKeeping(false);
    }
  };

  return {
    isKeeping,
    keepError,
    keepSuccess,
    keepSubscription,
  };
};

export default useKeepSubscription;