import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Fade,
  Stack,
  Card,
  CardContent,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "src/public/assets/icons/icons/deleteRed.png";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import { useAppContext } from "src/context/AppContext";
import { useAuth } from "src/context/AuthContext";
import { useAccountContext } from "src/context/AccountContext";
import { useUserNotificationsContext } from "src/context/UserNotificationsContext";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { customTheme } from "./textField";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";

const LocationInfoCard = ({
  open,
  onClose,
  onConfirm,
  itemName,
  item,
  isLoading,
  header,
  message,
  subText,
  buttonAction,
  addedUserPrice,
  hideReviewDialog,
  name,
  setName,
  email,
  setEmail,
  phone,
  setPhone,
  nameTouched,
  setNameTouched,
  emailTouched,
  setEmailTouched,
  phoneTouched,
  setPhoneTouched,
  isFormValid,
  setIsFormValid,
  handleEmailChange,
  handleNameChange,
  handlePhoneChange,
  validateEmail,
  validatePhoneNumber,
  handleOpenCancelCloseDialog,
  isCancelCloseOpen,
  handleCloseCancelCloseDialog,
  handleCreateNewLocationClick,
  locationNameTaken,
  editLocationData,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        height: "100%", // Set a fixed height
        width: "98%",
        mb: 4,
      }}
    >
      <Box>
        <ThemeProvider theme={customTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 1.5,
              height: "auto",
              alignItems: "center",
            }}
          >
            <StorefrontTwoToneIcon
              sx={{
                backgroundColor: "#E3F2FD",
                color: "#48768C",
                fontSize: "29px",
                border: "1px solid rgba(173, 216, 230, 0.4)",
                borderRadius: "5px",
                mr: 1,
                padding: "3px",
              }}
            />
            <Typography
              sx={{
                fontSize: "15px",
                padding: "3px 4px",
                border: "1px solid rgba(173, 216, 230, 0.9)",
                borderRadius: "5px",
                color: "#316178",
                fontWeight: 500,
                fontFamily: "Source Sans Pro, sans-serif",
                textAlign: "left",
                width: "100%",
                backgroundColor: "#F2F9FF",
              }}
            >
              {" "}
              {name}{" "}
            </Typography>
          </Box>
        </ThemeProvider>
        <ThemeProvider theme={customTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 1.5,
              height: "auto",
              alignItems: "center",
            }}
          >
            <AlternateEmailOutlinedIcon
              sx={{
                backgroundColor: "#E3F2FD",
                color: "#48768C",
                fontSize: "29px",
                border: "1px solid rgba(173, 216, 230, 0.4)",
                borderRadius: "5px",
                mr: 1,
                padding: "4px",
              }}
            />

            <Typography
              sx={{
                fontSize: "15px",
                padding: "3px 4px",
                border: "1px solid rgba(173, 216, 230, 0.9)",
                borderRadius: "5px",
                color: "#316178",
                fontWeight: 500,
                fontFamily: "Source Sans Pro, sans-serif",
                textAlign: "left",
                width: "100%",
                backgroundColor: "#F2F9FF",
              }}
            >
              {" "}
              {email}{" "}
            </Typography>
          </Box>
        </ThemeProvider>
        <ThemeProvider theme={customTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 1,
              height: "auto",
              alignItems: "center",
            }}
          >
            <LocalPhoneOutlinedIcon
              sx={{
                backgroundColor: "#E3F2FD",
                color: "#48768C",
                fontSize: "29px",
                border: "1px solid rgba(173, 216, 230, 0.4)",
                borderRadius: "5px",
                mr: 1,
                padding: "4px",
              }}
            />
            <Typography
              sx={{
                fontSize: "15px",
                padding: "3px 4px",
                border: "1px solid rgba(173, 216, 230, 0.9)",
                borderRadius: "5px",
                color: "#316178",
                fontWeight: 500,
                fontFamily: "Source Sans Pro, sans-serif",
                textAlign: "left",
                width: "100%",
                backgroundColor: "#F2F9FF",
              }}
            >
              {" "}
              {phone}{" "}
            </Typography>
          </Box>
        </ThemeProvider>
      </Box>
    </Box>
  );
};

export default LocationInfoCard;
