// AffectedAreasForm.js
import {
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Stack,
} from "@mui/material";
import { FormControl } from "@mui/material";
import { styled } from "@mui/system";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#E9EDF2",
  marginBottom: 3,
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -1.5px) scale(0.75)",
    paddingLeft: "7px", // Adjust the translateY value as needed
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#48768c", // Set the border color here
    },
    "&:hover fieldset": {
      borderColor: "#6fa3b7", // Set the hover border color here (optional)
    },
    "&.Mui-focused fieldset": {
      borderColor: "#48768c", // Set the focused border color here
    },
  },
}));

const ReviewForm = ({ ticketDetails, handleChange }) => {


  const severityLabels = {
    Low: "Causes issues, but is still functional",
    Medium: "Requires workaround, but still works",
    High: "Does not work at all",
  };

  return (
      <Stack
        direction="column"
        spacing={0}
        sx={{
          width: "100%",
          display: "flex",
          whiteSpace: "wrap",
          alignItems: "fit-contain",
     
          mb:4
        }}
      >
          <Box
    sx={{
      position: "sticky",
      top: 0,
      backgroundColor: "#ffffff", // Ensures it's visible over content
      zIndex: 1000, // Keeps it on top
      paddingTop: "5px",
      paddingBottom: "5px",
    }}
  >
        <Typography
                   

                   sx={{
                     fontSize: "1.29rem",
                     fontWeight: 600,
                  
                 
                     width: "100%", // Makes sure it takes up full width
                     letterSpacing: "-0.03em", // Adds letter spacing
                     color: "#48768c",
                     lineHeight:'-.02em'
                 
                   }}
                 >    
          Make sure everything is correct before submitting it to us.
        </Typography>
        </Box>

        <Box sx={{height:'23vh', overflowY:'auto',                 border: "1.25px solid rgba(173, 216, 230, 0.8)", borderRadius:'5px',
          mt:'15px'
        }}>

        <Stack direction="row" sx={{ p: 1, alignItems:'center', mt:1    }}>
  

   
            <Typography
            color="textSecondary"
              sx={{
                fontSize: "1.07rem",
                fontWeight: 600,
           
                height: "10px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",

                textAlign:'center' ,
                mt:1,
                mb:.75
                
          
  
        



              }}
            >
         Subject:     {ticketDetails.category}
            </Typography>

        </Stack>


        <Box sx={{}}>
          <Typography
            color="textSecondary"
            sx={{ fontSize: ".95rem", fontWeight: 600,       p: 1, // Adds padding for better readability
            }}
          >
            Review Your Message
            </Typography>

            <Box sx={{ width: "98%", height: "auto", flexGrow: 1, mx:.75 }}>
  <Typography
    sx={{
      fontSize: ".93rem",
      fontWeight: 500,
      backgroundColor: "rgba(173, 216, 230, 0.4)",
      minHeight: "110px",
      borderRadius: "5px",
      display: "block",
      alignItems: "center",
      whiteSpace: "normal", // Allows wrapping
      wordBreak: "break-word", // Ensures long words don't overflow
      width: "100%",
      p: 1, // Adds padding for better readability
      color: "#395465",

    }}
  >
    {ticketDetails.description}
  </Typography>
</Box>
        </Box>
  
      </Box>
      </Stack>
  );
};

export default ReviewForm;
