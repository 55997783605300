import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAppContext } from "src/context/AppContext";
import useAddLocation from "src/components/payments/hooks/useAddLocation";
import useAddNewUser from "src/components/payments/hooks/useAddNewUser.js";
import usePlanDetails from "src/components/payments/hooks/usePlanDetails";
import {
  Button,
  Box,
  Fade,
  Stack,
  Card,
  Typography,
  Divider,
  Paper
} from "@mui/material";
import BlurCircularTwoToneIcon from "@mui/icons-material/BlurCircularTwoTone";
import CreditCard from "src/public/assets/icons/icons/creditCard.png";


// Load the Stripe object outside the component
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({
  clientSecret,
  customerId,
  priceId,
  apiUrl,
  onSubscriptionUpdate,
  refreshSubscription,
  onClose,
  subscriptionDetails,
  billingDetailOne, 
  billingValueOne, 
  billingDetailTwo, 
  billingValueTwo, 
  billingDetailThree, 
  billingValueThree, 
  billingDetailFour, 
  billingValueFour, 
  billingDetailFive, 
  billingValueFive,

  totalPlanCost,
  promotion,
  discountName,
  discountPercentage,
  planCost,
  discountedPlanCost,
  loadingPlanDetails  
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showSnackBar } = useAppContext();
  const { addNewLocation } = useAddLocation();
  const { addNewUser } = useAddNewUser();
  const { planDetails } = usePlanDetails();
  const [paymentElementLoaded, setPaymentElementLoaded] = useState(false);





  const billingDetails = [
    { label: billingDetailOne, value: billingValueOne },
    { label: billingDetailTwo, value: billingValueTwo },
    { label: billingDetailThree, value: billingValueThree },
    { label: billingDetailFour, value: billingValueFour },
    { label: billingDetailFive, value: billingValueFive },
  ];
  


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      setError("Stripe has not loaded or clientSecret is missing");
      return;
    }

    setLoading(true);

    try {
      // Step 1: Confirm the SetupIntent
      const { setupIntent, error: setupError } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          // Return URL if needed
        },
        redirect: "if_required",
      });

      if (setupError) {
        setError(setupError.message);
        setLoading(false);
        return;
      }

      // Step 2: Extract payment method from SetupIntent
      const paymentMethodId = setupIntent.payment_method;

      // Step 3: Create the subscription with the payment method
      const response = await fetch(
        `${apiUrl}/subscriptions/create-subscription`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            customerId,
            planDetails,
            paymentMethodId, // Include the payment method in the request
          }),
        },
      );

      const subscriptionResponse = await response.json();

      if (response.ok) {
        // Optional: Handle the first invoice payment if required
        if (subscriptionResponse.latest_invoice) {
          const paymentIntentClientSecret =
            subscriptionResponse.latest_invoice.payment_intent.client_secret;

          const { error: paymentError } = await stripe.confirmPayment({
            clientSecret: paymentIntentClientSecret,
            elements,
            confirmParams: {
              return_url: `http://${apiUrl}/dashboard/Account/Billing`, // Your return URL
            },
          });

          if (paymentError) {
            setError(paymentError.message);
            setLoading(false);
            return;
          }
        }

        // Success handling
        onSubscriptionUpdate();
        refreshSubscription();
        onClose();
        showSnackBar(
          "Subscription created and charged successfully",
          "success",
        );
      } else {
        setError(subscriptionResponse.error || "Failed to create subscription");
      }
    } catch (error) {
      console.error("Error during subscription creation:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };



  const formatCurrency = (amount) => {
    if (typeof amount !== "number" || isNaN(amount)) {
        return "$0.00"; // Handle invalid values
    }
  
    return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    });
  };

  return (
    <>
      <Box
        sx={{
          ml: 0,
          width: "100%",
          borderRadius: "15px",
          justifyContent: "space-around",
          height:'100%',  
          flexDirection: "row",  
          overflowY:'hidden' 

        }}
      >
<Stack 
  direction="column" 
  sx={{ 
    width: "100%", 
    flexWrap: "nowrap", 
    alignItems: "flex-start", // Move items to the left
    justifyContent: "flex-start", 
    pl: .75, // Optional: Add padding-left for spacing
    pt:.5,
  
  }}
>  
  <img
    src={CreditCard}
    style={{
      border: "1.25px solid rgba(203, 213, 217, 0.7)",
      width: "auto",
      height: "29px",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      padding: "1px",
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
      borderRadius: "6px",
      display: "block", // Prevents unintended spacing
    }}
  />
  
  <Typography
    align="left"
    sx={{
      fontSize: "20px",
      fontWeight: 550,
      color: "#48768C",
      letterSpacing: "-.027em",
      whiteSpace: "nowrap",
      pt: 1.5,
      pr:1,
      ml:'1px',
      mb: 1,
    }}
  >
    Activate Your Subscription
  </Typography>
</Stack>
<Stack direction="row" sx={{ width: "100%", flexWrap: "nowrap" }}>


<Fade in={!loadingPlanDetails} timeout={1000}>  

<Box sx={{ width: "50%", display: "flex", pl: .75,pt:2, flexDirection: "column", overflowX:'auto',my:.5,   pr:4 }}>

<Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                    }}
                  >
                    Your Subscription Summary
                  </Typography>

                




                


                <Divider sx={{ borderBottomWidth: "1.5px", mb:1.5, mt:1 }} />
             
                <Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                      mt:2
                    }}
                  >
                     Monthly Plan Cost
                  </Typography>

                
                  <Stack direction="row" sx={{ alignItems: "center" }}>
  <Typography
    align="left"
    sx={{
      fontSize: "15px",
      fontWeight: 550,
      color: "#6c90a2",
      letterSpacing: "-.2px",
      lineHeight: "-2",
      whiteSpace: "nowrap", // Prevent wrapping
    }}
  >
    {planDetails?.plan_name}
  </Typography>
  
  <Divider 
  sx={{
    flexGrow: 1,          // Takes up remaining width
    borderBottomWidth: 1.5,
    borderBottomStyle: "dashed",  // Change to "dashed" style
    borderColor: "#6c90a2",
    opacity: 0.5,
    mt: "12px", 
    ml: 1,
    mx: 1
  }} 
/>
    <Typography
    align="left"
    sx={{
      fontSize: "15px",
      fontWeight: 550,
      color: "#6c90a2",
      letterSpacing: "-.2px",
      lineHeight: "-2",
      whiteSpace: "nowrap", // Prevent wrapping
      ml: 1,
      mr:1,
    }}
  >
   ${planCost ? planCost + ".00" : "Unable to retrieve cost"}
  </Typography>
</Stack>

                 
                 
                 {billingDetailOne && (
                   <Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap", 
                      mt: 2,
                    }}
                  >
                    Additional Charges
                  </Typography>
                  )}
                 
                 
                 


{billingDetails.map((detail, index) => (
  <Stack direction="row" sx={{ alignItems: "center" }} key={index}>
    {/* Billing detail */}
    <Typography
      align="left"
      sx={{
        fontSize: "15px",
        fontWeight: 550,
        color: "#6c90a2",
        letterSpacing: "-.2px",
        lineHeight: "-2",
      }}
    >
      {detail.label || ""}
    </Typography>

    {/* Divider */}
    <Divider
      sx={{
        flexGrow: 1,          // Takes up remaining width
        borderBottomWidth: 1.5,
        borderBottomStyle: "dashed",  // Change to "dashed" style
        borderColor: "#6c90a2",
        opacity: 0.5,
        mt: "12px",
        ml: 1,
        mx: 1,
        display: detail.label ? "block" : "none", // Only show if label is not empty
      }}
    />

    {/* Billing value */}
    <Typography
      align="left"
      sx={{
        fontSize: "15px",
        fontWeight: 550,
        color: "#6c90a2",
        letterSpacing: "-.2px",
        lineHeight: "-2",
        pt:'2px',
        ml: 1,
        mr:1,
      }}
    >
      {detail.value || ""}
    </Typography>
  </Stack>
))}



<Stack direction="row" spacing={2} sx={{ width: "100%", flexWrap: "nowrap", alignItems: "center", justifyContent: "space-between",                       mt: 4,
mb:1,
 }}>
                <Typography
                    align="left"
                    sx={{
                      fontSize: "20px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap", 
                    }}
                  >
                    Your Monthly Cost
                  </Typography>

                <Typography
                    align="left"
                    sx={{
                      fontSize: "20px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap", 
                      mr:3,
                      mb:1,
                      pr:.85
                      
                    }}
                  >
                { promotion ? + formatCurrency(discountedPlanCost) :  totalPlanCost }
                </Typography>
                  </Stack>

                



                


            </Box>
            </Fade>
            

            <Box
  sx={{
    my:4.75,
    flexGrow: 1,
    minWidth: "300px", // Prevents shrinking too much
    maxWidth: "500px", // Keeps form at a reasonable width
    width: "100%",
    display: "flex",
    padding: 2,
    flexDirection: "column",

    pb:0,
    overflowY: "auto",
    mb:0,
    mb:'5px',
    height:'100%'
  }}
>

        <form onSubmit={handleSubmit}>
          
          {clientSecret && (
           
    <PaymentElement 
      onReady={() => setPaymentElementLoaded(true)}
      options={{
        layout: "tabs", // Ensure a structured layout for fields
      }}
    />
          )}  

          {paymentElementLoaded && (
            <Button
              type="submit"
              disabled={!stripe || !clientSecret || loading}
              sx={{
                display: "flex",
                border: "1px solid #48768C",
                borderRadius: "6px",
                padding: "8px",
                color: "#48768C",
                backgroundColor: "#FFFFFF",
                alignItems: "center",
                
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                width: "100%",
                height: "40px",
                mt:4,
                mb:1,
              
                "&:hover": {
                  backgroundColor: "#DBEAF0",
                },
                "&:active": {
                  backgroundColor: "#DBEAF0",
                },
              }}
              color="primary"
            >
              {loading ? "Processing..." : "Subscribe"}
            </Button>
          )}
        
        </form>
        </Box>  

</Stack>  


      </Box>
    </>
  );
};

// Wrapper component to ensure Elements is loaded correctly
const PaymentFormWrapper = ({
  customerId,
  priceId,
  apiUrl,
  onSubscriptionUpdate,
  onClose,
  clientSecret,
  refreshSubscription,
  subscriptionDetails,
  billingDetailOne, 
  billingValueOne, 
  billingDetailTwo, 
  billingValueTwo, 
  billingDetailThree, 
  billingValueThree, 
  billingDetailFour, 
  billingValueFour, 
  billingDetailFive, 
  billingValueFive,

  totalPlanCost,
  promotion,
  discountName,
  discountPercentage,
  planCost,
  discountedPlanCost,
  formatCurrency,
  loadingPlanDetails

}) => {
  const appearance = {
    theme: "stripe", // You can also try 'night', 'flat', 'none', etc.
    labels: 'floating',
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#7D4E4F",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
      labels: 'floating',


    },
    rules: {
 
      ".Label": {
        color: "#6c90a2",
        fontSize: "16.5px",
        marginBottom: "3px",
      },
      ".Input": {
        backgroundColor: "#F2F9FF",
        border: "1px solid #e1e6ef",
        fontSize: "14.5px",
        padding: 0,
        color: "#4E617D",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        height: "auto",
        fontFamily: "Source Sans Pro, sans-serif",
      },
      ".Input--invalid": {
        color: "#7D4E4F",
      },
      ".PaymentElement": {
        backgroundColor: "#f9fbfd",
        padding: "5px",
        borderRadius: "4px",
        boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15)",
      },
      ".Input::placeholder": {
        color: "#48768CCC", // Correct selector for placeholder text
      },
    },
  };

  // Enable the skeleton loader UI for optimal loading.
  const loader = "auto";

  return (
    <div>
      {clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, appearance, loader }}
        >
          <PaymentForm
            clientSecret={clientSecret}
            customerId={customerId}
            priceId={priceId}
            apiUrl={apiUrl}
            onSubscriptionUpdate={onSubscriptionUpdate}
            onClose={onClose}
            refreshSubscription={refreshSubscription}
            subscriptionDetails={subscriptionDetails}
            billingDetailOne={billingDetailOne}
            billingDetailTwo={billingDetailTwo}
            billingDetailThree={billingDetailThree}
            billingDetailFour={billingDetailFour}
            billingDetailFive={billingDetailFive}
            billingValueOne={billingValueOne}
            billingValueTwo={billingValueTwo}
            billingValueThree={billingValueThree}
            billingValueFour={billingValueFour}
            billingValueFive={billingValueFive}
  
            planCost={planCost}
            totalPlanCost={totalPlanCost}
            promotion={promotion}
            discountName={discountName}
            discountPercentage={discountPercentage}
            discountedPlanCost={discountedPlanCost}
            formatCurrency={formatCurrency} // Pass the formatCurrency function
            loadingPlanDetails={loadingPlanDetails} 
          />
        </Elements>
      ) : (
        <div>Loading Payment Information...</div>
      )}
    </div>
  );
};

export default PaymentFormWrapper;
