import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, NavLink } from "react-router-dom";
import {
  Box,
  Button,
  Drawer,
  Typography,
  Stack,
  Badge,
  Paper,
} from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import Scrollbar from "../../components/scrollbar";
import NavSection from "../../components/nav-section";
import { useAuth0 } from "@auth0/auth0-react";
import "@fontsource/montserrat"; // Replace 'montserrat' with the actual font you're using
import sendIcon from "../../public/assets/icons/navbar/send.png";
import helpIcon from "../../public/assets/icons/navbar/help.png";
import bugIcon from "../../public/assets/icons/navbar/bug.png";
import reviewIcon from "../../public/assets/icons/navbar/review.png";
import BugReportDialog from "../../components/modal/Support/BugReport/BugReportDialog";
import LeaveFeedbackDialog from "../../components/modal/Support/FeedbackDialog/LeaveFeedbackDialog";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import LinkWithIcon from "./LinkWithIcon";
import homeIcon from "../../public/assets/icons/navbar/home2.png";
import messageIcon from "../../public/assets/icons/navbar/message2.png";
import contactsIcon from "../../public/assets/icons/navbar/contacts.png";
import reportsIcon from "../../public/assets/icons/navbar/reports.png";
import accountsIcon from "../../public/assets/icons/navbar/account.png";
import { useUserLogo } from "../../context/LogoContext";
import NewNotification from "../../components/Custom-Notifications/NewNotification";
import { useAccountContext } from "src/context/AccountContext";
import CheckListModal from "src/components/NewUserChecklist/checklistModal.js";
import SetupComplete from "src/components/NewUserChecklist/setupComplete.js";
import SuccessDialog from "src/components/modal/SuccessDialog";
import checklistDone from "src/public/assets/illustrations/checklistDone1.png";
import { handleCompleteSetup } from "src/components/NewUserChecklist/completeSetup";
import { useAuth } from "src/context/AuthContext";
import { useSingleUser } from "src/context/SingleUserContext";
import { useAppContext } from "src/context/AppContext";
import phoneIcon from "../../public/assets/icons/messageModal/phone.png";
import ContactListIcon from "src/public/assets/icons/icons/contactList.svg";
import { useUserSelectedNotifications } from "../../context/UserNotificationsContext";


const NAV_WIDTH = 260;

Nav.propTypes = {
  reloadComponents: PropTypes.func.isRequired,
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
  user: PropTypes.object, // Add user prop
};

export default function Nav({ openNav, onCloseNav, reloadComponents }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { userLogoUrl, uploadLogo } = useUserLogo();
  const [appLogoUrl, setAppLogoUrl] = useState(
    "/assets/illustrations/genericLogo.png",
  );
  const [devLogoUrl, setdevLogoUrl] = useState(
    "/assets/illustrations/devLogo.png",
  );

  const [openModal, setOpenModal] = useState(false);
  const [isBugReportDialogOpen, setIsBugReportDialogOpen] = useState(false); // State variable for bug report dialog
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false); // State variable for feedback dialog
  const [isHelpCenterOpen, setIsHelpCenterOpen] = useState(false);
  const navigate = useNavigate(); // Use useNavigate hook instead of useHistory
  const { accountData, completedOnboarding, fetchAccountData, onboardingData } =
    useAccountContext();
  const { isLoggedIn, subUserData } = useAuth();
  const [isNewUserChecklistOpen, setIsNewUserChecklistOpen] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const { fetchLatestSingleUserData } = useSingleUser();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { fetchLatestUserData } = useAppContext();
    const { selectedNotifications } = useUserSelectedNotifications();
  

  const handleOpenBugReportDialog = () => {
    setIsBugReportDialogOpen(true); // Open bug report dialog
  };

  const handleCloseBugReportDialog = () => {
    setIsBugReportDialogOpen(false); // Close bug report dialog
  };

  const handleOpenFeedbackDialog = () => {
    setIsFeedbackDialogOpen(true); // Open feedback dialog
  };

  const handleCloseFeedbackDialog = () => {
    setIsFeedbackDialogOpen(false); // Close feedback dialog
  };

    

  const handleDashboardClick = () => {
    navigate("dashboard"); // Navigate to the Dashboard page
  };
  const handleReportsClick = () => {
    navigate("reports"); // Navigate to the Reports page
  };
  const handleMessagesClick = () => {
    navigate("messages"); // Navigate to the Messages page
  };
  const handleContactsClick = () => {
    navigate("contacts"); // Navigate to the Contacts page
  };
  const handleAccountClick = () => {
    navigate("account"); // Navigate to the Account page
  };

  const handleHelpCenterClick = () => {
    navigate("help-center"); // Navigate to the Help Center page
  };

  const handleOpenModal = () => {
    // Check if any notification has selected_data as null
    if (selectedNotifications.some(notification => notification.selected_data === null) && accountData && accountData.onboarding_complete === true) {
      navigate("/dashboard/Account/UserNotifications"); // Navigate instead of returning early
      return;
    }
  
    // If onboarding is not complete, open the checklist
    if (accountData && accountData.onboarding_complete === false) {
      setIsNewUserChecklistOpen(true);
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {}, [reloadComponents]);

  const handleCloseSuccessDialog = async () => {
    try {
      await handleCompleteSetup(
        user,
        getAccessTokenSilently,
        accountData,
        fetchAccountData,
        fetchLatestSingleUserData,
        fetchLatestUserData,
      );
    } catch (error) {
      console.error("Error:", error);
    }
    setShowSuccessDialog(false);
  };

  useEffect(() => {
    if (completedOnboarding && accountData && !isNewUserChecklistOpen) {
      if (
        accountData.single_user === false &&
        isLoggedIn &&
        !showSuccessDialog
      ) {
        setShowSuccessDialog(true);
      } else if (accountData.single_user === true && !showSuccessDialog) {
        setShowSuccessDialog(true);
      } else if (showSuccessDialog) {
        setShowSuccessDialog(false);
      }
    } else if (showSuccessDialog) {
      setShowSuccessDialog(false);
    }
  }, [completedOnboarding, isLoggedIn, isNewUserChecklistOpen, accountData]);

  useEffect(() => {
    if (completedOnboarding) {
      setIsNewUserChecklistOpen(false);
    }
  }, [completedOnboarding, isNewUserChecklistOpen]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap"
      />
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Display user logo at the top if found */}
        {userLogoUrl && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={userLogoUrl}
              alt="User Logo"
              style={{ maxWidth: "90px", maxHeight: "auto" }}
            />
          </Box>
        )}

        {/* Display app logo if user logo is not found */}
        {!userLogoUrl && accountData && (
          <Box
            sx={{
              mt: 0.25,
              pr: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={appLogoUrl}
              alt="Your App's Logo"
              style={{
                width: "60%", // Adjust the width as needed for app or dev logo
              }}
            />
          </Box>
        )}

        {/* Separator line */}
        <Box
          sx={{
            mt: 2,
            mb: 0.5,
            width: "80%",
            height: "2px",
            bgcolor: "#B8C2CC",
          }}
        />
      </Box>
      <Box
        sx={{
          mb: 2.5,
          mt: 1.25,
          alignItems: "center",
          justifyContent: "center",
          mx: "auto",
        }}
      >
        <Button
          variant="contained"
          onClick={handleOpenModal}
          disabled={!subUserData}
          sx={{
            display: "flex", // Added display property
            backgroundColor: "#3FC2A2",
            "&:hover": {
              backgroundColor: "#2BBB9E",
              transition: "background-color 0.3s ease",
            },
            "&.Mui-disabled": {
              backgroundColor: "#d1f6eb", // Change to your desired disabled color
              color: "#3fc2a5", // Optional: Adjust text/icon color
              opacity: 0.7, // Optional: Control transparency
            },
            color: "#FFFFFF",
            borderRadius: "8px",
            boxShadow: "0 8px 24px rgba(0, 0, 0, 0.2)",
            border: "none",
            transition: "box-shadow 0.3s ease",
            px: "15px",
            py: "10px",
          }}
        >
          <img
            src={sendIcon}
            alt="dashboard"
            style={{ width: 22, height: 22, justifyContent: "center" }}
          />

          <div>
            <Typography
              sx={{
                fontSize: "1.2rem",
                letterSpacing: "0.03rem",
                fontWeight: 600,
                whiteSpace: "nowrap",
                mx: "14px",
              }}
            >
              New Notification
            </Typography>
          </div>
        </Button>
      </Box>

      <LinkWithIcon
        iconSrc={homeIcon}
        text="Dashboard"
        to="/dashboard/app" // Specify the route path for the Dashboard page
      />
      <LinkWithIcon
        iconSrc={messageIcon}
        text="Messages"
        to="/dashboard/Messages" // Specify the route path for the Messages page
      />
      <LinkWithIcon
        iconSrc={contactsIcon}
        text="Customers"
        to="/dashboard/CustomersPage" // Specify the route path for the Customers page
      />

      {!accountData?.single_user && subUserData?.is_admin && (
      <LinkWithIcon
        iconSrc={reportsIcon}
        text="Reports"
        to="/dashboard/Reports" // Specify the route path for the Reports page
      />
      )}

      <LinkWithIcon
        iconSrc={accountsIcon}
        text="Account"
        to="/dashboard/Account/Profile" // Specify the route path for the Account page
        style={{ marginLeft: "20px" }}
      />

      <Box
        sx={{
          mb: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          borderRadius: "8px", // Rounded corners on hover
        }}
      >
        <Typography
          sx={{
            mb: 0.5,
            color: "#F0F0F0",
            pl: "10px",
            fontWeight: 600,
            fontSize: "14px",
            mt: 2,
          }}
        >
          Support
        </Typography>
        <LinkWithIcon
          iconSrc={helpIcon}
          text="Help Center"
          to="/dashboard/help-center" // Specify the route path for the Dashboard page
        />
        <Box
          onClick={handleOpenBugReportDialog} // Add onClick handler to open bug report dialog
          sx={{
            height: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            py: "10px",
            px: "10px",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "rgba(255, 255, 255, 0.02)",
              borderRadius: "10px",
            },
          }}
        >
          <img
            src={bugIcon}
            alt="Icon"
            style={{
              marginLeft: "13px",
              width: 24,
              height: 24,
              marginRight: "8px",
            }}
          />
          <Typography
            sx={{
              color: "#F0F0F0",
              fontSize: 14,
              fontWeight: 500,
              pl: 0.75,
              letterSpacing: "0.02rem",
            }}
          >
            Report a bug
          </Typography>
        </Box>
        <Box
          onClick={handleOpenFeedbackDialog} // Add onClick handler to open bug report dialog
          sx={{
            height: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            py: "10px",
            px: "10px",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "rgba(255, 255, 255, 0.02)",
              borderRadius: "10px",
            },
          }}
        >
          <img
            src={reviewIcon}
            alt="Icon"
            style={{
              marginLeft: "13px",
              width: 24,
              height: 24,
              marginRight: "8px",
            }}
          />
          <Typography
            sx={{
              color: "#F0F0F0",
              fontSize: 14,
              fontWeight: 500,
              pl: 0.75,
              letterSpacing: "0.02rem",
            }}
          >
            Feedback
          </Typography>
        </Box>
      </Box>

      {/* Bottom text */}
      <Box
        sx={{
          mb: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          mt: "auto",
        }}
      >
        {userLogoUrl && (
          <Box sx={{ pb: 0, mt: 2, px: 3 }}>
            <Stack alignItems="center" spacing={3}>
              <Box
                sx={{
                  mt: 0,
                  pr: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={appLogoUrl}
                  alt="Your App's Logo"
                  style={{ width: "65%" }}
                />
              </Box>
            </Stack>
          </Box>
        )}

{!userLogoUrl && (
<Typography sx={{ color: "#B8C2CC", mb: 0.5, mt: 2, fontSize: "14px", }}>
                  NanoNotify.io  
               </Typography>
)}

        <Typography variant="body2" sx={{ color: "#B8C2CC", mb: 1.5, mt:1 }}>
          Made with ❤️ in Portland, OR
        </Typography>
        {accountData && accountData.dev && (
                    <>
         
          <Paper
            elevation={1}
            sx={{
              padding: 1,
              backgroundColor: "#dfeaee",
              borderRadius: "6px",
              mt: 0.25,
              mb: 1.75,
              px: 2,
              border:'.5px solid #395465', 
              width:'90%', 
              
            }}
          >
    
            <Typography
              sx={{
                color: "#aa5869",
                fontSize: 16.5,
                fontWeight: 700,
                letterSpacing: -.02,
                mt:'2px'
              }}
            >
              Developer Account
            </Typography>
            <Typography sx={{ color: "#48768C", mb: 0.5, mt:'-2px', fontSize: "14px", }}>
                  Version: 1.0.0.2
               </Typography>
          </Paper>

               
               </>
        )}
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 }, width: { lg: NAV_WIDTH }, height: "100vh" }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              height: "100%",

              bgcolor: "#48768C",
              borderRightStyle: "dashed",
              color: "#F0F0F0",
              boxShadow: "3px 4px 8px rgba(0, 0, 0, 0.50)", // Adjusted shadow properties
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "#48768C",
              color: "#F0F0F0",
              boxShadow: "3px 4px 8px rgba(0, 0, 0, 0.50)", // Adjusted shadow properties
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
      <NewNotification
        open={openModal}
        onClose={handleCloseModal}
        phoneIcon={phoneIcon}
        ContactListIcon={ContactListIcon}
      />
      <CheckListModal
        open={isNewUserChecklistOpen}
        onClose={() => setIsNewUserChecklistOpen(false)}
        completedOnboarding={completedOnboarding}
        user={user}
      />
      <SuccessDialog
        successDialogOpen={showSuccessDialog}
        handleButtonAction={handleCloseSuccessDialog}
        header={"Setup Complete!"}
        image={checklistDone}
        caption={"Great Work!"}
        body={"Your account is now set up and ready to use."}
        buttonText={"Close"}
        user={user}
      />

      {isBugReportDialogOpen && (
        <BugReportDialog
          isOpen={isBugReportDialogOpen}
          onClose={handleCloseBugReportDialog}
        />
      )}
      {isFeedbackDialogOpen && (
        <LeaveFeedbackDialog
          isOpen={isFeedbackDialogOpen}
          onClose={handleCloseFeedbackDialog}
        />
      )}
    </Box>
  );
}
