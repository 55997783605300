import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import {
  Card,
  Typography,
  Box,
  Button,
  Paper,
  Stack,
  Fade,
} from "@mui/material";
import { fShortenNumber } from "../utils/formatNumber";
import Iconify from "../components/iconify";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "../context/AppContext";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { fetchNotifications } from "../utils/notificationUtils";
import { useWidgetContext } from "src/context/WidgetContext";

const StyledIcon = styled("div")(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.9)", // Adjust the alpha value as needed
  borderRadius: "50%",
  backgroundColor: "rgba(42, 61, 79, 0.7)", // Adjust the alpha value as needed
  width: theme.spacing(8),
  height: theme.spacing(8),
  display: "flex",
  justifyContent: "flex-end",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  transition: "background-color 0.3s, transform 0.3s",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const apiUrl = process.env.REACT_APP_API_URL;

export default function AppWidgetSummary({
  title,
  total,
  onClick,
  icon,
  formatAsCurrency = false,
  sx,
  selected,
  fetchData = true,
  ...other
}) {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [totalValue, setTotalValue] = useState(total);
  const [currentTitle, setCurrentTitle] = useState(title);
  const { reloadComponents } = useAppContext();
  const {
    loadingRecentActivity,
    loadingScheduledNotifications,
    loadingPendingApproval,
  } = useWidgetContext();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Box sx={{ mx: 1, mt: 0.5, mb: 1 }}>
      <Paper
        elevation={selected ? 8 : 6}
        onClick={handleClick}
        sx={{
          justifyContent: "space-between",
          py: 2,
          px: 2.5,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: "rgba(173, 216, 230, 0.3)",
          borderRadius: "15px",
          width: "auto",
          transition: "background-color 0.3s, transform 0.3s",
          cursor: "pointer",
          transform: selected ? "scale(1.02)" : "scale(1)",
          border: "1.25px solid rgba(173, 216, 230, 0.5)",
          maxWidth: "100%",

          "&:hover": {
            transform: selected ? "scale(1.02)" : "scale(1.01)",
          },
        }}
      >
        <Stack
          spacing={1}
          sx={{
            textAlign: "left",
            maxWidth: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",

            display: "block",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="b5"
              sx={{
                color: "#48768C",
                fontWeight: selected ? 600 : 500,
                letterSpacing: "-1%",
                lineHeight: "-1%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {currentTitle}
            </Typography>
          </Box>
          <Fade in={true} timeout={300}>
            <Typography
              variant="h6"
              sx={{
                color: "#48768C",
                fontSize: 16.5,
                fontWeight: selected ? 600 : 500,
                lineHeight: "-2%",
                letterSpacing: "-2%",
              }}
            >
              {total}
            </Typography>
          </Fade>
        </Stack>

        <StyledIcon>
          <Button sx={{ color: "white" }} onClick={handleClick}>
            {icon}
          </Button>
        </StyledIcon>
      </Paper>
    </Box>
  );
}

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string.isRequired,
  total: PropTypes.number,
  formatAsCurrency: PropTypes.bool,
  sx: PropTypes.object,
  fetchData: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
