import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Fade,
  Stack,
  Card,
  CardContent,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "src/public/assets/icons/icons/deleteRed.png";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import { useAppContext } from "src/context/AppContext";
import { useAuth } from "src/context/AuthContext";
import { useAccountContext } from "src/context/AccountContext";
import { useUserNotificationsContext } from "src/context/UserNotificationsContext";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { customTheme } from "./textField";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

const LocationInfoCard = ({
  open,
  onClose,
  onConfirm,
  itemName,
  item,
  isLoading,
  header,
  message,
  subText,
  buttonAction,
  addedUserPrice,
  hideReviewDialog,
  name,
  setName,
  email,
  setEmail,
  phone,
  setPhone,
  nameTouched,
  setNameTouched,
  emailTouched,
  setEmailTouched,
  phoneTouched,
  setPhoneTouched,
  isFormValid,
  setIsFormValid,
  handleEmailChange,
  handleNameChange,
  handlePhoneChange,
  validateEmail,
  validatePhoneNumber,
  handleOpenCancelCloseDialog,
  isCancelCloseOpen,
  handleCloseCancelCloseDialog,
  handleCreateNewLocationClick,
  locationNameTaken,
  editLocationData,
  handleEditLocationSubmit,
  handleEditLocationClick,
  isButtonDisabled,
  handlePhoneBlur,
  handleEmailBlur,
  locationInfoChanged,
  validateName,
  handleNameBlur,
  newLocationName,
  hideWarning,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        height: "100%", // Set a fixed height
        width: "98%",
      }}
    >
      <Box>
        <ThemeProvider theme={customTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 1.5,
              height: "auto",
              alignItems: "center",
            }}
          >
            <DriveFileRenameOutlineOutlinedIcon
              sx={{
                backgroundColor: "#E3F2FD",
                color: "#48768C",
                fontSize: "29px",
                border: "1px solid rgba(173, 216, 230, 0.4)",
                borderRadius: "5px",
                mr: 1,
                padding: "4px",
              }}
            />
            <TextField
              error={!validateName(newLocationName) && nameTouched}
              placeholder="Location Name"
              variant="outlined"
              fullWidth
              size="small"
              value={newLocationName}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              sx={{
                backgroundColor: "#FFFFFF",
                input: {
                  color:
                    !validateName(newLocationName) && nameTouched
                      ? "#48768C"
                      : "#48768C",
                  fontWeight: 550,
                  fontSize: "14px",
                  padding: 0,
                  alignItems: "center",
                  fontFamily: "Source Sans Pro, sans-serif",
                  textAlign: "left",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor:
                      !validateName(newLocationName) && nameTouched
                        ? "#B22222"
                        : "#48768C",
                  },
                  "&:hover fieldset": {
                    borderColor:
                      !validateName(newLocationName) && nameTouched
                        ? "#B22222"
                        : "#365D6C", // Darker red on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor:
                      !validateName(newLocationName) && nameTouched
                        ? "#8B0000"
                        : "#1E4D5F", // Darker shade for focused state
                  },
                },
              }}
              InputProps={{
                endAdornment: locationNameTaken && !hideWarning && (
                  <InputAdornment position="end">
                    <Typography sx={{ color: "#8C4350", fontSize: 13 }}>
                      Location Name Already Exists
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </ThemeProvider>
        <ThemeProvider theme={customTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 1.5,
              height: "auto",
              alignItems: "center",
            }}
          >
            <AlternateEmailOutlinedIcon
              sx={{
                backgroundColor: "#E3F2FD",
                color: "#48768C",
                fontSize: "29px",
                border: "1px solid rgba(173, 216, 230, 0.4)",
                borderRadius: "5px",
                mr: 1,
                padding: "4px",
              }}
            />

            <TextField
              error={!validateEmail(email) && emailTouched}
              placeholder="Location Email"
              variant="outlined"
              size="small"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              sx={{
                backgroundColor: "#FFFFFF",
                input: {
                  color:
                    !validateEmail(email) && emailTouched
                      ? "#48768C"
                      : "#48768C",
                  fontWeight: 550,
                  fontSize: "14px",
                  padding: 0,
                  alignItems: "center",
                  fontFamily: "Source Sans Pro, sans-serif",
                  textAlign: "left",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor:
                      !validateEmail(email) && emailTouched
                        ? "#B22222"
                        : "#48768C",
                  },
                  "&:hover fieldset": {
                    borderColor:
                      !validateEmail(email) && emailTouched
                        ? "#B22222"
                        : "#365D6C", // Darker red on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor:
                      !validateEmail(email) && emailTouched
                        ? "#8B0000"
                        : "#1E4D5F", // Darker shade for focused state
                  },
                },
              }}
            />
          </Box>
        </ThemeProvider>
        <ThemeProvider theme={customTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mb: 1,
              height: "auto",
              alignItems: "center",
            }}
          >
            <LocalPhoneOutlinedIcon
              sx={{
                backgroundColor: "#E3F2FD",
                color: "#48768C",
                fontSize: "29px",
                border: "1px solid rgba(173, 216, 230, 0.4)",
                borderRadius: "5px",
                mr: 1,
                padding: "4px",
              }}
            />
            <TextField
              error={!validatePhoneNumber(phone) && phoneTouched}
              placeholder="Location Phone"
              variant="outlined"
              size="small"
              fullWidth
              value={phone}
              onChange={handlePhoneChange}
              onBlur={handlePhoneBlur}
              sx={{
                backgroundColor: "#FFFFFF",
                input: {
                  color:
                    !validatePhoneNumber(phone) && phoneTouched
                      ? "#48768C"
                      : "#48768C",
                  fontWeight: 550,
                  fontSize: "14px",
                  padding: 0,
                  alignItems: "center",
                  fontFamily: "Source Sans Pro, sans-serif",
                  textAlign: "left",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor:
                      !validatePhoneNumber(phone) && phoneTouched
                        ? "#B22222"
                        : "#48768C",
                  },
                  "&:hover fieldset": {
                    borderColor:
                      !validatePhoneNumber(phone) && phoneTouched
                        ? "#B22222"
                        : "#365D6C", // Darker red on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor:
                      !validatePhoneNumber(phone) && phoneTouched
                        ? "#8B0000"
                        : "#1E4D5F", // Darker shade for focused state
                  },
                },
              }}
            />
          </Box>
        </ThemeProvider>
      </Box>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          mt: 5,
          mb: 2,
          width: "50%",
          ml: "auto",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleOpenCancelCloseDialog}
          sx={{
            border: "1px solid #8C435029",
            borderRadius: "6px",
            color: "#8C4350",
            backgroundColor: "#8C435021",
            padding: "1px 0", // Same padding as the first button
            fontSize: 13.76, // Matching font size for consistency
            width: "200px",
            height: "35px", // Fixed height to match the first button
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              backgroundColor: "#8C435017",
            },
            "&:active": {
              backgroundColor: "#A4C5D230",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleEditLocationClick}
          disabled={
            isLoading ||
            !validateEmail(email) ||
            !validatePhoneNumber(phone) ||
            !newLocationName.trim() ||
            locationNameTaken ||
            isButtonDisabled ||
            !locationInfoChanged
          }
          sx={{
            whiteSpace: "pre-wrap",
            border: "1px solid #A4C5D2E8",
            borderRadius: "6px",
            padding: "1px 0", // Consistent padding
            color: "#48768C",
            backgroundColor: "#FFFFFF",
            fontSize: 13.76,
            width: "490px",
            height: "35px", // Fixed height to match the first button
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              backgroundColor: "#DBEAF0",
            },
            "&:active": {
              backgroundColor: "#DBEAF0",
            },
          }}
        >
          {isLoading ? "Saving...." : "Save Changes"}
        </Button>
      </Stack>
    </Box>
  );
};

export default LocationInfoCard;
