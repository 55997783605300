import { useMemo } from "react";



//**IMPORTANT! IF TOTAL NEW COST TESTS FAIL WHEN TESTING USING ACCOUNT W/O DISCOUNT, "TOTALNEWCOST" AND THE END OF THIS HOOK SHOULD BE THE ONLY THAT THAT NEEDS TO BE ADJUSTED" */
//** MODIFYING ANY OTHER FUNCTIONS MAY RESULT IN A CHAIN OF BREAKING CHANGES IN THE REVIEW DIALOG. USE CAUTION WHEN MAKING CHANGES TO THIS HOOK  */

export const useCalculateChanges = ({
  accountData,
  addedUser,
  addedLocation,
  deletedUser,
  deletedLocation,
  upgradedPlan,
  downgradedPlan,
}) => {
  const PLAN_COSTS = {
    singleUser: { cost: 5.00, name: "Single User Plan" },
    multiUser: { cost: 10.00, name: "Multi User Plan" },
    multiLocation: { cost: 20.00, name: "Multi Location Plan" },
  };

  const ADDITIONAL_COSTS = {
    addedUser: 5.00,
    addedLocation: 10.00,
  };



  const isSingleUser = accountData.single_user && accountData?.single_location;
  const isMultiUser = !accountData.single_user && accountData?.single_location;
  const isMultiLocation = !accountData.single_user && !accountData?.single_location;


  let planName = "";

  if (isSingleUser) {
    planName = "Single User Plan";
  } else if (isMultiUser) {
    planName = "Multi-User Plan";
  } else if (isMultiLocation) {
    planName = "Multi-Location Plan";
  }



  const currentPlan = isSingleUser
    ? PLAN_COSTS.singleUser
    : isMultiUser
    ? PLAN_COSTS.multiUser
    : PLAN_COSTS.multiLocation;

  const calculations = useMemo(() => {
    let additionalUserCost = 0;
    let additionalLocationCost = 0;
    let planDifference = 0;
    let additionalUserIncrease = false;


    const totalUsers = accountData.total_employees || 0;
    let totalLocations = accountData.total_locations || 0;


    let includedUser = false;
    let includedLocation = false

    //Used for when New User Creates Multi Location Account & Hasnt Added Additional User Yet
    let includedPlanUser = false

    // **Calculate additional user cost**
    if (addedUser) {
      const effectiveUserCount = totalUsers + 1 - (deletedUser ? 1 : 0);

      

      // Check if both a user and location are added
      if (addedLocation && addedUser) {
        additionalUserCost = 0; // Set additional user cost to 0 if both are added
        includedUser = true
      } else if (isMultiUser) {
        // Multi User accounts get 2 free users
        if (effectiveUserCount > 2) {
          additionalUserCost = ADDITIONAL_COSTS.addedUser;
        } else {
          additionalUserCost = 0;
          includedUser = true;
        }
      } 
      if (effectiveUserCount > totalLocations) {
        additionalUserCost = ADDITIONAL_COSTS.addedUser;
      } else {
        additionalUserCost = 0;
        includedUser = true;
      }

      if ((isMultiLocation && effectiveUserCount < totalLocations && !addedLocation) && accountData.total_employees < 1) {
        includedUser = true;
      }

      if (isMultiLocation && addedLocation ) {
        includedUser = true
        additionalUserCost = 0;
      }

      if (isMultiLocation && accountData.total_employees === 1 ) {
        includedPlanUser = true
        additionalUserCost = 0;
      }





    }





    // **Calculate additional location cost**
    if (addedLocation && accountData.total_locations <= 2 ) {
      additionalLocationCost += ADDITIONAL_COSTS.addedLocation;
      totalLocations += 1;
    }

    if (addedLocation && accountData.total_locations === 1 ) {
      additionalLocationCost = 0;
      includedLocation = true
    }
    

    if (deletedLocation) {
      additionalLocationCost -= ADDITIONAL_COSTS.addedLocation;

    }

    // **Calculate price difference if deleting a user**
    if (deletedUser) {
      const effectiveUserCount = totalUsers - 1;
    
      if (isMultiUser) {
        if (effectiveUserCount < 2) {
          additionalUserCost = 0; // No extra users left to charge for
        } 
        
        if (effectiveUserCount === 3 && !downgradedPlan) {
          additionalUserCost = 0; // Adjust cost for exactly 3 users
        }
        
        if (effectiveUserCount > 3 && !downgradedPlan) {
          additionalUserCost = 0; // No extra users left to charge for
        }
      
        


        
        
        else {
          // Deduct $5.00 for each user removed
          additionalUserCost -= ADDITIONAL_COSTS.addedUser; // Deduct $5.00

        }
       
      } 





            if (isMultiLocation && effectiveUserCount < totalLocations) {
              includedUser = true
            }    

            if (isMultiLocation && effectiveUserCount >= totalLocations && !downgradedPlan ) {
              includedUser = false
              additionalUserCost = -5; // No extra users left to charge for

            }   

    
    }





    // **Handle plan upgrades/downgrades**
    let newPlanCost = currentPlan.cost;
    let oldPlanCost = currentPlan.cost
    let newPlanName = currentPlan.name;

    if (upgradedPlan) {
      newPlanCost = PLAN_COSTS.multiLocation.cost;
      newPlanName = PLAN_COSTS.multiLocation.name;
      planDifference = newPlanCost - currentPlan.cost;
    }

    if (downgradedPlan) {
      // For downgrade, check whether it's a multi-location or multi-user to adjust accordingly
      if (isMultiLocation) {
        newPlanCost = PLAN_COSTS.multiUser.cost;
        newPlanName = PLAN_COSTS.multiUser.name;
        
      } else if (isMultiUser) {
        newPlanCost = PLAN_COSTS.singleUser.cost;
        newPlanName = PLAN_COSTS.singleUser.name;
        additionalUserCost = 0
      }
      planDifference = newPlanCost - currentPlan.cost;
      oldPlanCost = currentPlan.cost
    }


  if (deletedLocation && totalUsers === totalLocations && !downgradedPlan) {
    additionalUserIncrease = true;
    additionalUserCost = (totalUsers - (totalLocations - 1)) * ADDITIONAL_COSTS.addedUser;
  }







//**IMPORTANT! IF TOTAL NEW COST TESTS FAIL WHEN TESTING USING ACCOUNT W/O DISCOUNT. THE FUNCTION BELOW SHOULD ONLY NEED TO BE ADJUSTED */
//** MODIFYING ANY OTHER FUNCTIONS MAY RESULT IN A CHAIN OF BREAKING CHANGES IN THE REVIEW DIALOG. USE CAUTION WHEN CHANGES TO THIS HOOK  */

    // **Calculate total cost**
    const totalNewCost = downgradedPlan && isSingleUser
      ? 5.00  // Flat rate for single-user plan, overriding other costs
      : (upgradedPlan || downgradedPlan ? newPlanCost + planDifference : 0) +
        additionalUserCost +
        additionalLocationCost;



        




    return {

      planName,
      newPlanName,
      planCost: newPlanCost,
      totalUsers,
      totalLocations,
      additionalUserCost: additionalUserCost > 0 ? additionalUserCost : 0, // Keep it as an integer      additionalLocationCost,
      totalNewCost,
      newPlanCost,
      includedUser,
      isMultiUser,
      isMultiLocation,
      additionalLocationCost,
      additionalUserIncrease,
      oldPlanCost,
      includedLocation,
      includedPlanUser
    };
  }, [
    addedUser,
    addedLocation,
    deletedUser,
    deletedLocation,
    upgradedPlan,
    downgradedPlan,
  ]);

  return calculations;
};