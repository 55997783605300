import React, { useState, useEffect } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Stack,
  Button,
  Card,
  Tabs,
  Tab,
  Fade,
  DialogActions,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSubscriptionDetails } from "../routes/useSubscriptionDetails";
import { useValidateSubscription } from "../routes/validateSubscription";
import { useAppContext } from "src/context/AppContext";
import useAddLocation from "src/components/payments/hooks/useAddLocation";
import useAddNewUser from "src/components/payments/hooks/useAddNewUser.js";
import { loadStripe } from "@stripe/stripe-js";
import PlanCard from "./planCard";
import EditForm from "./EditForm";
import CreditCard from "src/public/assets/icons/icons/creditCard.png";
import { Edit } from "@mui/icons-material";
import BillingInfoForm from "./BillingInfoForm";
import { use } from "react";
import { object } from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

const NewSubscription = ({
  open,
  onClose,
  planDetails,
  customerId,
  onSubscriptionUpdate,
  subscriptionId,
  refetchSubscriptionDetails,
  billingAddress,
  subUserData,
  billingHistory,
  billingContact,
  fetchBillingHistory,
  handleSubscriptionUpdate,
  billingDetailOne, 
  billingValueOne, 
  billingDetailTwo, 
  billingValueTwo, 
  billingDetailThree, 
  billingValueThree, 
  billingDetailFour, 
  billingValueFour, 
  billingDetailFive, 
  billingValueFive,

  totalPlanCost,
  promotion,
  discountName,
  discountPercentage,
  planCost,
  discountedPlanCost,
  loadingPlanDetails,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);

  const priceId = planDetails ? planDetails.plan_price_id : null;
  const { refreshSubscription } = useValidateSubscription();
  const [loading, setLoading] = useState(false);
  const { showSnackBar } = useAppContext();
  const [selectedTab, setSelectedTab] = useState(0);
  const [warning, setWarning] = useState(false);
  const [values, setValues] = React.useState({
    firstName: billingContact?.first_name,
    lastName: billingContact?.last_name,
    address: billingAddress?.line1,
    address2: billingAddress?.line2,
    city: billingAddress?.city,
    state: billingAddress?.state,
    zip: billingAddress?.postal_code,
    email: billingContact?.email,
  });
  const [errors, setErrors] = useState({});
  const [sameAddress, setSameAddress] = React.useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const [unconfirmedFields, setUnconfirmedFields] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: billingContact?.first_name,
    lastName: billingContact?.last_name,
    address: billingAddress?.line1,
    address2: billingAddress?.line2,
    city: billingAddress?.city,
    state: billingAddress?.state,
    zip: billingAddress?.postal_code,
    email: billingContact?.email,
  });

  const [dataChanged, setDataChanged] = useState(false);

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const [selectedState, setSelectedState] = useState(values.state);
  const [checked, setChecked] = useState(true);

  const handleCheckboxChange = () => {
    setWarning(false);

    if (!checked) {
      setValues({
        firstName: billingContact?.firstName,
        lastName: billingContact?.lastName,
        address: billingAddress?.line1,
        address2: billingAddress?.line2,
        city: billingAddress?.city,
        state: billingAddress?.state,
        zip: billingAddress?.postal_code,
        email: billingContact?.email,
      });
      setErrors({});
      setWarning(false);
      setButtonDisabled(true);
      setChecked(true);
    }
    if (checked) {
      setValues({
        firstName: values.firstName,
        lastName: values.lastName,
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        email: values.email,
      });
      setChecked(false);
    }

    setErrors((prevErrors) => ({
      ...prevErrors, // Keep the current state
      address: false, // Clear specific field errors
      address2: false,
      city: false,
      state: false,
      zip: false,
    }));
  };

  useEffect(() => {
    if (open) setSelectedTab(0);
    setErrors({});
    setWarning(false);
    setValues({
      firstName: billingContact?.first_name,
      lastName: billingContact?.last_name,
      address: billingAddress?.line1,
      address2: billingAddress?.line2,
      city: billingAddress?.city,
      state: billingAddress?.state,
      zip: billingAddress?.postal_code,
      email: billingContact?.email,
    });
    setInitialValues({
      firstName: billingContact?.first_name,
      lastName: billingContact?.last_name,
      address: billingAddress?.line1,
      address2: billingAddress?.line2,
      city: billingAddress?.city,
      state: billingAddress?.state,
      zip: billingAddress?.postal_code,
      email: billingContact?.email,
    });
    setSameAddress(true);
    setShowErrorMessage(false);
    setButtonDisabled(true);
    setChecked(true);
  }, [open, billingAddress, billingContact]);

  useEffect(() => {
    if (
      values &&
      values.firstName?.trim().toLowerCase() ===
        subUserData?.first_name?.trim().toLowerCase()
    ) {
      setErrors((prev) => ({ ...prev, firstName: false }));

      setWarning(false);
    }
    if (
      values &&
      values.lastName?.trim().toLowerCase() ===
        subUserData?.last_name?.trim().toLowerCase()
    ) {
      setErrors((prev) => ({ ...prev, lastName: false }));

      setWarning(false);
    }
    if (
      values &&
      values.email?.trim().toLowerCase() ===
        subUserData?.email?.trim().toLowerCase()
    ) {
      setErrors((prev) => ({ ...prev, email: false }));

      setWarning(false);
    }
  }, [values.firstName, values.lastName, values.email]);

  useEffect(() => {
    if (warning) {
      setButtonDisabled(true);
    }
    if (
      values.address === "" ||
      values.city === "" ||
      values.state === "" ||
      values.zip === ""
    ) {
      setButtonDisabled(true);
    }
  }, [errors]);

  useEffect(() => {
    if (!customerId || !priceId) {
      return;
    }

    // Get the access token
    getAccessTokenSilently()
      .then((token) => {
        fetch(`${apiUrl}/subscriptions/handle-subscription`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ customerId, priceId }),
        })
          .then((res) => {
            if (!res.ok) throw new Error("Failed to fetch client secret");
            return res.json();
          })
          .then((data) => {
            if (data.clientSecret) {
              setClientSecret(data.clientSecret); // Updates the state
            } else {
              throw new Error("Invalid client secret received");
            }
          })
          .catch((error) => {
            console.error("Error fetching clientSecret:", error);
            setError("Failed to initialize payment. Please try again.");
          });
      })
      .catch((error) => {
        console.error("Error getting access token:", error);
        setError("Authorization failed. Please try again.");
      });
  }, [apiUrl, customerId, priceId, showSnackBar, getAccessTokenSilently]);

  React.useEffect(() => {
    if (sameAddress) {
      // Populate default values when sameAddress is true
      setValues({
        firstName: billingContact?.first_name,
        lastName: billingContact?.last_name,
        address: billingAddress?.line1,
        address2: billingAddress?.line2,
        city: billingAddress?.city,
        state: billingAddress?.state,
        zip: billingAddress?.postal_code,
        email: billingContact?.email,
      });
    } else {
      // Clear values when sameAddress is false
      setValues({
        firstName: values.firstName,
        lastName: values.lastName,
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        email: values.email,
      });
    }
  }, [sameAddress]);

  const handleBlur = (field) => {
    setErrors((prev) => {
      const updatedErrors = {
        ...prev,
        [field]: !validateField(field, values[field] || ""),
      };
      setButtonDisabled(Object.values(updatedErrors).some((error) => error));
      return updatedErrors;
    });
  };

  const handleChange = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => {
      const updatedErrors = { ...prev, [field]: !validateField(field, value) };
      setButtonDisabled(Object.values(updatedErrors).some((error) => error));
      return updatedErrors;
    });
    setWarning(false);
    if (checked) {
      setChecked(false);
    }
  };

  const validateField = (type, value) => {
    const validators = {
      firstName: (name) => name.trim().length > 0,
      lastName: (name) => name.trim().length > 0,
      email: (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email),
      phone: (phone) => /^\d{10}$/.test(phone),
      address: (address) => address.trim().length > 0,
      city: (city) => city.trim().length > 0,
      state: (state) => state.trim().length > 0,
      zip: (zip) => /^\d{5}$/.test(zip),
      country: (country) => country.trim().length > 0,
    };

    return validators[type]?.(value) ?? true; // Return true if no validator is found
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);

    // Loop through all fields in values and call handleBlur
    Object.keys(values).forEach((field) => {
      handleBlur(field);
    });
  };

  const handleStateChange = (e) => {
    const value = e.target.value;

    // Update the `values` state with the manually entered value
    setValues((prev) => ({ ...prev, state: value }));
    setErrors((prev) => ({ ...prev, state: false }));
    setWarning(false);
  };

  const [lastKey, setLastKey] = useState("");
  const [keyIndex, setKeyIndex] = useState(0);

  const handleKeyDown = (event) => {
    const key = event.key.toLowerCase();

    // Filter states that match the typed key
    const matchingStates = states.filter((state) =>
      state.toLowerCase().startsWith(key),
    );

    if (matchingStates.length > 0) {
      let nextState;
      let nextIndex;

      if (lastKey === key) {
        // If the same key is pressed, navigate to the next match
        nextIndex = (keyIndex + 1) % matchingStates.length;
        nextState = matchingStates[nextIndex];
      } else {
        // If a new key is pressed, reset to the first match
        nextIndex = 0;
        nextState = matchingStates[0];
      }

      setSelectedState(nextState); // Update the selected state for display
      setKeyIndex(nextIndex); // Update the index for the next match
      setLastKey(key); // Track the last key pressed

      // Update the `values` state with the new selected state
      setValues((prev) => ({ ...prev, state: nextState }));
      setErrors((prev) => ({ ...prev, state: false }));
      setWarning(false);
    }
  };

  const fieldMapping = {
    postal_code: "zip", // Map 'postal_code' from backend to 'zip' in your form
    address: "address", // Directly map 'address' to 'address'
    street_number: "address", // Map 'street_number' to 'address'
    route: "address", // Map 'route' to 'address'
    address2: "address2", // Same for address2
    locality: "city", // Map 'locality' to 'city'
    administrative_area_level_1: "state", // Map 'administrativeArea' to 'state'
  };

  const handleUpdateAddressSubmit = async () => {
    setIsLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      // Get the access token using Auth0's getAccessTokenSilently
      const token = await getAccessTokenSilently();

      // Send a POST request to your backend with the access token
      const response = await fetch(
        `${apiUrl}/subscriptions/manage/update-billing-address`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass the access token in the Authorization header
          },
          body: JSON.stringify({
            customerId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            address: values.address,
            address2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zip,
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to update billing address");
      }

      const data = await response.json();

      if (data.status === "success") {
        showSnackBar("Billing address updated successfully", true);
        await fetchBillingHistory(customerId);
        setIsLoading(false);
      } else if (data.status === "invalid") {
        setShowErrorMessage(true);
        setIsLoading(false);

        // Handle unconfirmed fields
        if (data.unconfirmedFields && data.unconfirmedFields.length > 0) {
          console.error("Unconfirmed address fields:", data.unconfirmedFields);
          showSnackBar("Unable to save. Address invalid", false);
          setShowErrorMessage(true);
          setIsLoading(false);

          // Map unconfirmed fields to set the error state for corresponding fields
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            // Loop through the unconfirmed fields array
            data.unconfirmedFields.forEach((field) => {
              const mappedField = fieldMapping[field.type]; // Use the 'type' property for mapping
              if (mappedField) {
                updatedErrors[mappedField] = true; // Set error to true for the corresponding field
              }
            });

            return updatedErrors;
          });
        } else {
          showSnackBar("Unable to update address. Please try again.", false);
          setValidationErrors(data.validationErrors); // Update state to display validation errors
          setIsLoading(false);
        }
      } else if (data.status === "error") {
        console.error("Error updating billing address:", data.message);
        showSnackBar(
          data.message || "An error occurred while updating the address.",
          false,
        );
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error updating billing address:", error);
      showSnackBar("An unexpected error occurred. Please try again.", false);
      setIsLoading(false);
    }
    finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    // Compare values and initialValues
    const isEqual = Object.keys(values).every(
      (key) => values[key] === initialValues[key],
    );

    setDataChanged(!isEqual);
    if (
      values.address === "" ||
      values.city === "" ||
      values.state === "" ||
      values.zip === ""
    ) {
      setButtonDisabled(true);
    }
  }, [values, initialValues]); // Run the effect whenever values or initialValues change

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "10px", width:'100%', display:"flex", flexGrow:1,     minWidth: "300px", // Prevents shrinking too much
        maxWidth: "980px", } }}
    >

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 1,
            top: 3,
            color: "#7589A2",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>


      <DialogContent sx={{ width:'100%',height:'100%', py:2.5, pt:2.5}}>
      <EditForm
                customerId={customerId}
                priceId={priceId}
                clientSecret={clientSecret}
                apiUrl={apiUrl}
                onSubscriptionUpdate={onSubscriptionUpdate}
                onClose={onClose}
                refreshSubscription={refreshSubscription}
                planDetails={planDetails}
                subscriptionId={subscriptionId}
                refetchSubscriptionDetails={refetchSubscriptionDetails}
                subUserData={subUserData}
                billingAddress={billingAddress}
                errors={errors}
                warning={warning}
                setWarning={setWarning}
                values={values}
                handleSubscriptionUpdate={handleSubscriptionUpdate}
                fetchBillingHistory={fetchBillingHistory}
                billingDetailOne={billingDetailOne}
                billingDetailTwo={billingDetailTwo}
                billingDetailThree={billingDetailThree}
                billingDetailFour={billingDetailFour}
                billingDetailFive={billingDetailFive}
                billingValueOne={billingValueOne}
                billingValueTwo={billingValueTwo}
                billingValueThree={billingValueThree}
                billingValueFour={billingValueFour}
                billingValueFive={billingValueFive}

                planCost={planCost}
                totalPlanCost={totalPlanCost}
                promotion={promotion}
                discountName={discountName}
                discountPercentage={discountPercentage}
                discountedPlanCost={discountedPlanCost}
                loadingPlanDetails={loadingPlanDetails}

              />
      </DialogContent>
      <DialogActions>

        
      </DialogActions>
    </Dialog>
  );
};

export default NewSubscription;
