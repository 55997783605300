import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Modal,
  Typography,
  Fade,
  Stack,
  Skeleton,
  CardMedia,
} from "@mui/material";
import moment from "moment-timezone"; // Import moment-timezone
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import companyImage from "src/public/assets/companyImage.png";
import RestartSetupDialog from "src/components/modal/NewUserSignUp/Refresh/Components/RestartSetupDialog";
import { add } from "lodash";





  // List of all US states
  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];


  const theme = createTheme({
    palette: {
      primary: {
        main: "#48768C", // Set the main color to white
      },
    },
  });
  


const AccountInfoStep = ({ loading, isNextButtonDisabled, setIsNextButtonDisabled, user, hideWelcome,
setCompanyName, setCompanyAddress, setCompanyCity, setCompanyState, setCompanyZip, setCompanyEmail, setCompanyUrl, setCompanyPhone, values, setValues,
errors, setErrors, setWarning, validateField, handleChange, handleBlur, handleStateChange, typing, setHideWelcome
  }) => {

  




    useEffect(() => {
      if (
        values.name ||
        values.address ||
        values.city ||
        values.state ||
        values.zip ||
        values.email ||
        values.url
      ) {
        setHideWelcome(true);
      }

      if (errors.name || 
        errors.address || 
        errors.city ||
        errors.state || 
        errors.zip || 
        errors.email ||
        errors.url) {
      setIsNextButtonDisabled(true);
      } else {
        setIsNextButtonDisabled(false);
      }

    }, []); 








    return (
      !hideWelcome ? (
        <Fade in={true} timeout={400}>
          <Box
            sx={{
              width: "auto",
              justifyContent: "center",
              borderRadius: "40px",
              fontSize: 17,
              fontWeight: 600,
              backgroundColor: "#FFFFFF",
              color: "#48768C",
              padding: 2,
            }}
          >
            <Typography sx={{ mt: 1, fontSize: 22, textAlign: "left", mb: 1, fontWeight: 600 }}>
              Hey there!
            </Typography>
            <Typography sx={{ mt: 0, fontSize: 19, textAlign: "left", mb: 1, fontWeight: 600 }}>
              Welcome to NanoNotify!
            </Typography>
            <Typography sx={{ textAlign: "left", fontSize: 15, fontWeight: 500, mt: 2 }}>
              Let's finish setting up your new account
            </Typography>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <Typography sx={{ display: "flex", whiteSpace: "pre-wrap", textAlign: "left", fontSize: 15, width: "100%", mt: 2 }}>
                We just need to get a few details about your company and how you plan to send notifications to your customers
              </Typography>
            </Box>
          </Box>
        </Fade>
      ) : (
        <Fade in={true} timeout={600}>
          <Box
            sx={{
              height: '100%',
              width: "auto",
              justifyContent: "center",
              borderRadius: "40px",
              fontSize: 17,
              fontWeight: 600,
              backgroundColor: "#FFFFFF",
              color: "#48768C",
              padding: 1,
              mb:1
            }}
          >
            <Typography sx={{ mt: 1, fontSize: '1.28rem', textAlign: "left", fontWeight: 600, px: .25, letterSpacing: '-0.02em', lineHeight: '-0.02em' }}>
              Tell Us A Little About Your Company
            </Typography>
            <Typography color="textSecondary" sx={{ mt: 0, fontSize: '.87rem', textAlign: "left", mb: 1.5, fontWeight: 500, px: .25, letterSpacing: '-0.01em', lineHeight: '-0.04em' }}>
              Important details like your company’s website, email, and location’s phone number are automatically included in notifications.
            </Typography>
    
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1.15, width: "100%" }}>
              <ThemeProvider theme={theme}>
                <TextField
                  label="Company Name"
                  variant="outlined"
                  size="small"
                  value={values.name || ""}
                  onChange={(e) => handleChange("name", e.target.value)}
                  onBlur={() => handleBlur("name")}
                  error={errors.name}
                  fullWidth
                  InputProps={{
                    style: { color: "#48768C", borderRadius: "6px" },
                  }}
                  InputLabelProps={{ style: { color: "#82879B" } }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6595ab",
                    },
                    color: "#48768C",
                  }}
                />
                <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                <TextField
                  label="Street Address"
                  variant="outlined"
                  size="small"
                  value={values.address || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 45) {
                      handleChange("address", value);
                    }
                  }}                  onBlur={() => handleBlur("address")}
                  error={!typing && errors.address}
                  fullWidth
                  InputProps={{
                    style: { color: "#48768C", borderRadius: "6px" },
                  }}
                  InputLabelProps={{ style: { color: "#82879B" } }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6595ab",
                    },
                    color: "#48768C",
                  }}
                />
                                <TextField
                  label="Unit / Suite / Floor"
                  variant="outlined"
                  size="small"
                  value={values.address2 || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 45) {
                      handleChange("address2", value);
                    }
                  }}                  onBlur={() => handleBlur("address2")}
                  error={!typing && errors.address2}
                  fullWidth
                  InputProps={{
                    style: { color: "#48768C", borderRadius: "6px" },
                  }}
                  InputLabelProps={{ style: { color: "#82879B" } }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#6595ab",
                    },
                    color: "#48768C",
                  }}
                />
                </Stack>
              </ThemeProvider>
    
              <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
  <ThemeProvider theme={theme}>
    <TextField
      label="City"
      variant="outlined"
      size="small"
      value={values.city || ""}
      onChange={(e) => {
        const alphanumericValue = e.target.value.replace(/[^a-zA-Z0-9\s]/g, ""); // Allow letters, numbers, and spaces
        if (alphanumericValue.length <= 25) {
          handleChange("city", alphanumericValue); // Allow only up to 10 characters
        }
      }}        onBlur={() => handleBlur("city")}
      error={!typing && errors.city}
      InputProps={{
        style: { color: "#48768C", borderRadius: "6px" },
      }}
      InputLabelProps={{ style: { color: "#82879B" } }}
      sx={{
        flex: 1,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#6595ab",
        },
        color: "#48768C",
      }}
    />
  </ThemeProvider>

  <ThemeProvider theme={theme}>
    <FormControl
      variant="outlined"
      size="small"
      sx={{
        flex: 1,
        color: "#82879B",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#6595ab",
        },
      }}
    >
      {!values?.state && (
      <InputLabel sx={{ color: "#48768C", alignItems:'center' }} shrink={false}>State</InputLabel>
      )}
      <Select
        value={values.state || ""}
        onChange={handleStateChange}
        sx={{
          color: "#48768C",
          borderRadius: "8px",
          width: "100%",
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300, // Adjust the height here
              overflowY: "auto", // Allows scrolling if content exceeds maxHeight
            },
          },
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
        }}
      >
        {usStates.map((state) => (
          <MenuItem key={state} value={state} sx={{ backgroundColor: "#FFFFFF", color: "#48768C", borderRadius: "8px" }}>
            {state}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </ThemeProvider>

  <ThemeProvider theme={theme}>
    <TextField
      label="Zip Code"
      variant="outlined"
      size="small"
      value={values.zip || ""}
      onChange={(e) => {
        const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
        if (numericValue.length <= 5) {
          handleChange("zip", numericValue); // Allow only up to 4 digits
        }
      }}
      onBlur={() => handleBlur("zip")}
      error={!typing && errors.zip}
      InputProps={{
        style: { color: "#48768C", borderRadius: "6px" },
      }}
      InputLabelProps={{ style: { color: "#82879B" } }}
      sx={{
        flex: 1,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#6595ab",
        },
        color: "#48768C",
      }}
    />
  </ThemeProvider>
</Stack>
    
              <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                <ThemeProvider theme={theme}>
                  <TextField
                    label="Company Email"
                    size="small"
                    variant="outlined"
                    value={values.email || ""}
                    onChange={(e) => handleChange("email", e.target.value)}
                    onBlur={() => handleBlur("email")}
                    error={!typing && errors.email}
                    fullWidth
                    InputProps={{
                      style: { color: "#48768C", borderRadius: "6px" },
                    }}
                    InputLabelProps={{ style: { color: "#82879B" } }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6595ab",
                      },
                      color: "#48768C",
                    }}
                  />
                </ThemeProvider>
    
                <ThemeProvider theme={theme}>
                  <TextField
                    label="Company Website"
                    variant="outlined"
                    size="small"
                    value={values.url || ""}
                    onChange={(e) => handleChange("url", e.target.value)}
                    onBlur={() => handleBlur("url")}
                    error={!typing && errors.url}
                    fullWidth
                    InputProps={{
                      style: { color: "#48768C", borderRadius: "6px" },
                    }}
                    InputLabelProps={{ style: { color: "#82879B" } }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6595ab",
                      },
                      color: "#48768C",
                    }}
                  />
                </ThemeProvider>
              </Stack>
            </Box>
          </Box>
        </Fade>
      )
    );

  };


export default AccountInfoStep;