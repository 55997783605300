import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useFetchNotifications = (apiUrl, isAuthenticated, user) => {
  const [userNotifications, setUserNotifications] = useState([]);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        if (isAuthenticated && user) {
          const accessToken = await getAccessTokenSilently(); // Get access token
          const response = await fetch(
            `${apiUrl}/api/global-notifications?userSub=${user.sub}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`, // Include access token in headers
              },
            },
          );
          if (response.ok) {
            const data = await response.json();
            setUserNotifications(data.notifications);
          } else {
            throw new Error("Failed to fetch global notifications");
          }
        }
      } catch (error) {
        console.error("Error fetching global notifications:", error);
        setError(error);
      }
    };

    fetchNotifications();
  }, [apiUrl, isAuthenticated, user]);

  return { useFetchNotifications, error };
};

export default useFetchNotifications;
