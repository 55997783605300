  import React, { useEffect, useState } from "react";
  import {
    Typography,
    Stack,
    Button,
    Box,
    Modal,
    IconButton,
    Skeleton,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Fade,
  } from "@mui/material";
  import AlertIcon from "src/public/assets/icons/icons/alert.png";
  import {
    PrimaryButton,
    CancelButton,
    SecondaryButton,
  } from "src/components/Buttons/ModalButtons";
  
  
  
  const ConfirmAdminDialog = ({
    open,
    onClose,
    handleInfoDialogClose,


}) => {
  
  
 return (
    

  <Dialog
                PaperProps={{
                  sx: {
                    borderRadius: "8px",
                    px:1.5,
                    pt:1,
                    pb:3,
                    minWidth: "120px",
                    maxWidth:'670px',
                    backgroundColor: "white",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  },
                }}
                open={open}
                onClose={onClose}
              >

 <DialogContent
    sx={{
      paddingBottom: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    }}
  >
            <Box sx={{mt:2}}>
          <img
            src={AlertIcon}
            style={{
              border: "transparent",
              width: "auto",
              height: "47px",
              backgroundColor: "#dfeaee",
              borderRadius: "6px",
              padding: "1px",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
              marginBottom: "5px",
            }}
          />
        </Box>
    {/* Success Message */}
    <Typography
      sx={{
        fontSize: 20.5,
        fontWeight: 550,
        color: "#48768C",
        letterSpacing: "-.02em",
        mt:2.5
      }}
    >
     Heads Up!
    </Typography>
                    <Typography
    
                      sx={{
                        textAlign: "center",
                        color: "#48768C",
                        fontWeight: 500,
                        padding:2,
                        fontSize:16.5,
                        pt:2,
                        letterSpacing:'-.015em',
                        lineHeight:'-2%',
                        
                      }}
                    >
                      An account administrator can make important changes to
                      your NanoNotify account, such as adding or deleting
                      locations, adding users and changing users' pins.
                    </Typography>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width:'100%'
                    }}
                  >
                    <SecondaryButton
                      sx={{
                        borderRadius: "8px",
        
                        mb: 1.25,
                        transition: "background-color 0.3s ease",
                        width: "40%",
                        fontSize:17,
                        letterSpacing:'-.015em',
                        py:.5
              
                      }}
                      onClick={onClose}
                    >
                     Confirm
                    </SecondaryButton>
                  </DialogActions>
              </Dialog>

                    )

};

export default ConfirmAdminDialog;