import React from 'react';
import { Typography, Stack, Box } from '@mui/material';

const FieldTypeHelp = ({}) => {
  return (
    <Stack direction="column" spacing={2}>
      <Box sx={{ fontSize: 16.5, color: "#40657a" }}>
        <Stack direction="column" spacing={1} sx={{mb:2}}>
            <Stack direction="column">
        <Typography sx={{
        fontSize: 16.5,
        letterSpacing: "-0.02em",
        color: "#40657a",
        fontWeight: 'bold',
     

      }}>
       There are 5 customizable field types to choose from, each designed for different types of information you may need to send in your notifications.
      </Typography>
      <Typography sx={{
        mt:.5,
        fontSize: 16.5,
        letterSpacing: "-0.02em",   
        color: "#40657a",

        pb:2
      }}>
       Include whatever information you need, and we’ll take care of the formatting automatically based on the field type you select for your notification.
      </Typography>
      </Stack>
      <Box sx={{pt:2}}>
          <Typography sx={{
            mt:-2,
            fontSize: 17.5,
            letterSpacing: "-0.02em",
            color: "#40657a",
            fontWeight: 'bold',
          }}>
            <strong>Currency</strong>
          </Typography>
          <Typography>
            Use this field for quotes, prices, or balances. Numbers entered here are automatically converted to a dollar amount.
          </Typography>
          <Typography sx={{ fontStyle: 'italic', color: "#40657a",mt:1 }}>
            For example, "Total cost: $500" or "Your current balance is $150.00."
          </Typography>
          </Box>
        </Stack>

        <Stack direction="column" spacing={0} sx={{mb:2,pt:1 }}>
          <Typography sx={{
              fontSize: 17.5,
            letterSpacing: "-0.02em",
            color: "#40657a",
            fontWeight: 'bold',
          }}>
            <strong>Date</strong>
          </Typography>
          <Typography>
            This field is for sending dates such as delivery or completion dates. Numbers are automatically formatted into a readable date.
          </Typography>
          <Typography sx={{ fontStyle: 'italic', color: "#40657a", mt:1 }}>
            For example, "Delivery date: 12/15/2025" or "The completion date for your project is 05/10/2025."
          </Typography>
        </Stack>

        <Stack direction="column" spacing={0} sx={{mb:2,pt:1 }}>
          <Typography sx={{
           fontSize: 17.5,
            letterSpacing: "-0.02em",
            color: "#40657a",
            fontWeight: 'bold',
          }}>
            <strong>Time</strong>
          </Typography>
          <Typography>
            This field is used when you need to specify a time, without a date, for updates or specific time-related details.
          </Typography>
          <Typography sx={{ fontStyle: 'italic', color: "#40657a", mt:1 }}>
            For example, "Updated: 2:30 PM." or "Your meeting is scheduled for 10:15 AM"
          </Typography>
        </Stack>

        <Stack direction="column" spacing={0} sx={{mb:2,pt:1 }}>
          <Typography sx={{
             fontSize: 17.5,
            letterSpacing: "-0.02em",
            color: "#40657a",
            fontWeight: 'bold',
          }}>
            <strong>Date/Time</strong>
          </Typography>
          <Typography>
            Ideal for situations requiring both date and time, such as estimated completion times or delivery schedules. Numbers entered here will be formatted as a date and time.
          </Typography>
          <Typography sx={{ fontStyle: 'italic', color: "#40657a", mt:1 }}>
            For example, "Delivered: 12/15/2025 at 10:15 AM" or "Your estimated completion time is 05/10/2025 at 3:00 PM."
          </Typography>
        </Stack>

        <Stack direction="column" spacing={0} sx={{mb:2,pt:1 }}>
          <Typography sx={{
             fontSize: 17.5,
            letterSpacing: "-0.02em",
            color: "#40657a",
            fontWeight: 'bold',
          }}>
            <strong>Length of Time</strong>
          </Typography>
          <Typography>
            Perfect for specifying durations such as completion time, turnaround times, or lead times. Simply enter a number and select "minutes", "hours", or "days", and it will be automatically converted into a readable format.
          </Typography>
          <Typography sx={{ fontStyle: 'italic', color: "#40657a", mt:1 }}>
            For example, "Time Needed: 3 hours" or "Your project will be completed in 5 days."
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export default FieldTypeHelp;