import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
  Stack,
  Divider,
  Dialog,
  Paper,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";
import bugIcon from "../../../../../public/assets/icons/navbar/bugSmall.png";
import styled from "@mui/system/styled";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import NewAccountSetupStepper from "./NewAccountSetupStepper";  
import { useAccountContext } from "src/context/AccountContext";
import { handleSubmit } from "../Hooks/handleSubmit";
import RestartSetupDialog from "src/components/modal/NewUserSignUp/Refresh/Components/RestartSetupDialog.js";




const apiUrl = process.env.REACT_APP_API_URL;



const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#E9EDF2",
  marginBottom: theme.spacing(2),
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -1.5px) scale(0.75)",
    paddingLeft: "7px", // Adjust the translateY value as needed
  },
}));

const SeverityFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 400,
  marginBottom: theme.spacing(2),
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -3.5px) scale(0.79)",
    paddingLeft: "10px",
    paddingTop: "5px", // Adjust the translateY value as needed
  },
}));

const steps = [ "Your Company Info",  "Your Location Info",  "Users & Employees",  "Review "];




const AccountSetupDialog = ({ isOpen, onClose, onSubmit }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const { accountData } = useAccountContext();  
  const [activeStep, setActiveStep] = useState(0);
  const [isSingleUser, setIsSingleUser] = useState(false);  
  const [isSingleLocation, setIsSingleLocation] = useState(false);  
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [hideWelcome, setHideWelcome] = useState(false);  
  const [showCreateLocation, setShowCreateLocation] = useState(false);  
  const [showCreateUser, setShowCreateUser] = useState(false);  
  const [errors, setErrors] = useState({});
  const [warning, setWarning] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [debouncedField, setDebouncedField] = useState(null);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [typing, setTyping] = useState(false);
    const [showRestartDialog, setShowRestartDialog] = useState(false);
    const [previousStep, setPreviousStep] = React.useState(activeStep);
  const [confirmRestart, setConfirmRestart] = useState(false);
  const [addressValidated, setAddressValidated] = useState(false);


  







  const [values, setValues] = React.useState({
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    url: '',
    locationName: '',
    locationPhone: '',
    locationEmail: '',
    newFirstName: '',
    newLastName:  '',
    newUsername: '',
    newPin: '',
    confirmNewPin: '',
    title:'',
  });



  const [roleOpen, setRoleOpen] = useState(false); // Controls Select dropdown


  const handleChangeTitle = (e) => {
    const selectedTitle = e.target.value;
    setValues((prevValues) => ({
        ...prevValues,
        title: selectedTitle, // Properly updates the title inside values
    }));
};

const handleRoleButtonClick = () => {
  setRoleOpen(true); // Open the dropdown when button is clicked
};



  const [timeoutId, setTimeoutId] = useState(null);

const handleChange = (field, value) => {
  let newValue = value;

  if (field === "locationPhone") {
    const numericValue = value.replace(/\D/g, "");
    let formattedValue = numericValue;

    if (numericValue.length > 6) {
      formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6)}`;
    } else if (numericValue.length > 3) {
      formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
    } else if (numericValue.length > 0) {
      formattedValue = `(${numericValue}`;
    }

    newValue = formattedValue;
  }

  setValues((prev) => ({ ...prev, [field]: newValue }));

  if (timeoutId) clearTimeout(timeoutId);

  const newTimeout = setTimeout(() => {
    const isValid = validateField(field, newValue);
    setErrors((prev) => ({ ...prev, [field]: !isValid }));
    setIsNextButtonDisabled(Object.values(errors).some((error) => error));
    setTimeoutId(null); // Clears timeout when validation is done
  }, 500);

  setTimeoutId(newTimeout);
};
  







    const validateField = (type, value) => {
      const validators = {
        name: (company_name) => company_name.trim().length > 0,
        email: (company_email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(company_email),
        phone: (phone) => /^\d{10}$/.test(phone),
        address: (company_address) => {
          // Regular expression: Starts with digits, followed by letters, and no trailing spaces
          const addressPattern = /^\d+[a-zA-Z\s]+[a-zA-Z]+$/;
        
          return company_address.trim().length > 0 && addressPattern.test(company_address);
        },
        city: (company_city) => company_city.trim().length > 0,
        state: (company_state) => company_state.trim().length > 0,
        zip: (zip_code) => /^\d{5}$/.test(zip_code),
        url: (url) => /^(https?:\/\/)?([\w.-]+\.[a-z]{2,})$/.test(url),
        newFirstName: (newFirstName) => newFirstName.trim().length > 2,
        newLastName: (newLastName) => newLastName.trim().length > 2,
        newPin: (newPin) => /^\d{4}$/.test(newPin),
        confirmNewPin: (confirmNewPin) => /^\d{4}$/.test(confirmNewPin),
        newUsername: (newUsername) => newUsername.trim().length > 5,
        locationName: (company_name) => {
          if (isSingleLocation) return true; // Skip validation if single location
          return company_name.trim().length > 0;
        },
        locationEmail: (locationEmail) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(locationEmail),
        locationPhone: (locationPhone) => {
          const numericPhone = locationPhone.replace(/\D/g, ""); // Remove non-numeric characters
          return /^\d{10}$/.test(numericPhone); // Validate exactly 10 digits
        },

      };
  
      return validators[type]?.(value) ?? true; // Return true if no validator is found
    };




        const handleBlur = (field) => {
          setErrors((prev) => {
            const updatedErrors = {
              ...prev,
              [field]: !validateField(field, values[field] || ""),
            };
            setIsNextButtonDisabled(Object.values(updatedErrors).some((error) => error));
            return updatedErrors;
          });
        };



    
        const handleStateChange = (e) => {
          const value = e.target.value;
      
          // Update the `values` state with the manually entered value
          setValues((prev) => ({ ...prev, state: value }));
          setErrors((prev) => ({ ...prev, state: false }));
          setWarning(false);
        };
      
    
        useEffect(() => {
          if ((activeStep === 0 && !hideWelcome) || (activeStep === 1 && !showCreateLocation)) {
            setIsNextButtonDisabled(false);
            return;
          }
        
          let requiredFields = [];
        
          if (activeStep === 0) {
            requiredFields = ["name", "address", "city", "state", "zip", "email", "url"];
          } else if (activeStep === 1) {
            requiredFields = isSingleLocation
              ? ["locationPhone", "locationEmail"]
              : ["locationName", "locationPhone", "locationEmail"];
          } else if (activeStep === 2 && !isSingleUser) { 
            requiredFields = ["newFirstName", "newLastName", "newUsername", "newPin", "confirmNewPin"];
          } else if (activeStep === 2 && isSingleUser) { 
            requiredFields = ["newFirstName", "newLastName", "title"];
          }
        
          // Check if any required field is empty or contains only whitespace
          const hasEmptyFields = requiredFields.some((key) => {
            const fieldValue = values[key]?.trim();
            return !fieldValue;
          });
        

        
          // Check for PIN mismatch
          const pinMismatch = values.newPin && values.confirmNewPin && values.newPin !== values.confirmNewPin;
        
          // Update errors for PIN mismatch only when necessary
          setErrors((prevErrors) => {
            if (pinMismatch && !prevErrors.confirmNewPin) {
              return { ...prevErrors, confirmNewPin: "PINs do not match" };
            } else if (!pinMismatch && prevErrors.confirmNewPin) {
              const { confirmNewPin, ...restErrors } = prevErrors;
              return restErrors;
            }
            return prevErrors;
          });

          const hasErrors = Object.values(errors).some(Boolean);

        
          // Disable button if there are errors or empty required fields
          setIsNextButtonDisabled(hasEmptyFields || pinMismatch || hasErrors);
        }, 


        
        
        
        [
          activeStep,
          hideWelcome,
          showCreateLocation,
          isSingleLocation,
          showCreateUser,
          values,
          errors,
        ]);




      




        const handleNext = () => {
          if (activeStep === 0 && !hideWelcome) {
            setHideWelcome(true);
            setIsNextButtonDisabled(true);
            return;
          }
        

        
          if (activeStep === 1 && !showCreateLocation) {
            setShowCreateLocation(true);
            setIsNextButtonDisabled(true);
            return;
          }
         
        
          if (activeStep === 1 &&  showCreateLocation && !isSingleLocation) {
            setIsNextButtonDisabled(true);
            setShowCreateUser(true);
            setActiveStep(2);
            return;
          }

          if (activeStep === 1 &&  showCreateLocation && isSingleLocation) {
            setIsNextButtonDisabled(false);
            setShowCreateUser(false);
            setActiveStep(2);
            return;
          }
          

          
        
    

          if (activeStep === 2 && showCreateUser) {
            setActiveStep(3);
            return;
          }

                if (activeStep === 2 && !showCreateUser && !isSingleUser) {
            setShowCreateUser(true);
            setIsNextButtonDisabled(true);
            return;
          }

          if (activeStep === 2 && !showCreateUser && isSingleUser) {
            setShowCreateUser(true);

            setIsNextButtonDisabled(true);
            return;
          }
        
          // If we reach here, we simply move to the next step
          setActiveStep((prevStep) => prevStep + 1);
        };
        



    // Log state update properly using useEffect
    useEffect(() => {

      if (!showCreateUser) {
        setIsNextButtonDisabled(false);
      }



    }, [activeStep, showCreateUser]);



  const handleBack = () => {



    if (activeStep === 1 && showCreateLocation) {
      setShowCreateLocation(false);
      setIsNextButtonDisabled(false);
      return;
    }

    if (activeStep === 1 && !showCreateLocation) {
      setAddressValidated(false); 
      setIsNextButtonDisabled(false);
      setActiveStep(0);
    }



    if (activeStep === 2 && showCreateUser && !isSingleLocation) {
      setShowCreateLocation(false);
      setActiveStep(1); 
      return;
    }

    if (activeStep === 2 && showCreateUser && isSingleLocation) {
      setShowCreateUser(false);
      setIsNextButtonDisabled(false);
      return;
    }

  
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
    setPreviousStep(activeStep);
  };



const handleClose = () => {
  onClose();
}






const handleOpenRestartDialog = () => {
  if (activeStep === 4) {
    onClose(); // Close the modal if on the last step 
    return;
  }

  if(confirmRestart) {  
    setConfirmRestart(false); // Reset confirmation state
  }
    setShowRestartDialog(true); // Open RestartSetupDialog
  };


  const handleCloseRestartDialog = () => {
    setShowRestartDialog(false); // Close RestartSetupDialog
  };


const handleRestartSetup = () => {  
  if (!confirmRestart) {
    setConfirmRestart(true); // Show confirmation message
    return; // Prevent immediate restart
  } else {
  setShowRestartDialog(false); // Close the RestartSetupDialog
  setActiveStep(0); // Reset to the first step
  setShowCreateLocation(false); // Reset the create location screen
  setShowCreateUser(false); // Reset the create user screen
  setIsNextButtonDisabled(true); // Disable the next button
  setHideWelcome(false); // Reset the welcome screen
  setIsSingleLocation(false); // Reset the single location state
  setIsSingleUser(false); // Reset the single user state
  setIsLoading(false); // Reset loading state
  setValues({
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    url: '',
    locationName: '',
    locationPhone: '',
    locationEmail: '',
    newFirstName: user?.firstName || '',
    newLastName: user?.lastName || '',
    newUsername: `${user?.firstName?.charAt(0).toLowerCase() || ''}${user?.lastName?.toLowerCase() || ''}`,
    newPin: '',
    confirmNewPin: '',
  });
  setErrors({}); // Reset errors
  setWarning(false); // Reset warning state
  setButtonDisabled(false); // Reset button disabled state
  setTimeoutId(null); // Clear any existing timeout
  setTyping(false); // Reset typing state
  setIsNextButtonDisabled(true); // Disable the next button
  setShowCreateLocation(false); // Reset the create location screen
  setShowCreateUser(false); // Reset the create user screen
  setIsSingleLocation(false); // Reset the single location state
  setIsSingleUser(false); // Reset the single user state
  setIsLoading(false); // Reset loading state
}
  };


useEffect(() => {
  if (activeStep < previousStep) { // Detect backward navigation
    const updatedValues = { ...values };

    Object.keys(errors).forEach((field) => {
      if (errors[field]) {
        updatedValues[field] = ''; // Clear the value if there’s an error
      }
    });

    setValues(updatedValues);
    setErrors({}); // Clear errors when going back
  }
}, [activeStep, previousStep, showCreateLocation, showCreateUser]);





  return (

    <Dialog
    open={isOpen}
    onClose={(event, reason) => {
      if (reason === "backdropClick" || reason === "escapeKeyDown") {
        handleOpenRestartDialog();
        return; // Prevent closing the modal
      }
    }}
    disableEscapeKeyDown // Prevents closing via Escape key
      TransitionComponent={Fade} // Enables fade-in/fade-out animation
      transitionDuration={300} // Optional: Adjust duration for smoother effect
      PaperProps={{
        style: {
          borderRadius: 12,
          maxWidth: "980px",
          minWidth: "710px", 
          minHeight: "25vh",
          overflowY: "auto",
          opacity: showRestartDialog ? 0 : 1, // Smooth fade instead of display: 'none'
          transition: "opacity 0.3s ease-in-out", // Ensures smooth fade when hiding
        },
      }}
    >


<Box
  sx={{

    bgcolor: "background.paper",
    boxShadow: 24,
    pt:0,
    pb: 2,
px:3,
    borderRadius: "12px",

  }}
>
      

        <IconButton
          aria-label="close"
          onClick={handleOpenRestartDialog}
          sx={{
            position: "absolute",
            right: 4,
            top: 6,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "37px",
            height: "37px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "auto",
            backgroundColor: "#FFFFFF",
            color: "#374247",
            width: "100%",
            mt:3,
          }}
        >



<Typography
  sx={{
    fontSize: "1.31rem",
    fontWeight: 600,
    marginRight: "auto",
    letterSpacing: "-0.034em",
    lineHeight: "1.2em",
    alignSelf: "center",
    color: "#48768C",
    width: "100%",
    whiteSpace: "nowrap",
  }}
>
  Finish Setting Up Your Account
</Typography>
        </Box>
      
  <Box sx={{display:'flex', flexDirection:'column', justifyContent:'flex-start',height:'auto', overflowY:'auto', width:'100%'}}>
<NewAccountSetupStepper
onClose={onClose}
isLoading={isLoading}
user={user}
activeStep={activeStep}
isNextButtonDisabled={isNextButtonDisabled}
steps={steps}
setIsNextButtonDisabled={setIsNextButtonDisabled}
hideWelcome={hideWelcome}
setIsSingleLocation={setIsSingleLocation}
setIsSingleUser={setIsSingleUser}
isSingleLocation={isSingleLocation}
isSingleUser={isSingleUser} 
showCreateLocation={showCreateLocation}
showCreateUser={showCreateUser}
values={values}
setValues={setValues}
handleChange={handleChange}
errors={errors}
setErrors={setErrors}
setWarning={setWarning}
warning={warning}
validateField={validateField}
handleBlur={handleBlur}
handleStateChange={handleStateChange}
typing={typing}
setActiveStep={setActiveStep}
setHideWelcome={setHideWelcome}
setShowCreateLocation={setShowCreateLocation}
setShowCreateUser={setShowCreateUser}
handleClose={handleClose} 
handleChangeTitle={handleChangeTitle}
handleRoleButtonClick={handleRoleButtonClick}
roleOpen={roleOpen}
setRoleOpen={setRoleOpen}


/>

<Box sx={{ display: "flex",
         justifyContent: activeStep === 0 ? "flex-end" : "flex-end",
          alignItems: "left", gap: 2,       
 backgroundColor: "#FFFFFF", 
 mr:2, mt:0, mb:1
        }}>



{activeStep > 0 && (
          <CancelButton
          sx={{           width: activeStep === 0 ? "150px" : "140px" , 
            height: activeStep === 0 ? "45px" : "39px" ,
            borderRadius: "6px",
            display: activeStep === 4 ? "none" : "block",

          }}
          disabled={activeStep === 0} onClick={handleBack}>
            Back
          </CancelButton>
        )}


          {activeStep < steps.length - 1 ? (
            <PrimaryButton sx={{ 
              width: activeStep === 0 ? "150px" : "140px" , 
              height: activeStep === 0 ? "45px" : "39px" , 
              borderRadius: "6px",
            }}
            disabled={isNextButtonDisabled || timeoutId !== null || isLoading}
              onClick={handleNext}>
               {isLoading? "Saving..." : "Continue"} 
            </PrimaryButton>
          ) : (
<PrimaryButton
  sx={{ width: "125px", display: activeStep === 4 ? "none" : "block",
    width: activeStep === 0 ? "150px" : "140px" , 
    height: activeStep === 0 ? "45px" : "39px" , 
    borderRadius: "6px",

   }}
  variant="contained"
  disabled={typing || isButtonDisabled}
  onClick={() =>
    handleSubmit({ values, isSingleLocation, isSingleUser, setButtonDisabled, getAccessTokenSilently, user, setActiveStep, setIsLoading, showSnackBar, handleClose })
  }
>
  {isButtonDisabled ? "Submitting..." : "Submit"}
</PrimaryButton>
          )}
        

        </Box>

  </Box>
     </Box>
     <RestartSetupDialog
            open={showRestartDialog}
            onClose={handleCloseRestartDialog}
            handleRestartSetup={handleRestartSetup}
            confirmRestart={confirmRestart}
            setConfirmRestart={setConfirmRestart}

          />
      </Dialog>
  );
};

export default AccountSetupDialog;



