import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  TableCell,
  Stack,
  IconButton,
  Box,
  Chip,
  Avatar,
  Fade,
  TableContainer,
  Paper,
  Table, 
  TableRow,
  TableHead, 
  TableBody,
} from "@mui/material";

const BillingHistory = ({billingHistory, loadingBilling}) => {
  return (
    <Box 
                sx={{  
                  flexGrow: 1, 
                  width: "100%",  
                  minWidth: "100%",  
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{
                    margin: 0,
                    padding: 0,
                    borderRadius: "6px",
                    minHeight: "42vh",
                    flexGrow: 1, 
                    overflowY: "auto", 
                    maxHeight: "42vh",
                    width: "100%",
                  }}
                >
                    <Table size="small" sx={{}} stickyHeader>
  <TableHead>
    <TableRow>
      {["Date", "Description", "Amount Paid", "Status", "Invoice Link"].map((text, index) => (
        <TableCell
          key={index}
          sx={{
            color: "#48768C",
            textAlign: "center",  // Ensures text is centered
            padding: "8px",  // Padding for better spacing
            fontWeight: 600,  // Make header text bold
            ...(index === 1 && { width: "250px" }), // Adjust width for the Description column
            flex: index !== 1 ? 1 : "none",  // Let the Description column take a fixed width
          }}
        >
          <strong>{text}</strong>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>

  <TableBody>
    {billingHistory &&
      billingHistory.length > 0 &&
      billingHistory
        .filter((invoice) => invoice.status.toLowerCase() === "paid")
        .map((invoice) => (
          <Fade key={invoice.invoice_id} in={true} timeout={200}>
            <TableRow key={invoice.invoice_id}>
              {[
                invoice.date,
                invoice.description,
                `$${invoice.amount_paid}`,
                invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1),
                <a
                  href={invoice.hosted_invoice_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "#48768C" }}  // Optional link styling
                >
                  View Invoice
                </a>,
              ].map((content, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontWeight: index !== 0 ? 500 : 550,
                    color: "#48768C",
                    letterSpacing: "-2%",
                    lineHeight: "-2%",
                    height: "50px",
                    textAlign: "center",  // Center the text in table cells
                    padding: "8px",  // Ensure consistency with padding
                    ...(index === 1 && { width: "500px" }), // Match the width adjustment for Description
                    flex: index !== 1 ? 1 : "none",  // Let Description column take a fixed width
                  }}
                >
                  {content}
                </TableCell>
              ))}
            </TableRow>
          </Fade>
        ))}
                          {billingHistory &&
                            billingHistory.length === 0 &&
                            !loadingBilling && (
                              <TableRow>
                              <TableCell
                                colSpan={5} // Ensures it spans across all columns
                                sx={{
                                  backgroundColor: "rgba(191, 218, 231, 0.1)",
                                  height: "39vh", // Ensures enough vertical space
                                  textAlign: "center", // Centers text horizontally
                                  verticalAlign: "middle", // Centers text vertically
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#48768C",
                                    fontSize: 17,
                                    fontWeight: 600,
                                    padding: "20px",
                                    letterSpacing: "-.02em",
                                  }}
                                >
                                  No Recent Payment History...
                                </Typography>
                              </TableCell>
                            </TableRow>
                            )}
                          {billingHistory &&
                            billingHistory.length === 0 &&
                            loadingBilling && (
                              <TableRow>
                                <TableCell
                                  colSpan={5}
                                  sx={{ backgroundColor: "rgba(191, 218, 231, 0.1)" }}
                                >
                                  <Typography
                                    align="center"
                                    sx={{
                                      mt: 15,
                                      width: "100%",
                                      padding: "20px",
                                      color: "#48768C",
                                      fontSize: "18px",
                                      ml: 2,
                                      justifyContent: "center",
                                      height: "30vh",
                                      borderLeft: "none",
                                      borderRight: "none",
                                      fontWeight: 600,
                                      fontStyle: "italic",
                                    }}
                                  >
                                    Loading Payment History...
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
  );
};

export default BillingHistory;