import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  Modal,
  Select,
  MenuItem,
  Box,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  InputAdornment,
  Portal,
  Stack,
  ButtonBase,
  FormControlLabel,
  Checkbox,

} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import editUser from "src/public/assets/icons/icons/editUser.png";
import userDetailsIcon from "src/public/assets/icons/icons/userDetails.png";
import userAccountDetailsIcon from "src/public/assets/icons/icons/userDetailsIcon.png";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useAccountContext } from "src/context/AccountContext";
import ChangePinModal from "src/components/modal/ChangePinModal/ChangePinModal.js";
import { useAuth0 } from "@auth0/auth0-react";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import AccountInfoIcon from 'src/public/assets/icons/icons/AccountInfoIcon.png'
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import LockPersonTwoToneIcon from '@mui/icons-material/LockPersonTwoTone';
import ConfirmAdminDialog from "./ConfirmAdminDialog";

const scaleFactor = 0.75;



const EditUsersModal = ({
  isOpen,
  onClose,
  onSave,
  editData,
  setEditData,
  locations,
  adminCount,
  loading,
  userNameTaken,
  setUserNameTaken,
  users,
  setEditUserName,
  username,
}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const { accountData } = useAccountContext();
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);
  const [selectedUserForPinChange, setSelectedUserForPinChange] =
    useState(null);

  const { user, getAccessTokenSilently } = useAuth0();
  const [title, setTitle] = useState("");
    const [newPin, setNewPin] = useState(["", "", "", ""]);
    const [confirmNewPin, setConfirmNewPin] = useState(["", "", "", ""]);
    const [isLoading, setIsLoading] = useState(false)
      const [roleOpen, setRoleOpen] = useState(false); // Controls Select dropdown
      const [locationOpen, setLocationOpen] = useState(false)

  const user_id = editData.user_id;

  const handleChangePinOpen = (user) => {
    setSelectedUserForPinChange(user);

    setIsChangePinModalOpen(true);
  };

  useEffect(() => {
    if (isOpen) {
      setEditUserName(editData.username);
    }
  }, [isOpen]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [admin, setAdmin] = useState(false);
  const [location, setLocation] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (isOpen) {
      // Initialize phone and email independently from editData
      setFirstName(editData.first_name || "");
      setLastName(editData.last_name || "");
      setNewTitle(editData.title || "");
      setAdmin(editData.is_admin || false);
      setLocation(editData.assigned_location || "");
      setNewUsername(editData.username || "");
    }
  }, [isOpen]);

  useEffect(() => {
    // Disable the button if phone and email match the initial editData
    if (
      firstName === editData.first_name &&
      lastName === editData.last_name &&
      newTitle === editData.title &&
      admin === editData.is_admin &&
      location === editData.assigned_location &&
      newUsername === editData.username
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    firstName,
    lastName,
    newTitle,
    admin,
    location,
    newUsername,
    editData.first_name,
    editData.last_name,
    editData.title,
    editData.is_admin,
    editData.assigned_location,
    editData.username,
  ]);

  const handleChangePinSave = async ({ newPin, confirmNewPin }) => {
    setIsLoading(true);
    try {
      // Convert the PIN arrays to strings
      const newPinString = newPin.join("");  // Convert array to a string "1111"
      const confirmNewPinString = confirmNewPin.join("");  // Convert array to a string "1111"
  
      // Validate that PINs match
      if (newPinString === confirmNewPinString) {
        const apiUrl = process.env.REACT_APP_API_URL;
        const accessToken = await getAccessTokenSilently();
  
        // Update user PIN
        const response = await fetch(
          `${apiUrl}/api/users/${editData.user_id}/change-pin`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              newPin: newPinString,  // Pass the string version of the PIN
            }),
          }
        );
  
        const responseData = await response.json();
  
        if (response.ok) {
          // Optionally return a success message or the response data
          return { success: true, data: responseData };
        } else {
          console.error("Failed to change PIN:", responseData.error);
          return { success: false, error: responseData.error };
        }
      } else {
        console.error("Entered PINs do not match");
        return { success: false, error: "Entered PINs do not match" };
      }
    } catch (error) {
      console.error("Error changing PIN:", error);
      return { success: false, error: error.message };
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePinClose = () => {
    setIsChangePinModalOpen(false);
    setSelectedUserForPinChange(null);

  };

  useEffect(() => {
    if (editData && typeof editData.is_admin === "boolean") {
      setIsAdmin(editData.is_admin);
    }
  }, [editData]);

  useEffect(() => {
    if (editData && editData.tite) {
      setTitle(editData.title);
    }
  }, [editData]);

  const handleToggleSwitch = () => {
    setIsAdmin(!isAdmin);
  
    // Open the dialog when the switch is checked
    if (!isAdmin) {
      setInfoDialogOpen(true);
    }
  
    // Update editData state
    setEditData({
      ...editData,
      is_admin: !isAdmin, // Toggle the is_admin property in editData
    });
  };
  
  // Function to count users with is_admin set to true
  const countAdmins = () => {
    return users.filter(user => user.is_admin).length;
    
  };
  
  const isSwitchDisabled = countAdmins() <= 1 && admin;


  const handleChangeTitle = (e) => {
    const selectedTitle = e.target.value;
    setTitle(selectedTitle);
    // Update editData state
    setEditData({
      ...editData,
      title: selectedTitle, // Update the title property in editData
    });
  };


  const handleSave = () => {
    onSave();
    onClose();
  };


  useEffect(() => {
    if (!isChangePinModalOpen) {
      const timeout = setTimeout(() => {
        // Reset the PIN arrays to empty values
        setNewPin(["", "", "", ""]);
        setConfirmNewPin(["", "", "", ""]);
      }, 300); // 300ms timeout, you can adjust this value as needed

      // Clear the timeout if the modal is opened again before the timeout completes
      return () => clearTimeout(timeout);
    }
  }, [isChangePinModalOpen, setNewPin, setConfirmNewPin]); // Trigger the effect when `isOpen` changes




  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };


  const handleRoleButtonClick = () => {
    setRoleOpen(true); // Open the dropdown when button is clicked
  };


  const handleLocationButtonClick = () => {
    setLocationOpen(true); // Open the dropdown when button is clicked
  };


  const fullName = editData.first_name + " " + editData.last_name


  



  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "10px", backgroundColor: "#FFFFFF", display:  infoDialogOpen ? "none" : "block", minWidth:'550px', maxWidth:'565px', p:.5 } }}
    >
        <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>
      <DialogTitle
        sx={{
          px: 3,
          pt:2,
          ml:.5


        }}
      >
        <img
          src={editUser}
          alt="editUserIcon"
          style={{
            border: "1px solid #B0BEC5",
            width: "auto",
            height: "28px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "3px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
            marginLeft:"-5px"
        
          }}
        />
                <Typography
      sx={{
     
        color: "#48768C",
        fontSize: 21,
        fontWeight: 600,
        textAlign:'left',
   

        letterSpacing: '-.03em',
        
        mt:1,
        ml:-.65

      }}
    >
     Edit Employee
    </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: 3,
          width: "100%",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >



           
<Stack direction="row" sx={{alignItems:'center', mb:-.5, ml:-.5,  mt:1}}> 

<img
    src={userAccountDetailsIcon}
    style={{
      border: "transparent",
      width: "auto",
      height: "28.5px",
      borderRadius: "6px",
      marginBottom: "15px",
      marginTop:'5px'
    }}
  />
    <Typography
      sx={{
     
        color: "#48768C",

        fontSize: 18.5,
        fontWeight: 600,
        textAlign:'center',
        ml:.5,

        alignItems:'center',
        letterSpacing: '-.02em',
        mb:1,
       

      }}
    >
     Employee Details
    </Typography>
</Stack>
            <Stack direction="column" spacing={1} sx={{}}>

      
          <Stack
          spacing={1} direction="row" 
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
   

   
            }}
          >
             <Stack spacing={0.25}  sx={{width:'100%'}}>
      <Typography 
        variant="body2" 
        sx={{ color: "#48768C", fontWeight: 600, marginLeft: "5px" }}
      >
       First Name
      </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editData.first_name}
              onChange={(e) =>
                setEditData({ ...editData, first_name: e.target.value })
              }
              sx={{
                color: "#000",
      
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#2F5C71", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#48768CBA", // Focused border color
                  },
                },
                "& input": {
                  width: "100%",
                  textAlign: "left",
                  color: "#2F5C71", // Text color inside the input
                },
                "& .MuiInputLabel-root": {
                  color: "#2F5C71", // Default label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#2F5C71", // Label color when focused
                },
              }}
            />
            </Stack>
            <Stack spacing={0.25} alignItems="flex-start" sx={{width:'100%'}}>
      <Typography 
        variant="body2" 
        sx={{ color: "#48768C", fontWeight: 600, marginLeft: "5px" }}
      >
       Last Name
      </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editData.last_name}
              onChange={(e) =>
                setEditData({ ...editData, last_name: e.target.value })
              }
              sx={{
                color: "#000",
              
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#2F5C71", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#48768CBA", // Focused border color
                  },
                },
                "& input": {
                  width: "100%",
                  textAlign: "left",
                  color: "#2F5C71", // Text color inside the input
                },
                "& .MuiInputLabel-root": {
                  color: "#2F5C71", // Default label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#2F5C71", // Label color when focused
                },
              }}
            />
                </Stack>
                   </Stack>
                      <Stack
                             spacing={1} direction="row" 
                               sx={{
                                 display: "flex",
                                 justifyContent: "center",
                                 width: "100%",
                      
                    
                   
                      
                               }}
                             >
                             <Stack spacing={0.25} alignItems="flex-start" sx={{width:'100%'}}>
                         <Typography 
                           variant="body2" 
                           sx={{ color: "#48768C", fontWeight: 600, }}
                         >
                          Role
                         </Typography>
          <FormControl
            variant="outlined"
            sx={{
              width: "100%",
              mb: 1,
              ml: "7.5px",
              backgroundColor: "#FFFFFF",
            }}
          >
   
            <Select
              size="small"
              value={editData.title}
              onChange={handleChangeTitle}
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#576369",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2F5C71", // Hover state border color
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#48768CBA", // Focused border color
                },
                "& .Mui-focused .MuiInputLabel-root": {
                  color: "#2F5C71", // Focused label color
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #2F5C715E",
                    borderTop: "none",
                  },
                },
              }}
              startAdornment={
                <InputAdornment position="start" sx={{ml: editData.title? -2.95 : 0 }}>
                        {!editData.title && (
                      <ButtonBase onClick={handleRoleButtonClick}>    
                  <Typography sx={{color: "#48768C", fontWeight:500, ml:-.5}}>
                 Select A Role
                  </Typography>
                  </ButtonBase>
                        )}
          
                </InputAdornment>
              }
            >
              <MenuItem
                value="Employee"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Employee
              </MenuItem>
              <MenuItem
                value="Manager"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Manager
              </MenuItem>
              <MenuItem
                value="Owner"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Owner
              </MenuItem>
              <MenuItem
                value="Regional Manager"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Regional Manager
              </MenuItem>
              <MenuItem
                value="Account Administrator"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Account Administrator
              </MenuItem>
              <MenuItem
                value="Director of Operations"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Director of Operations
              </MenuItem>
              <MenuItem
                value="Assistant Manager"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Assistant Manager
              </MenuItem>
              <MenuItem
                value="Shift Lead"
                sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
              >
                Shift Lead
              </MenuItem>
            </Select>
          </FormControl>
       </Stack>
       </Stack>

       {!accountData.single_location && (
                                      <Stack spacing={0.25} alignItems="flex-start" sx={{width:'100%', }}>
                                      <Typography 
                                        variant="body2" 
                                        sx={{ color: "#48768C", fontWeight: 600,pt:.45 }}
                                      >
                                      Assigned Location
                                      </Typography>
                            <FormControl
                              sx={{
                                width: "100%",
                 
                                backgroundColor: "#F0F0F0",
                                borderRadius:'13px'
              
                              }}
                            >


                <Select
                  value={editData.assigned_location}
                  size="small"
                  onChange={(e) => {
                    const selectedLocationName = e.target.value;
                    const selectedLocation = locations.find(
                      (location) => location.name === selectedLocationName,
                    );
                    const selectedLocationId = selectedLocation
                      ? selectedLocation.location_id
                      : null;
                    setEditData({
                      ...editData,
                      assigned_location: selectedLocationName,
                      assigned_location_id: selectedLocationId,
                    });
                  }}
                  fullWidth
                  sx={{
                    backgroundColor: "#FFFFFF",
                    color: "#2F5C71",

                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#2F5C71", // Hover state border color
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#48768CBA", // Focused border color
                    },
                    "& .Mui-focused .MuiInputLabel-root": {
                      color: "#2F5C71", // Focused label color
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #2F5C715E",
                        borderTop: "none",
                      },
                    },
                  }}
                >
                  {locations.map((location) => (
                    <MenuItem
                      sx={{ backgroundColor: "#FFFFFF", color: "#2F5C71" }}
                      key={location.location_id}
                      value={location.name}
                    >
                      {location.name}
                    </MenuItem>
                  ))}
                          </Select>
                          </FormControl>
                          </Stack>
     
            )}
      </Stack>
      
      <Stack direction="row" sx={{alignItems:'center',   mt:2.5, mb:-1, ml:-.5}}> 
      
      <img
          src={AccountInfoIcon}
          style={{
            border: "transparent",
            width: "auto",
            height: "28.5px",
            borderRadius: "6px",
            marginBottom: "15px",
            marginTop:'5px'
          }}
        />
          <Typography
            sx={{
           
              color: "#48768C",
      
              fontSize: 18.5,
              fontWeight: 600,
              textAlign:'center',
              ml:.25,
      
              alignItems:'center',
              letterSpacing: '-.02em',
              mb:1
      
            }}
          >
         Account Information
          </Typography>
      
      </Stack>
      
                  
      
                  <Stack direction="column" spacing={1} sx={{}}>
                  <Stack spacing={0.25}  sx={{width:'100%'}}>
            <Typography 
              variant="body2" 
              sx={{ color: "#48768C", fontWeight: 600, marginLeft: "5px" }}
            >
            Username
            </Typography>

       
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editData.username}
              onChange={(e) => {
                const newValue = e.target.value.replace(/[^A-Za-z]/g, ""); // Sanitize to allow only alphabetic characters

                // Limit the length to 10 characters
                const limitedValue = newValue.slice(0, 10);

                // Check if the username is already taken, excluding the signed-in user
                const isUsernameTaken = users.some(
                  (user) =>
                    user.username === limitedValue &&
                    user.username !== newUsername,
                );

                // Update the editData state with the sanitized and limited value
                setEditData((prevData) => ({
                  ...prevData,
                  username: limitedValue,
                }));

                // Update the username taken state based on the check
                setUserNameTaken(isUsernameTaken);

                // Optionally, clear the userNameTaken state when the username is available
                if (!isUsernameTaken && userNameTaken) {
                  setUserNameTaken(false);
                }
              }}
              sx={{
                mb: 0,
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#2F5C71", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#48768CBA", // Focused border color
                  },
                },
                "& input": {
                  width: "100%",
                  textAlign: "left",
                  color: "#2F5C71", // Text color inside the input
                },
                "& .MuiInputLabel-root": {
                  color: "#2F5C71", // Default label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#2F5C71", // Label color when focused
                },
              }}
              InputProps={{
                endAdornment: userNameTaken && (
                  <InputAdornment position="end">
                    <Typography sx={{ color: "#8C4350", fontSize: 13 }}>
                      Username Already Exists
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
       </Stack>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                ml: 0,
                width: "100%",
                alignItems: "left",
                mt: 1,
              }}
            >
                   <Stack
                        direction="row"
                        sx={{ alignItems: "center", }}
                      >
                    <ButtonBase
                    onClick={handleChangePinOpen}
                    sx={{
                        backgroundColor: "rgba(108, 144, 162, 0.1)" ,
                        py:.75,
                        borderRadius:'4px',
                        mt:1.25,
                        mb:.25,
                        "&:hover": {
                          backgroundColor: "rgba(108, 144, 162, 0.1)", // Subtle hover effect
                          transform: "scale(1.02)", // Slight scaling for interaction feedback
                        },
                      }}
                    >
                   < LockPersonTwoToneIcon sx={{color:'#40657a', mr:1}} />
                        <Typography
                          noWrap
                          sx={{
                            color: `#48768C` ,
                            borderRadius: "8px",
                            fontSize: 16,
                            fontWeight: 600,
                            textAlign: "left",
                            letterSpacing: "-.02em",
                            pr:1.5
              
                          }}
                        >
Change  Pin          
                        </Typography>
                        </ButtonBase>
                      </Stack>

                      <Stack
                        direction="row"
                        sx={{ alignItems: "center", }}
                      >
                        <FormControlLabel
                          control={

                            <Checkbox sx={{ml:"1px"}}
                            checked={isAdmin}
                            disabled={isSwitchDisabled}
                            onChange={handleToggleSwitch}
                              icon={<CheckBoxOutlineBlankRoundedIcon />}
                              checkedIcon={<CheckBoxTwoToneIcon sx={{color:'#48768C'}} 
                      
                              />}
                            />
                          }
                        />
                        <Typography
                          noWrap
                          sx={{
                            color: `#48768C` ,
                            borderRadius: "8px",
                            fontSize: 16,
                            fontWeight: 600,
                            textAlign: "left",
                            letterSpacing: "-.02em",
                            ml: -1.75,
                          }}
                        >
                                Make this user an administrator
          
                        </Typography>
                      </Stack>
                 

            </Box>
   
</Stack>
       <Box
            sx={{ width: "100%", display: "flex",justifyContent:'flex-start', gap:2, pr:.5, mt:1, ml:-.25 }}
          >
          <SecondaryButton
                      disabled={
                        !editData.first_name ||
                        !editData.last_name ||
                        !editData.username ||
                        loading ||
                        userNameTaken ||
                        disabled
                      }
            sx={{
              mt: 4,
              fontSize: 18,
              transition: "background-color 0.3s ease",
              width: "64%",
              justifyContent: "center",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              borderRadius:'6px',
              color: '#F0F0F0',
              backgroundColor: '#46A793',
              '&:hover': { backgroundColor: '#079E80' },
              '&:active': { backgroundColor: '#2E7D32' },
          
            }}
            onClick={onSave}
          >
 {loading ? "Saving..." : "Save Changes"}
           </SecondaryButton>
         
          <SecondaryButton

                        onClick={onClose}

            sx={{
              mt: 4,
              fontSize: 18,
              transition: "background-color 0.3s ease",
              width: "34%",
              justifyContent: "center",
              alignSelf: "center",
              display: "flex",
              alignItems: "center",
              borderRadius:'6px',
              boxShadow:'none',
              '&:hover': { backgroundColor: '#faf5f7', border:'1px solid #f6edf0', color:'#bf7588' },

            }}
         

          >
            
           Cancel
          </SecondaryButton>
        </Box>
      
      </DialogContent>
      <ChangePinModal
        isOpen={isChangePinModalOpen}
        onClose={handleChangePinClose}
        onSave={handleChangePinSave}
        userName={`${editData?.first_name || ""} ${editData?.last_name || ""}`}
        newPin={newPin} // Pass the array, not the setter
        confirmNewPin={confirmNewPin} // Pass the array, not the setter
        setNewPin={setNewPin} // Optionally pass setter if you want child to update the state
        setConfirmNewPin={setConfirmNewPin} // Optionally pass setter if you want child to update the state
        isLoading={isLoading}
      />
            <ConfirmAdminDialog
                      open={infoDialogOpen}
                      onClose={handleInfoDialogClose}
      />
    </Dialog>
  );
};

export default EditUsersModal;
