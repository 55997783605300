import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAppContext } from "src/context/AppContext";
import useAddLocation from "src/components/payments/hooks/useAddLocation";
import useAddNewUser from "src/components/payments/hooks/useAddNewUser.js";
import usePlanDetails from "src/components/payments/hooks/usePlanDetails";
import {   Button,
  Box,
  Fade,
  Stack,
  Card,
  Typography,
  Divider,
  Paper } from "@mui/material";
  import CreditCard from "src/public/assets/icons/icons/creditCard.png";

import BillingInfoForm from "./BillingInfoForm";
import { SecurityUpdateWarningRounded } from "@mui/icons-material";
import { values } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";



// Load the Stripe object outside the component
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const EditForm = ({
  clientSecret,
  customerId,
  priceId,
  apiUrl,
  onSubscriptionUpdate,
  refreshSubscription,
  onClose,
  subscriptionId,
  refetchSubscriptionDetails,
  errors,
  setWarning,
  warning,
  values,
  fetchBillingHistory,
  subscriptionDetails,
  billingDetailOne, 
  billingValueOne, 
  billingDetailTwo, 
  billingValueTwo, 
  billingDetailThree, 
  billingValueThree, 
  billingDetailFour, 
  billingValueFour, 
  billingDetailFive, 
  billingValueFive,

  totalPlanCost,
  promotion,
  discountName,
  discountPercentage,
  planCost,
  discountedPlanCost,
  loadingPlanDetails,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showSnackBar } = useAppContext();
  const { addNewLocation } = useAddLocation();
  const { addNewUser } = useAddNewUser();
  const { planDetails } = usePlanDetails();
  const [paymentElementLoaded, setPaymentElementLoaded] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [paymentMethodId, setPaymentMethodId] = useState(null);

 


        const billingDetails = [
          { label: billingDetailOne, value: billingValueOne },
          { label: billingDetailTwo, value: billingValueTwo },
          { label: billingDetailThree, value: billingValueThree },
          { label: billingDetailFour, value: billingValueFour },
          { label: billingDetailFive, value: billingValueFive },
        ];




        

  useEffect(() => {
    // Ensure the PaymentElement initializes properly before rendering
    const timer = setTimeout(() => {
      setPaymentElementLoaded(true);
    }, 0);

    return () => clearTimeout(timer); // Cleanup the timeout
  }, []);

  useEffect(() => {
    const handleUpdate = async () => {
      if (!paymentMethodId) return;
    
      try {
        const token = await getAccessTokenSilently();
    
        const response = await fetch(
          `${apiUrl}/subscriptions/plans/${subscriptionId}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ paymentMethodId, customerId }),
          },
        );
    
        const data = await response.json();
    
        if (response.ok) {
          showSnackBar("Payment method and subscription updated successfully", true);
          refetchSubscriptionDetails();
          fetchBillingHistory(customerId);
          setLoading(false);
          onClose();
        } else {
          console.error("Backend error:", data.error);
          setError(data.error || "Failed to update billing details.");
          showSnackBar("Unable to update card. Please try again", false);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during backend processing:", error.message);
        setError(error.message || "An unexpected error occurred.");
        showSnackBar("Unable to update card. Please try again", false);
        setLoading(false);
      }
    };

    handleUpdate();
  }, [paymentMethodId]); // Dependency array ensures this runs when paymentMethodId changes

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      setError("Stripe has not loaded or clientSecret is missing");
      return;
    }

    if (errors && Object.values(errors).some((value) => value === true)) {
      setWarning(true);
      return;
    }

    setLoading(true);

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message || "Form submission failed");
      }

      const { setupIntent, error: setupError } = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: "if_required",
      });

      if (setupError || !setupIntent.payment_method) {
        throw new Error(
          setupError?.message ||
            "Setup confirmation failed: No payment method found",
        );
      }

      setPaymentMethodId(setupIntent.payment_method); // Update state to trigger useEffect

    } catch (error) {
      console.error("Error during payment setup:", error.message);
      setError(
        error.message || "An unexpected error occurred. Please try again.",
      );
      setLoading(false);
    }
  };



const formatCurrency = (amount) => {
  if (typeof amount !== "number" || isNaN(amount)) {
      return "$0.00"; // Handle invalid values
  }

  return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
  });
};




  return (
    <>
      <Box
        sx={{
          ml: 0,
          width: "100%",
          borderRadius: "15px",
          justifyContent: "space-around",
          height:'100%',  
          flexDirection: "row",  
          overflowY:'hidden' 

        }}
      >
<Stack 
  direction="column" 
  sx={{ 
    width: "100%", 
    flexWrap: "nowrap", 
    alignItems: "flex-start", // Move items to the left
    justifyContent: "flex-start", 
    pl: .75, // Optional: Add padding-left for spacing
    pt:.5,
  
  }}
>  
  <img
    src={CreditCard}
    style={{
      border: "1.25px solid rgba(173, 216, 230, 0.7)",
      width: "auto",
      height: "29px",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      padding: "1px",
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
      borderRadius: "6px",
      display: "block", // Prevents unintended spacing
    }}
  />
  
  <Typography
    align="left"
    sx={{
      fontSize: "20px",
      fontWeight: 550,
      color: "#48768C",
      letterSpacing: "-.027em",
      whiteSpace: "nowrap",
      pt: 1.5,
      pr:1,
      mb: 1,
    }}
  >
    Update Payment Method
  </Typography>
</Stack>
<Stack direction="row" sx={{ width: "100%", flexWrap: "nowrap" }}>
  <Fade in={!loadingPlanDetails} timeout={1000}>  
  
<Box sx={{ width: "50%", display: "flex", pl: .75,pt:1, flexDirection: "column", overflowX:'auto',my:.5,   pr:4 }}>
<Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                    }}
                  >
                    Your Subscription Summary
                  </Typography>

                




                


                <Divider sx={{ borderBottomWidth: "1.5px", mb:1.5, mt:1 }} />
             
                <Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap",
                      mt:1
                    }}
                  >
                     Monthly Plan Cost
                  </Typography>

                
                  <Stack direction="row" sx={{ alignItems: "center" }}>
  <Typography
    align="left"
    sx={{
      fontSize: "15px",
      fontWeight: 550,
      color: "#6c90a2",
      letterSpacing: "-.2px",
      lineHeight: "-2",
      whiteSpace: "nowrap", // Prevent wrapping
    }}
  >
    {planDetails?.plan_name}
  </Typography>
  
  <Divider 
  sx={{
    flexGrow: 1,          // Takes up remaining width
    borderBottomWidth: 1.5,
    borderBottomStyle: "dashed",  // Change to "dashed" style
    borderColor: "#6c90a2",
    opacity: 0.5,
    mt: "12px", 
    ml: 1,
    mx: 1
  }} 
/>
    <Typography
    align="left"
    sx={{
      fontSize: "15px",
      fontWeight: 550,
      color: "#6c90a2",
      letterSpacing: "-.2px",
      lineHeight: "-2",
      whiteSpace: "nowrap", // Prevent wrapping
      ml: 1,
      mr:1,
    }}
  >
   {planCost ? "$" + planCost + ".00" : ""}
  </Typography>
</Stack>

                 
                 
                 {billingDetailOne && (
                   <Typography
                    align="left"
                    sx={{
                      fontSize: "18.5px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap", 
                      mt: 2,
                    }}
                  >
                    Additional Charges
                  </Typography>
                  )}
                 
                 
                 


{billingDetails.map((detail, index) => (
  <Stack direction="row" sx={{ alignItems: "center" }} key={index}>
    {/* Billing detail */}
    <Typography
      align="left"
      sx={{
        fontSize: "15px",
        fontWeight: 550,
        color: "#6c90a2",
        letterSpacing: "-.2px",
        lineHeight: "-2",
      }}
    >
      {detail.label || ""}
    </Typography>

    {/* Divider */}
    <Divider
      sx={{
        flexGrow: 1,          // Takes up remaining width
        borderBottomWidth: 1.5,
        borderBottomStyle: "dashed",  // Change to "dashed" style
        borderColor: "#6c90a2",
        opacity: 0.5,
        mt: "12px",
        ml: 1,
        mx: 1,
        display: detail.label ? "block" : "none", // Only show if label is not empty
      }}
    />

    {/* Billing value */}
    <Typography
      align="left"
      sx={{
        fontSize: "15px",
        fontWeight: 550,
        color: "#6c90a2",
        letterSpacing: "-.2px",
        lineHeight: "-2",
        pt:'2px',
        ml: 1,
        mr:1,
      }}
    >
      {detail.value || ""}
    </Typography>
  </Stack>
))}


{promotion && (
            <Stack direction="row" sx={{ alignItems: "center" }}>
              {/* Billing detail */}
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                }}
              >
                {discountName}
              </Typography>

              {/* Divider */}
              <Divider
                sx={{
                  flexGrow: 1, // Takes up remaining width
                  borderBottomWidth: 1.5,
                  borderBottomStyle: "dashed", // Change to "dashed" style
                  borderColor: "#6c90a2",
                  opacity: 0.5,
                  mt: "12px",
                  ml: 1,
                  mx: 1,
                }}
              />

              {/* Billing value */}
              <Typography
                align="left"
                sx={{
                  fontSize: "15px",
                  fontWeight: 550,
                  color: "#6c90a2",
                  letterSpacing: "-.2px",
                  lineHeight: "-2",
                  pt: "2px",
                  ml: 1,
                  mr: 1,
                }}
              >
               { "-" + discountPercentage + "%"}
              </Typography>
            </Stack>
)}




<Stack direction="row" spacing={2} sx={{ width: "100%", flexWrap: "nowrap", alignItems: "center", justifyContent: "space-between",                       mt: 4,
mb:1, 
 }}>
                <Typography
                    align="left"
                    sx={{
                      fontSize: "20px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap", 
                    }}
                  >
                    Your Monthly Cost
                  </Typography>

                <Typography
                    align="left"
                    sx={{
                      fontSize: "20px",
                      fontWeight: 550,
                      color: "#48768C",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      whiteSpace: "noWrap", 
                      mr:3,
                      mb:1,
                      
                    }}
                  >
                { promotion ?  formatCurrency(discountedPlanCost) : "$" + totalPlanCost }
                </Typography>
                  </Stack>

                



                


            </Box>
            
</Fade>
            <Box
  sx={{
    my:8.5,
    flexGrow: 1,
    minWidth: "300px", // Prevents shrinking too much
    maxWidth: "500px", // Keeps form at a reasonable width
    width: "100%",
    display: "flex",
    padding: 2,
    flexDirection: "column",

    pb:0,
    overflowY: "auto",
    mb:0,
    mb:'5px',
    height:'100%'

  }}
>

        <form onSubmit={handleSubmit}>
          
          {clientSecret && (
           
    <PaymentElement 
      onReady={() => setPaymentElementLoaded(true)}
      options={{
        layout: "tabs", // Ensure a structured layout for fields
      }}
    />
          )}  

          {paymentElementLoaded && (
            <Button
              type="submit"
              disabled={!stripe || !clientSecret || loading}
              sx={{
                display: "flex",
                border: "1px solid #48768C",
                borderRadius: "6px",
                padding: "8px",
                color: "#48768C",
                backgroundColor: "#FFFFFF",
                alignItems: "center",
                
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                width: "100%",
                height: "40px",
                mt:4,
                mb:1,
              
                "&:hover": {
                  backgroundColor: "#DBEAF0",
                },
                "&:active": {
                  backgroundColor: "#DBEAF0",
                },
              }}
              color="primary"
            >
              {loading ? "Processing..." : "Update Payment Method"}
            </Button>
          )}
        
        </form>
        </Box>  

</Stack>  


      </Box>
    </>
  );
};

// Wrapper component to ensure Elements is loaded correctly
const EditFormWrapper = ({
  customerId,
  priceId,
  apiUrl,
  onSubscriptionUpdate,
  onClose,
  clientSecret,
  refreshSubscription,
  subscriptionId,
  refetchSubscriptionDetails,
  errors,
  setWarning,
  warning,
  loading,
  values,
  fetchBillingHistory,
  subscriptionDetails,
  billingDetailOne, 
  billingValueOne, 
  billingDetailTwo, 
  billingValueTwo, 
  billingDetailThree, 
  billingValueThree, 
  billingDetailFour, 
  billingValueFour, 
  billingDetailFive, 
  billingValueFive,

  totalPlanCost,
  promotion,
  discountName,
  discountPercentage,
  planCost,
  discountedPlanCost,
  formatCurrency,
  loadingPlanDetails,
}) => {
  const appearance = {
    theme: "stripe", // You can also try 'night', 'flat', 'none', etc.
    labels: 'floating',
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#7D4E4F",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
      labels: 'floating',


    },
    rules: {
 
      ".Label": {
        color: "#6c90a2",
        fontSize: "16.5px",
        marginBottom: "3px",
      },
      ".Input": {
        backgroundColor: "#F2F9FF",
        border: "1px solid #e1e6ef",
        fontSize: "14.5px",
        padding: 0,
        color: "#4E617D",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        height: "auto",
        fontFamily: "Source Sans Pro, sans-serif",
      },
      ".Input--invalid": {
        color: "#7D4E4F",
      },
      ".PaymentElement": {
        backgroundColor: "#f9fbfd",
        padding: "5px",
        borderRadius: "4px",
        boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15)",
      },
      ".Input::placeholder": {
        color: "#48768CCC", // Correct selector for placeholder text
      },
    },
  };

  // Enable the skeleton loader UI for optimal loading.
  const loader = "auto";  

  return (
    <div>
      {clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, appearance, loader }}
        >
          <EditForm
            clientSecret={clientSecret}
            customerId={customerId}
            priceId={priceId}
            apiUrl={apiUrl}
            onSubscriptionUpdate={onSubscriptionUpdate}
            onClose={onClose}
            refreshSubscription={refreshSubscription}
            subscriptionId={subscriptionId}
            refetchSubscriptionDetails={refetchSubscriptionDetails}
            errors={errors}
            setWarning={setWarning}
            warning={warning}
            loading={loading}
            values={values}
            fetchBillingHistory={fetchBillingHistory}
            subscriptionDetails={subscriptionDetails} 
            billingDetailOne={billingDetailOne}
            billingDetailTwo={billingDetailTwo}
            billingDetailThree={billingDetailThree}
            billingDetailFour={billingDetailFour}
            billingDetailFive={billingDetailFive}
            billingValueOne={billingValueOne}
            billingValueTwo={billingValueTwo}
            billingValueThree={billingValueThree}
            billingValueFour={billingValueFour}
            billingValueFive={billingValueFive}

            planCost={planCost}
            totalPlanCost={totalPlanCost}
            promotion={promotion}
            discountName={discountName}
            discountPercentage={discountPercentage}
            discountedPlanCost={discountedPlanCost}
            formatCurrency={formatCurrency}
            loadingPlanDetails={loadingPlanDetails}

          />
        </Elements>
      ) : (
        <div>Loading Payment Information...</div>
      )}
    </div>
  );
};

export default EditFormWrapper;
