import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  IconButton,
  DialogTitle,
  Stack,
  LinearProgress,
  Checkbox,
  CircularProgress,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useAccountContext } from "src/context/AccountContext";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import { useSendVerificationEmail } from "./sendVerificationEmail";
import EditHoursModal from "../modal/EditHoursModal";
import SingleUserEditHoursModal from "../modal/SingleUserEditHoursModal";
import styles from "./styles.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { dismissItem } from "./dismissItem";
import SetupComplete from "./setupComplete";
import AddUserModal from "../modal/AddUserModal/AddUserModal";
import useFetchOnboardingRecord from "./useFetchOnboardingRecord";
import AddAdditionalModal from "./addAdditionalModal";
import AddLocationModal from "../modal/AddNewLocation/AddLocationModal";
import { add, set } from "lodash";
import { useUserLogo } from "src/context/LogoContext";
import handleCompleteSetup from "./setupComplete";
import SuccessDialog from "../modal/SuccessDialog";
import checklistDone from "src/public/assets/illustrations/checklistDone1.png";
import addItemComplete from "src/public/assets/illustrations/addItemComplete.png";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import { useAppContext } from "src/context/AppContext";
import { useSingleUser } from "src/context/SingleUserContext"; // Import useSingleUserContext from SingleUserContext
import { useAuth } from "src/context/AuthContext";
import SetupIcon from "src/public/assets/icons/icons/setup.png";


function ChecklistModal({ open, onClose, completedOnboarding, fetchUsers }) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const {subUserData} = useAuth();  
  const {
    accountData,
    onboardingData,
    onboardingProgress,
    requiredItemsProgress,
    optionalItemsProgress,
    totalOptionalItems,
    fetchOnboardingRecord,
    fetchAccountData,
  } = useAccountContext();
  const { fetchLatestSingleUserData } = useSingleUser(); // Import the fetchLatestSingleUserData function
  const { isLoading, message, handleSubmit } = useSendVerificationEmail();
  const [isEditHoursModalOpen, setIsEditHoursModalOpen] = React.useState(false);
  const [isSingleUserEditHoursModalOpen, setIsSingleUserEditHoursModalOpen] =
    React.useState(false);
  const [confirmHours, setConfirmHours] = React.useState(false);
  const [wasModalOpen, setWasModalOpen] = React.useState(false); // To track modal open state
  const basePath = "/dashboard/Account";
  const navigate = useNavigate();
  const [dismissLogo, setDismissLogo] = React.useState(false);
  const [dismissUser, setDismissUser] = React.useState(false);
  const [dismissLocation, setDismissLocation] = React.useState(false);
  const [setupCompleteDialogOpen, setSetupCompleDialogOpen] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false);
  const [addAdditionalModalOpen, setAddAdditionalModalOpen] = useState(false);
  const { fetchLocations, totalLocations, locations } =
    useFetchOnboardingRecord();
  const [addedLocation, setAddedLocation] = React.useState(false);
  const [addedUser, setAddedUser] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [action, setAction] = React.useState(null);
  const { userLogoUrl, uploadLogo, deleteLogo } = useUserLogo();
  const [loading, setLoading] = React.useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const { fetchLatestUserData, showSnackBar } = useAppContext();

  const userId = isAuthenticated && user ? user.sub : null; // Safely access user.sub

  const handleAddedLocation = () => {
    setAddedLocation(true);
  };

  const handleAddedUser = () => {
    setAddedUser(true);
  };

  const handleOpenEditHoursModal = () => {
    if (accountData.single_user) {
      setIsSingleUserEditHoursModalOpen(true);
      setConfirmHours(true);
    } else {
      setIsEditHoursModalOpen(true);
      setConfirmHours(true);
    }
  };

  const handleDismissItem = async (item) => {
    setLoading(true);
    set(item, true);
    await dismissItem(item, getAccessTokenSilently, userId);
    fetchOnboardingRecord(setLoading);
  };

  useEffect(() => {
    if (open && completedOnboarding) {
      setSetupCompleDialogOpen(true);
    }
  }, [open, completedOnboarding]);

  useEffect(() => {
    if (accountData && totalLocations > 1 && accountData.single_user) {
      fetchLocations(user);
    }
  }, [open, accountData]); // Dependency on accountData

  const handleAddUserModal = () => {
    setAddUserModalOpen(true);
    setAddedUser(null);
  };

  const handleCloseAddUserModal = () => {
    if (addedUser) {
      setItem("User");
      setAction(() => handleAddUserModal); // Ensure the action is correctly set
      setAddAdditionalModalOpen(true);

      setAddUserModalOpen(false);
    } else {
      setAddUserModalOpen(false);
    }
  };

  const handleAddLocationModal = () => {
    setAddLocationModalOpen(true);
    setAddedLocation(null);
  };

  const handleCloseAddLocationModal = () => {
    if (addedLocation) {
      // Batch all state updates together
      setItem("location");
      setAction(() => handleAddLocationModal); // Ensure the action is correctly set
      setAddAdditionalModalOpen(true);

      setAddLocationModalOpen(false); // Close the location modal first

      // After state is set, open the additional modal
    } else {
      setAddLocationModalOpen(false);
    }
  };

  useEffect(() => {
    // When the location modal closes
    if (!addLocationModalOpen && addedLocation) {
      // Trigger opening of the additional modal once the location modal is closed
      setAddAdditionalModalOpen(true);
      setItem("location");
      setAction(() => handleAddLocationModal); // Set the action to handleAddLocationModal
    }
  }, [addLocationModalOpen, addedLocation]); // This effect tracks changes in both addLocationModalOpen and addedLocation states

  useEffect(() => {
    // When the location modal closes
    if (
      !addUserModalOpen &&
      addedUser &&
      accountData &&
      !accountData.onboarding_complete
    ) {
      // Trigger opening of the additional modal once the location modal is closed
      setAddAdditionalModalOpen(true);
      setItem("user");
      setAction(() => handleAddUserModal); // Set the action to handleAddLocationModal
    }
  }, [addUserModalOpen, addedUser, accountData]); // This effect tracks changes in both addLocationModalOpen and addedLocation states

  useEffect(() => {
    // When the location modal closes
    if (
      !addUserModalOpen &&
      addedUser &&
      accountData &&
      !accountData.onboarding_complete
    ) {
      // Trigger opening of the additional modal once the location modal is closed
      setAddAdditionalModalOpen(true);
      setAction(() => handleAddUserModal); // Set the action to handleAddLocationModal
    }
  }, [addUserModalOpen, addedUser, accountData]); // This effect tracks changes in both addLocationModalOpen and addedLocation states

  //TRIGGER RENDER OF SUCCESS DIALOG HERE. MAYBE ADD IF/ELSE USEEFFECT TO TRACK?
  const handleCloseAddAdditionalModal = () => {
    if (completedOnboarding) {
      setAddAdditionalModalOpen(false);
      onClose();
    } else {
      setAddedLocation(false);
      setAddAdditionalModalOpen(false);
      setItem("");
      setAction(null);
      setAddedUser(false);
    }
  };

  const handleCloseEditHoursModal = () => {
    if (accountData.single_user && !onboardingData.hours_confirmed) {
      setIsSingleUserEditHoursModalOpen(false);
    } else {
      setIsEditHoursModalOpen(false);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      setLoading(true);
      const success = await uploadLogo(file); // Trigger the uploadLogo function and wait for it to finish
      if (success) {
        handleDismissItem("addLogo"); // Call handleDismissItem if upload was successful
      }
    }
    setLoading(false);
  };

  const handleManageNotifications = () => {
    // Close the dialog
    onClose();
    // Navigate to the notifications page
    navigate(`${basePath}/UserNotifications`);
  };

  const handleResendVerificationEmail = async (e) => {
    const sendSuccess = await handleSubmit(e, userId); // Pass userId here
    if (sendSuccess) {
      showSnackBar("Verification email sent successfully", true);
    } else {
      showSnackBar("Error sending verification email", false);
    }
  };



  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          height: "auto",
          overflowX: "hidden",
          minWidth: "575px",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 3,
          top: 3,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "1px",
          mx: 0.5,
          my: 0.25,
          transition: "all 0.3s ease-in-out", // Transition effect for smooth animations
          "&:hover": {
            backgroundColor: "#f0f4fa", // Slightly different background color on hover
            color: "#536b88", // Change color on hover
          },
          "&:active": {
            transform: "scale(0.95)", // Slight scale effect on click
          },
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>
      <Box
        sx={{
          border: "1px solid rgba(173, 216, 230, 0.7)",
          borderRadius: "12px",
          backgroundColor: "rgba(191, 218, 231, 0.2)",
        }}
      >
                  {subUserData?.is_admin ? (
                    <>
        <DialogTitle sx={{ padding: 2.5 }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              width: "100%",
              height: "auto",
              mr: "auto",
              alignItems: "center",
            }}
          >
            <Paper elevation={6} sx={{ alignItems: "center" }}>
              <CheckRoundedIcon
                style={{
                  display: "block",
                  width: "33px",
                  height: "33px",
                  border: ".5px solid #48B09B",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  padding: 1,
                  backgroundColor: "#A9D6CD",
                }}
              />
            </Paper>
            <Box
              sx={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                color="textSecondary"
                sx={{
                  fontWeight: 600,
                  fontSize: 16.5,
                  textAlign: "left",
                  mt: "2px", // Remove margin-top to vertically align text
                  letterSpacing: "-0.5%",
                  lineHeight: 1.2,
                  ml: 0.5,
                }}
              >
                Finish Setting Up Your Account
              </Typography>
              <Typography
                sx={{
                  color: "#80ABC0",
                  fontWeight: 600,
                  fontSize: 14,
                  textAlign: "left",
                  letterSpacing: "-0.5%",
                  lineHeight: 1.2,
                  ml: 0.5,
                }}
              >
                Double check that everything looks good before you start sending
                notifications.
              </Typography>
            </Box>
          </Stack>
          <Box sx={{ px: "2px" }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 15,
                textAlign: "left",
                mb: 1,
                mt: 3,
                letterSpacing: "-0.5%",
                lineHeight: 1.2,
                color: "#48768C",
              }}
            >
              {" "}
              Setup Progress: {Math.round(onboardingProgress)}%{" "}
            </Typography>

            <LinearProgress
              variant="determinate"
              value={onboardingProgress}
              sx={{
                width: "100%",
                color: "#48768C",
                mb: 1,
                "& .MuiLinearProgress-bar": { backgroundColor: "#48B09B" },
                backgroundColor: "rgba(191, 218, 231, 0.7)",
                borderRadius: "12px",
              }}
            />
          </Box>
        </DialogTitle>

        <DialogContent
          sx={{
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >

          <Box sx={{ diplay: "flex", flexDirection: "column", width: "100%" }}>
            <Stack
              direction="column"
              spacing={2}
              sx={{ display: "flex", width: "100%" }}
            >
              <Paper
                elevation={1}
                sx={{
                  margin: 1,
                  borderRadius: "12px",
                  pb: 1,
                  backgroundColor: "rgba(191, 218, 231, 0.1)",
                  px: "2px",
                }}
              >
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <Typography
                    sx={{
                      color: "#48768C",
                      fontWeight: 600,
                      fontSize: "15px",
                      textAlign: "left",
                      padding: 1,
                      mt: 1,
                      letterSpacing: "-0.5%",
                      lineHeight: 1.2,
                    }}
                  >
                    Finish Setting Up Your New Account
                  </Typography>

                  {requiredItemsProgress < 100 ? (
                    <Box
                      sx={{
                        border: "3px solid #BAE6DD", // Border color for the background
                        borderRadius: "50%", // Make it circular
                        display: "flex", // Center contents
                        alignItems: "center", // Center vertically
                        justifyContent: "center", // Center horizontally
                        width: "18px", // Set the width to accommodate the CircularProgress
                        height: "18px", // Set the height to accommodate the CircularProgress
                        position: "relative",
                        marginRight: 1,
                        mt: 1,
                        ml: "auto",
                      }}
                    >
                      <CircularProgress
                        variant="determinate"
                        value={requiredItemsProgress}
                        size={18} // Adjust the size as needed
                        thickness={6} // Increase the thickness of the line (default is 3.6)
                        sx={{
                          color: "#48B09B", // Filled portion color
                          position: "absolute", // Position absolutely within the Box
                        }}
                      />
                    </Box>
                  ) : (
                    <CheckCircleTwoToneIcon
                      sx={{
                        color: "#48B09B",
                        fontSize: 22,
                        marginRight: 1.2,
                        ml: "auto",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        mt: 1,
                      }}
                    />
                  )}

                  <Typography
                    sx={{
                      display: "flex",
                      color: "#48768C",
                      fontWeight: 600,
                      fontSize: "14px",
                      alignItems: "center",
                      mr: 2,
                      mt: 1,
                    }}
                  >
                    3 Tasks
                  </Typography>
                </Stack>
                <Box sx={{ padding: 0.2 }}>
                  {onboardingData && onboardingData.email_verified ? (
                    <Box className="listItemContainer">
                      <CheckBoxTwoToneIcon className="listItemComplete" />
                      <Typography className="listItemText">
                        Email Verified
                      </Typography>
                    </Box>
                  ) : (
                    <Box className="listItemContainer">
                      <CheckBoxOutlineBlankRoundedIcon className="listItemCheckbox" />
                      <Typography className="listItemText">
                        {" "}
                        Verify Email
                      </Typography>
                      <Box sx={{ marginLeft: "auto", marginRight: 2 }}>
                        <Button
                          className="setupLaterButton"
                          onClick={(e) =>
                            handleResendVerificationEmail(e, userId)
                          } // Pass userId here
                          disabled={isLoading} // Corrected to use 'isLoading'
                        >
                          {isLoading
                            ? "Sending..."
                            : "Resend Verification Email"}
                        </Button>
                      </Box>
                    </Box>
                  )}

                  {onboardingData && onboardingData.hours_confirmed ? (
                    <Box className="listItemContainer">
                      <CheckBoxTwoToneIcon className="listItemComplete" />
                      <Typography className="listItemText">
                        Location Hours Confirmed
                      </Typography>
                    </Box>
                  ) : (
                    <Box className="listItemContainer">
                      <CheckBoxOutlineBlankRoundedIcon className="listItemCheckbox" />
                      <Typography className="listItemText">
                        {" "}
                        Confirm Location Hours
                      </Typography>
                      <Box sx={{ marginLeft: "auto", marginRight: 2 }}>
                        <Button
                          onClick={handleOpenEditHoursModal}
                          className="setupLaterButton"
                        >
                          View Location Hours
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {onboardingData && onboardingData.notifications_confirmed ? (
                    <Box className="listItemContainer">
                      <CheckBoxTwoToneIcon className="listItemComplete" />
                      <Typography className="listItemText">
                        {" "}
                        Notifications Saved
                      </Typography>
                    </Box>
                  ) : (
                    <Box className="listItemContainer">
                      <CheckBoxOutlineBlankRoundedIcon className="listItemCheckbox" />
                      <Typography className="listItemText">
                        {" "}
                        Set Up Notifications
                      </Typography>
                      <Box sx={{ marginLeft: "auto", marginRight: 2 }}>
                        <Button
                          onClick={handleManageNotifications}
                          className="setupLaterButton"
                        >
                          Manage Notifications
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Paper>
            </Stack>

            <Stack
              direction="column"
              spacing={2}
              sx={{ display: "flex", width: "100%", marginTop: 2 }}
            >
              <Paper
                elevation={1}
                sx={{
                  margin: 1,
                  borderRadius: "12px",
                  pb: 1,
                  backgroundColor: "rgba(191, 218, 231, 0.1)",
                  px: "2px",
                }}
              >
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <Typography
                    sx={{
                      color: "#48768C",
                      fontWeight: 600,
                      fontSize: "15.5px",
                      textAlign: "left",
                      padding: 1,
                      mt: 1,
                      letterSpacing: "-0.5%",
                      lineHeight: 1.2,
                    }}
                  >
                    Customize Your Account
                  </Typography>

                  {optionalItemsProgress < 100 ? (
                    <Box
                      sx={{
                        border: "3px solid #BAE6DD", // Border color for the background
                        borderRadius: "50%", // Make it circular
                        display: "flex", // Center contents
                        alignItems: "center", // Center vertically
                        justifyContent: "center", // Center horizontally
                        width: "18px", // Set the width to accommodate the CircularProgress
                        height: "18px", // Set the height to accommodate the CircularProgress
                        position: "relative",
                        marginRight: 1,
                        mt: 1,
                        ml: "auto",
                      }}
                    >
                      <CircularProgress
                        variant="determinate"
                        value={optionalItemsProgress}
                        size={18} // Adjust the size as needed
                        thickness={6} // Increase the thickness of the line (default is 3.6)
                        sx={{
                          color: "#48B09B", // Filled portion color
                          position: "absolute",
                        }}
                      />
                    </Box>
                  ) : (
                    <CheckCircleTwoToneIcon
                      sx={{
                        color: "#48B09B",
                        fontSize: 22,
                        marginRight: 1.2,
                        ml: "auto",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        mt: 1,
                      }}
                    />
                  )}

                  <Typography
                    sx={{
                      display: "flex",
                      color: "#48768C",
                      fontWeight: 600,
                      fontSize: "14px",
                      alignItems: "center",
                      mr: 2,
                      mt: 1,
                    }}
                  >
                    {totalOptionalItems} Optional{" "}
                    {totalOptionalItems === 1 ? "Task" : "Tasks"}
                  </Typography>
                </Stack>
                <Box sx={{ padding: 0.2 }}>
                  {accountData && !accountData.single_user && (
                    <>
                      {onboardingData && onboardingData.add_users_confirmed ? (
                        <Box className="listItemContainer">
                          <CheckBoxTwoToneIcon className="listItemComplete" />
                          <Typography className="listItemText">
                            Add Additional Users
                          </Typography>
                        </Box>
                      ) : (
                        <Box className="listItemContainer">
                          <CheckBoxOutlineBlankRoundedIcon className="listItemCheckbox" />
                          <Typography className="listItemText">
                            {" "}
                            Add Additional Users
                          </Typography>
                          <Box sx={{ marginLeft: "auto", marginRight: 2 }}>
                            <Stack direction="row" spacing={2}>
                              <Button
                                onClick={handleAddUserModal}
                                className="addButton"
                              >
                                <PersonAddAltRoundedIcon
                                  sx={{
                                    border: ".5px solid #48768C",
                                    borderRadius: "4px",
                                    fontSize: "18.5px",
                                    marginRight: 1,
                                    padding: "1.5px",
                                    color: "#72C5B4",
                                  }}
                                />
                                Add User
                              </Button>
                              <Button
                                onClick={() => handleDismissItem("addUser")}
                                className="setupLaterButton"
                                disabled={loading}
                                sx={{
                                  letterSpacing: "-0.5%",
                                  lineHeight: 1.2,
                                  color: "#48768C",
                                }}
                              >
                                {!loading ? "Set Up Later" : "Saving..."}
                              </Button>
                            </Stack>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}

                  {accountData && !accountData.single_location && (
                    <>
                      {onboardingData &&
                      onboardingData.add_locations_confirmed ? (
                        <Box className="listItemContainer">
                          <CheckBoxTwoToneIcon className="listItemComplete" />
                          <Typography className="listItemText">
                            Add Additional Locations
                          </Typography>
                        </Box>
                      ) : (
                        <Box className="listItemContainer">
                          <CheckBoxOutlineBlankRoundedIcon className="listItemCheckbox" />
                          <Typography className="listItemText">
                            {" "}
                            Add Additional Locations
                          </Typography>
                          <Box sx={{ marginLeft: "auto", marginRight: 2 }}>
                            <Stack direction="row" spacing={2}>
                              <Button
                                onClick={handleAddLocationModal}
                                className="addButton"
                              >
                                <AddBusinessRoundedIcon
                                  sx={{
                                    border: ".5px solid #48768C",
                                    borderRadius: "4px",
                                    fontSize: "18.5px",
                                    marginRight: 0.75,
                                    padding: "1.5px",
                                    color: "#72C5B4",
                                  }}
                                />
                                Add Location
                              </Button>
                              <Button
                                onClick={() => handleDismissItem("addLocation")}
                                className="setupLaterButton"
                                disabled={loading}
                              >
                                {" "}
                                {!loading ? "Set Up Later" : "Saving..."}
                              </Button>
                            </Stack>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}

                  {onboardingData && onboardingData.company_logo ? (
                    <Box className="listItemContainer">
                      <CheckBoxTwoToneIcon className="listItemComplete" />

                      <Typography className="listItemText">
                        {" "}
                        Upload Your Company Logo
                      </Typography>
                    </Box>
                  ) : (
                    <Box className="listItemContainer">
                      <CheckBoxOutlineBlankRoundedIcon className="listItemCheckbox" />
                      <Typography className="listItemText">
                        {" "}
                        Upload Your Company Logo
                      </Typography>
                      <Box sx={{ marginLeft: "auto", marginRight: 2 }}>
                        <Stack direction="row" spacing={2}>
                          <input
                            type="file"
                            accept="image/*" // Accepts image files
                            onChange={handleFileChange} // Trigger handleFileChange on file selection
                            style={{ display: "none" }} // Hide the input element
                            id="logo-file-input" // Give it an ID to refer in the button's label
                          />
                          <Button
                            onClick={() =>
                              document.getElementById("logo-file-input").click()
                            } // Trigger file input on button click
                            className="addButton"
                            disabled={loading}
                          >
                            {" "}
                            {!loading ? "Upload Logo" : "Saving...."}{" "}
                          </Button>
                          <Button
                            onClick={() => handleDismissItem("addLogo")}
                            className="setupLaterButton"
                            disabled={loading}
                          >
                            {" "}
                            {!loading ? "Set Up Later" : "Saving..."}
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Paper>
            </Stack>
          </Box>

        </DialogContent>
        </>
                  ) : (
                    <>                   
                     <Stack direction="column" spacing={4} sx={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center', pb:4}}>
                       <Box sx={{pt:5}}>
                                <img
                                  src={SetupIcon}
                    
                                  style={{
                                    border: "transparent",
                                    width: "auto",
                                    height: "50px",
                                    backgroundColor: "#c6d4dc",
                                    borderRadius: "6px",
                                    padding: "1px",
                                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                                    marginBottom: "5px",
                                  }}
                                />
                              </Box>
                              <Box sx={{ px: 4, width:'100%' }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 15,
                textAlign: "left",
                mb: 1,
                mt: 2,
                letterSpacing: "-0.5%",
                lineHeight: 1.2,
                color: "#48768C",
              }}
            >
              {" "}
              Setup Progress: {Math.round(onboardingProgress)}%{" "}
            </Typography>

            <LinearProgress
              variant="determinate"
              value={onboardingProgress}
              sx={{
                width: "100%",
                color: "#48768C",

                "& .MuiLinearProgress-bar": { backgroundColor: "#48B09B" },
                backgroundColor: "rgba(191, 218, 231, 0.7)",
                borderRadius: "12px",
              }}
            />
          </Box>
                      <Typography
                                     color="textSecondary"
                        sx={{
                          fontSize: "17.5px", // slightly larger for the first text
                          fontWeight: 550,
                          letterSpacing: "-.02em",
                          lineHeight: "1.5",
                        }}
                      >
                    Your account setup is still in progress—your administrator is working on it.
                      </Typography>
                      <Typography
                      color="textSecondary"
                        sx={{
                          fontSize: "16px", // slightly smaller for the next text
                          fontWeight: 550,
                    
                          letterSpacing: "-.02em",
                          lineHeight: "1.5",
                        }}
                      >
                        Once everything is set up, you'll be able to send notifications.
                      </Typography>
                      <Typography
                        color="textSecondary"
                    
                        sx={{
                          fontSize: "16px", // matching the previous text size
                          fontWeight: 550,
                    
                          letterSpacing: "-.02em",
                          lineHeight: "1.5",
                        }}
                      >
                        Hang tight and check back soon!
                      </Typography>
                    </Stack>
                    </>

                              )}
                     

        <DialogActions>
          <Box sx={{ width: "10%" }}></Box>
        </DialogActions>
        <EditHoursModal
          isOpen={isEditHoursModalOpen}
          onClose={() => setIsEditHoursModalOpen(false)}
          confirmHours={confirmHours}
          newUser={true}
        />
        {accountData && accountData.single_user && (
          <SingleUserEditHoursModal
            isOpen={isSingleUserEditHoursModalOpen}
            onClose={handleCloseEditHoursModal}
            confirmHours={confirmHours}
            newUser={true}
          />
        )}

        <AddUserModal
          open={addUserModalOpen}
          onClose={handleCloseAddUserModal}
          locations={locations}
          onAddUser={handleAddedUser}
          fetchUsers={handleCloseAddUserModal}
          accountData={accountData}
        />
        <AddLocationModal
          open={addLocationModalOpen}
          onClose={handleCloseAddLocationModal}
          locations={locations}
          onAddLocation={handleAddedLocation}
          fetchLocations={handleCloseAddLocationModal}
          fetchUsers={fetchUsers}
        />
        <SuccessDialog
          successDialogOpen={addAdditionalModalOpen}
          header={`New ${item} Added`}
          caption={"Checklist Item Completed!"}
          body={`Would you like to add another ${item}?`}
          image={addItemComplete}
          onClose={handleCloseAddAdditionalModal}
          handleAlternateButtonAction={action}
          handleButtonAction={handleCloseAddAdditionalModal}
          closeButtonClick={handleCloseAddAdditionalModal}
          alternateButton={true}
          alternateButtonText={`Add Another ${item}`}
          buttonText={"Not right now"}
          item={item}
          action={action}
        />
      </Box>
    </Dialog>
  );
}

export default ChecklistModal;
