// useColorPicker.js
import { useState } from "react";
import { generateColor } from "./generateColor"; // Import the function that generates the color
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";


const useColorPicker = () => {
  const [colors, setColors] = useState({
    cardBackgroundColor: "#FFFFFF",
    borderColor: "rgba(173, 216, 230, 0.7)",
    fieldFontTextColor: "#000000",
    iconBackgroundColor: "#FFFFFF",
    titleColor: "#7589A2",
    containerBackgroundColor: "#FFFFFF",
    iconColor: "#2BBB9E",
    isDefault: true,
  });

  const apiUrl = process.env.REACT_APP_API_URL; // Add this line to define apiUrl
  const {getAccessTokenSilently, user} = useAuth0()
    const { showSnackBar } = useAppContext();
  

  const userId = user.sub

  const handleIconClick = (color) => {
    const newColors = generateColor(color) || {}; // Ensure fallback to avoid undefined
    setColors(newColors); // Update local state
  };






  const handleUpdateColor = async (newColor, notificationType) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${apiUrl}/api/user-notifications/update-configs/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          notificationType,
          configs: { Color: newColor },
        }),
      });
  
      if (response.ok) {
        showSnackBar("Notification Color Updated", true);
      } else {
        showSnackBar("Failed to update color", false);
        throw new Error("Failed to update color");
      }
  
      return response; // Return response so calling function can check it
    } catch (error) {
      console.error("Error updating color:", error);
      throw error; // Rethrow error so calling function can handle it
    }
  };



  const handleClearColors = () => {
    setColors({    cardBackgroundColor: "#EAEDF1",
      borderColor: "rgba(173, 216, 230, 0.7)",
      fieldFontTextColor: "#747C86",
      iconBackgroundColor: "#7589A2",
      titleColor: "#7589A2",
      containerBackgroundColor: "#FFFFFF",
      iconColor: "#2BBB9E",
      isDefault: false,})
  }

  return { colors, handleIconClick, handleClearColors, handleUpdateColor };
};

export default useColorPicker;
