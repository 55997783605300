import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Paper,
  IconButton,
  Typography,
  TextField,
  Stack,
  Fade,
} from "@mui/material";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import TitleIcon from "src/public/assets/icons/icons/newTitle.png";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { useNotificationState } from "../hooks/useNotificationState";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import ColorPicker from "./ColorPicker/ColorPicker";
import useColorPicker from "./ColorPicker/useColorPicker"; // Import the custom hook
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import LightTooltip from "@mui/material/Tooltip"; // Make sure you have this imported

import "./styles.css";

const NotificationTitle = ({
  notificationTitle,
  handleSetTitle,
  handleKeyDown,
  handleIconClick,
  colors,
  handleClearTitle,
  handlePrintFieldTitle,
  isTitleDefault,
  isTitleValid,
  setIsTitleValid,
  printedFieldTitle,
  handleClearColors
}) => {
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = useState(null);
  const [titleButtonsEnabled, setTitleButtonsEnabled] = useState(false);

  const handleResetField = () => {
    handleClearTitle();
    handleClearColors();
  }

  const handleBoxClick = (event) => {
    setColorPickerAnchorEl(event.currentTarget);
    setColorPickerOpen(true);
  };

  const handleColorPopoverClose = () => {
    setColorPickerOpen(false);
    setColorPickerAnchorEl(null);
  };

  useEffect(() => {
    const enableTitleButtons =
      notificationTitle !== "" && colors.isDefault !== true;
    setTitleButtonsEnabled(enableTitleButtons);
  }, [notificationTitle, colors.isDefault]);

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              "--TextField-brandBorderColor": "#E0E3E7",
              "--TextField-brandBorderHoverColor": "#B2BAC2",
              "--TextField-brandBorderFocusedColor": "#6F7E8C",
              "& label.Mui-focused": {
                color: "#48768C",
                borderBottom: "none",
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: "var(--TextField-brandBorderColor)",
            },
            root: {
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderBottom: "none",
              },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                borderBottom: "none",
                color: "#48768C",
              },
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              "&::before, &::after": {
                borderBottom: "none",
                color: "#48768C",
              },
              "&:hover:not(.Mui-disabled, .Mui-error):before": {
                borderBottom: "none",
              },
              "&.Mui-focused:after": {
                borderBottom: "none",
                color: "#48768C",
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              "&::before": {
                borderBottom: "none",
                color: "#48768C",
              },
              "&:hover:not(.Mui-disabled, .Mui-error):before": {
                borderBottom:
                  "2px solid var(--TextField-brandBorderHoverColor)",
              },
              "&.Mui-focused:after": {
                borderBottom:
                  "2px solid var(--TextField-brandBorderFocusedColor)",
                color: "#48768C",
              },
            },
          },
        },
      },
    });

  const outerTheme = useTheme();

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", width: "100%", mt: 1 }}
    >
      <Box
        sx={{
          marginTop: "5px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "10px",
          paddingTop: "20px",
          paddingBottom: "20px",
          minWidth: "100%",
          maxWidth: !isTitleValid ? "45%" : "45%",
          borderRadius: "8px",
          height: "35px",
          backgroundColor: isTitleDefault ? "#FFFFF" : "#FFFFFF",
          border: "1px solid rgba(173, 216, 230, 0.9)",
          // Corrected this line
        }}
      >
                  {!printedFieldTitle && (
        <Box
          style={{
            width: "auto",
            alignItems: "center", // Vertically center the icon

            display: "flex",

            borderRadius: "3px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            transition: "background-color 0.3s, color 0.3s, box-shadow 0.3s",
            color: "#72C5B4",
            "&:hover": {
              backgroundColor: "#7589A2",
              cursor: "pointer",
              color: "#526071",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            },
          }}
          onClick={handleBoxClick}
        >
          <SquareRoundedIcon
            style={{
              color: colors.titleColor,
              width: "auto",
              height: "22px",
              borderRadius: "4px",
              border: "1.5px solid rgba(173, 216, 230, 0.9)",
              padding: ".5px",
             
            }}
          />
        </Box>
                  )}

        <ThemeProvider theme={customTheme}>

          <ColorPicker
            colorPickerOpen={colorPickerOpen}
            colorPickerAnchorEl={colorPickerAnchorEl}
            handleColorPopoverClose={handleColorPopoverClose}
            handleIconClick={handleIconClick} // Pass the color change handler
          />

<TextField
  hiddenLabel
  placeholder="Notification Name"
  variant="filled"
  size="small"
  name="fieldName"
  value={notificationTitle || ""}
  onChange={(e) => {
    let value = e.target.value;

    // Remove any special characters or numbers (allowing spaces)
    value = value.replace(/[^a-zA-Z\s]/g, "");

    // Limit the length to 20 characters
    if (value.length > 20) {
      value = value.slice(0, 20);
    }

    // Capitalize the first letter of the input
    handleSetTitle(value.charAt(0).toUpperCase() + value.slice(1));
  }}
  onKeyDown={handleKeyDown}
  sx={{
    alignContent: "center",
    ml: printedFieldTitle ? 0.25 : 1.5,
    width: 411,
    mr: 0.5,
    mt: "1px",

    input: {
      color: colors.titleColor || '#48768C',
      fontWeight: 600,
      fontSize: printedFieldTitle ? "15px" : "13.5px",
      padding: 0,
      alignItems: "center",
      backgroundColor: isTitleDefault ? "#FFFFFF" : "#FFFFFF",
      fontFamily: "Source Sans Pro, sans-serif",
    },
  }}
/>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "auto",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {!isTitleValid && (
              <Typography
                sx={{
                  fontSize: "12.45px",
                  color: "#A65160",
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                  mt: "4.5px",
                  ml: 2,
                  opacity: 0.9,
                  mr: "4px",
                }}
              >
                Notification name is already in use
              </Typography>
            )}



          </Stack>
        </ThemeProvider>
      </Box>
    </Box>
  );
};

export default NotificationTitle;
