import React, { useEffect, useState } from "react";
import {
  Typography,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Modal,
  Box,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  MenuItem, Select, FormControl, InputLabel
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import editCustomerIcon from "../../public/assets/icons/icons/editCustomer.png";
import DeleteCustomerConfirmation from "./DeleteCustomerConfirmation";
import { is } from "date-fns/locale";
import { useAppContext } from "src/context/AppContext";
import { useCustomersContext } from "../../context/CustomersContext"; // Import the useCustomersContext hook
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import DeleteDialog from "./DeleteDialog";
import DeleteIcon from "../../public/assets/icons/icons/deleteRed.png";
import { useWidgetContext } from "src/context/WidgetContext";
import { useAuth } from "src/context/AuthContext.js"; // Assuming subUserData is coming from this context

const CustomerEditModal = ({ open, onClose, customer, onUpdate, onDelete, locations, accountData, isButtonDisabled, setIsButtonDisabled }) => {
  const [localCustomer, setLocalCustomer] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleDeleteCustomer, fetchCustomers } = useCustomersContext(); // Import the handleDeleteCustomer function from the CustomersContext
  const { fetchAllWidgetTypes } = useWidgetContext(); // Import the fetchAllWidgetTypes function from the WidgetContext
  const { handleReloadComponents, showSnackBar } = useAppContext(); // Import the handleReloadComponents and showSnackBar functions from the AppContext
  const { subUserData } = useAuth();
  const [isPhoneValid, setIsPhoneValid] = useState(false);
const [locationUpdated, setLocationUpdated] = useState(false);  


  const location_name = subUserData?.location_name
    ? subUserData?.location_name
    : "";

  


  
    useEffect(() => {
      if (!open) {
        setIsDeleteDialogOpen(false);
      }
    }, [open]);



    
    useEffect(() => {
      if (customer) {
        setLocalCustomer({ ...customer });
  

      } else {
        setLocalCustomer(null);
      }
    }, [customer, open]);

  
    const handleUpdateCustomer = async () => {
      setLoading(true);
      await onUpdate(localCustomer);
      setLoading(false);
      onClose();
    };
  
    const handleChange = (field, value) => {
      setLocalCustomer((prev) => ({ ...prev, [field]: value }));
    };
  
    const handleFirstNameChange = (e) => {
      handleChange("first_name", e.target.value || "");
    };
  
    const handleLastNameChange = (e) => {
      handleChange("last_name", e.target.value || "");
    };
  
    const handleSelectChange = (event) => {
      const selectedLocationId = event.target.value;
      const selectedLocation = locations.find(
        (loc) => loc.location_id === selectedLocationId
      );
      setLocationUpdated(true);
      handleChange("location_id", selectedLocationId);
      handleChange("location_name", selectedLocation?.name || "");
    };
  
    const handlePhoneNumberChange = (event) => {
      let input = event.target.value.replace(/\D/g, "").slice(0, 10);
      const formattedPhoneNumber =
        input.length > 6
          ? `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`
          : input.length > 3
          ? `(${input.slice(0, 3)}) ${input.slice(3)}`
          : input.length > 0
          ? `(${input}`
          : "";
  
      handleChange("phone_number", formattedPhoneNumber);
      setIsPhoneValid(input.length === 10);

    };
  
    // useEffect to track validity of data
    useEffect(() => {

    

    
      // Check if first_name, last_name, and phone_number are valid
      const isFirstNameValid = localCustomer?.first_name.trim() !== '';
      const isLastNameValid = localCustomer?.last_name.trim() !== '';
      const isPhoneValid = localCustomer?.phone_number.length >= 14;
    

    
      // Check if the localCustomer values match customer values (no change)
      const firstNameMatch = localCustomer?.first_name.trim() === customer?.first_name.trim();
      const lastNameMatch = localCustomer?.last_name.trim() === customer?.last_name.trim();
      const locationMatch = localCustomer?.location_id === customer?.location_id;
      const phoneMatch = localCustomer?.phone_number === customer?.phone_number;

      // Determine if all fields match (no changes made)
      const isAllMatching = firstNameMatch && lastNameMatch && locationMatch && phoneMatch;
    
      // If all fields match, disable the button (no changes made)
      if (isAllMatching) {
        setIsButtonDisabled(true);  // Disable button if no changes have been made
      } else {
        // If any fields are invalid OR data is changed, disable the button
        if (
          !isFirstNameValid || 
          !isLastNameValid || 
          !isPhoneValid
        ) {
          setIsButtonDisabled(true);  // Disable button if any field is invalid
        } else {
          setIsButtonDisabled(false);  // Enable button if valid and data has changed
        }
      }
    
    }, [localCustomer, customer]); // Dependencies to ensure it runs on relevant changes
    




  const getInitials = () => {
    if (localCustomer && localCustomer.first_name && localCustomer.last_name) {
      const firstNameInitial = localCustomer.first_name[0];
      const lastNameInitial = localCustomer.last_name[0];
      return `${firstNameInitial}${lastNameInitial}`;
    }
    return "";
  };

  const message = `Are you sure you want to delete ${localCustomer ? `${localCustomer.first_name} ${localCustomer.last_name}` : ""}`;

  const handleDelete = async (loading) => {
    try {
      setLoading(true);
      const success = await handleDeleteCustomer(localCustomer.phone_number);
      if (success) {
        onClose();
      } else {
        onClose();
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
    } finally {
      setLoading(false);
      fetchAllWidgetTypes();
      fetchCustomers();
    }
  };







  

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true} // Make the dialog take up the full width
      PaperProps={{ sx: { borderRadius: "8px", maxWidth:'650px', minWidth:'650px' } }}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 4,
          top: 7,
          color: "#7589A2",
          backgroundColor: "transparent",
          zIndex: 1,
          borderRadius: "12px",
          width: "35px",
          height: "35px",
          padding: "3px",
          mx: 0.5,
          my: 0.25,
        }}
      >
        <CloseIcon sx={{ fontSize: "28px" }} />
      </IconButton>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          height: "60px",
          backgroundColor: "#FFFFFF",
          p:2.25,
          color: "#374247",
          mt:.5,
        }}
      >
        <img
          src={editCustomerIcon}
          alt="editCustomerIcon"
          style={{
            border: "1px solid #B0BEC5",
            marginLeft: "7px",
            width: "auto",
            height: "31px",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            padding: "3px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        />
      </DialogTitle>

      <DialogContent
        sx={{
          width: "100%",
          backgroundColor: "#FFFFFF",

          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          zIndex: 0,
        }}
      >
        <Typography
          sx={{ color: "#48768C", fontSize: "21px", mb: 3, fontWeight: 600, pl:.25 }}
        >
          Edit Customer{" "}
        </Typography>
        <Box
          sx={{
            mb: 2,
            border: "1.25px solid rgba(173, 216, 230, 0.7)",
            borderRadius: "15px",
            backgroundColor: "rgba(191, 218, 231, 0.1)",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "6px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              onClick={() => {
                setIsDeleteDialogOpen(true);
              }}
            >
              <Box sx={{}}>
                <img
                  src={DeleteIcon}
                  alt="detailsIcon"
                  style={{
                    border: "1px solid #8C435036",
                    width: "auto",
                    height: "32px",
                    backgroundColor: "#8C435036",
                    borderRadius: "6px",
                    padding: "1px",
                    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
                    marginBottom: "5px",
                  }}
                />
              </Box>
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              mb: 1,
            }}
          ></Box>
          <Avatar
            sx={{
              ml: 2,
              backgroundColor: "rgba(8, 19, 42, 0.7)",
              width: 55,
              height: 55,
              mb: 2,
              mr: 2,
              mt: -5,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography sx={{ fontSize: "22px" }}>{getInitials()}</Typography>
          </Avatar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
              mb: 0,
              px: 2,
              ml:.2
            }}
          >
            <TextField
              placeholder="First Name"
              variant="standard"
              helperText="First Name"
              fullWidth
              value={localCustomer ? localCustomer.first_name || "" : ""}
              onChange={handleFirstNameChange }

              sx={{
                ml: 1,
                color: "#48768C",
                pb: 0,
                mr: 2,
                "& .MuiInput-underline": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#2F5C71", // onHover border color
                  },
                  "&.Mui-focused:before": {
                    borderBottomColor: "#2F5C71", // onFocus border color
                  },
                  "&.Mui-focused:after": {
                    borderBottomColor: "#48768CBA", // Focused border color (with transparency)
                    borderWidth: "2px", // Optional: Adjust border width for focus state
                  },
                },
                "& input": {
                  width: "100%",
                  textAlign: "left",
                  color: "#48768C",
                },
              }}
            />

            <TextField
              placeholder="Last Name"
              variant="standard"
              helperText="Last Name"
              fullWidth
              value={localCustomer ? localCustomer.last_name || "" : ""}
              onChange={handleLastNameChange}

              sx={{
                color: "#48768C",
                pb: 0,
                "& .MuiInput-underline": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "#2F5C71", // onHover border color
                  },
                  "&.Mui-focused:before": {
                    borderBottomColor: "#2F5C71", // onFocus border color
                  },
                  "&.Mui-focused:after": {
                    borderBottomColor: "#48768CBA", // Focused border color (with transparency)
                    borderWidth: "2px", // Optional: Adjust border width for focus state
                  },
                },
                "& input": {
                  width: "100%",
                  textAlign: "left",
                  color: "#48768C",
                },
              }}
            />
          </Box>
          


          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 0,
              px: 2,
            }}
          >
            
            <TextField
              placeholder="Phone Number"
              variant="standard"
              helperText="Phone Number"
              fullWidth
              value={localCustomer ? localCustomer.phone_number || "" : ""}
              onChange={handlePhoneNumberChange}
              sx={{
                ml: 1,
                mt:1.5,
                color: "#48768C",
            
                "& input": {
                  color: "#48768C",

                  width: "100%",
                  textAlign: "left",
                },
              }}
            />
          </Box>
          {!accountData?.single_location && (
          <FormControl
  variant="outlined"
  size="small"
  sx={{
    flex: 1,
    width: '99%',
    px: .3,
    mt: .8,
    ml: 1,
    pr: '9px',
    color: "#82879B",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "none",
      borderRadius: "1px",
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      ml: "13px",
      mr: '11px',
    },
  }}
>
<Select
  value={localCustomer ? localCustomer.location_id || "" : ""}
  onChange={handleSelectChange}
  sx={{
    color: "#48768C",
    borderRadius: "5px",
    width: "100%",
    alignItems: 'left',
    display: "flex",
    justifyContent: "space-between",
    lineHeight: "-2.5em",
    padding: 0,
    height: '30px',
  }}
  MenuProps={{
    PaperProps: {
      style: {
        maxHeight: 300, // Adjust the height here
        overflowY: "auto", // Allows scrolling if content exceeds maxHeight
        alignItems: 'left',
      },
    },
    anchorOrigin: { vertical: "bottom", horizontal: "left" },
    transformOrigin: { vertical: "top", horizontal: "left" },
  }}
>
  {locations.map((location) => (
    <MenuItem key={location.location_id} value={location.location_id} sx={{ backgroundColor: "#FFFFFF", color: "#48768C", borderRadius: "5px", alignItems: 'left', pl: 2 }}>
      {location.name}
    </MenuItem>
  ))}
</Select>

  <Typography
    sx={{
      alignItems: 'left',
      pl: .5,
      mt: .15,
      fontSize: '0.75rem',
      color: "#637381",
      lineHeight: "1.5",
      fontFamily: "Source Sans Pro, sans-serif",
      fontWeight: "400",
      textAlign: "left",
      mt: "3px",
      mr: "0",
      mb: "0",
      ml: "10px",
    }}
  >
    Location
  </Typography>

</FormControl>
)}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              backgroundColor: "transparent",
              height: "auto",
              my: 1,
              mt:4,
              mb:2
            }}
          >
            <SecondaryButton
              disabled={ isButtonDisabled }
              sx={{
                fontSize: "17px",
                width: "95%",
                mb: 1,
              }}
              onClick={handleUpdateCustomer}
            >
              {loading ? "Saving..." : "Save Changes"}
            </SecondaryButton>
          </Box>
        </Box>
      </DialogContent>
      <DeleteDialog
        open={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        onConfirm={handleDelete}
        handleOpenReviewDialog={handleDelete}
        header="Confirm Delete Customer"
        message={message}
        subText="This will not remove any notifications you sent to this customer from your notification history."
        buttonText="Delete Customer"
        hideReviewDialog={true}
        buttonLoadingText="Deleting..."
        isLoading={loading}
      />
    </Dialog>
  );
};

export default CustomerEditModal;
