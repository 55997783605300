import { useState } from "react";
import { handleSubmit } from "./handleSaveNewFieldData";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext.js";
import { set } from "date-fns";
import ValidateData from "./validateData";
import useColorPicker from "src/components/AccountPage/Sections/UserNotifications/Components/AddNewNotification/components/ColorPicker/useColorPicker.js"; // Import the custom hook


export const useNotificationState = (icon, createNew, setCreateNew) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [notificationTitle, setNotificationTitle] = useState("");
  const [printedFieldTitle, setPrintedFieldTitle] = useState(
    "Create New Notification Type",
  );
  const [requiredFieldName, setRequiredFieldName] = useState("");
  const [requiredFieldDataSaved, setRequiredFieldDataSaved] = useState(false);
  const [optionalFieldDataSaved, setOptionalFieldDataSaved] = useState(false);
  const [optionalFieldName, setOptionalFieldName] = useState("");
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [optionalIcon, setOptionalIcon] = useState(null);
  const [
    optionalSelectedNotificationType,
    setOptionalSelectedNotificationType,
  ] = useState(null);
  const [selectedNotificationType, setSelectedNotificationType] =
    useState(null);
  const [saveData, setSaveData] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [isTitleDefault, setIsTitleDefault] = useState(true);
  const { validateNotificationTitle } = ValidateData();
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [contextMessage, setContextMessage] = useState('')
  const [requiredContextLabel, setRequiredContextLabel] = useState('')
  const [optionalContextLabel, setOptionalContextLabel] = useState('')
  const [isDefault, setIsDefault] = useState(false)
  const [twoWayEnabled, setTwoWayEnabled] = useState(false);  
  const [showRequiredContextLabel, setShowRequiredContextLabel] = useState(false)
const [showOptionalContextLabel, setShowOptionalContextLabel] = useState(false)





  

  //New Notification Title

  const handleClearTitle = () => {
    setNotificationTitle("");
    setIsTitleDefault(true);
    setPrintedFieldTitle("");
    setIsTitleValid(true);

  };

  const handlePrintFieldTitle = async () => {
    // Call the validateNotificationTitle function first
    const isValid = await validateNotificationTitle(notificationTitle);

    // If the notification title is not already in use (i.e., it's valid), update the state
    if (!isValid) {
      setPrintedFieldTitle(notificationTitle);
      setIsTitleDefault(false); // Or set it to the desired state
      setIsTitleValid(true);
    } else {
      setIsTitleValid(false);
    }
  };

  //Save the field on Enter key press
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Logic to save field on Enter key press
    }
  };

  // Setters for the fields
  const handleSetTitle = (newValue) => {
    setNotificationTitle(newValue);
    if (!isTitleValid) {
      setIsTitleValid(true);
    }
  };

  const handleSetRequiredFieldName = (newValue) => {
    setRequiredFieldName(newValue);
  };

  const handleCleaRequiredFieldName = () => {
    setRequiredFieldName("");
    setSelectedIcon(null);
  };

  const handleSelectedIcon = (icon) => {
    setSelectedIcon(icon); // Set selected icon state
  };

  const handleSaveRequiredFieldData = () => {
    setRequiredFieldDataSaved(true);
  };

  const handleSelectFieldType = (newValue) => {
    setSelectedNotificationType(newValue);
  };

  const handleClearRequiredFieldData = () => {
    setRequiredFieldName("");
    setSelectedIcon(null);
    setSelectedNotificationType(null);
    setRequiredFieldDataSaved(false);
    setShowRequiredContextLabel(false);
    setRequiredContextLabel('');
  };

  const handleSetRequiredContextLabel = (newValue) => {
    setRequiredContextLabel(newValue)
  }

  //Optional Field Data

  const handleSetOptionalFieldName = (newValue) => {
    setOptionalFieldName(newValue);
  };

  const handleSelectedOptionalIcon = (icon) => {
    setOptionalIcon(icon); // Set selected icon state
  };

  const handleSelectOptionalFieldType = (newValue) => {
    setOptionalSelectedNotificationType(newValue);
  };

  const handleClearOptionalFieldName = () => {
    setOptionalFieldName("");
    setOptionalIcon(null);
  };

  const handleClearOptionalFieldData = () => {
    setOptionalFieldName("");
    setOptionalIcon(null);
    setOptionalSelectedNotificationType(null);
    setOptionalFieldDataSaved(false);
    setOptionalContextLabel('');
    setShowOptionalContextLabel(false);

  };

  const handleSaveOptionalFieldData = () => {
    setOptionalFieldDataSaved(true);
  };

  const handleSetOptionalContextLabel = (newValue) => {
    setOptionalContextLabel(newValue)
  }


  const handleSetContextMessage = (newValue) => {
    setContextMessage(newValue);
  }
  

  const handleResetFields = () => {
    setNotificationTitle("");
    setIsTitleDefault(true);
    setPrintedFieldTitle("");
    setIsTitleValid(true);

    
    handleClearRequiredFieldData();
    handleClearOptionalFieldData();
    setContextMessage('');
    setRequiredContextLabel('');
    setOptionalContextLabel('');
  }







  const toggleIsDefault = () => {
    setIsDefault((prev) => !prev);
  };

  const toggleTwayWayEnabled = () => {
    setTwoWayEnabled((prev) => !prev);
  };


  const handleSaveNewFieldData = async (titleColor, handleCloseModal, accountData, fetchOnboardingRecord) => {
    await handleSubmit(
      notificationTitle,
      requiredFieldName,
      selectedIcon,
      selectedNotificationType,
      optionalFieldName,
      optionalIcon,
      optionalSelectedNotificationType,
      contextMessage,
      requiredContextLabel,
      optionalContextLabel,
      isDefault,
      user,
      apiUrl,
      getAccessTokenSilently,
      handleReloadComponents,
      showSnackBar,
      setIsLoading,
      titleColor,
      twoWayEnabled, 
      handleCloseModal,
      accountData, 
      fetchOnboardingRecord 
    );
  };

  // Return the state and handler functions
  return {
    handleKeyDown,
    notificationTitle,
    requiredFieldName,
    optionalFieldName,
    handleCleaRequiredFieldName,
    handleSetTitle,
    handleSetRequiredFieldName,
    handleSetOptionalFieldName,
    handleSelectedIcon,
    selectedIcon,
    setSelectedIcon,
    handleSelectedOptionalIcon,
    handleSaveRequiredFieldData,
    requiredFieldDataSaved,
    handleSelectFieldType,
    selectedNotificationType,
    handleSaveNewFieldData,
    handleClearRequiredFieldData,
    handleSelectOptionalFieldType,
    handleClearOptionalFieldName,
    handleClearOptionalFieldData,
    handleSaveOptionalFieldData,
    optionalIcon,
    optionalSelectedNotificationType,
    setOptionalIcon,
    optionalFieldDataSaved,
    setOptionalSelectedNotificationType,
    handleClearTitle,
    handlePrintFieldTitle,
    isTitleDefault,
    printedFieldTitle,
    isTitleValid,
    setIsTitleValid,
    contextMessage, 
    setContextMessage,
    requiredContextLabel, 
    setRequiredContextLabel,
    optionalContextLabel, 
    setOptionalContextLabel,
    handleSetRequiredContextLabel,
    handleSetOptionalContextLabel,
    handleSetContextMessage,
    handleResetFields,
    setNotificationTitle,
    setIsDefault, 
    isDefault,
    toggleIsDefault,
    toggleTwayWayEnabled,
    twoWayEnabled,
    setTwoWayEnabled,
    setShowOptionalContextLabel,
    showOptionalContextLabel,
    setShowRequiredContextLabel,
    showRequiredContextLabel,

  };
};
