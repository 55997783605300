import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { useAuth } from "../../../../context/AuthContext.js"; // Assuming subUserData is coming from this context
import MultiLocationUpgradeStepper from "./MultiLocationUpgradeStepper.js";
import { useAccountContext } from "src/context/AccountContext";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const MultiLocationUpgrade = ({
  multiLocationUpgradeDialogOpen,
  handleMultiLocationUpgradeDialogClose,
  users,
  setMultiLocationUpgradeDialogOpen,
  isDialogClosing,
}) => {
  const { accountData } = useAccountContext();

  const {
    subUserData,
    username,
    logout: customLogout,
    resetContext,
  } = useAuth();
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const navigate = useNavigate(); // Initialize the hook
  const [upgradeComplete, setUpgradeComplete] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    resetContext();
    navigate("/");
    window.location.reload();
  };

  const handleConditionalClose = () => {
    // If upgrade is complete, run handleLogout; if not loading, close the dialog
    if (upgradeComplete) {
      window.location.reload(); // Reload the page to trigger context refetch
    } else if (!isLoading) {
      handleMultiLocationUpgradeDialogClose();
    }
  };

  return (
    <Dialog
      open={multiLocationUpgradeDialogOpen}
      onClose={handleConditionalClose}
      PaperProps={{
        sx: { borderRadius: "20px", height: "auto", overflowX: "hidden", minWidth:'615px' },
      }}
    >
      <DialogContent
        sx={{
          justifyContent: "center",
          textAlign: "center",
          display: "flex",
          width: "100%",
          alignItems: "center",
          backgroundColor: "rgba(191, 218, 231, 0.2)",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleConditionalClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 7,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "35px",
            height: "35px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon sx={{ fontSize: "28px" }} />
        </IconButton>

        {subUserData?.is_admin ? (
          <MultiLocationUpgradeStepper
            users={users}
            subUserData={subUserData}
            setMultiLocationUpgradeDialogOpen={
              setMultiLocationUpgradeDialogOpen
            }
            isDialogClosing={isDialogClosing}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setUpgradeComplete={setUpgradeComplete}
            upgradeComplete={upgradeComplete}
            handleLogout={handleLogout}
            handleConditionalClose={handleConditionalClose}
          />
        ) : (
          <Typography
            sx={{
              color: "#48768C",
              fontSize: 15,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Only account administrators can handle account upgrades. Please
            contact your administrator to add more locations.
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "rgba(191, 218, 231, 0.2)" }}>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        ></Box>
      </DialogActions>
    </Dialog>
  );
};

export default MultiLocationUpgrade;
