export const handleCompleteSetup = async (
  user,
  getAccessTokenSilently,
  accountData,
  fetchAccountData,
  fetchLatestSingleUserData,
  fetchLatestUserData,
) => {
  if (user && user.sub) {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${apiUrl}/setup/complete_setup`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Add the correct Content-Type
        },
        body: JSON.stringify({ auth0_user: user.sub }), // Send the user.sub in the body
      });
      if (response.ok) {
        const data = await response.json();
        await fetchAccountData(user.sub);
        if (accountData && accountData.single_user === true) {
          await fetchLatestSingleUserData();
        } else if (accountData && accountData.single_user === false) {
          await fetchLatestUserData();
        }
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
};
