import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
  Stack,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppContext } from "src/context/AppContext";
import SupportIcon from "../../../../public/assets/icons/icons/support.png";
import styled from "@mui/system/styled";
import {
  PrimaryButton,
  CancelButton,
  SecondaryButton,
} from "src/components/Buttons/ModalButtons";
import AffectedAreasForm from "./Forms/CategorySelect";
import BugDescriptionForm from "./Forms/MessageForm";
import StepsToReproduceForm from "./Forms/ContactForm";
import BugReportStepper from "./CustomerServiceStepper.js";
import {handleSubmit} from "./hooks/handleSubmit.js"


const apiUrl = process.env.REACT_APP_API_URL;



const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#E9EDF2",
  marginBottom: theme.spacing(2),
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -1.5px) scale(0.75)",
    paddingLeft: "7px", // Adjust the translateY value as needed
  },
}));

const SeverityFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 400,
  marginBottom: theme.spacing(2),
  position: "relative",
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -3.5px) scale(0.79)",
    paddingLeft: "10px",
    paddingTop: "5px", // Adjust the translateY value as needed
  },
}));

const CustomerServiceDialog = ({ isOpen, onClose, onSubmit, activeStep, setActiveStep }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { handleReloadComponents, showSnackBar } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);


  const [ticketDetails, setTicketDetails] = useState({
    category: "",
    description: "",
    UserId: user.sub,
    firstName: "",
    lastName: "",
    email: "",
  });



  useEffect(() => {
    if (isOpen) {

      setTicketDetails({
        category: "",
        description: "",
        firstName: "",
        lastName: "",
        email: ""
      });
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTicketDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to submit a bug report
  const handleFormSubmit = async (e) => {
    const userId = user.sub
    e.preventDefault();
    setIsLoading(true)

    // Call the imported handleSubmit function
    const result = await handleSubmit(ticketDetails, getAccessTokenSilently, userId);

    if (result.success) {
      showSnackBar("Customer service request submitted", true);
      setActiveStep(4)
      setIsLoading(false)

    } else {
      console.error("Error creating service ticket:", result.error);
      showSnackBar("We ran into an issue. Please try again", false);
      setIsLoading(false)
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>


<Box
  sx={{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '50vw',
    // Ensures it doesn't shrink too much
    minWidth: "800px", // Prevents it from getting too large
    height: "auto", // Adapts to content but can be adjusted
    bgcolor: "background.paper",
    boxShadow: 24,
    pt:0,


px:3,
    borderRadius: "12px",
  }}
>
      

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 6,
            color: "#7589A2",
            backgroundColor: "transparent",
            zIndex: 1,
            borderRadius: "12px",
            width: "37px",
            height: "37px",
            padding: "3px",
            mx: 0.5,
            my: 0.25,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "90px",
            backgroundColor: "#FFFFFF",
            color: "#374247",
          }}
        >
          <Stack
            direction="row"
            sx={{
              width: "30%",
      display: "flex",
      alignItems: "flex-start", // Align to the top
      justifyContent: "flex-start", // Align to the top
      px: 1,
      mt:-1

         
       
            }}
          >
  
            <Typography
              sx={{ fontSize: '1.32rem', fontWeight: 600, marginRight: "auto", ml: .85, letterSpacing:'-0.034em', lineHeight: '1em', alignSelf: 'center',    color: "#48768C",
              }}
            >
              Customer Service
            </Typography>
          </Stack>
        </Box>
      
  <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', overflowY:'auto', width:'100%', mt:-1}}>
<BugReportStepper
ticketDetails={ticketDetails}
handleChange={handleChange}
handleSubmit={handleSubmit}
onClose={onClose}
isLoading={isLoading}
setTicketDetails={setTicketDetails}
handleFormSubmit={handleFormSubmit}
activeStep={activeStep}
setActiveStep={setActiveStep}

/>



  </Box>
     </Box>
      </Modal>
  );
};

export default CustomerServiceDialog;
