export const handleUpdateContactInfo = async (
  editData,
  getAccessTokenSilently,
  userId,
  customerId,
  fetchAccountData,
) => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL;
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(`${apiUrl}/api/companies/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ ...editData, userId, customerId }), // Include userId in the body
    });

    const responseData = await response.json();
    if (response.ok) {
      await fetchAccountData(userId); // Update the account data in the parent component
    }
    if (!response.ok) {
      throw new Error(responseData.message || "Failed to update contact info");
    }

    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Rethrow to handle it in the calling function
  }
};
