import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Button,
  Box,
  Modal,
  IconButton,
  Skeleton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Fade,
} from "@mui/material";
import EmailIcon from 'src/public/assets/icons/icons/email2.png';



//Pass icons as props to render different icons 
//Set accept to true to render confirm dialog with accept icon
//Set accept to false to render confirm dialog with decline icon

const ConfirmDialog = ({
  open,
  onClose,
  onCancel,
  confirmHeader,
  confirmMessage,
  confirmMessageSubText,    
  buttonText,
  buttonAction,

  confirmButtonText,
  confirmMessageSubTextTwo,
  accept,
  acceptIcon,
declineIcon,
isLoading,
buttonLoadingText,

}) => {




  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
      transitionDuration={{ enter: 300, exit: 300 }}
      PaperProps={{
        style: {
          backgroundColor: "#747C86",
          width: "200px",
          border: "1px solid rgba(173, 216, 230, 0.7)",
          minWidth: "475px",
          borderRadius: "10px",
          bottom: "5%",
          maxWidth: "475px",
        },
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          padding: 3,
          color: "#374247",
          alignItems: "center",
          pt:4
          
        }}
      >
<Box
  sx={{
    border: "transparent",
    width: "auto",
    height: "45px",
    backgroundColor: "#d5f2ea",
    borderRadius: "6px",
    padding: "1px",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.09)",
    marginBottom: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#2A8E7A",
  }}
>
  {/* Render the acceptIcon correctly */}
  <img src={EmailIcon} alt="accept" style={{ width: "45px", height: "45px" }} />
</Box>

        
        <Typography
          sx={{
            fontSize: "18.5px",
            fontWeight: 550,
            color: "#48768C",
            letterSpacing: "-.02em",
            lineHeight: "-2",
            mt: 1.5,
            whiteSpace: "noWrap",
            mb: 0.5,
            textAlign: "center", // Center the wrapped text
            p:1
          }}
        >
         Your password reset link has been sent to your email.
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          mt: -1,
          fontWeight: 600,
          letterSpacing: "0.02em",
          backgroundColor: "#FFFFFF",
          color: "#48768C",
          padding: ".75rem",
          px:2,
          pb:2.5

        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(191, 218, 231, 0.1)",
            border: "1px solid rgba(173, 216, 230, 0.7)",
            borderRadius: "10px",
            fontSize: 16,
            padding: ".75rem",
            pt: 3,
            px: 1.5,
          }}
        >
          <Typography sx={{       fontSize: "17px",
                      fontWeight: 550,
                      color: "#5e7f94",
                      letterSpacing: "-.02em",
                      lineHeight: "-2",
                      wordWrap: "break-word", // Allow breaking long words
                      overflowWrap: "break-word", // Ensures words can break and prevent overflow
                      whiteSpace: "normal", // Prevent text from staying in a single line
                      textAlign: "center", // Center the wrapped text
                      p:1

                      
                      
                      }}>
           Follow the instructions in the email to reset your password securely.
          </Typography>
          <Typography
            sx={{
                fontSize: "15.25px",
                fontWeight: 550,
                color: "#5e7f94",
                letterSpacing: "-.02em",
                lineHeight: "-2",
                mt: 2,
                wordWrap: "break-word", // Allow breaking long words
                overflowWrap: "break-word", // Ensures words can break and prevent overflow
                whiteSpace: "normal", // Prevent text from staying in a single line
                                      textAlign: "center", // Center the wrapped text

            }}
          >
          Once your password is reset, all users will be logged out and will need to sign in again.
          </Typography>
        

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              mt: 3,
              pt: 1,
              pb: 1,
            }}
          >
            <Stack
              direction="column"
              spacing={3}
              sx={{ width: "100%", display: "flex" }}
            >
            
            <Button
  onClick={onClose}
  sx={{
    marginRight: "10px",
    border: "2px solid #A4C5D2E8",
    borderRadius: "10px",
    padding: "8px",
    width: "100%",
    fontSize: "17px",
    fontWeight: 550,
    color: "#5e7f94",
    letterSpacing: "-.02em",
    lineHeight: "-2",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add this line for shadow
    "&:hover": { backgroundColor: "#48768C12" },
    "&:active": { backgroundColor: "#DBEAF0" },
  }}
  color="primary"
>
  Close
</Button>
            </Stack>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
