import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

// Define the status-to-message mapping
const statusMessages = {
  Sent: "Notification delivered",
  Failed: "Notification could not be delivered",
  Scheduled: "Notification scheduled for delivery",
  queued: "Notification sending....",
  PENDING_APPROVAL: (
    <>
      Two-way not enabled for this notification
      <br />
      Update this status by selecting 'Approved' or 'Declined'.
    </>
  ),
};

const LightTooltip = styled(({ className, error, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, error }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F2F9FF",
    color: error ? "#894d59" : "#48768C", // Change text color dynamically
    boxShadow: theme.shadows[1],
    fontSize: 13.5,
    textAlign: "center", // Center-align the text
    maxWidth: "190px", // Optional: Adjust width for better readability
    border:'.5px solid #c2d7df'
  },
}));

const TooltipWrapper = ({ children, status, errorMessage }) => {
  let tooltipTitle = "Unknown status";

  if (errorMessage) {
    tooltipTitle = errorMessage; // Display error message if available
  } else if (status === null) {
    tooltipTitle = "No pending approvals";
  } else if (typeof status === "string" && /^\d+$/.test(status)) {
    tooltipTitle = "Waiting on response..";
  } else if (statusMessages[status]) {
    tooltipTitle = statusMessages[status];
  }

  return (
    <LightTooltip title={tooltipTitle} error={!!errorMessage}>
      <Box display="inline-flex">{children}</Box>
    </LightTooltip>
  );
};

export default TooltipWrapper;
