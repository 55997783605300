import { useEffect, useState } from "react";
import { Typography, TextField, InputAdornment, Stack } from "@mui/material";

const MessageForm = ({ ticketDetails, handleChange }) => {
  const [charCount, setCharCount] = useState(ticketDetails.description.length);
  const charLimit = 500;
  const warningThreshold = 25; // Show warning when 50 characters are left

  useEffect(() => {
    setCharCount(ticketDetails.description.length);
  }, [ticketDetails.description]);

  return (
    <>
      <Stack spacing={0}>
        <Typography
          sx={{
            fontSize: "1.29rem",
            fontWeight: 600,
            width: "100%",
            letterSpacing: "-0.03em",
            color: "#48768c",
            lineHeight: "-.02em",
          }}
        >
          What can we help with?
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 600,
            width: "100%",
            letterSpacing: "-0.029em",
            color: "#6c90a2",
            mb: 3,
          }}
        >
          Feel free to be as detailed as needed. The more information we have,
          the better.
        </Typography>
      </Stack>

      <TextField
        sx={{
          marginBottom: 4.75,
          border: "none",
          "& textarea": {
            minHeight: "50px",
            pb:2
          },
          "& .MuiOutlinedInput-root": {
            border: "none",
            "&:hover": {
              borderColor: "none",
            },
          },
        }}
        placeholder="Let us know what we can help with!"
        name="description"
        value={ticketDetails.description}
        onChange={handleChange}
        multiline
        fullWidth
        required
        rows={1}
        inputProps={{
          maxLength: charLimit, // Prevents more than 500 characters
        }}
        InputProps={{
          endAdornment: (
            <>
              {charCount > 0 && charCount < 20 && (
                <InputAdornment
                  position="end"
                  sx={{
                    fontSize: "0.75rem",
                    color: "#d32f2f",
                    position: "absolute",
                    bottom: "15px",
                    right: "10px",
                  }}
                >
                  <Typography color="textSecondary" sx={{ fontSize: ".93rem", fontWeight: 600 }}>
                    Your message needs to be at least 20 characters.
                  </Typography>
                </InputAdornment>
              )}

              {charCount >= charLimit - warningThreshold && (
                <InputAdornment
                  position="end"
                  sx={{
                    fontSize: "0.75rem",
                    color: charCount >= charLimit ? "#d32f2f" : "#f57c00",
                    position: "absolute",
                    bottom: "15px",
                    right: "10px",
                    mt:10
                  }}
                >
                  <Typography  sx={{ fontSize: ".9rem", fontWeight: 600, color:'#8C4350', pt:'10px' }}>
                    {charLimit - charCount} / 500
                  </Typography>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </>
  );
};

export default MessageForm;