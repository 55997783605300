import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";

const NewPreviewNotification = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Title Here</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Content Goes Here</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
         Cancel
        </Button>
        <Button  color="primary" variant="contained">
         Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewPreviewNotification;